import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Tooltip, Typography } from "@mui/material";
import QuotesCard from "./QuotesCard";
import BlurQuotesCard from "./BlurQuotesCard";
import QuotesEmptyState from "./QuotesEmptyState";
import { AppState, authSelectors, dealSelector } from "../../state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { makeStyles } from "@mui/styles";
import { hotjar } from "react-hotjar";
import { PopupCTAUnpaidLenderModal } from "../modals/PopupCTAUnpaidLenderModal";
import { useMemoUserUnsecuredJwtCTA } from "../../hooks/CTA/useMemoUserUnsecuredJwtCTA";

export default function QuotesTab() {
  const activeDeal = useSelector(dealSelector.activeDeal);
  const competingQuotes = useSelector(dealSelector.competingQuotes);
  const [showEmptyState, setShowEmptyState] = useState(true);
  const authUser = useSelector((state: AppState) => state.auth.user);
  const user = useSelector(authSelectors.userInfo);
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleOnClickCTA = () => {
    setIsModalOpen(true);
  };
  const keysToExclude = useMemo(
    () => [
      "isAdmin",
      "gpPaid",
      "isLender",
      "companyId",
      "companyName",
      "lenderPaid",
    ],
    [],
  );
  const [CTA] = useMemoUserUnsecuredJwtCTA(
    user,
    keysToExclude,
    "LENDER_PORTAL_COMPETING_QUOTES",
  );

  const useStyles = useMemo(() => {
    return makeStyles((theme) => ({
      arrow: {
        color: "#03363D",
      },
      tooltip: {
        backgroundColor: "#03363D",
        display: "flex",
        flexDirection: "row",
        width: "hug(163px)",
        height: "hug(64px)",
        alignItems: "flex-end",
        justifyContent: "center",
        padding: "14px",
      },
    }));
  }, []);

  const arrowStyles = useStyles();

  useEffect(() => {
    if (activeDeal.quote_status === 2 || competingQuotes.length > 0) {
      setShowEmptyState(false);
    } else {
      setShowEmptyState(true);
    }
  }, [activeDeal.quote_status, competingQuotes]);

  return (
    <>
      {showEmptyState ? (
        <QuotesEmptyState />
      ) : (
        <>
          {activeDeal && activeDeal.quote_status === 2 && (
            <>
              <Typography variant="body1" sx={{ fontWeight: 500 }}>
                My quote
              </Typography>
              <QuotesCard data={activeDeal} canEdit={!activeDeal.is_rewarded} />
            </>
          )}

          {!authUser?.isPremiumLender
            ? competingQuotes.length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Competing Quotes
                    <Tooltip
                      classes={arrowStyles}
                      title={
                        isModalOpen ? (
                          ""
                        ) : (
                          <Typography
                            color={"#FFFFFF"}
                            style={{ fontSize: "12px" }}
                          >
                            Become a member and unlock competing quotes
                          </Typography>
                        )
                      }
                      arrow
                      placement="right"
                    >
                      <span>
                        <FontAwesomeIcon
                          color="#22AA94"
                          height={22}
                          width={14}
                          icon={
                            isHovered ? regular("lock-open") : regular("lock")
                          }
                          style={{ marginLeft: 5, cursor: "pointer" }}
                          onMouseEnter={handleMouseEnter}
                          onMouseLeave={handleMouseLeave}
                          onClick={() => {
                            hotjar.event(
                              `Lender non-member clicked Become a member on Competitive Quotes in the Deal`,
                            );
                            handleOnClickCTA();
                            setIsModalOpen(true);
                          }}
                        />
                        {isModalOpen && (
                          <PopupCTAUnpaidLenderModal
                            url={CTA.url}
                            token={CTA.token}
                            closePopupCallback={setIsModalOpen}
                          />
                        )}
                      </span>
                    </Tooltip>
                  </Typography>
                  {competingQuotes.map((obj) => (
                    <BlurQuotesCard key={obj.id} />
                  ))}
                </>
              )
            : competingQuotes.length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    Competing Quotes
                  </Typography>
                  {competingQuotes.map((obj) => (
                    <QuotesCard data={obj} key={obj.id} />
                  ))}
                </>
              )}
        </>
      )}
    </>
  );
}
