import { Select, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export function YearsMonthsDropdown(props: any) {
  const { value, onChange } = props;
  return (
    <Select
      disableUnderline
      variant="standard"
      IconComponent={KeyboardArrowDownIcon}
      onChange={onChange}
      label={value}
      value={value}
      sx={{
        ".MuiSelect-select": {
          fontSize: "14px",
          paddingLeft: "15px",
          "&:focus": {
            backgroundColor: "transparent",
          },
        },
        svg: {
          fontSize: "24px",
          marginRight: "5px",
        },
      }}
    >
      <MenuItem value={false as any}>Months</MenuItem>
      <MenuItem value={true as any}>Years</MenuItem>
    </Select>
  );
}
