import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import QuotesTab from "./QuotesTab";
import WriteUpCard from "./WriteUpCard";
import DocumentTab from "./DocumentTab";
import { useDispatch, useSelector } from "react-redux";
import { dealActions, dealSelector } from "../../state";

export default function DealsDetailsTab() {
  const [value, setValue] = useState("1");
  const dispatch = useDispatch();
  const activeDeal = useSelector(dealSelector.activeDeal);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (activeDeal?.id) {
      dispatch(dealActions.getDealCompetingQuotes(activeDeal.id));
      dispatch(dealActions.getDealDocuments(activeDeal.id, activeDeal.deal_id));
    }
  }, [dispatch, activeDeal?.id, activeDeal?.deal_id]);

  return (
    <TabContext value={value}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <TabList
          onChange={handleChange}
          aria-label="Quotes and other opportunity tabs"
        >
          <Tab label="Quotes" value="1" />
          <Tab label="Write up" value="2" />
          <Tab label="Documents" value="3" />
        </TabList>
      </Box>
      <TabPanel value="1">
        <QuotesTab />
      </TabPanel>
      <TabPanel value="2">
        <WriteUpCard />
      </TabPanel>
      <TabPanel value="3">
        <DocumentTab />
      </TabPanel>
    </TabContext>
  );
}
