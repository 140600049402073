import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Grid, Tab, Tooltip, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EmptyState from "../../components/Admin/EmptyState";
import MyOpportunitiesGrid from "../../components/Admin/MyOpportunitiesGrid";
import OtherOpportunitiesGrid from "../../components/Admin/OtherOpportunitiesGrid";
import OtherDealsInfoBanner from "../../components/Admin/OtherDealsInfoBanner";
import { FiltersDrawer } from "../../components/modals/FiltersModal";
import { MainPages } from "../../pages/main";
import {
  AppState,
  authActions,
  authSelectors,
  dealActions,
  uiActions,
  useAction,
  useDispatch,
} from "../../state";
import { AdminAccessDropdown } from "../../components/Admin/AdminAccessDropdown";
import { hotjar } from "react-hotjar";
import {
  REACT_APP_HOTJAR_HJSV,
  REACT_APP_HOTJAR_ID,
  REACT_APP_AUTH0_CLAIM_DOMAIN,
} from "../../config";
import { useMobile } from "../../themes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { makeStyles } from "@mui/styles";
import { PopupCTAUnpaidLenderModal } from "../../components/modals/PopupCTAUnpaidLenderModal";
import { useMemoUserUnsecuredJwtCTA } from "../../hooks/CTA/useMemoUserUnsecuredJwtCTA";
import { Navigation } from "../../lib";
import Pages from "../../pages";

export const OpportunitiesPage = memo(function OpportunitiesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setTabValue] = useState("1");
  const userFromState = useSelector(authSelectors.userInfo);
  const [isHovered, setIsHovered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const keysToExclude = useMemo(
    () => [
      "isAdmin",
      "gpPaid",
      "isLender",
      "companyId",
      "companyName",
      "lenderPaid",
    ],
    [],
  );
  const [CTA] = useMemoUserUnsecuredJwtCTA(
    userFromState,
    keysToExclude,
    "LENDER_PORTAL_DISCOVER_OTHER_OPPORTUNITIES",
  );

  const useStyles = useMemo(() => {
    return makeStyles(() => ({
      arrow: {
        color: "#03363D",
      },
      tooltip: {
        backgroundColor: "#03363D",
        width: "hug(167px)",
        height: "hug(64px)",
        top: "6px",
        padding: "14px",
      },
    }));
  }, []);

  const arrowStyles = useStyles();
  const { user_deals, other_opportunities } = useSelector(
    (state: AppState) => state.deals,
  );
  const { getAccessTokenSilently, user } = useAuth0();
  const setToken = useAction(authActions.setToken);
  const title = "No opportunities to display";
  const subTitle = "Check in again soon";
  const getToken = useCallback(async () => {
    const token = await getAccessTokenSilently();
    await setToken(token);
  }, [getAccessTokenSilently, setToken]);

  const activeConciergeOriginatorId = useSelector(
    authSelectors.activeConciergeOriginatorId,
  );
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    // newValue is the new value of the tab where 1 is the first tab and 2 is the second tab
    if (newValue === "1") {
      dispatch(uiActions.setFiltersOpen(false));
      dispatch(uiActions.setCurrentTab(newValue));
      navigate(MainPages.opportunities.path);
    } else {
      dispatch(uiActions.setFiltersOpen(false));
      dispatch(uiActions.setCurrentTab(newValue));
      navigate(`${MainPages.opportunities.path}?tabPanel=otherOpportunities`);
    }
    setTabValue(newValue);
    hotjar.event(`Tab ${event.target["innerText"]} Click`);
  };

  useEffect(() => {
    console.log("userFromState", userFromState);
    (async () => {
      if (!userFromState?.isLender) {
        Navigation.go(MainPages.join.path);
      }
    })();
  }, [userFromState]);

  useEffect(() => {
    if (REACT_APP_HOTJAR_ID && REACT_APP_HOTJAR_HJSV) {
      hotjar.initialize(+REACT_APP_HOTJAR_ID, +REACT_APP_HOTJAR_HJSV);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tabPanel = params.get("tabPanel");
    if (tabPanel === "other") {
      dispatch(uiActions.setCurrentTab("2"));
      setTabValue("2");
    } else {
      dispatch(uiActions.setCurrentTab("1"));
      setTabValue("1");
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(dealActions.getUserDeals());
    dispatch(dealActions.getOtherOpportunities());
  }, [dispatch, getToken, activeConciergeOriginatorId]);

  useEffect(() => {
    if (userFromState.loanTrackerId) {
      hotjar.identify(`${userFromState.loanTrackerId}`, {
        loanTrackerId: userFromState.loanTrackerId,
        lenderPaid: userFromState.lenderPaid,
        isLender: userFromState.isLender,
        gpPaid: userFromState.gpPaid,
        email: userFromState.email,
      });
    }
    return () => {
      dispatch(uiActions.setFiltersOpen(false));
    };
  }, [dispatch, userFromState, getToken]);

  const isMobile = useMobile();

  const isPremiumLender =
    user?.[`${REACT_APP_AUTH0_CLAIM_DOMAIN}/isPremiumLender`];
  return (
    <Box
      sx={{
        "& .move-to-active-quotes-btn": {
          padding: "10px 14px !important",
          borderRadius: "4px !important",
          color: "#808E90 !important",
          fontFamily: "ABC Monument Grotesk",
          fontSize: "14px !important",
          fontWeight: "500",
          letterSpacing: "0",
          lineHeight: "14px",
          textAlign: "center",
          backgroundColor: "rgba(162, 172, 186, 0.2)",
          pointerEvents: "none",
          transition: "350ms ease",
          transform: `${isMobile ? "none" : "translateY(-40px)"}`,
          marginTop: `${isMobile ? "10px" : "0"}`,
          marginBottom: `${isMobile ? "60px" : "0"}`,
          "& span": {
            display: "none",
            marginLeft: "5px",
          },

          "&.active": {
            pointerEvents: "all !important",
            color: "#fff !important",
            backgroundColor: "#03363D !important",
            "& span": {
              display: "block",
            },

            ":hover": {
              backgroundColor: "rgb(77, 134, 149)!important",
            },
          },
        },
      }}
    >
      <Grid container justifyContent="space-between" alignItems="center">
        <Typography variant="h2">Opportunities</Typography>
        {userFromState.isAdmin && <AdminAccessDropdown />}
        <OtherDealsInfoBanner value={value} />
      </Grid>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="Quotes and other opportunity tabs"
          >
            <Tab label="Active Quotes" value="1" />
            {!isPremiumLender ? (
              <Tab
                style={{ pointerEvents: "auto" }}
                disabled
                icon={
                  <Tooltip
                    classes={arrowStyles}
                    disableFocusListener
                    disableHoverListener={isModalOpen}
                    // disable the hover over effect when isModalOpen is true
                    title={
                      isModalOpen ? (
                        ""
                      ) : (
                        <Typography
                          color={"#FFFFFF"}
                          style={{
                            fontSize: "12px",
                            width: "200px",
                            textAlign: "center",
                          }}
                        >
                          Upgrade your membership to unlock more opportunities
                        </Typography>
                      )
                    }
                    arrow
                  >
                    <span>
                      <FontAwesomeIcon
                        color={"#22aa94"}
                        height={22}
                        width={14}
                        icon={
                          isHovered ? regular("lock-open") : regular("lock")
                        }
                        style={{ marginLeft: 5, cursor: "pointer" }}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        onClick={() => {
                          hotjar.event(
                            `Lender non-member clicked Become a member on Discover Other Opportunities tab`,
                          );
                          setIsModalOpen(true);
                        }}
                      />
                      {isModalOpen && (
                        <PopupCTAUnpaidLenderModal
                          url={CTA.url}
                          token={CTA.token}
                          closePopupCallback={setIsModalOpen}
                        />
                      )}
                    </span>
                  </Tooltip>
                }
                iconPosition="end"
                label="Discover other opportunities"
                value="2"
              />
            ) : (
              <Tab label="Discover other opportunities" value="2" />
            )}
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ p: 1 }}>
          {user_deals?.length ? (
            <MyOpportunitiesGrid />
          ) : (
            <EmptyState title={title} subTitle={subTitle} />
          )}
        </TabPanel>
        <TabPanel value="2" sx={{ p: 1 }}>
          {other_opportunities?.length ? (
            !isPremiumLender ? (
              <EmptyState title={title} subTitle={subTitle} />
            ) : (
              <OtherOpportunitiesGrid />
            )
          ) : (
            <EmptyState title={title} subTitle={subTitle} />
          )}
        </TabPanel>
      </TabContext>
      <FiltersDrawer />
    </Box>
  );
});
