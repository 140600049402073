import { Typography } from "@mui/material";
import React from "react";

const RequiredAsterisk = () => {
  return (
    <Typography
      color={"error"}
      variant={"caption"}
      component={"span"}
      style={{ fontSize: 20 }}
    >
      {" "}
      *
    </Typography>
  );
};

export default RequiredAsterisk;
