import React, { useEffect } from "react";
import { FormControl, InputLabel } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { YearsMonthsDropdown } from "../../inputs/YearsMonthsDropdown";
import { useSelector } from "react-redux";
import { dealSelector } from "../../../state";
import { QuoteFormNumberFormatter } from "./Formatters/QuoteFormNumberFormatter";

export const InterestOnlyField = ({ isIOYears, setIsIOYears }) => {
  const { setFieldValue }: any = useFormikContext();
  const initialValues = useSelector(dealSelector.quoteDealInitialState);

  useEffect(() => {
    if (initialValues.io_months) {
      setIsIOYears(false);
    } else {
      setIsIOYears(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleChange = (e) => {
    if (e.target.value) {
      setFieldValue("io_months", "");
    } else {
      setFieldValue("io_years", "");
    }
    setIsIOYears(e.target.value);
    setFieldValue("isIOYears", e.target.value);
  };

  return (
    <FormControl variant="standard">
      <InputLabel shrink>Interest Only</InputLabel>
      <Field
        component={TextField}
        name={isIOYears ? "io_years" : "io_months"}
        placeholder="Enter Interest"
        defaultValue=""
        sx={{ "& .MuiInputBase-root": { pr: 0 } }}
        variant="outlined"
        InputProps={{
          inputComponent: QuoteFormNumberFormatter,
          endAdornment: (
            <FormControl
              variant="standard"
              sx={{
                paddingTop: "0",
                paddingLeft: !isIOYears ? "15px" : "30px",
              }}
            >
              <YearsMonthsDropdown
                value={isIOYears}
                onChange={handleToggleChange}
              />
            </FormControl>
          ),
        }}
      />
    </FormControl>
  );
};
