import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { REACT_APP_SITE_TITLE } from "../../config";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useLocation } from "react-router-dom";

function Page(props) {
  const { view: Component, title, anon, ...rest } = props;
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const location = useLocation();
  useEffect(() => {
    if (anon === false && !isLoggedIn) {
      // Navigation.go("/auth/login?after=" + location.pathname + location.search);
    }
  }, [anon, isLoggedIn, location.pathname, location.search]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {title} | {REACT_APP_SITE_TITLE}
        </title>
      </Helmet>
      <Component {...rest} />
    </>
  );
}

export function renderPageRoutes(pages) {
  if (!pages) {
    throw new Error("Missing pages: " + typeof pages);
  }
  return Object.keys(pages).map((pageName) => {
    const { path, ...rest } = pages[pageName];
    if (!rest.title) {
      throw new Error("Page missing title");
    }
    return (
      <Route
        {...rest}
        key={path}
        // exact={pathExact}
        path={path}
        element={<Page {...rest} />}
      />
    );
  });
}
