import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { H4Title } from "./styles";
import { Select, TextField } from "formik-mui";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../inputs/MultiSelect";
import { ErrorMessageWrapper } from "../lenderProfile/styles";
import {
  AppState,
  useSelector,
  fixedFloatingList,
  ownerOccupiedAllowedList,
  useDispatch,
  lendingProfileActions,
} from "../../state";
import { FieldWrapper } from "../Admin/styles";
import RequiredAsterisk from "../inputs/RequiredAsterisk";
import { NumberFormatter } from "../inputs/NumberFormatter";
import { hotjar } from "react-hotjar";
import { PercentFormatter } from "../inputs/PercentFormatter";
import { YearsMonthsDropdown } from "../inputs/YearsMonthsDropdown";
import { findIds, getLabelsFromIds } from "../../lib";
import { editDefaultCriteriaValidationSchema } from "../../lib/validationSchemas";

const DefaultCriteriaEditForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    propertyTypeList,
    states,
    termList,
    amortizationList,
    minDscrList,
    recourseList,
    prePaymentPenaltyList,
  } = useSelector((state: AppState) => state.lists);

  const { activeTab } = useSelector((state: AppState) => state.lendingProfile);

  const [initialValues, setInitialValues] = useState<any>({
    profileName: "",
    defaultOriginators: [],
    propertyTypes: [],
    loanTypes: [],
    states: [],
    executionTypes: [],
    minLoan: "",
    maxLoan: "",
    minRate: "",
    maxRate: "",
    maxTerm: "",
    maxAmortization: "",
    originationFee: "",
    interestOnly: "",
    maxLTV: "",
    minDSCR: "",
    rateTypes: [],
    recourses: [],
    interestOnlyYears: "",
    interestOnlyMonths: "",
    prePaymentPenalty: [],
    ownerOccupiedAllowed: "",
    notes: "",
  });
  const [isIOYears, setIsIOYears] = useState(false);

  const handleSubmit = async (values: any) => {
    const isNationwide = values.states && values.states.includes("Nationwide");

    const ppp = findIds(prePaymentPenaltyList, values.prePaymentPenalty);
    const recourse = findIds(recourseList, values.recourses);
    const rateType = findIds(
      fixedFloatingList,
      values.rateTypes,
      "description",
    );

    const formValues = {
      profile_name: values.profileName,
      property_type_ids: findIds(propertyTypeList, values.propertyTypes),
      states: findIds(states, values.states, "state"),
      nationwide: isNationwide,
      min_loan: values.minLoan,
      max_loan: values.maxLoan,
      min_rate: values.minRate,
      max_rate: values.maxRate,
      max_term: values.maxTerm,
      max_amortization: values.maxAmortization,
      origination_fee: values.originationFee,
      max_ltv: values.maxLTV,
      dscr_id: values.minDSCR,
      rate_type_ids: rateType?.length > 0 ? rateType : null,
      recourse_ids: recourse?.length > 0 ? recourse : [0],
      ppp_ids: ppp && ppp?.length > 0 ? ppp : null,
      oo_allowed: values.ownerOccupiedAllowed,
      note: values.notes,
      interest_only_months: isIOYears
        ? null
        : values.interestOnlyMonths || values.interestOnlyYears,
      interest_only_years: isIOYears
        ? values.interestOnlyYears || values.interestOnlyMonths
        : null,
    };

    try {
      dispatch(
        lendingProfileActions.updateLendingDefaultProfile(
          formValues,
          activeTab.default_lending_id,
        ),
      );
    } catch (error) {
    } finally {
      setTimeout(() => {
        navigate("/lending-profile");
      }, 3000);
    }
  };

  useEffect(() => {
    // change initial values to the values from the API
    if (activeTab) {
      let interestOnlyObject = {};
      if (activeTab.interest_only_months) {
        setIsIOYears(false);
        interestOnlyObject = {
          interestOnlyMonths: activeTab.interest_only_months,
        };
      } else if (activeTab.interest_only_years) {
        setIsIOYears(true);
        interestOnlyObject = {
          interestOnlyYears: activeTab.interest_only_years,
        };
      }
      setInitialValues({
        profileName: activeTab.profile_name,
        propertyTypes: getLabelsFromIds(
          activeTab.property_type_ids,
          propertyTypeList,
          "id",
          "value",
        ),
        states: activeTab.nationwide
          ? ["Nationwide"]
          : getLabelsFromIds(activeTab.states, states, "id", "state"),
        minLoan: activeTab.min_loan,
        maxLoan: activeTab.max_loan,
        maxLTV: activeTab.max_ltv,
        rateTypes: getLabelsFromIds(
          activeTab.rate_type_ids,
          fixedFloatingList,
          "id",
          "description",
        ),
        recourses: getLabelsFromIds(
          activeTab.recourse_ids,
          recourseList,
          "id",
          "value",
        ) || ["Not Specified"],
        prePaymentPenalty: getLabelsFromIds(
          activeTab.ppp_ids,
          prePaymentPenaltyList,
          "id",
          "value",
        ),
        minRate: activeTab.min_rate,
        maxRate: activeTab.max_rate,
        notes: activeTab.note,
        minDSCR: activeTab.dscr_id,
        maxTerm: activeTab.max_term,
        maxAmortization: activeTab.max_amortization,
        ownerOccupiedAllowed: activeTab.oo_allowed,
        originationFee: activeTab.origination_fee,
        ...interestOnlyObject,
      });
    }
  }, [activeTab]);

  return (
    <Box
      sx={{
        paddingLeft: "26px",
        ".MuiFormHelperText-root": {
          color: "#757575",
          fontSize: "12px",
          margin: "4px 0 10px 0",
        },
        ".MuiFormControl-root": {
          margin: "0 0 5px 0",
          width: "100%",
          padding: 0,
          paddingTop: "0",
        },
        ".MuiFormControl-root-MuiTextField-root, .MuiFormControl-root": {
          margin: "0",
          padding: "0",
        },
        ".MuiTypography-root": {
          position: "relative",
          top: "3px",
          paddingBottom: "0",
        },
        label: {
          color: "#03363D !important",
          lineHeight: "21px",
          fontSize: "12px",
          fontWeight: "700",
          transform: "none",
          position: "static",
          "&:empty": {
            display: "none",
          },
        },
        ".has-dropdown-icon": {
          position: "relative",
          ".MuiSvgIcon-root": {
            display: "none",
          },
          svg: {
            position: "absolute",
            right: "12px",
            bottom: "10px",
            width: "13px",
            height: "14px",
            pointerEvents: "none",
          },
        },
        ".has-chevron-icon": {
          position: "relative",
          ".MuiSvgIcon-root": {
            display: "none",
          },
          "&::before, &::after": {
            position: "absolute",
            top: "40px",
            content: '""',
            width: "9px",
            height: "1px",
            backgroundColor: "#666666",
            pointerEvents: "none",
          },
          "&::before": {
            transform: "rotate(45deg)",
            right: "16px",
          },
          "&::after": {
            transform: "rotate(-45deg)",
            right: "10px",
          },
        },

        ".owner-occupied": {
          "&::before, &::after": {
            top: "32px",
          },
        },
        "& .MuiSelect-select": {
          paddingLeft: "0 !important",
        },

        "& .MuiOutlinedInput-input": {
          paddingLeft: "0 !important",
        },
        "& .field-wrapper": {
          marginTop: "10px",
        },
        "& .field-wrapper--alt": {
          marginTop: "12px",
        },

        "& .rate-recourse": {
          marginTop: "7px",
        },

        "& .min-dscr": {
          marginTop: "9px",
        },
        "& .max-ltv": {
          marginTop: "9px",
        },

        ".MuiInputAdornment-root p": {
          color: "#757575",
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={editDefaultCriteriaValidationSchema}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <H4Title>Profile Name</H4Title>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "20px",
                position: "relative",
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  backgroundColor: "#6FC0D5",
                  height: "2px",
                  width: "214px",
                  bottom: "0",
                },
              }}
            >
              <FormControl style={{ padding: 0 }} variant="standard">
                <Field
                  sx={{
                    width: "191px",
                    paddingTop: 0,
                    marginBottom: "7px",
                    marginRight: "10px",
                    ".MuiInputBase-formControl": {
                      backgroundColor: "#fff",
                      padding: 0,
                      width: 191,
                    },
                    input: {
                      color: "#03363D",
                      fontSize: "14px",
                      "&.MuiInputBase-input": {
                        padding: "6px 12px 8px !important",
                      },
                    },
                    // This hides the autofill dropdown in Safari
                    "& input::-webkit-contacts-auto-fill-button": {
                      visibility: "hidden",
                      display: "none !important",
                      pointerEvents: "none",
                      position: "absolute",
                      right: "0",
                    },
                  }}
                  component={TextField}
                  name="profileName"
                />
              </FormControl>
              <Button
                variant="outlined"
                onClick={() => {
                  hotjar.event(
                    `Lending Profile Default Criteria Edit Form Cancel - ${new Date(
                      Date.now(),
                    )
                      .toUTCString()
                      .replace(",", "")}`,
                  );
                  navigate("/lending-profile");
                }}
                sx={{
                  width: "200px",
                  marginLeft: "auto",
                  border: "none",
                  color: "#1F9F8A",
                  fontWeight: "400",
                  marginRight: "27px",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "transparent",
                  height: "50px",
                  "&:hover": {
                    borderColor: "#1F9F8A !important",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  hotjar.event(
                    `Lending Profile Default Criteria Edit Form Save - ${new Date(
                      Date.now(),
                    )
                      .toUTCString()
                      .replace(",", "")}`,
                  );
                }}
                sx={{
                  width: "200px",
                  backgroundColor: "text.primary",
                  padding: "10px 0",
                  fontSize: "16px",
                  height: "50px",
                  flexShrink: 0,
                }}
              >
                Save Changes
              </Button>
            </Box>
            <Box
              sx={{
                paddingLeft: "52px",
                margin: "0 -20px 0 -50px",
                backgroundColor: "#fff",
                height: "calc(100vh - 188px)",
              }}
            >
              <H4Title
                sx={{
                  paddingTop: "16px",
                }}
              >
                Default Criteria
              </H4Title>
              <Box
                sx={{
                  display: "flex",
                  maxWidth: "840px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "400px",
                    width: "100%",
                    marginRight: "40px",
                    flexShrink: 0,
                  }}
                >
                  <FormControl
                    sx={{
                      label: {
                        marginBottom: "-3px",
                      },
                    }}
                  >
                    <Field
                      component={MultiSelect}
                      name={"propertyTypes"}
                      options={[
                        {
                          list_type_id: "",
                          value: "All",
                          description: "",
                        },
                        ...propertyTypeList,
                        {
                          list_type_id: "",
                          value: "Uncheck All",
                          description: "",
                        },
                      ]}
                      placeholder="Select a property type to add"
                      label="Property Type(s)"
                      isRequired
                      icon={true}
                    />
                    <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
                      <ErrorMessage name="propertyTypes" />
                    </ErrorMessageWrapper>
                  </FormControl>
                  <FieldWrapper className="field-wrapper">
                    <FormControl
                      variant="standard"
                      sx={{
                        paddingTop: "0",
                        marginBottom: "5px",
                        label: {
                          marginBottom: "-3px",
                        },
                      }}
                    >
                      <InputLabel shrink>
                        Loan Min <RequiredAsterisk />
                      </InputLabel>
                      <Field
                        component={TextField}
                        name="minLoan"
                        variant="outlined"
                        InputProps={{
                          inputComponent: NumberFormatter,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Loan min Clicked")}
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      sx={{
                        label: {
                          marginBottom: "-3px",
                        },
                      }}
                    >
                      <InputLabel shrink>
                        Loan Max <RequiredAsterisk />
                      </InputLabel>
                      <Field
                        component={TextField}
                        name="maxLoan"
                        variant="outlined"
                        InputProps={{
                          inputComponent: NumberFormatter,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Loan Max Clicked")}
                      />
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper className="field-wrapper">
                    <FormControl variant="standard">
                      <InputLabel shrink>Min Rate</InputLabel>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="minRate"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Min Rate Clicked")}
                      />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel shrink>Max Rate</InputLabel>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="maxRate"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Max Rate Clicked")}
                      />
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper className="field-wrapper">
                    <FormControl
                      variant="standard"
                      className="has-chevron-icon"
                    >
                      <InputLabel shrink>Max Term</InputLabel>
                      <Field
                        component={Select}
                        name="maxTerm"
                        variant="outlined"
                        placeholder="Select Max Term"
                        onClick={(e) => {
                          hotjar.event(
                            `Dropdown Max Term - ${e.target["innerText"]} Selected`,
                          );
                        }}
                      >
                        {termList?.map((obj: any) => (
                          <MenuItem key={obj.id} value={Number(obj.id)}>
                            {obj.value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                    <FormControl
                      variant="standard"
                      className="has-chevron-icon"
                    >
                      <InputLabel shrink>Max Amortization</InputLabel>
                      <Field
                        component={Select}
                        variant="outlined"
                        name="maxAmortization"
                        placeholder="Select Max Amortization"
                        onClick={(e) => {
                          hotjar.event(
                            `Dropdown Max Amortization - ${e.target["innerText"]} Selected`,
                          );
                        }}
                      >
                        {amortizationList?.map((obj: any) => (
                          <MenuItem key={obj.id} value={obj.id}>
                            {obj.value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper className="field-wrapper">
                    <FormControl variant="standard">
                      <InputLabel shrink>Origination Fee</InputLabel>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="originationFee"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        placeholder="Enter Origination Fee"
                        onClick={() =>
                          hotjar.event("Input Origination Fee Clicked")
                        }
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      sx={{
                        padding: "0",
                        ".MuiFormControl-root-MuiTextField-root": {
                          padding: "0",
                        },
                      }}
                    >
                      <InputLabel shrink>Interest Only</InputLabel>
                      <Field
                        component={TextField}
                        name={
                          isIOYears ? "interestOnlyYears" : "interestOnlyMonths"
                        }
                        variant="outlined"
                        sx={{ "& .MuiInputBase-root": { pr: 0 } }}
                        placeholder="Enter Interest..."
                        InputProps={{
                          inputComponent: NumberFormatter,
                          endAdornment: (
                            <FormControl
                              variant="standard"
                              sx={{ padding: "0", margin: "0" }}
                            >
                              <YearsMonthsDropdown
                                value={isIOYears}
                                onChange={() => {
                                  setIsIOYears(!isIOYears);
                                  hotjar.event(
                                    `Input Origination Fee - ${
                                      isIOYears ? "Month" : "Years"
                                    } - Selected`,
                                  );
                                }}
                              />
                            </FormControl>
                          ),
                        }}
                        onClick={() =>
                          hotjar.event("Input Interest Only Clicked")
                        }
                      />
                    </FormControl>
                  </FieldWrapper>
                </Box>
                <Box
                  sx={{
                    maxWidth: "400px",
                    width: "100%",
                    flexShrink: 0,
                  }}
                >
                  <FormControl
                    sx={{
                      padding: "0",
                      marginBottom: "-7px",
                      label: {
                        marginBottom: "-3px",
                      },
                    }}
                  >
                    <Field
                      component={MultiSelect}
                      label="State(s)"
                      name={"states"}
                      options={[
                        { id: 0, state: "Nationwide" },
                        { id: 9999, state: "All" },
                        ...states,
                      ]}
                      placeholder="Select state(s) to add"
                      customLabelName="state"
                      isRequired
                      icon={true}
                    />
                    <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
                      <ErrorMessage name="states" />
                    </ErrorMessageWrapper>
                  </FormControl>
                  <FieldWrapper className="field-wrapper">
                    <FormControl variant="standard" className="max-ltv">
                      <InputLabel shrink>Max LTV</InputLabel>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="maxLTV"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Max LTV Clicked")}
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      className="has-chevron-icon min-dscr"
                      sx={{
                        marginTop: "14px",
                      }}
                    >
                      <InputLabel shrink>Min DSCR </InputLabel>
                      <Field
                        component={Select}
                        variant="outlined"
                        name="minDSCR"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onClick={() =>
                          hotjar.event("Input Max LTV (Non Recourse) Clicked")
                        }
                      >
                        {minDscrList?.map((obj: any) => (
                          <MenuItem key={obj.id} value={obj.id}>
                            {obj.value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper className="field-wrapper rate-recourse">
                    <FormControl>
                      <Field
                        component={MultiSelect}
                        name={"rateTypes"}
                        options={fixedFloatingList}
                        placeholder="Select Rate Type"
                        label="Rate Type"
                        customLabelName="description"
                        icon={true}
                      />
                    </FormControl>
                    <FormControl variant="standard">
                      <Field
                        component={MultiSelect}
                        name={"recourses"}
                        options={recourseList}
                        placeholder="Select Recourse"
                        label="Recourse"
                        icon={true}
                      />
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper className="field-wrapper field-wrapper--alt">
                    <FormControl
                      variant="standard"
                      sx={{
                        label: {
                          marginTop: "-10px",
                        },
                      }}
                    >
                      <Field
                        component={MultiSelect}
                        name={"prePaymentPenalty"}
                        options={prePaymentPenaltyList}
                        placeholder="Select Pre Payment Penalty"
                        label="Pre Payment Penalty"
                        icon={true}
                      />
                    </FormControl>

                    <FormControl
                      variant="standard"
                      className="has-chevron-icon owner-occupied"
                      sx={{
                        label: {
                          marginTop: "-7px",
                        },
                        ".MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                          {
                            width: "100%",
                          },
                      }}
                    >
                      <InputLabel shrink>Owner Occupied Allowed</InputLabel>
                      <Field
                        component={Select}
                        variant="outlined"
                        name="ownerOccupiedAllowed"
                        onClick={(e) => {
                          hotjar.event(
                            `Dropdown Owner Occupied Allowed - ${e.target["innerText"]} Selected`,
                          );
                        }}
                      >
                        {ownerOccupiedAllowedList?.map(
                          (obj: { value: number; description: string }) => (
                            <MenuItem key={obj.value} value={obj.value}>
                              {obj.description}
                            </MenuItem>
                          ),
                        )}
                      </Field>
                    </FormControl>
                  </FieldWrapper>
                </Box>
              </Box>
              <FormControl
                style={{ maxWidth: "840px", width: "100%" }}
                variant="standard"
                sx={{
                  padding: "0",
                  margin: "7px 0 0",
                  label: {
                    marginBottom: "5px",
                  },
                }}
              >
                <InputLabel shrink>Notes</InputLabel>
                <Field
                  component={TextField}
                  placeholder="Enter Additional Notes Here..."
                  sx={{
                    height: "120px",
                    ".MuiInputBase-root-MuiOutlinedInput-root": {
                      padding: "8px 12px",
                    },
                    ".MuiOutlinedInput-root": {
                      padding: "8px 12px",
                    },
                    textarea: {
                      height: "120px !important",
                      padding: "0",
                      "&::placeholder": {
                        color: "#757575",
                        fontSize: "14px",
                        opacity: "1",
                      },
                    },
                  }}
                  multiline
                  inputProps={{
                    maxLength: 500,
                  }}
                  rows={3}
                  helperText="Max. 500 Characters. All notes are public facing."
                  variant="outlined"
                  name="notes"
                  onClick={() => hotjar.event("Input Notes Clicked")}
                />
              </FormControl>
              <FormControl variant="standard"></FormControl>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
export default DefaultCriteriaEditForm;
