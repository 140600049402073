import React, { memo } from "react";
import { Box, styled, Grid, Typography, Paper, Card } from "@mui/material";

import AccountInfoCard from "../../components/Admin/AccountInfoCard";
// import ChangePasswordCard from "../../components/Admin/ChangePasswordCard";

export const PaperCard = styled(Paper)(({ theme }) => ({
  padding: 16,
  [theme.breakpoints.up("sm")]: {
    height: 230,
    marginBottom: 24,
    justifyContent: "space-between",
    display: "flex",
  },
}));

export const CardContainer = styled(Card)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  maxWidth: 700,
}));

export const AccountPage = memo(function AccountPage() {
  return (
    <Box>
      <Typography variant="h2">My account</Typography>
      <Grid container sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <AccountInfoCard />
        </Grid>
        {/* <Grid item xs={6}>
          <ChangePasswordCard />
        </Grid> */}
      </Grid>
    </Box>
  );
});
