import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import { useNavigate } from "react-router-dom";
import {
  AppState,
  useSelector,
  fixedFloatingList,
  ownerOccupiedAllowedList,
  useDispatch,
  uiActions,
  lendingProfileActions,
} from "../../state";
import MultiSelect from "../inputs/MultiSelect";
import { NumberFormatter } from "../inputs/NumberFormatter";
import { hotjar } from "react-hotjar";
import { FieldWrapper } from "../Admin/styles";
import { PercentFormatter } from "../inputs/PercentFormatter";
import RequiredAsterisk from "../inputs/RequiredAsterisk";
import { lendingProfileCreationValidationSchema } from "../../lib/validationSchemas";
import { ErrorMessageWrapper } from "../lenderProfile/styles";
import { H4Title } from "./styles";
import AutocompleteOriginators from "../inputs/AutocompleteOriginators";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { YearsMonthsDropdown } from "../inputs/YearsMonthsDropdown";
import { findIds } from "../../lib/utils";

const DefaultProfileCreateForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    propertyTypeList,
    termList,
    states,
    executionList,
    loanTypeList,
    amortizationList,
    minDscrList,
    recourseList,
    prePaymentPenaltyList,
    designatedOriginatorList,
  } = useSelector((state: AppState) => state.lists);
  const user = useSelector((state: AppState) => state.auth.user);
  const { property_type_tabs } = useSelector(
    (state: AppState) => state.lendingProfile,
  );
  const [formInitialValues, setFormInitialValues] = useState({
    profileName: "",
    defaultOriginators: [],
    propertyTypes: [],
    loanTypes: [],
    states: [],
    executionTypes: [],
    minLoan: null,
    maxLoan: null,
    minRate: null,
    maxRate: null,
    maxTerm: null,
    maxAmortization: 0,
    originationFee: null,
    interestOnlyMonths: null,
    interestOnlyYears: null,
    maxLTV: null,
    minDSCR: null,
    rateTypes: [],
    recourses: ["Not Specified"],
    prePaymentPenalty: [],
    ownerOccupiedAllowed: null,
    notes: null,
  });
  const [lendingProfileCreated, setLendingProfileCreated] = useState(false);
  const handleSubmit = async (values: any) => {
    // dispatch(lendingProfileActions);
    const propertyTypeIds = findIds(propertyTypeList, values.propertyTypes);
    const stateIds = findIds(states, values.states, "state");
    const loanTypeIds = findIds(loanTypeList, values.loanTypes);
    const executionTypeIds = findIds(executionList, values.executionTypes);
    const originatorIds = findIds(
      designatedOriginatorList,
      values.defaultOriginators,
      "contact_name",
      "contact_id",
    );
    const rateTypeIds = findIds(
      fixedFloatingList,
      values.rateTypes,
      "description",
    );
    const recourseIds = findIds(recourseList, values.recourses);
    const prePaymentPenaltyIds = findIds(
      prePaymentPenaltyList,
      values.prePaymentPenalty,
    );

    const isNationwide = values.states && values.states.includes("Nationwide");

    let interestOnly = {};
    if (isIOYears === true)
      interestOnly = { interest_only_years: values.interestOnlyYears };
    else interestOnly = { interest_only_months: values.interestOnlyMonths };

    const formValues = {
      profile_name: values.profileName,
      bank_id: user?.companyId, // lender id
      property_type_ids: propertyTypeIds,
      execution_type_ids: executionTypeIds,
      loan_type_ids: loanTypeIds,
      states: stateIds,
      nationwide: isNationwide, // boolean 0 ili 1
      originators: originatorIds.length > 0 ? originatorIds : null, // na kreiranju i editovanju vise nisu obavezni
      min_loan: values.minLoan,
      max_loan: values.maxLoan,
      max_ltv: values.maxLTV,
      dscr_id: values.minDSCR,
      min_rate: values.minRate,
      max_rate: values.maxRate,
      rate_type_ids: rateTypeIds?.length > 0 ? rateTypeIds : null,
      recourse_ids: recourseIds,
      max_term: values.maxTerm,
      max_amortization: values.maxAmortization,
      origination_fee: values.originationFee,
      ppp_ids: prePaymentPenaltyIds?.length > 0 ? prePaymentPenaltyIds : null,
      oo_allowed: values.ownerOccupiedAllowed,
      note: values.notes,
      ...interestOnly,
    };

    try {
      dispatch(uiActions.setLoading(true));
      dispatch(lendingProfileActions.createDefaultLendingProfile(formValues));
      setLendingProfileCreated(true);
    } catch (error) {
      console.log(error, "Create Lending Profile Error");
    } finally {
      setTimeout(() => {
        navigate("/lending-profile/create/success");
      }, 3000);
    }
  };

  useEffect(() => {
    if (formInitialValues.interestOnlyMonths) {
      setIsIOYears(false);
      setFormInitialValues({
        ...formInitialValues,
        interestOnlyYears: null,
      });
    } else {
      setIsIOYears(true);
      setFormInitialValues({
        ...formInitialValues,
        interestOnlyMonths: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formInitialValues?.interestOnlyMonths,
    formInitialValues?.interestOnlyYears,
  ]);

  useEffect(() => {
    if (property_type_tabs.length > 0) {
      setFormInitialValues({
        ...formInitialValues,
        profileName: `Lending Profile ${property_type_tabs.length + 1}`,
      });
    }
    if (lendingProfileCreated && property_type_tabs.length > 0) {
      dispatch(
        lendingProfileActions.setActiveSelectedTab(property_type_tabs.length),
      );
    }
  }, [property_type_tabs, lendingProfileCreated]);

  const handleOriginatorDelete = (id: number) => {
    // show modal
    dispatch(uiActions.setRemoveOriginatorPopupOpen(true));

    console.log(id);
  };
  const [isIOYears, setIsIOYears] = useState(false);

  return (
    <Box
      sx={{
        paddingLeft: "26px",
        ".MuiFormHelperText-root": {
          color: "#757575",
          fontSize: "12px",
          margin: "4px 0 10px 0",
          "&.Mui-error": {
            color: "rgb(233, 28, 28, 1)",
          },
        },
        ".MuiFormControl-root": {
          margin: "0 0 5px 0",
          width: "100%",
          padding: 0,
          paddingTop: "0",
          // justifyContent: "flex-end",
        },
        ".MuiFormControl-root-MuiTextField-root, .MuiFormControl-root": {
          margin: "0",
          padding: "0",
        },
        ".has-dropdown-icon": {
          position: "relative",
          ".MuiSvgIcon-root": {
            display: "none",
          },
          svg: {
            position: "absolute",
            right: "12px",
            bottom: "10px",
            width: "13px",
            height: "14px",
            pointerEvents: "none",
          },
        },
        ".has-chevron-icon": {
          position: "relative",
          ".MuiSvgIcon-root": {
            display: "none",
          },
          "& svg": {
            display: "none",
          },
          "&::before, &::after": {
            position: "absolute",
            top: "40px",
            content: '""',
            width: "9px",
            height: "1px",
            backgroundColor: "#666666",
            pointerEvents: "none",
          },
          "&::before": {
            transform: "rotate(45deg)",
            right: "16px",
          },
          "&::after": {
            transform: "rotate(-45deg)",
            right: "10px",
          },
          "&.min-dscr::before, &.min-dscr::after": {
            top: "49px",
          },

          "&.pre-payment-penalty::before, &.pre-payment-penalty::after": {
            top: "30px",
          },

          "&.states::before, &.states::after": {
            top: "51px",
          },

          "&.align-center::before, &.align-center::after": {
            top: "51px",
          },

          "&.default-originators::before, &.default-originators::after": {
            top: "45px",
          },
        },

        "& .default-originators": {
          width: "400px",
          "& .MuiInput-root": {
            marginTop: "0",
          },
        },
        ".owner-occupied": {
          marginTop: "1px",
          "& .MuiOutlinedInput-root": {
            height: "43px",
          },
          "&::before, &::after": {
            top: "27px",
          },
        },
        ".MuiTypography-root": {
          position: "relative",
          top: "1px",
        },

        "& .MuiSelect-select": {
          paddingLeft: "0 !important",
        },

        "& .MuiOutlinedInput-input": {
          paddingLeft: "0 !important",
        },

        label: {
          color: "#03363D !important",
          lineHeight: "21px",
          fontSize: "12px",
          fontWeight: "700",
          transform: "none",
          position: "static",
          "&:empty": {
            display: "none",
          },
        },
        ".rates-type": {
          marginTop: "-3px",
        },

        ".recourse": {
          marginTop: "-3px",
          marginBottom: "4px",
        },

        ".default-criteria": {
          marginTop: "35px",
        },

        ".MuiBox-root + div": {
          backgroundColor: "#fff",
        },

        ".has-error": {
          h6: {
            paddingLeft: 0,
            top: "7px",
          },
          ".css-qtrt43-MuiInputBase-root-MuiOutlinedInput-root fieldset": {
            borderColor: "rgb(233, 28, 28, 1)",
          },
        },
        ".MuiInputAdornment-root p": {
          color: "#757575",
        },
        ".MuiInputBase-input::placeholder": {
          fontSize: "14px",
        },
      }}
    >
      <Formik
        initialValues={formInitialValues}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        validationSchema={lendingProfileCreationValidationSchema}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <H4Title>
              Profile Name <span>*</span>
            </H4Title>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "15px",
                position: "relative",
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  backgroundColor: "#6FC0D5",
                  height: "2px",
                  width: "214px",
                  bottom: "0",
                },
              }}
            >
              <FormControl
                style={{ padding: 0, width: 191 }}
                variant="standard"
              >
                <Field
                  sx={{
                    width: "191px",
                    paddingTop: 0,
                    ".MuiInputBase-formControl": {
                      backgroundColor: "#fff",
                      padding: "0 10px",
                    },
                    "p.Mui-error": {
                      position: "absolute",
                      bottom: "-30px",
                    },
                    // This hides the autofill dropdown in Safari
                    "& input::-webkit-contacts-auto-fill-button": {
                      visibility: "hidden",
                      display: "none !important",
                      pointerEvents: "none",
                      position: "absolute",
                      right: "0",
                    },
                  }}
                  component={TextField}
                  name="profileName"
                  autocomplete="off"
                />
              </FormControl>
              <Button
                variant="outlined"
                onClick={() => {
                  hotjar.event(
                    `Lending Profile Create Form Cancel - ${new Date(Date.now())
                      .toUTCString()
                      .replace(",", "")}`,
                  );
                  navigate("/lending-profile");
                }}
                sx={{
                  marginLeft: "auto",
                  border: "none",
                  color: "#1F9F8A",
                  fontWeight: "400",
                  marginRight: "27px",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "transparent  !important",
                  height: "46px",
                  "&:hover": {
                    borderColor: "#1F9F8A !important",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  hotjar.event(
                    `Lending Profile Create Form Save - ${new Date(Date.now())
                      .toUTCString()
                      .replace(",", "")}`,
                  );
                }}
                sx={{
                  width: "218px",
                  backgroundColor: "text.primary",
                  padding: "10px 0",
                  fontSize: "16px",
                  height: "46px",
                  flexShrink: 0,
                }}
              >
                Create Lending Profile
              </Button>
            </Box>
            <Box
              sx={{
                paddingLeft: "52px",
                margin: "0 -20px 0 -50px",
                backgroundColor: "#fff",
                height: "calc(100vh - 188px)",
              }}
            >
              <div>
                <H4Title
                  sx={{
                    paddingTop: "16px",
                    marginBottom: "5px",
                  }}
                >
                  Default Originators
                </H4Title>
                <p
                  style={{
                    color: "#757575",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    margin: "0 0 17px",
                  }}
                >
                  All originators added to this team will be assigned to every
                  lending combination you create.
                </p>
                {/* Default originators select goes here */}
                <FormControl className="default-originators">
                  <AutocompleteOriginators
                    name="defaultOriginators"
                    setFieldValue={setFieldValue}
                    // isRequired
                    values={values}
                    label="Default Originators"
                    options={designatedOriginatorList}
                    customLabelName={"contact_name"}
                    handleOriginatorDelete={handleOriginatorDelete}
                  />
                  <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
                    <ErrorMessage name="defaultOriginators" />
                  </ErrorMessageWrapper>
                </FormControl>
              </div>
              <H4Title className="default-criteria">Default Criteria</H4Title>
              <Box
                sx={{
                  display: "flex",
                  maxWidth: "840px",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "400px",
                    width: "100%",
                    marginRight: "40px",
                    flexShrink: 0,
                  }}
                >
                  <FormControl
                    className={`has-chevron-icon align-center ${
                      errors["propertyTypes"] ? "has-error" : ""
                    }`}
                    sx={{
                      label: {
                        marginBottom: "-3px",
                      },
                    }}
                  >
                    <Field
                      component={MultiSelect}
                      name={"propertyTypes"}
                      errors={errors}
                      options={[
                        {
                          list_type_id: "",
                          value: "All",
                          description: "",
                        },
                        ...propertyTypeList,
                        {
                          list_type_id: "",
                          value: "Uncheck All",
                          description: "",
                        },
                      ]}
                      placeholder="Select a property type to add"
                      label="Property Type(s)"
                      isRequired
                      icon={true}
                    />
                    <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
                      <ErrorMessage name="propertyTypes" />
                    </ErrorMessageWrapper>
                  </FormControl>
                  <FormControl
                    className={`has-chevron-icon states ${
                      errors["states"] ? "has-error" : ""
                    }`}
                    sx={{
                      padding: "0",
                      marginBottom: "-7px",
                      label: {
                        marginBottom: "-3px",
                      },
                    }}
                  >
                    <Field
                      component={MultiSelect}
                      label="State(s)"
                      name={"states"}
                      options={[{ id: 0, state: "Nationwide" }, ...states]}
                      placeholder="Select state(s) to add"
                      customLabelName="state"
                      isRequired
                      icon={true}
                    />
                    <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
                      <ErrorMessage name="states" />
                    </ErrorMessageWrapper>
                  </FormControl>
                  <FieldWrapper>
                    <FormControl
                      variant="standard"
                      sx={{
                        paddingTop: "0",
                        marginBottom: "5px",
                        label: {
                          marginBottom: "-3px",
                        },
                      }}
                    >
                      <InputLabel shrink>
                        Loan Min <RequiredAsterisk />
                      </InputLabel>
                      <Field
                        component={TextField}
                        name="minLoan"
                        variant="outlined"
                        InputProps={{
                          inputComponent: NumberFormatter,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Loan min Clicked")}
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      sx={{
                        label: {
                          marginBottom: "-3px",
                        },
                      }}
                    >
                      <InputLabel shrink>
                        Loan Max <RequiredAsterisk />
                      </InputLabel>
                      <Field
                        component={TextField}
                        name="maxLoan"
                        variant="outlined"
                        InputProps={{
                          inputComponent: NumberFormatter,
                          startAdornment: (
                            <InputAdornment position="start">$</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Loan Max Clicked")}
                      />
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper>
                    <FormControl variant="standard">
                      <InputLabel shrink>Min Rate</InputLabel>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="minRate"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Min Rate Clicked")}
                      />
                    </FormControl>
                    <FormControl variant="standard">
                      <InputLabel shrink>Max Rate</InputLabel>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="maxRate"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Max Rate Clicked")}
                      />
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper>
                    <FormControl
                      variant="standard"
                      className="has-chevron-icon"
                    >
                      <InputLabel shrink>Max Term</InputLabel>
                      <Field
                        component={Select}
                        name="maxTerm"
                        variant="outlined"
                        placeholder="Select Max Term"
                        onClick={(e) => {
                          hotjar.event(
                            `Dropdown Max Term - ${e.target["innerText"]} Selected`,
                          );
                        }}
                      >
                        {termList?.map((obj: any) => (
                          <MenuItem key={obj.id} value={Number(obj.id)}>
                            {obj.value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                    <FormControl
                      variant="standard"
                      className="has-chevron-icon"
                    >
                      <InputLabel shrink>Max Amortization</InputLabel>
                      <Field
                        component={Select}
                        variant="outlined"
                        name="maxAmortization"
                        placeholder="Select Max Amortization"
                        onClick={(e) => {
                          hotjar.event(
                            `Dropdown Max Amortization - ${e.target["innerText"]} Selected`,
                          );
                        }}
                      >
                        {amortizationList?.map((obj: any) => (
                          <MenuItem key={obj.id} value={obj.id}>
                            {obj.value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper>
                    <FormControl variant="standard">
                      <InputLabel shrink>Origination Fee</InputLabel>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="originationFee"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        placeholder="Enter Origination Fee"
                        onClick={() =>
                          hotjar.event("Input Origination Fee Clicked")
                        }
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      className="has-chevron-icon"
                      sx={{
                        padding: "0",
                        ".MuiFormControl-root-MuiTextField-root": {
                          padding: "0",
                        },
                      }}
                    >
                      <InputLabel shrink>Interest Only</InputLabel>
                      <Field
                        component={TextField}
                        name={
                          isIOYears === true
                            ? "interestOnlyYears"
                            : "interestOnlyMonths"
                        }
                        variant="outlined"
                        sx={{
                          "& .MuiInputBase-root": { pr: 0 },
                          "& .MuiOutlinedInput-root": {
                            fontSize: "14px",
                          },
                        }}
                        placeholder="Enter Interest..."
                        InputProps={{
                          inputComponent: NumberFormatter,
                          endAdornment: (
                            <FormControl
                              variant="standard"
                              sx={{
                                padding: "0",
                                margin: "0",
                                fontSize: "14px",
                                "& .MuiSelect-select": {
                                  color: "#757575",
                                },
                              }}
                            >
                              <YearsMonthsDropdown
                                value={isIOYears}
                                onChange={() => {
                                  setIsIOYears(!isIOYears);
                                  hotjar.event(
                                    `Input Origination Fee - ${
                                      isIOYears ? "Month" : "Years"
                                    } - Selected`,
                                  );
                                }}
                              />
                            </FormControl>
                          ),
                        }}
                        onClick={() =>
                          hotjar.event("Input Interest Only Clicked")
                        }
                      />
                    </FormControl>
                  </FieldWrapper>
                </Box>
                <Box
                  sx={{
                    maxWidth: "400px",
                    width: "100%",
                    flexShrink: 0,
                  }}
                >
                  <FormControl
                    className={`has-chevron-icon align-center ${
                      errors["loanTypes"] ? "has-error" : ""
                    }`}
                    sx={{
                      label: {
                        marginBottom: "-3px",
                      },
                    }}
                  >
                    <Field
                      component={MultiSelect}
                      label="Loan Type(s)"
                      name={"loanTypes"}
                      options={loanTypeList}
                      placeholder="Select a loan type to add"
                      isRequired
                      icon={true}
                    />
                    <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
                      <ErrorMessage name="loanTypes" />
                    </ErrorMessageWrapper>
                  </FormControl>

                  <FormControl
                    className={`has-chevron-icon align-center ${
                      errors["executionTypes"] ? "has-error" : ""
                    }`}
                    sx={{
                      label: {
                        marginBottom: "-3px",
                      },
                    }}
                  >
                    <Field
                      component={MultiSelect}
                      label="Execution Type(s)"
                      name={"executionTypes"}
                      options={executionList}
                      placeholder="Select an execution type to add"
                      isRequired
                      icon={true}
                    />
                    <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
                      <ErrorMessage name="executionTypes" />
                    </ErrorMessageWrapper>
                  </FormControl>
                  <FieldWrapper
                    sx={{
                      ".MuiFormControl-root": {
                        justifyContent: "flex-end",
                      },
                    }}
                  >
                    <FormControl
                      variant="standard"
                      sx={{
                        marginBottom: "5px",
                        label: {
                          marginBottom: "0",
                          marginTop: "-3px",
                        },
                      }}
                    >
                      <InputLabel shrink>Max LTV</InputLabel>
                      <Field
                        component={TextField}
                        variant="outlined"
                        name="maxLTV"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          placeholder: "Enter Max LTV",
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onClick={() => hotjar.event("Input Max LTV Clicked")}
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      className="has-chevron-icon min-dscr"
                      sx={{
                        label: {
                          marginTop: "10px",
                        },
                      }}
                    >
                      <InputLabel shrink>Min DSCR </InputLabel>
                      <Field
                        component={Select}
                        variant="outlined"
                        name="minDSCR"
                        InputProps={{
                          inputComponent: PercentFormatter,
                          startAdornment: (
                            <FontAwesomeIcon
                              color="#757575"
                              size={"1x"}
                              style={{ marginLeft: 8 }}
                              icon={regular("chevron-down")}
                            />
                          ),
                          endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                        }}
                        onClick={() =>
                          hotjar.event("Input Max LTV (Non Recourse) Clicked")
                        }
                      >
                        {minDscrList?.map((obj: any) => (
                          <MenuItem key={obj.id} value={obj.id}>
                            {obj.value}
                          </MenuItem>
                        ))}
                      </Field>
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper>
                    <FormControl className="has-chevron-icon rates-type">
                      <Field
                        component={MultiSelect}
                        name={"rateTypes"}
                        options={fixedFloatingList}
                        placeholder="Select Rate Type"
                        label="Rate Type"
                        customLabelName="description"
                        icon={true}
                      />
                    </FormControl>
                    <FormControl
                      variant="standard"
                      className="has-chevron-icon recourse"
                    >
                      <Field
                        component={MultiSelect}
                        name={"recourses"}
                        options={recourseList}
                        placeholder="Select Recourse"
                        label="Recourse"
                        icon={true}
                      />
                    </FormControl>
                  </FieldWrapper>
                  <FieldWrapper>
                    <FormControl
                      variant="standard"
                      className="has-chevron-icon pre-payment-penalty"
                      sx={{
                        label: {
                          marginTop: "-13px",
                        },
                      }}
                    >
                      <Field
                        component={MultiSelect}
                        name={"prePaymentPenalty"}
                        options={prePaymentPenaltyList}
                        placeholder="Select Pre Payment Penalty"
                        label="Pre Payment Penalty"
                        icon={true}
                      />
                    </FormControl>

                    <FormControl
                      variant="standard"
                      className="has-chevron-icon owner-occupied"
                      sx={{
                        marginTop: "3px",
                        label: {
                          marginTop: "-15px",
                        },
                        ".MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                          {
                            width: "100%",
                          },
                      }}
                    >
                      <InputLabel shrink>Owner Occupied Allowed</InputLabel>
                      <Field
                        component={Select}
                        variant="outlined"
                        name="ownerOccupiedAllowed"
                        onClick={(e) => {
                          hotjar.event(
                            `Dropdown Owner Occupied Allowed - ${e.target["innerText"]} Selected`,
                          );
                        }}
                        sx={{
                          "& fieldset": {
                            height: "41px",
                            marginTop: "3px",
                          },
                        }}
                      >
                        {ownerOccupiedAllowedList?.map(
                          (obj: { value: number; description: string }) => (
                            <MenuItem key={obj.value} value={obj.value}>
                              {obj.description}
                            </MenuItem>
                          ),
                        )}
                      </Field>
                    </FormControl>
                  </FieldWrapper>
                </Box>
              </Box>
              <FormControl
                style={{ maxWidth: "840px", width: "100%" }}
                variant="standard"
                sx={{
                  padding: "0",
                  margin: "7px 0 0",
                  label: {
                    marginBottom: "5px",
                  },
                }}
              >
                <InputLabel
                  shrink
                  sx={{ marginBottom: "2px !important", marginTop: "16px" }}
                >
                  Notes
                </InputLabel>
                <Field
                  component={TextField}
                  placeholder="Enter Additional Notes Here..."
                  sx={{
                    height: "120px",
                    ".MuiInputBase-root-MuiOutlinedInput-root": {
                      padding: "8px 12px",
                    },
                    ".MuiOutlinedInput-root": {
                      padding: "8px 12px",
                    },
                    "& p.MuiFormHelperText-root": {
                      paddingBottom: "10px",
                    },
                    textarea: {
                      height: "120px !important",
                      padding: "0",
                      "&::placeholder": {
                        color: "#757575",
                        fontSize: "14px",
                        opacity: "1",
                      },
                    },
                  }}
                  multiline
                  inputProps={{
                    maxLength: 500,
                  }}
                  rows={3}
                  helperText="Max. 500 Characters. All notes are public facing."
                  variant="outlined"
                  name="notes"
                  onClick={() => hotjar.event("Input Notes Clicked")}
                />
              </FormControl>
              <FormControl variant="standard"></FormControl>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DefaultProfileCreateForm;
