import React from "react";
import { Paper, styled, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import EmptyState from "./EmptyState";
import { dealSelector } from "../../state";

const PaperCard = styled(Paper)(({ theme }) => ({
  padding: 16,
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(2, 4),
  },
}));

export default function WriteUpCard() {
  const activeDeal = useSelector(dealSelector.activeDeal);
  return (
    <>
      {activeDeal.write_up ? (
        <PaperCard>
          <Typography
            variant="body1"
            sx={{ opacity: 0.7 }}
            dangerouslySetInnerHTML={{ __html: activeDeal.write_up }}
          />
        </PaperCard>
      ) : (
        <EmptyState title="We don’t have additional information about this opportunity" />
      )}
    </>
  );
}
