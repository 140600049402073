import Box from "@mui/material/Box";
export const PartDefaultLendingCriteria = () => {
  return (
    <Box
      className="part-default-lending-criteria"
      sx={{
        position: "relative",
        padding: "2px 8px",
        lineHeight: "14px",
        fontWeight: "500",
        fontSize: "10px",
        color: "#22AA94",
        backgroundColor: "#CEECE8",
        borderRadius: "10px",
        marginRight: "12px",
      }}
    >
      Default
      <Box
        sx={{
          position: "absolute",
          top: "-61px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#03363D",
          color: "#fff",
          fontSize: "14px",
          lineHeight: "16px",
          padding: "14px 13px",
          fontWeight: "400",
          opacity: "0",
          visibility: "hidden",
          pointerEvents: "none",
          transition: ".3s ease",
          textTransform: "initial",
          "&::before": {
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            transform: "translateX(-50%)",
            content: '""',
            display: "block",
            width: 0,
            height: 0,
            borderWidth: "8px 4.5px 0 4.5px",
            borderColor: "#03363D transparent transparent transparent",
            borderStyle: "solid",
          },
        }}
      >
        Matches default lending profile criteria.
      </Box>
    </Box>
  );
};
