import Box from "@mui/material/Box";
import { PageTitle } from "../../pages/main/styles";
import { Typography } from "@mui/material";
import { PartEmptyKey } from "./PartEmptyKey";
import { PartDefaultLendingCriteria } from "./PartDefaultLendingCriteria";
import { PartCustomLendingCriteria } from "./PartCustomLendingCriteria";
import { PartDefaultLendingTeam } from "./PartDefaultLendingTeam";
import { PartCustomLendingTeam } from "./PartCustomLendingTeam";
import { PartNoLendingTeam } from "./PartNoLendingTeam";
export const DefaultLendingKeys = () => {
  return (
    <Box sx={{ padding: 0, position: "relative" }}>
      <PageTitle
        sx={{
          fontSize: 18,
          marginTop: "13px",
          marginBottom: "14px",
          lineHeight: "28px",
        }}
        variant="h4"
      >
        Key:
      </PageTitle>

      <Box sx={{ padding: 0, display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            marginRight: "54px",
            marginBottom: "12px",
          }}
        >
          <PartDefaultLendingCriteria />
          <Typography
            component="span"
            sx={{
              lineHeight: "16px",
              fontSize: "12px",
              color: "#03363D",
            }}
          >
            Default lending criteria
          </Typography>
        </Box>
        <Box
          sx={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            marginRight: "49px",
            marginBottom: "12px",
          }}
        >
          <PartDefaultLendingTeam />
          <Typography
            component="span"
            sx={{
              lineHeight: "16px",
              fontSize: "12px",
              color: "#03363D",
            }}
          >
            Default lending team
          </Typography>
        </Box>
      </Box>

      <Box sx={{ padding: 0, display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            marginRight: "50px",
            marginBottom: "12px",
          }}
        >
          <PartCustomLendingCriteria />
          <Typography
            component="span"
            sx={{
              lineHeight: "16px",
              fontSize: "12px",
              color: "#03363D",
            }}
          >
            Custom lending criteria
          </Typography>
        </Box>

        <Box
          sx={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            marginRight: "53px",
            marginBottom: "12px",
          }}
        >
          <PartCustomLendingTeam />
          <Typography
            component="span"
            sx={{
              lineHeight: "16px",
              fontSize: "12px",
              color: "#03363D",
            }}
          >
            Custom lending team
          </Typography>
        </Box>
      </Box>

      <Box sx={{ padding: 0, display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            marginRight: "43px",
            marginBottom: "12px",
          }}
        >
          <PartEmptyKey />
          <Typography
            component="span"
            sx={{
              lineHeight: "16px",
              fontSize: "12px",
              color: "#03363D",
            }}
          >
            No active lending criteria
          </Typography>
        </Box>
        <Box
          sx={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            marginRight: "44px",
            marginBottom: "12px",
          }}
        >
          <PartNoLendingTeam />
          <Typography
            component="span"
            sx={{
              lineHeight: "16px",
              fontSize: "12px",
              color: "#03363D",
            }}
          >
            No lending team
          </Typography>
        </Box>
      </Box>
      {/* <DefaultProfilesVerification /> */}
    </Box>
  );
};
