import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Box from "@mui/material/Box";
import { PageTitle } from "../../pages/main/styles";
import { DefaultGroup, DefaultRow, EditBtn } from "../lenderProfile/styles";
import MainPages from "../../pages/main";
import { hotjar } from "react-hotjar";
import { useNavigate } from "react-router-dom";
import {
  AppState,
  lendingProfileSelector,
  useSelector,
  fixedFloatingList,
  ownerOccupiedAllowedList,
  uiActions,
  useDispatch,
} from "../../state";

import { useEffect, useState } from "react";
import {
  getLabelFromId,
  getLabelsFromIds,
  getYearsMonthsLabel,
} from "../../lib";
import PartField from "./PartField";
export const DefaultLendingSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeTab = useSelector(lendingProfileSelector.activeTab);
  const {
    propertyTypeList,
    termList,
    amortizationList,
    states,
    minDscrList,
    recourseList,
    prePaymentPenaltyList,
    designatedOriginatorList,
  } = useSelector((state: AppState) => state.lists);

  const [isInterestOnlyYears, setIsInterestOnlyYears] = useState<any>({
    years: false,
    label: "interest_only_months",
  });

  useEffect(() => {
    if (activeTab?.interest_only_years) {
      setIsInterestOnlyYears({
        years: true,
        label: "interest_only_years",
      });
    }
  }, [activeTab]);

  const recourse = getLabelsFromIds(
    activeTab.recourse_ids,
    recourseList,
    "id",
    "value",
  );

  return (
    <Box sx={{ padding: 0 }}>
      <PageTitle
        sx={{
          fontSize: 18,
          display: "flex",
          alignItems: "center",
          marginBottom: "0",
          marginTop: "24px",
        }}
        variant="h4"
      >
        Default Originators
        <EditBtn
          onClick={() => {
            hotjar.event(
              `Lending Profile Default Originator Edit Form Open - ${new Date(
                Date.now(),
              )
                .toUTCString()
                .replace(",", "")}`,
            );
            navigate(MainPages.editDefaultOriginators.path);
            dispatch(uiActions.setFlyoutOpen(false));
            dispatch(uiActions.setAddTab(false));
            dispatch(uiActions.setEditTab(false));
            dispatch(uiActions.setBulkEditColumn(null));
            dispatch(uiActions.setBulkEditRow(null));
            dispatch(uiActions.setBulkEdit(false));
            dispatch(uiActions.setBulkEditAll(null));
          }}
        >
          Edit <FontAwesomeIcon icon={solid("edit")} />
        </EditBtn>
      </PageTitle>

      <Box
        sx={{
          padding: 0,
          color: "#757575",
          lineHeight: "5px",
          fontSize: "14px",
          marginBottom: "24px",
        }}
      >
        <PartField
          value={getLabelsFromIds(
            activeTab.originators,
            designatedOriginatorList,
            "contact_id",
            "contact_name",
          )}
          type="comma-separated"
        />
      </Box>

      <PageTitle
        sx={{
          fontSize: 18,
          display: "flex",
          alignItems: "center",
          marginBottom: "9px",
        }}
        variant="h4"
      >
        Default Criteria
        <EditBtn
          onClick={() => {
            hotjar.event(
              `Lending Profile Default Criteria Edit Form Open - ${new Date(
                Date.now(),
              )
                .toUTCString()
                .replace(",", "")}`,
            );
            navigate(MainPages.editDefaultCriteria.path);
            dispatch(uiActions.setFlyoutOpen(false));
            dispatch(uiActions.setAddTab(false));
            dispatch(uiActions.setEditTab(false));
            dispatch(uiActions.setBulkEditColumn(null));
            dispatch(uiActions.setBulkEditRow(null));
            dispatch(uiActions.setBulkEdit(false));
            dispatch(uiActions.setBulkEditAll(null));
          }}
        >
          Edit <FontAwesomeIcon icon={solid("edit")} />
        </EditBtn>
      </PageTitle>

      <DefaultRow>
        <DefaultGroup>
          <PartField
            value={getLabelsFromIds(
              activeTab.property_type_ids,
              propertyTypeList,
              "id",
              "value",
            )}
            label="Property Type(s)"
            type="comma-separated"
          />
          <PartField
            value={
              activeTab.nationwide
                ? ["Nationwide"]
                : getLabelsFromIds(activeTab.states, states, "id", "state")
            }
            label="State(s)"
            type="comma-separated"
          />
        </DefaultGroup>
      </DefaultRow>

      <DefaultRow>
        <DefaultGroup>
          <PartField
            value={activeTab.min_loan}
            label="Loan Min"
            type="currency"
          />
          <PartField
            value={+activeTab.min_rate}
            label="Min Rate"
            type="percentage"
          />
        </DefaultGroup>
        <DefaultGroup>
          <PartField
            value={activeTab.max_loan}
            label="Loan Max"
            type="currency"
          />
          <PartField
            value={+activeTab.max_rate}
            label="Max Rate"
            type="percentage"
          />
        </DefaultGroup>
        <DefaultGroup>
          <PartField
            value={getLabelsFromIds(
              activeTab.rate_type_ids,
              fixedFloatingList,
              "id",
              "description",
            )}
            label="Rate Type"
            type="comma-separated"
          />
          <PartField
            value={getLabelFromId(
              activeTab.max_amortization,
              amortizationList,
              "id",
              "value",
            )}
            label="Max Amortization"
            type="text"
          />
        </DefaultGroup>
        <DefaultGroup>
          <PartField
            value={getLabelFromId(activeTab.max_term, termList, "id", "value")}
            label="Max Term"
            type="text"
          />
          <PartField
            value={+activeTab.max_ltv}
            label="Max LTV"
            type="percentage"
          />
        </DefaultGroup>
      </DefaultRow>

      <DefaultRow>
        <DefaultGroup>
          <PartField
            value={
              recourse && recourse?.length > 0 ? recourse : ["Not Specified"]
            }
            label="Recourse"
            type="comma-separated"
          />
          <PartField
            value={getLabelFromId(
              activeTab.dscr_id,
              minDscrList,
              "id",
              "value",
            )}
            label="Min DSCR"
            type="text"
          />
        </DefaultGroup>
        <DefaultGroup>
          <PartField
            value={activeTab.origination_fee}
            label="Origination Fee"
            type="percentage"
          />
          <PartField
            value={getLabelsFromIds(
              activeTab.ppp_ids,
              prePaymentPenaltyList,
              "id",
              "value",
            )}
            label="Prepayment Penalty"
            type="comma-separated"
          />
        </DefaultGroup>
        <DefaultGroup>
          <PartField
            value={getYearsMonthsLabel(
              activeTab[isInterestOnlyYears.label],
              isInterestOnlyYears.years,
            )}
            label="Interest Only"
            type="text"
          />
          <PartField
            value={getLabelFromId(
              activeTab.oo_allowed,
              ownerOccupiedAllowedList,
              "value",
              "description",
            )}
            label="Owner Occupied Allowed"
            type="text"
          />
        </DefaultGroup>
      </DefaultRow>
    </Box>
  );
};
