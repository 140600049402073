import { AppThunk, lenderActions } from "..";
import { authClient } from "../../lib/api";
import { apis } from "../../lib/gateways";
import { uiActions } from "../states";
import { deals } from "./state";

const { actions } = deals;

export const dealActions = {
  ...actions,
  //ToDo: implement md5
  getUserDeals(): AppThunk {
    return async (dispatch, getState: any) => {
      const { user_deals } = getState().deals;
      !user_deals?.length && dispatch(uiActions.setLoading(true));
      const { data } = await authClient.get(apis.GetMyDeals);
      dispatch(uiActions.setLoading(false));
      if (data.isResponse) {
        dispatch(dealActions.loadUserDeals(data.data));
        dispatch(lenderActions.setFilteredDeals(data.data));
      } else {
        dispatch(uiActions.showError("Failed to get data"));
      }
    };
  },
  getOtherOpportunities(): AppThunk {
    return async (dispatch, getState: any) => {
      const { other_opportunities } = getState().deals;
      !other_opportunities?.length && dispatch(uiActions.setLoading(true));
      const { data } = await authClient.get(apis.GetOtherDeals);
      dispatch(uiActions.setLoading(false));
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get other opportunities"));
      } else {
        dispatch(dealActions.loadOtherOpportunities(data.data));
        dispatch(lenderActions.setFilteredOtherDeals(data.data));
      }
    };
  },
  getDealCompetingQuotes(dealId): AppThunk {
    return async (dispatch, getState: any) => {
      const { user } = getState().auth;
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.get(
        apis.GetCompetingQuotes(dealId, user.loanTrackerId),
      );
      if (!data.isResponse) {
        dispatch(uiActions.showError("Failed to get competing quotes"));
      } else {
        dispatch(
          dealActions.updateDealCompetingQuotes({ dealId, data: data.data }),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getDealDocuments(dealId, id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.get(apis.GetDealDocuments(id));
      if (data.isResponse && data.data) {
        dispatch(dealActions.updateDealDocuments({ dealId, data: data.data }));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getGoogleDoc(id, fileName): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.get(apis.GetGoogleDoc(id));
      if (data.isResponse) {
        authClient.download(data.data, fileName);
      } else {
        dispatch(uiActions.showError("Failed to download document"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  saveQuote(values): AppThunk {
    return async (dispatch, getState: any) => {
      const { user } = getState().auth;
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.post(
        apis.SaveQuote(user.loanTrackerId, values.quote_id),
        values,
      );
      if (data.isResponse) {
        await dispatch(dealActions.saveQuoteSuccess(values.quote_id));
      } else {
        dispatch(uiActions.showError(data.message ?? "Saving Quote Failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  saveQuoteSuccess(dealId): AppThunk {
    return async (dispatch, getState: any) => {
      await dispatch(dealActions.getUserDeals());
      dispatch(dealActions.getDealCompetingQuotes(dealId));
      dispatch(uiActions.setQuotesOpen(false));
      dispatch(uiActions.showSuccess("Quote Saved Successfully"));
    };
  },
  removeDealFromOpportunities(deal): AppThunk {
    return async (dispatch, getState: any) => {
      const { user } = getState().auth;
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.post(
        apis.SaveQuote(user.loanTrackerId, deal.quote_id),
        deal,
      );
      if (data.isResponse) {
        // await dispatch(dealActions.getUserDeals);
        dispatch(uiActions.showSuccess("Removed Deal Successfully"));
      } else {
        dispatch(uiActions.showError(data.message ?? "Removing Deal Failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  moveToActiveQuotes(quoteId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.put(
        apis.MoveToActiveQuotes(quoteId),
        quoteId,
      );
      dispatch(uiActions.setLoading(false));
      if (data.isResponse) {
        dispatch(dealActions.removeOtherDeal(quoteId));
        dispatch(uiActions.showSuccess("Quote moved to active quotes"));
        dispatch(dealActions.getUserDeals());
      } else {
        dispatch(uiActions.showError(`Failed to move quote to active quotes`));
      }
    };
  },
};
