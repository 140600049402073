import React from "react";
import { Avatar } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";

const UserAvatar = () => {
  const { user } = useAuth0();

  return (
    <Avatar
      sx={{
        width: 30,
        height: 30,
        fontSize: 11,
        backgroundColor: "secondary.main",
      }}
    >
      {user?.given_name?.charAt(0)}
      {user?.family_name?.charAt(0)}
    </Avatar>
  );
};

export default UserAvatar;
