import React from "react";
import { Box, styled, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export const InfoBox = styled(Box)(({ theme }) => ({
  minHeight: 40,
  width: "437px",
  height: "81px",
  borderRadius: 3,
  padding: 12,
  gap: "12px",
  backgroundColor: "rgba(31,159,138,0.13)",
  display: "flex",
  alignItems: "start",
  marginRight: "12px",
  marginTop: "12px",
  marginBottom: "12px",
  marginLeft: "40px",
}));

export default function OtherDealsInfoBanner({ value }) {
  return (
    <InfoBox>
      <FontAwesomeIcon
        icon={solid("info-circle")}
        style={{
          margin: "0",
          padding: "0",
          paddingTop: "5px",
          height: "16px",
          width: "16px",
        }}
      />
      <Typography
        component={"span"}
        style={{
          alignItems: "center",
          fontSize: "14px",
          lineHeight: "19.38px",
          height: "57px",
          width: "385px",
        }}
      >
        {value === "1" && (
          <>
            Opportunities listed{" "}
            <Typography
              component={"span"}
              style={{
                alignItems: "center",
                fontSize: "14px",
                lineHeight: "19.38px",
                height: "57px",
                width: "385px",
              }}
              sx={{ textDecoration: "underline" }}
              display="inline"
            >
              below were added by you
            </Typography>{" "}
            or have been identified by our team to be a deal you could win. You
            can submit and view your quotes on any of these deals.
          </>
        )}
        {value === "2" && (
          <>
            Opportunities listed match your lending criteria. Select an
            opportunity and move it to your <b>‘Active Quotes’</b> list in order
            to submit a quote.
          </>
        )}
      </Typography>
    </InfoBox>
  );
}
