import React from "react";
import { Grid, Paper, styled, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  dealActions,
  dealSelector,
  useDispatch,
  useSelector,
} from "../../state";
import EmptyState from "./EmptyState";

const PaperCard = styled(Paper)(({ theme }) => ({
  padding: 16,
  marginBottom: 16,
  [theme.breakpoints.up("sm")]: {
    maxHeight: 71,
    marginBottom: 24,
    alignItems: "center",
  },
}));

export default function DocumentTab() {
  const dispatch = useDispatch();
  const documents = useSelector(dealSelector.documents);
  const handleDownload = (id, fileName) => {
    dispatch(dealActions.getGoogleDoc(id, fileName));
  };
  return (
    <>
      {documents.length > 0 ? (
        documents.map(({ id, title }) => (
          <PaperCard key={id}>
            <Grid
              container
              spacing={4}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <FontAwesomeIcon size="lg" icon={solid("file-alt")} />
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  color="text.primary"
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => handleDownload(id, title)}
                >
                  {title}
                </Typography>
              </Grid>
            </Grid>
          </PaperCard>
        ))
      ) : (
        <EmptyState title="We don’t have any documents for this opportunity" />
      )}
    </>
  );
}
