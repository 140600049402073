/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { Field, useFormikContext } from "formik";
import { TextField, Select } from "formik-mui";
import { YearsPerExtensionField } from "./YearsPerExtensionField";
import { NumberFormatterDecimals } from "../../inputs/NumberFormatterDecimals";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useSelector, AppState, rateList, dealSelector } from "../../../state";
import { PercentFormatter } from "../../inputs/PercentFormatter";
import { DropdownFormInput } from "../../inputs/DropdownFormInput";
import { FormPercentInput } from "../../inputs/FormPercentInput";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FieldWrapper } from "../styles";

export const RateSpreadComponent = ({
  isExtYears,
  setIsExtYears,
  //isSecondPeriod,
  //setSecondPeriod,
  indexFloor,
  setIndexFloor,
  rateFloor,
  setRateFloor,
}) => {
  const { indexTypes } = useSelector((state: AppState) => state.lists);
  const { setFieldValue, values, handleChange }: any = useFormikContext();
  const initialValues = useSelector(dealSelector.quoteDealInitialState);
  const [isIndexGrossSpread, setIndexGrossSpread] = useState(true);
  const [isTotalRate, setTotalRate] = useState(false);
  const [radioRates, setRadioRates] = useState("index_plus_gross");

  useEffect(() => {
    let s, it;
    if (typeof initialValues.spread === "number") {
      s = initialValues.spread;
    } else {
      s = 0;
    }
    if (typeof initialValues.index_today_rate === "number") {
      it = initialValues.index_today_rate;
    } else {
      it = 0;
    }
    if (s === 0 && it === 0) {
      setRadioRates("total_rate");
      setTotalRate(true);
      setIndexGrossSpread(false);
    } else {
      setRadioRates("index_plus_gross");
      setIndexGrossSpread(true);
      setTotalRate(false);
      setFieldValue(
        "rate",
        Number(String(s).replaceAll(",", "")) +
          Number(String(it).replaceAll(",", "")),
      );
    }
  }, []);

  useEffect(() => {
    console.log("values.rate_type_name", values.rate_type_name);
    if (values.rate_type_name === "Fixed") {
      setFieldValue("spread_type", "fix");
      setFieldValue("rate_type", 2);
      //setSecondPeriod(false);
      if (
        values?.quote_index ||
        values.index_today_rate > 0 ||
        values.spread > 0
      ) {
        setFieldValue(
          "rate",
          Number(String(values.spread).replaceAll(",", "")) +
            Number(String(values.index_today_rate).replaceAll(",", "")),
        );
      } else if (Number(values.rate) > 0) {
        setFieldValue("spread_type", "");
        setFieldValue("rate_type", 1);
      }
    }
    // user enters rate type variable and either rate or index/spread - both have same spread type and rate type
    else if (values.rate_type_name === "Variable") {
      setFieldValue("spread_type", "float");
      setFieldValue("rate_type", 2);
      //setSecondPeriod(false);
    } else if (values.rate_type_name === "Hybrid") {
      setFieldValue("spread_type", "fix");
      setFieldValue("rate_type", 3);
      //setFieldValue("rate_type_name", "Hybrid");
      //setSecondPeriod(true);

      if (
        values?.quote_index ||
        values.index_today_rate > 0 ||
        values.spread > 0
      ) {
        setFieldValue("spread_type", "fix");
      } else if (Number(values.rate) > 0) {
        setFieldValue("spread_type", "");
      }
    }
    // if (values.number_of_option) {
    //   setFieldValue("rate_type", 3);
    //   setFieldValue("rate_type_name", "Hybrid");
    //   //setSecondPeriod(true);
    //   if (
    //     values?.quote_index ||
    //     values.index_today_rate > 0 ||
    //     values.spread > 0
    //   ) {
    //     setFieldValue("spread_type", "fix");
    //   } else if (Number(values.rate) > 0) {
    //     setFieldValue("spread_type", "");
    //   }
    // } else if (values.rate_type === 2) {
    //   //setSecondPeriod(false);
    //   if (
    //     values?.quote_index ||
    //     values.index_today_rate > 0 ||
    //     values.spread > 0
    //   ) {
    //     setFieldValue(
    //       "rate",
    //       Number(String(values.spread).replaceAll(",", "")) +
    //         Number(String(values.index_today_rate).replaceAll(",", "")),
    //     );
    //     setFieldValue("spread_type", "fix");
    //     setFieldValue("rate_type_name", "Fixed");
    //   } else if (Number(values.rate) > 0) {
    //     setFieldValue("spread_type", "float");
    //     setFieldValue("rate_type_name", "Variable");
    //   }
    // }
    // user enters rate type variable and either rate or index/spread - both have same spread type and rate type
    // else if (values.rate_type === 1) {
    //   setFieldValue("spread_type", "float");
    //   setFieldValue("rate_type", 2);
    //   //setSecondPeriod(false);
    //   if (Number(values.rate) > 0) {
    //     setFieldValue("rate_type_name", "Fixed");
    //     setFieldValue("spread_type", "");
    //   }
    // }
  }, [values.rate_type_name]);

  // useEffect(() => {
  //   if (isSecondPeriod) {
  //     setFieldValue("number_of_option", initialValues.number_of_option);
  //     setFieldValue("option_months", initialValues.option_months);
  //     setFieldValue("option", initialValues.option);
  //     setFieldValue("adjustment_spread", initialValues.adjustment_spread);
  //     setFieldValue("adjustment_index", initialValues.adjustment_index);
  //     setFieldValue("index_floor", initialValues.index_floor);
  //     setFieldValue("rate_floor", initialValues.rate_floor);
  //   } else {
  //     setFieldValue("number_of_option", "");
  //     setFieldValue("option_months", "");
  //     setFieldValue("option", "");
  //     setFieldValue("adjustment_spread", "");
  //     setFieldValue("adjustment_index", "");
  //     setFieldValue("index_floor", "");
  //     setFieldValue("rate_floor", "");
  //   }
  // }, []);

  const useStyles = makeStyles(() => ({
    input: {
      background: "#CFCFCF",
    },
  }));

  const handleFieldCalc = useCallback((e, otherValue) => {
    const firstVal = e;
    const secondVal = otherValue;
    if (firstVal >= 0 || secondVal >= 0) {
      let num =
        (Number(String(firstVal).replaceAll(",", "")) ?? 0) +
        (Number(String(secondVal).replaceAll(",", "")) ?? 0);
      setFieldValue("rate", num);
    } else {
      setFieldValue("rate", "");
    }
  }, []);

  function clearInputs() {
    setFieldValue("quote_index", "");
    setFieldValue("index_today_rate", "");
    setFieldValue("spread", "");
    setFieldValue("rate", "");
    // setFieldValue("number_of_option", initialValues.number_of_option || "");
    // setFieldValue("option", initialValues.option || "");
    // setFieldValue("option_months", initialValues.option_months || "");
    // setFieldValue("adjustment_spread", initialValues.adjustment_spread || "");
    // setFieldValue("adjustment_index", initialValues.adjustment_index || "");
    // setFieldValue("index_floor", initialValues.index_floor || "");
    // setFieldValue("rate_floor", initialValues.rate_floor || "");
  }

  const classes = useStyles();

  return (
    <>
      <FieldWrapper>
        <Typography
          variant="h3"
          sx={{
            fontFamily: "ABC Monument Grotesk",
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "28px",
            color: "#03363D",
          }}
        >
          Rate
        </Typography>
      </FieldWrapper>
      <FieldWrapper sx={{ marginTop: "0" }}>
        <RadioGroup
          value={radioRates}
          name="rate-entry-type"
          sx={{
            "& .MuiSvgIcon-root": {
              color: "#03363D",
            },
          }}
        >
          <FormControlLabel
            value="index_plus_gross"
            control={
              <Radio
                onChange={(event) => {
                  setIndexGrossSpread(event.target.checked);
                  setTotalRate(!event.target.checked);
                  clearInputs();
                  setRadioRates("index_plus_gross");
                }}
                sx={{
                  color: "#22AA94 !important",
                  marginLeft: "1px",
                  height: "18px",
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                    color: !isIndexGrossSpread ? "#C3C3C3" : "#03363D",
                  },
                }}
              />
            }
            label="Enter Index + Gross Spread"
          />
          <FormControlLabel
            value="total_rate"
            control={
              <Radio
                onChange={(event) => {
                  setTotalRate(event.target.checked);
                  setIndexGrossSpread(!event.target.checked);
                  clearInputs();
                  setRadioRates("total_rate");
                }}
                sx={{
                  color: "#22AA94 !important",
                  marginLeft: "1px",
                  height: "18px",
                  "& .MuiSvgIcon-root": {
                    fontSize: 18,
                    color: !isTotalRate ? "#C3C3C3" : "#03363D",
                  },
                }}
              />
            }
            label="Enter Total Rate"
          />
        </RadioGroup>
      </FieldWrapper>
      <FieldWrapper>
        <DropdownFormInput
          label="Rate Type"
          isRequired={true}
          fieldName="rate_type_name"
          list={rateList}
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormControl variant="standard">
          <InputLabel shrink>{"Index Type"}</InputLabel>
          <Field
            component={Select}
            variant="outlined"
            displayEmpty
            IconComponent={KeyboardArrowDownIcon}
            name="quote_index"
            disabled={isTotalRate}
            value={values?.quote_index || ""}
            onChange={(e) => {
              setFieldValue("quote_index", e.target.value);
            }}
          >
            <MenuItem
              disabled
              hidden
              style={{ display: "none" }}
              key={-0}
              value={""}
            >
              {/* TODO: this */}
              {!isTotalRate ? (
                <span style={{ color: "#a9a9a9" }}>Select Index Type</span>
              ) : (
                <span style={{ color: "red" }}>Select Index Type</span>
              )}
            </MenuItem>
            {indexTypes?.map((obj: any) => (
              <MenuItem
                onClick={() => {
                  setFieldValue("index_today_rate", obj?.rate);
                  handleFieldCalc(obj?.rate, values.spread);
                }}
                key={obj.name}
                value={obj.name}
              >
                {obj.name}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <FormPercentInput
          onChange={(e) => {
            handleChange(e);
            handleFieldCalc(e.target.value, values.spread);
            setFieldValue("index_today_rate", e.target.value);
          }}
          InputPropKey="className"
          InputPropVal={isIndexGrossSpread ? classes.input : null}
          isDisabled={true}
          label={` Index Rate`}
          fieldName="index_today_rate"
          overrideStyle={{ backGround: "1px solid red" }}
        />
      </FieldWrapper>
      <FieldWrapper>
        <FormPercentInput
          fieldName="spread"
          onChange={(e) => {
            handleChange(e);
            handleFieldCalc(e.target.value, values.index_today_rate);
          }}
          label={`Gross Spread`}
          isDisabled={isTotalRate}
        />

        <FormPercentInput
          label={`Total Rate`}
          InputPropKey="className"
          InputPropVal={isIndexGrossSpread ? classes.input : null}
          fieldName="rate"
          isDisabled={isIndexGrossSpread}
          isRequired={true}
        />
      </FieldWrapper>
      {false ? ( // removing isSecondPeriod till we iron out all the complications
        <>
          <FieldWrapper>
            <Typography
              variant="h3"
              sx={{
                fontFamily: "ABC Monument Grotesk",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "28px",
                color: "#03363D",
              }}
            >
              2nd Period
            </Typography>
          </FieldWrapper>

          <FieldWrapper>
            <FormControl variant="standard">
              <InputLabel shrink>
                Extension Options<span style={{ color: "#E91C1C" }}>*</span>
              </InputLabel>
              <Field
                component={TextField}
                name="number_of_option"
                placeholder="Enter Extension Options"
                variant="outlined"
                sx={{ "& .MuiInputBase-root": { pr: 0 } }}
                InputProps={{
                  inputComponent: NumberFormatterDecimals,
                }}
              />
            </FormControl>
            <YearsPerExtensionField
              setIsExtYears={setIsExtYears}
              isExtYears={isExtYears}
            />
          </FieldWrapper>
          <FieldWrapper>
            <FormControl variant="standard">
              <InputLabel shrink>
                {
                  <>
                    Adjustment Index
                    <span style={{ color: "#E91C1C" }}>*</span>
                  </>
                }
              </InputLabel>
              <Field
                component={Select}
                variant="outlined"
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                name="adjustment_index"
                //disabled={isTotalRate}
                value={values?.adjustment_index || ""}
                onChange={(e) =>
                  setFieldValue("adjustment_index", e.target.value)
                }
              >
                <MenuItem
                  disabled
                  hidden
                  style={{ display: "none" }}
                  key={-0}
                  value={""}
                >
                  <span style={{ color: "#a9a9a9" }}>
                    Enter Adjustment Index
                  </span>
                </MenuItem>
                {indexTypes?.map((obj: any) => (
                  <MenuItem
                    onClick={() => {
                      setFieldValue("adjustment_index", obj?.name);
                    }}
                    key={obj.name}
                    value={obj.name}
                  >
                    {obj.name}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>

            <FormControl variant="standard">
              <span>
                <InputLabel shrink>
                  {
                    <>
                      Adjustment Spread
                      <span style={{ color: "#E91C1C" }}>*</span>
                    </>
                  }
                </InputLabel>
                <Field
                  component={TextField}
                  variant="outlined"
                  name="adjustment_spread"
                  placeholder="Enter Spread"
                  onBlur={(e) => {
                    setFieldValue("adjustment_spread", e.target.value);
                  }}
                  InputProps={{
                    inputComponent: PercentFormatter,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </span>
            </FormControl>
          </FieldWrapper>
          <FieldWrapper>
            <FormControl variant="standard">
              <span>
                <InputLabel shrink>Index Floor</InputLabel>

                <Field
                  component={TextField}
                  variant="outlined"
                  placeholder="Enter Index Floor"
                  name="index_floor"
                  value={indexFloor}
                  onChange={(e) => {
                    handleChange(e);
                    if (+e.target.value === 0) {
                      setFieldValue("index_floor", null);
                      setIndexFloor("");
                    } else {
                      setFieldValue("index_floor", +e.target.value);
                      setIndexFloor(+e.target.value);
                    }
                  }}
                  InputProps={{
                    inputComponent: PercentFormatter,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </span>
            </FormControl>

            <FormControl variant="standard">
              <span>
                <InputLabel shrink>Rate Floor</InputLabel>
                <Field
                  component={TextField}
                  variant="outlined"
                  name="rate_floor"
                  value={rateFloor}
                  placeholder="Enter Rate Floor"
                  onChange={(e) => {
                    if (+e.target.value === 0) {
                      setFieldValue("rate_floor", null);
                      setRateFloor("");
                    } else {
                      setFieldValue("rate_floor", +e.target.value);
                      setRateFloor(+e.target.value);
                    }
                  }}
                  InputProps={{
                    inputComponent: PercentFormatter,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </span>
            </FormControl>
          </FieldWrapper>
        </>
      ) : null}
    </>
  );
};
