import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface Deal {
  id: number;
  address: {
    street_name: string;
    street_number: string;
    city: string;
    state: string;
    zip: string;
  };
  bank_fee: string;
  bank_fee_out: string;
  ltv_stabilized: string;
  ltc: string;
  portfolio_name?: string;
  is_quoted: boolean;
  issue_date: string;
  property_type: string;
  loan_amount: string;
  requested_amount: string;
  quoted_amount: string;
  term: string;
  term_months: string;
  amortization: string;
  option: number;
  option_months: number;
  deal_display_status: string;
  number_of_option: number;
  transaction_type: string;
  activity_date: string;
  loan_type: string;
  hot_button: string;
  date_sent: string;
  deal_type: string;
  rate_type: string;
  client?: {
    first_name: string;
    last_name: string;
    id: number;
  };
  contact_info?: {
    id: number;
    first_name: string;
    last_name: string;
  };
}

export interface Contact {
  name: string;
  address: string;
  email: string;
  mobile: string;
  phone: string;
  img: string | undefined;
}

export interface DealState {
  activeDealId: null | number;
  user_deals: Deal[];
  other_opportunities: Deal[];
}

const initialState: DealState = {
  activeDealId: null,
  other_opportunities: [],
  user_deals: [],
};

export const deals = {
  ...createSlice({
    name: "deals",
    initialState,
    reducers: {
      setDealState(state, { payload }: PayloadAction<DealState | undefined>) {
        if (!payload) {
          return initialState;
        }
        Object.assign(state, payload);
      },
      loadUserDeals(state, { payload }: PayloadAction<any>) {
        state.user_deals = payload ?? [];
      },
      updateUserDealDetails(state, { payload }: PayloadAction<any>) {
        state.user_deals = state.user_deals.map((d) => {
          if (d.id === payload.id) {
            return {
              ...d,
              ...payload,
            };
          } else {
            return d;
          }
        });
      },
      updateDealCompetingQuotes(state, { payload }: PayloadAction<any>) {
        state.user_deals = state.user_deals.map((d) => {
          if (d.id === payload.dealId) {
            return {
              ...d,
              competing_quotes: payload.data,
            };
          } else {
            return d;
          }
        });
      },
      updateDealDocuments(state, { payload }: PayloadAction<any>) {
        state.user_deals = state.user_deals.map((d) => {
          if (d.id === payload.dealId) {
            return {
              ...d,
              documents: payload.data,
            };
          } else {
            return d;
          }
        });
      },
      loadOtherOpportunities(state, { payload }: PayloadAction<any>) {
        state.other_opportunities = payload;
      },
      loadActiveDealId(state, { payload }: PayloadAction<any>) {
        state.activeDealId = payload;
      },
      removeOtherDeal(state, { payload }: PayloadAction<any>) {
        state.other_opportunities = state.other_opportunities.filter(
          (d) => d.id !== payload,
        );
      },
    },
  }),
  persist: true,
};
