import React from "react";
import { Avatar, Button, CardActions, Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { uiActions } from "../../state";
import { EditAccountInfoModal } from "../../components/modals/EditAccountInfoModal";
import { CardContainer, PaperCard } from "../../pages/main/AccountPage";
import { hotjar } from "react-hotjar";
import { useAuth0 } from "@auth0/auth0-react";

export default function AccountInfoCard() {
  const dispatch = useDispatch();
  const { isAuthenticated, user } = useAuth0();

  const handleEditUserProfile = () => {
    dispatch(uiActions.setEditProfileModalOpen(true));
    hotjar.event("Button Edit Personal Info Flyout Open");
  };

  return isAuthenticated ? (
    <>
      <PaperCard>
        <CardContainer>
          <Grid container sx={{ p: 2 }} spacing={2}>
            <Grid item>
              <Avatar
                sx={{
                  height: 61,
                  width: 61,
                  backgroundColor: "secondary.main",
                }}
              >
                {user?.given_name?.charAt(0)}
                {user?.family_name?.charAt(0)}
              </Avatar>
            </Grid>
            <Grid item>
              <Typography variant="h2" sx={{ pl: 0 }}>
                {user?.given_name + " " + user?.family_name}
              </Typography>
              {/* <Typography variant="body1" color="secondary.main">
                {companyName}
              </Typography> */}
              <Typography variant="body1" color="text.primary" sx={{ mt: 2 }}>
                {user?.email}
              </Typography>
            </Grid>
          </Grid>
          <CardActions sx={{ ml: 2, mt: 1 }}>
            <Button
              variant="contained"
              sx={{
                width: 196,
                backgroundColor: "text.primary",
              }}
              onClick={handleEditUserProfile}
            >
              Edit personal info
            </Button>
          </CardActions>
        </CardContainer>
      </PaperCard>
      <EditAccountInfoModal />
    </>
  ) : null;
}
