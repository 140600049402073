import React, { useEffect } from "react";
import { Backdrop, CssBaseline } from "@mui/material";
import { uiSelectors, useSelector } from "../../state";
import MiniDrawer from "./MiniDrawer";
import MobileDrawer from "./MobileDrawer";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import { MainContentStyled } from "./MainLayout.styles";
import { useMobile } from "../../themes";
import { useAuth0 } from "@auth0/auth0-react";
import { refeshTokenHelper } from "../../lib/utils";
import MobileTopBar from "./MobileTopBar";
import MobileBottomNavigation from "./MobileBottomNavigation";
import Loader from "../../assets/img/loader.gif";

export const MainLayout = React.memo(function () {
  const loading = useSelector(uiSelectors.loading);
  const isMobile = useMobile();
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  refeshTokenHelper.tokenGetter = getAccessTokenSilently;
  refeshTokenHelper.isAuthenticated = isAuthenticated;

  const { loginWithRedirect } = useAuth0();
  refeshTokenHelper.tokenGetter = getAccessTokenSilently;
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      loginWithRedirect();
    }
  }, [isAuthenticated, loginWithRedirect, isLoading]);
  return isAuthenticated ? (
    <>
      <CssBaseline />
      {isMobile ? (
        <>
          <MobileTopBar />
          <MobileDrawer />
        </>
      ) : (
        <MiniDrawer />
      )}
      <MainContentStyled>
        <Outlet />
      </MainContentStyled>
      {isMobile && <MobileBottomNavigation />}
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <img
            style={{
              width: "128px",
              transition: "350ms ease",
            }}
            src={Loader}
            alt="Loader"
          />
        </Backdrop>
      )}
    </>
  ) : null;
});
