import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Avatar, Box, Button, Grid, styled, Typography } from "@mui/material";
import leftG from "../assets/img/G-gradient-left.png";
import rightG from "../assets/img/G-gradient-right.png";
import companyLogo from "../assets/img/company-logo.svg";
import AuthPages from "./auth";
import { authActions, useAction } from "../state";

export const Container = styled(Box)(({ theme }) => ({
  background: "linear-gradient(90deg, #E2F6F3 0%, #DCF8FF 100%)",
  minHeight: "100vh",
  alignItems: "center",
  justifyContent: "center",
  margin: -24,
  marginLeft: -96,
  marginTop: -40,
  zIndex: 10000,
  position: "fixed",
  width: "100%",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    marginLeft: -15,
    marginTop: -80,
  },
}));

const IconBox = styled(`div`)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "end",
}));

export const LoggedOutpage = React.memo(
  /**
   *
   */
  function LoggedOutPage() {
    const { logout } = useAuth0();
    const _logout = useAction(authActions.logout);

    return (
      <Container>
        <Grid container sx={{ minHeight: "100vh" }}>
          <Grid item xs={3} md={4}>
            <img
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "22vw",
              }}
              src={leftG}
              alt="big-g"
            />
          </Grid>
          <Grid item xs={6} md={4} sx={{ mt: 11 }} justifyContent="center">
            <img src={companyLogo} width="240px" alt="GParency" />
            <Typography
              variant="h1"
              sx={{ mb: 4, mt: 12, lineHeight: "140px" }}
            >
              <IconBox>
                <FontAwesomeIcon
                  color="#1F9F8A"
                  style={{ height: 105, width: 100 }}
                  icon={solid("shield-alt")}
                />
                <Avatar sx={{ backgroundColor: "#03363D", ml: -5 }}>
                  <FontAwesomeIcon
                    color="#FFFFFF"
                    size={"1x"}
                    icon={solid("sign-out")}
                  />
                </Avatar>
              </IconBox>
            </Typography>
            <Typography
              variant="h3"
              sx={{ fontWeight: 400, textAlign: "center" }}
            >
              For your safety, you've been logged out
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                _logout().then(() => {
                  logout({
                    returnTo: `${window.location.origin}${AuthPages.login.path}`,
                  });
                })
              }
              sx={{ maxWidth: 240, marginTop: 10 }}
            >
              Login
            </Button>
          </Grid>
          <Grid item md={4} xs={3}>
            <img
              style={{
                position: "absolute",
                right: 0,
                bottom: -80,
                width: "22vw",
              }}
              src={rightG}
              alt="big-g"
            />
          </Grid>
        </Grid>
      </Container>
    );
  },
);
