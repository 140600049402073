import { Typography } from "@mui/material";
import React, { useEffect } from "react";

interface PartFieldProps {
  value: any;
  label?: string;
  type: "currency" | "percentage" | "number" | "text" | "comma-separated";
}

const PartField = ({ value, type, label }: PartFieldProps) => {
  const [displayValue, setDisplayValue] = React.useState(value);

  useEffect(() => {
    if (value) {
      switch (type) {
        case "currency":
          setDisplayValue(
            value.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            }),
          );
          break;
        case "percentage":
          const formattedValue = parseFloat(value);
          setDisplayValue(formattedValue.toFixed(2) + "%");
          break;
        case "number":
          setDisplayValue(value.toLocaleString("en-US"));
          break;
        case "comma-separated":
          value.length === 0
            ? setDisplayValue("-")
            : setDisplayValue(value.join(", "));
          break;
        case "text":
          setDisplayValue(value);
          break;
        default:
          setDisplayValue(value);
      }
    } else {
      setDisplayValue("-");
    }
  }, [setDisplayValue, value, type]);

  return (
    <Typography component="span">
      {label && (
        <React.Fragment>
          <b>{label}:</b>{" "}
        </React.Fragment>
      )}
      <span
        style={{ display: "inline-block", color: "#757575", fontSize: "14px" }}
        title={displayValue}
      >
        {displayValue?.length > 35
          ? displayValue.slice(0, 35) + "..."
          : displayValue}
      </span>
    </Typography>
  );
};

export default PartField;
