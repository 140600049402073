import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, CardContent } from "@mui/material";
import { Formik, Form } from "formik";
import {
  AppState,
  lendingProfileSelector,
  lendingProfileActions,
  authSelectors,
  uiActions,
  fixedFloatingList,
} from "../../state";
import { FieldWrapper, FormContainer, LastSavedWrapper } from "../Admin/styles";
import { LendingProfileFields } from "./LendingProfileFields";
import { hotjar } from "react-hotjar";
import { findIds, getLabelsFromIds } from "../../lib/utils";
import { lendingProfileValidationSchema } from "../../lib/validationSchemas";
import { GPPrimaryButton } from "./styles";

export const LendingProfileForm = React.memo(() => {
  const dispatch = useDispatch();

  const user = useSelector((state: AppState) => state.auth.user);
  const activeDefaultLP = useSelector(lendingProfileSelector.activeDefaultLP);
  const activeTab = useSelector(lendingProfileSelector.activeTab);
  const _initialValues = useSelector(
    lendingProfileSelector.lendingProfileInitialState,
  );
  const activeConciergeOriginatorId = useSelector(
    authSelectors.activeConciergeOriginatorId,
  );

  const {
    propertyTypeList,
    states,
    recourseList,
    prePaymentPenaltyList,
    designatedOriginatorList,
  } = useSelector((state: AppState) => state.lists);

  const [initialValues, setInitialValues] = useState(_initialValues);
  const { executionList, loanTypeList } = useSelector(
    (state: AppState) => state.lists,
  );

  const preFillForm = (values, setFieldValue?) => {
    const last_updated = values?.last_updated || null;
    const last_verified = values?.last_verified || null;
    const max_ltv = values?.max_ltv || null;
    const min_loan = values?.min_loan || null;
    const max_loan = values?.max_loan || null;
    const min_rate = values?.min_rate || null;
    const max_rate = values?.max_rate || null;
    const max_term = values?.max_term || 0;
    const max_ltv_non_recourse = values?.max_ltv_non_recourse || null;
    const origination_fee = values?.origination_fee || 0;

    const statesArr = values?.nationwide
      ? ["Nationwide"]
      : getLabelsFromIds(values?.states, states, "id", "state");

    const io_years = values?.io_years
      ? values?.io_years
      : values?.interest_only_years
      ? values?.interest_only_years
      : "";

    const rateTypes = values?.fixed_floating
      ? getLabelsFromIds(
          values?.fixed_floating,
          fixedFloatingList,
          "id",
          "description",
        )
      : values?.rate_type_ids
      ? getLabelsFromIds(
          values?.rate_type_ids,
          fixedFloatingList,
          "id",
          "description",
        )
      : [];

    const pre_payment_penalty_id = values?.pre_payment_penalty
      ? getLabelsFromIds(
          values?.pre_payment_penalty,
          prePaymentPenaltyList,
          "id",
          "value",
        )
      : values?.ppp_ids
      ? getLabelsFromIds(values?.ppp_ids, prePaymentPenaltyList, "id", "value")
      : [];

    const io_months = values?.io_months
      ? values?.io_months
      : values?.interest_only_months
      ? values?.interest_only_months
      : "";

    let interestOnlyObject = {};
    if (io_years) {
      interestOnlyObject = {
        io_years: +io_years,
      };
    }
    if (io_months) {
      interestOnlyObject = {
        io_months: +io_months,
      };
    }

    const recourse = values?.recourse_ids
      ? getLabelsFromIds(values?.recourse_ids, recourseList, "id", "value")
      : getLabelsFromIds(values?.recourse, recourseList, "id", "value");
    const recourse_id = recourse?.length > 0 ? recourse : ["Not Specified"];

    const max_term_id = values?.max_term;
    const max_amortization_id = values?.max_amortization;
    const min_dscr = values?.min_dscr
      ? +values?.min_dscr
      : values?.dscr_id
      ? +values?.dscr_id
      : "";

    const ooc = values?.hasOwnProperty("oo_allowed")
      ? values?.oo_allowed
      : values?.owner_occupied;
    let owner_occupied = ooc === 0 ? 0 : ooc === 1 ? 1 : null;

    const propertyTypes = values?.property_types
      ? getLabelsFromIds(values.property_types, propertyTypeList, "id", "value")
      : values?.property_type_ids
      ? getLabelsFromIds(
          values?.property_type_ids,
          propertyTypeList,
          "id",
          "value",
        )
      : [];

    let originators = [];
    if (
      values &&
      values?.originators !== null &&
      values?.originators !== undefined
    ) {
      originators = designatedOriginatorList.filter((originator: any) => {
        return values.originators
          .split(",")
          .map(Number)
          .includes(originator.contact_id);
      });
    }

    if (setFieldValue) {
      setFieldValue("last_updated", last_updated);
      setFieldValue("last_verified", last_verified);
      setFieldValue("max_ltv", max_ltv);
      setFieldValue("propertyTypes", propertyTypes);
      setFieldValue("states", statesArr);
      setFieldValue("min_loan", min_loan);
      setFieldValue("max_loan", max_loan);
      setFieldValue("min_rate", min_rate);
      setFieldValue("max_rate", max_rate);
      setFieldValue("rateTypes", rateTypes);
      setFieldValue("recourse_id", recourse_id);
      setFieldValue("max_term_id", +max_term_id);
      setFieldValue("max_amortization_id", max_amortization_id);
      setFieldValue("min_dscr", min_dscr);
      setFieldValue("pre_payment_penalty_id", pre_payment_penalty_id);
      setFieldValue("owner_occupied", owner_occupied);
      setFieldValue("origination_fee", origination_fee);
      setFieldValue(
        `${values.interest_only_years ? "io_years" : "io_months"}`,
        values.interest_only_years
          ? values.interest_only_years
          : values.interest_only_months,
      );
      setFieldValue("note", activeDefaultLP.property_types?.note);
    } else {
      const note = activeDefaultLP.form_profile_data?.external_note;

      setInitialValues({
        ..._initialValues,
        last_updated,
        last_verified,
        max_ltv,
        min_loan,
        max_loan,
        min_rate,
        max_term,
        max_rate,
        origination_fee,
        max_ltv_non_recourse,
        originators,
        states: statesArr,
        propertyTypes,
        rateTypes,
        max_term_id: +max_term_id,
        recourse_id,
        max_amortization_id,
        min_dscr: min_dscr,
        pre_payment_penalty_id,
        owner_occupied,
        note,
        ...interestOnlyObject,
      });
    }
  };

  useEffect(() => {
    if (activeDefaultLP && activeDefaultLP.form_profile_data !== null) {
      preFillForm(activeDefaultLP.form_profile_data);
    } else {
      setInitialValues(_initialValues);
    }
    // setInitialValues(_initialValues);
  }, [_initialValues, activeDefaultLP]);

  useEffect(() => {
    if (initialValues?.nationwide && !initialValues?.statesArr.length) {
      initialValues.statesArr = [{ id: 0, state: "Nationwide" }];
    }
  }, [initialValues]);

  const addNewLendingProfile = useCallback(
    (payload) => {
      payload.lender_type = executionList.find(
        (et: any) => et.value === initialValues.lender_program_value,
      )?.id;
      payload.loan_type_id = loanTypeList.find(
        (lt: any) => lt.value === initialValues.loan_type_value,
      )?.id;
      dispatch(lendingProfileActions.saveNewLendingProfile(payload));
    },
    [
      dispatch,
      executionList,
      initialValues.lender_program_value,
      initialValues.loan_type_value,
      loanTypeList,
    ],
  );

  const handleApplyLendingDefaults = (setFieldValue: any) => {
    preFillForm(activeDefaultLP.property_types, setFieldValue);
  };

  const handleApplyOrigDefaults = (setFieldValue: any) => {
    const originators = activeDefaultLP.property_types?.originators
      ? designatedOriginatorList.filter((originator: any) => {
          return activeDefaultLP.property_types?.originators
            .split(",")
            .map(Number)
            .includes(originator.contact_id);
        })
      : [];
    setFieldValue("originators", originators);
  };

  const closeFlyout = () => {
    dispatch(uiActions.setFlyoutOpen(false));
    dispatch(uiActions.setAddTab(false));
    dispatch(uiActions.setEditTab(false));
    dispatch(uiActions.setBulkEditColumn(null));
    dispatch(uiActions.setBulkEditRow(null));
    dispatch(uiActions.setBulkEdit(false));
    dispatch(uiActions.setBulkEditAll(null));
  };

  const handleSubmit = useCallback(
    (values, { setSubmitting }) => {
      if (values.statesArr.length > 0) {
        values.states = values.statesArr.map((s) => Number(s.id));
      }
      if (values.nationwide) {
        delete values.states;
      }
      if (values.property_types_array?.length) {
        values.property_types = values.property_types_array.map((pt) =>
          pt.id ? Number(pt.id) : Number(pt),
        );
      }
      values.bank_id = user?.companyId;
      values.max_term = values.max_term_id;
      values.max_amortization = values.max_amortization_id;
      values.pre_payment_penalty = values.pre_payment_penalty_id;
      values.recourse = values?.recourse_id ? [Number(values.recourse_id)] : "";
      if (values.banker_id === "") {
        values.banker_id = !!user?.isAdmin
          ? activeConciergeOriginatorId
          : user?.loanTrackerId;
      }
      const arrayValues = Object.entries(values)
        .filter((obj: any) => obj[1] !== "" && obj[1] !== null)
        ?.map((obj: any) =>
          typeof obj[1] === "string" &&
          obj[1].includes("%") &&
          obj[0] !== "note"
            ? [obj[0], (obj[1] = Number(obj[1].replace("%", "")))]
            : obj,
        );
      const {
        property_types_array,
        lender_program_value,
        recourse_id,
        statesArr,
        max_term_id,
        max_amortization_id,
        pre_payment_penalty_id,
        contact_id,
        loan_type_value,
        ...payload
      } = Object.fromEntries(arrayValues);

      const isNationwide =
        values.states && values.states.includes("Nationwide");

      const originatorIds: any = [];
      if (values.originators?.length > 0) {
        values.originators.forEach((originator: any) => {
          originatorIds.push(originator.contact_id);
        });
      }

      const rateTypes = findIds(
        fixedFloatingList,
        values.rateTypes,
        "description",
        "id",
      );
      const recourse = findIds(recourseList, values.recourse_id);

      const pppIds = findIds(
        prePaymentPenaltyList,
        values.pre_payment_penalty_id,
      );

      const formValues = {
        property_type_ids: findIds(propertyTypeList, values.propertyTypes),
        states: findIds(states, values.states, "state", "id"),
        nationwide: isNationwide,
        min_loan: values.min_loan,
        max_loan: values.max_loan,
        min_rate: values.min_rate ?? null,
        max_rate: values.max_rate,
        max_ltv: values.max_ltv,
        max_ltv_non_recourse: values.max_ltv_non_recourse ?? null,
        origination_fee: values.origination_fee || null,
        max_amortization: +values.max_amortization_id,
        originators: originatorIds?.length > 0 ? originatorIds : null,
        rate_type_ids: rateTypes?.length > 0 ? rateTypes : null,
        max_term: +values.max_term_id,
        recourse_ids: recourse?.length > 0 ? recourse : [0],
        dscr_id: values.min_dscr || null,
        ppp_ids: pppIds?.length > 0 ? pppIds : null,
        oo_allowed: values.owner_occupied || null,
        note: values.note || null,
        interest_only_months: values.io_months ? values.io_months : null,
        interest_only_years: values.io_years ? values.io_years : null,
        last_updated: values.last_updated ? values.last_updated : "",
      };

      const default_lending_id = activeTab.default_lending_id;
      const execution_type_id = activeDefaultLP.execution_type_id;
      const loan_type_id = activeDefaultLP.loan_type_id;

      try {
        dispatch(
          lendingProfileActions.updateSingleCell(
            formValues,
            default_lending_id,
            execution_type_id,
            loan_type_id,
          ),
        );

        closeFlyout();
      } catch (error) {}
      setSubmitting(false);

      let timestamp = new Date(Date.now()).toLocaleString().replaceAll(",", "");
      hotjar.event(`Button Save Lending Profile Fields- ${timestamp}`);
    },
    [addNewLendingProfile, dispatch, user, activeConciergeOriginatorId],
  );

  //verify code
  const verifyProfile = () => {
    const default_lending_id = activeTab.default_lending_id;
    const execution_type_id = activeDefaultLP.execution_type_id;
    const loan_type_id = activeDefaultLP.loan_type_id;
    dispatch(
      lendingProfileActions.verifyProfile(
        default_lending_id,
        execution_type_id,
        loan_type_id,
      ),
    );
    hotjar.event(`Verify Profile:DEFAULT LENDING ID : ${default_lending_id} 
    ~ EXECUTION TYPE ID : ${execution_type_id} ~ LOAN TYPE ID : ${loan_type_id}`);
  };

  return (
    <Card
      sx={{
        boxShadow: "none",
        ".MuiCardContent-root": {
          padding: 0,
          "&:last-child": {
            paddingBottom: 0,
          },
        },
      }}
    >
      {/* START: This is the Verify Button for Concierge only */}
      {user?.isAdmin && (
        <Box
          sx={{
            position: "absolute",
            top: "15px",
            right: "75px",
            fontSize: "12px",
          }}
        >
          <FieldWrapper>
            <GPPrimaryButton
              sx={{
                width: "100px",
                "line-break": "strict",
              }}
              onClick={() => verifyProfile()}
            >
              {`Verify Profile ${" "}
              ${
                initialValues.last_verified
                  ? new Date(initialValues.last_verified).toLocaleDateString(
                      "en-US",
                    )
                  : ""
              }`}
            </GPPrimaryButton>
          </FieldWrapper>
        </Box>
      )}
      {/* END: This is the Verify Button for Concierge only */}
      <CardContent sx={{ pt: 0, pr: 1 }}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={lendingProfileValidationSchema}
          onSubmit={(values, actions) => {
            const { setFieldValue } = actions;
            setFieldValue(
              "last_updated",
              String(new Date(Date.now()).toLocaleDateString("en-US")),
            );
            handleSubmit(values, actions);
          }}
        >
          {({ isSubmitting }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              <FormContainer
                sx={{
                  maxHeight: `calc(100vh - 240px)`,
                  ".MuiInputBase-root-MuiOutlinedInput-root": {
                    paddingLeft: 0,
                    paddingRight: "10px",
                  },
                  ".MuiFormHelperText-root": {
                    color: "#757575",
                    fontSize: "12px",
                    margin: "4px 0 10px 0",
                  },
                  input: {
                    padding: "8px 8px 8px 12px",
                  },
                  label: {
                    fontWeight: 700,
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#03363D !important",
                    transform: "none",
                  },
                  ".MuiFormControl-root .MuiAutocomplete-popper": {
                    transform: "translate(1px, 60px)!important",
                  },
                  ".css-1nm6dl6-MuiTypography-root": {
                    padding: 0,
                    marginBottom: "4px",
                  },
                  ".css-4jl16v-MuiFormHelperText-root.Mui-error": {
                    margin: "-1px 0 4px",
                  },
                  // ".autocomplete-slideout": {
                  //   margin: "-30px 0 9px 0",
                  // },
                }}
              >
                <LendingProfileFields
                  handleApplyOrigDefaults={handleApplyOrigDefaults}
                  handleApplyLendingDefaults={handleApplyLendingDefaults}
                />
              </FormContainer>
              {initialValues.last_updated !== "" ? (
                <LastSavedWrapper>
                  <p
                    style={{
                      margin: 0,
                      color: "#757575",
                      fontFamily: "ABC Monument Grotesk",
                      fontSize: "12px",
                      lineHeight: "16px",
                      paddingTop: "5px",
                    }}
                  >
                    Profile last saved on{" "}
                    {new Date(initialValues.last_updated).toLocaleDateString(
                      "en-US",
                    )}
                  </p>
                </LastSavedWrapper>
              ) : null}
              <FieldWrapper>
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    backgroundColor: "transparent",
                    color: "#1F9F8A",
                    border: "none",
                    width: 200,
                    fontWeight: 400,
                    "&:hover": {
                      backgroundColor: "primary.light",
                      borderColor: "#1F9F8A",
                    },
                  }}
                  onClick={() => {
                    hotjar.event(
                      `Lending Profile Cell Edit Form Cancel + ${
                        initialValues.lender_program_value
                      } + ${initialValues.loan_type_value} - ${new Date(
                        Date.now(),
                      )
                        .toUTCString()
                        .replace(",", "")}`,
                    );
                    closeFlyout();
                    hotjar.event("Button Reset Lending Profile Fields");
                  }}
                  disabled={isSubmitting}
                >
                  {" "}
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  onClick={() =>
                    hotjar.event(
                      `Lending Profile Cell Edit Form Save + ${
                        initialValues.lender_program_value
                      } + ${initialValues.loan_type_value} - ${new Date(
                        Date.now(),
                      )
                        .toUTCString()
                        .replace(",", "")}`,
                    )
                  }
                  sx={{
                    width: 200,
                    height: 50,
                    borderRadius: "6px",
                    backgroundColor: "#03363D",
                    "&:hover": {
                      backgroundColor: "#1F9F8A",
                    },
                  }}
                >
                  Save Changes
                </Button>
              </FieldWrapper>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
});
