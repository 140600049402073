import React from "react";
import { useDispatch } from "react-redux";
// import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import Badge from "@mui/material/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button, Box, Typography } from "@mui/material";
import { AppState, defaultFilters, listActions, uiActions, useSelector } from "../../state";

export default function FilterButton() {
  const dispatch = useDispatch();
  const filterValues = useSelector((state: AppState) => state.lender.filters);

  let categories = {
    property_types : false,
    loan_amount : false,
    loan_types : false,
    term : false,
    states : false
  };

  for (const key in filterValues) {
    if (filterValues[key] !== defaultFilters[key]) {
      if(key === "property_types" && filterValues[key].length > 0) {
          categories.property_types = true;
      }
      if(key === "loan_min" && Number(filterValues[key]) !== 0 && filterValues[key] !== "") {
          categories.loan_amount = true;
      }
      if( key === "loan_max" && Number(filterValues[key]) !== 0 && filterValues[key] !== "") {
          categories.loan_amount = true;
      }

      if(key === "loan_types" && filterValues[key].length > 0) {
          categories.loan_types = true;
      }

      if(key === "max_term" && Number(filterValues[key]) !== 0 && filterValues[key] !== "") {
          categories.term = true;
      }

      if( key === "min_term" && Number(filterValues[key]) !== 0 && filterValues[key] !== "") {
          categories.term = true;
      }

      if( key === "states" && filterValues[key].length > 0) {
          categories.states = true;
      }

      if(key === "nationwide" && Boolean(filterValues[key])) {
          categories.states = true;
      }
    }
  }

  // filter and count true values in categories
  const categoryCount = Object.values(categories).filter(Boolean).length;

  const showFilters = () => {
    dispatch(listActions.getLoanTypeList());
    dispatch(uiActions.setFiltersOpen(true));
  };

  return (
    <Box
    >
      <Badge
        color="primary"
         sx={{
           '& .MuiBadge-badge': {
             backgroundColor: "#03363D",
             color: "#FAFAFA",
             borderColor: "#FAFAFA",
             border: "1px solid",
           }
         }}
         badgeContent={categoryCount}
      >
      <Button
        variant="outlined"
        sx={{
          width: 120,
          height: 40,
          alignSelf: "end",
          justifyContent: "space-evenly",
          borderColor: categoryCount > 0 ? "#03363D" : "#C9CCCF",
          color: categoryCount > 0 ? "#03363D" : "#C9CCCF",
          border: "2px solid",
          '&:hover': {
            width: 120,
            height: 40,
            alignSelf: "end",
            justifyContent: "space-evenly",
            borderColor: "#03363D",
            color: "#03363D",
            border: "2px solid",
          },
          '&:active': {
            width: 120,
            height: 40,
            alignSelf: "end",
            justifyContent: "space-evenly",
            borderColor: "#03363D",
            color: "#03363D",
            border: "2px solid",
          },
          '&:focus': {
            width: 120,
            height: 40,
            alignSelf: "end",
            justifyContent: "space-evenly",
            borderColor: "#03363d",
            color: "#03363d",
            border: "2px solid",
          }
        }}
        onClick={showFilters}
      >
        <FontAwesomeIcon
          color={"#03363D"}
          icon={regular("filter-list")}  />
        <Typography sx={{color: "#03363D"}}>Filter </Typography>
      </Button>
        </Badge>
    </Box>
  );
}
