import React from "react";

export function NotAuthorizedPage() {
  return (
    <div className="paper">
      <h1>Incorrect Permissions</h1>
      <p>
        It appears you do not have correct permissions to be here. Please contact support@gparency.com for help.
      </p>
    </div>
  );
}
