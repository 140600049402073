import { KEY_PREFIX } from "redux-persist";
import {
  REACT_APP_API_KEY,
  REACT_APP_PERSIST_KEY,
  REACT_APP_SECRET_ID,
} from "../../config";
import { auth, AuthState } from "./state";
import { AppState, AppThunk } from "../types";
import { uiActions } from "../ui/actions";
import { apiClient, authClient, createApiClient } from "../../lib/api";
import { Navigation } from "../../lib";
import AuthPages from "../../pages/auth";
import { lenderActions, lendingProfileActions } from "../states";
import { dealActions } from "../deals/actions";
import { apis } from "../../lib/gateways";
import jwt_decode from "jwt-decode";
import { useAuth0 } from "@auth0/auth0-react";
import Pages from "../../pages";

const { actions } = auth;

interface AuthResponse {
  user?: { app_user_id: number; roles: string[]; email: string };
  token?: string;
  id?: number;
}

export const authActions = {
  ...actions,
  /** @param {any} [authResponseData] Response data to load. Optional. */
  load(authResponseData: AuthResponse): AppThunk {
    let authStateFromResponse: AuthState;
    if (authResponseData) {
      const { user, token } = authResponseData;
      if (user) {
        authStateFromResponse = {
          userId: user.app_user_id,
          token,
        };
      }
    }
    return async (dispatch, getState) => {
      let authState: AuthState;
      if (authStateFromResponse) {
        authState = authStateFromResponse;
        dispatch(actions.setAuthState(authState));
      } else {
        authState = getState().auth;
      }
      createApiClient(authState?.token);
    };
  },
  _login(isAuthenticated): AppThunk<Promise<boolean>> {
    return async (dispatch, getState: () => AppState) => {
      const { token } = getState().auth;
      dispatch(uiActions.setLoading(true));
      createApiClient(token);
      if (isAuthenticated && token) {
        const response = await dispatch(authActions.getUserDetails());
        dispatch(uiActions.setLoading(false));
        return response;
      } else {
        return false;
      }
    };
  },
  getUserDetails(): AppThunk<any> {
    return async (dispatch, getState: () => AppState) => {
      const auth0State = getState().auth;
      const { token } = auth0State ?? "";
      var decoded: any = token ? jwt_decode(token) : "";

      const user = {
        email: decoded["https://auth.gparency.com/email"],
        isAdmin: decoded["https://auth.gparency.com/isAdmin"],
        isLender: decoded["https://auth.gparency.com/isLender"],
        isLenderPaid: decoded["https://auth.gparency.com/isLenderPaid"],
        isPremiumLender: decoded["https://auth.gparency.com/isPremiumLender"],
        isRateAdmin: decoded["https://auth.gparency.com/isRateAdmin"],
        isTrial: decoded["https://auth.gparency.com/isTrial"],
        loanTrackerId: decoded["https://auth.gparency.com/loanTrackerId"],
        subscription_expiry:
          decoded["https://auth.gparency.com/subscription_expiry"],
      };
      const authState: AuthState = {
        userId: user.loanTrackerId,
        token,
        userName: user.email,
        user: user,
        isAdmin: user.isAdmin,
        isLender: user.isLender,
        isLenderPaid: user.isLenderPaid,
        isPremiumLender: user.isPremiumLender,
        isRateAdmin: user.isRateAdmin,
        isTrial: user.isTrial,
        loanTrackerId: user.loanTrackerId,
        userCompanyId: user.email.replace(".com", "").split("@")[1],
      };
      dispatch(actions.setAuthState(authState));
      if (user.isAdmin) {
        dispatch(lenderActions.getConciergeBanksOriginators());
      }
    };
  },
  logout(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { user } = getState().auth;
      logout(dispatch, user?.isAdmin);
    };
  },
  recoverPassword(values: { username: string }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      await logout(dispatch);
      const { data } = await apiClient.put(
        `/password/${REACT_APP_SECRET_ID}/${REACT_APP_API_KEY}`,
        values,
      );
      if (!data.isResponse) {
        dispatch(uiActions.showError(data.message));
      } else {
        dispatch(actions.setUsername(values));
        Navigation.go(AuthPages.recoverySent.path);
      }
    };
  },
  resetPassword(values: {
    new: string;
    new_confirm: string;
    temp: string;
    username: string;
  }): AppThunk {
    return async (dispatch) => {
      await Promise.resolve();
      try {
        await apiClient.put(`password`, values);
        await logout(dispatch);
      } catch (e) {
        dispatch(uiActions.showError("Oops Something went wrong"));
      }
    };
  },
  confirmAccount(values: {
    email: string;
    newPassword: string;
    token: string;
  }): AppThunk {
    return async (dispatch) => {
      await apiClient.put(`/auth/confirm-account`, values);
      // dispatch(actions.setAuthState(undefined));
    };
  },
};

async function logout(dispatch, isAdmin = false) {
  // NOTE: We could do  window.localStorage.clear(); but other JS might be
  // using localStorage, so just remove the key that our Redux app saves.
  if (isAdmin) {
    await dispatch(
      lenderActions.updateConciergeOriginator(undefined, undefined),
    );
  }
  window.localStorage.removeItem(`${KEY_PREFIX}${REACT_APP_PERSIST_KEY}`);
  dispatch(actions.setAuthState(undefined));
  dispatch(dealActions.setDealState(undefined));
  dispatch(lendingProfileActions.setLendingProfileState(undefined));
}
