import React from "react";

export function ProcessingPage() {
  return (
    <div className="paper">
      <h1>Processing</h1>
      <p>
        Please wait..
      </p>
    </div>
  );
}
