import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";

export const MainContentStyled = styled(`div`)(({ theme }) => ({
  padding: 24,
  paddingLeft: 96,
  paddingTop: 40,
  minHeight: "100vh",
  backgroundColor: "#fff",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down("sm")]: {
    padding: 16,
  },
}));

export const BottomBox = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: "#F9F9FB",
  borderTop: "1px solid #D4E3E1",
  borderRadius: 0,
}));
