import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import {
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_SCOPE,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_ISSUER,
  REACT_APP_COOKIE_DOMAIN,
} from "./config";

// Example to use service worker / progressive web app (PWA):
// import * as serviceWorker from "./serviceWorker";
// import serviceWorkerConfig from "./serviceWorkerConfig";

import { defaultTheme } from "./themes";
import "./assets/css/styles.css";

import { App } from "./App";
import { REACT_APP_MUI_LICENSE } from "./config";
import { authActions, store } from "./state";
import AuthPages from "./pages/auth";

LicenseInfo.setLicenseKey(REACT_APP_MUI_LICENSE!);
// NOTE: The `store` already comes preloaded slices that where persisted.

/**
 * Startup function.
 */
function main() {
  const dispatch: any = store.dispatch;
  dispatch(authActions.load({}));
  render();
  registerServiceWorker();
}
/**
 * Service worker registration.
 *
 * Should be registered after a call to `render` **UNLESS you are using
 * `self.clients.claim()` within your service worker.** Otherwise, you want
 * to register late (after a call to `render`) to avoid problems on "low-end
 * mobile devices" (per the docs).
 *
 * 1. [CRA docs on PWA](https://create-react-app.dev/docs/making-a-progressive-web-app)
 * 2. [Reasons to register early](https://developers.google.com/web/fundamentals/primers/service-workers/registration#reasons_to_register_early)
 *
 */
function registerServiceWorker() {
  // Example to implement register service worker:
  // serviceWorker.register(serviceWorkerConfig);
}

function render() {
  // TODO: Add <ErrorSentry> as root.
  ReactDOM.render(
    <Auth0Provider
      domain={REACT_APP_AUTH0_DOMAIN}
      cookieDomain={REACT_APP_COOKIE_DOMAIN}
      clientId={REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={`${window.location.origin}${AuthPages.authenticate.path}`}
      scope={REACT_APP_AUTH0_SCOPE}
      audience={REACT_APP_AUTH0_AUDIENCE}
      issuer={REACT_APP_AUTH0_ISSUER}
      useRefreshTokens={true}
    >
      <ThemeProvider theme={defaultTheme}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ReduxProvider store={store}>
            <CssBaseline />
            <App />
          </ReduxProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Auth0Provider>,
    document.getElementById("root"),
  );
}

main();
