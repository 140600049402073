import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
export const PartNoLendingTeam = () => {
  return (
    <Box
      className="part-no-lending-team"
      sx={{
        position: "relative",
        padding: 0,
        backgroundColor: "#E0E0E0",
        borderRadius: "10px",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        width: "18px",
        height: "18px",
        marginRight: "8px",
      }}
    >
      <FontAwesomeIcon
        color="rgb(117, 117, 117)"
        size={"1x"}
        style={{
          width: "13px",
          height: "10px",
          margin: 0,
          pointerEvents: "none",
        }}
        icon={regular("user-group")}
      />

      <Box
        sx={{
          position: "absolute",
          top: "-61px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#03363D",
          color: "#fff",
          fontSize: "14px",
          lineHeight: "16px",
          padding: "14px 13px",
          fontWeight: "400",
          opacity: "0",
          visibility: "hidden",
          pointerEvents: "none",
          transition: ".3s ease",
          textTransform: "initial",
          "&::before": {
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            transform: "translateX(-50%)",
            content: '""',
            display: "block",
            width: 0,
            height: 0,
            borderWidth: "8px 4.5px 0 4.5px",
            borderColor: "#03363D transparent transparent transparent",
            borderStyle: "solid",
          },
        }}
      >
        No assigned originators.
      </Box>
    </Box>
  );
};
