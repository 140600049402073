import { OpportunitiesPage } from "./OpportunitiesPage";
import { LendingProfilePage } from "./LendingProfilePage";
import EditRatesPage from "./EditRatesPage";
import { OpportunityDetailPage } from "./OpportunityDetailPage";
import { HomePage } from "./HomePage";
import { NotAuthorizedPage } from "./NotAuthorizedPage";
import { NotFoundPage } from "../NotFoundPage";
import { AccountPage } from "./AccountPage";
import { LoggedOutpage } from "../LoggedOutPage";
import { CreateLendingProfilePage } from "./CreateLendingProfilePage";
import { CreateLendingProfileSuccessPage } from "./CreateLendingProfileSuccessPage";
import { EditDefaultOriginatorsPage } from "./EditDefaultOriginatorsPage";
import { EditDefaultCriteriaPage } from "./EditDefaultCriteriaPage";
import { JoinPage } from "./JoinPage";

export const MainPages = {
  opportunities: {
    anon: false,
    path: "/opportunities",
    title: "Opportunities",
    view: OpportunitiesPage,
  },
  join: {
    anon: false,
    path: "/join",
    title: "Join",
    view: JoinPage,
  },
  loggedOut: {
    path: "/logged-out",
    title: "Logged Out",
    view: LoggedOutpage,
  },
  opportunityDetails: {
    anon: false,
    path: "/opportunities/details/:id",
    title: "Details",
    view: OpportunityDetailPage,
  },
  lendingProfile: {
    anon: false,
    path: "/lending-profile",
    title: "My lending profile",
    view: LendingProfilePage,
  },
  editRates: {
    anon: false,
    path: "/edit-rates",
    title: "Edit Interest Rates",
    view: EditRatesPage,
  },
  createLendingProfile: {
    anon: false,
    path: "/lending-profile/create",
    title: "Create lending profile",
    view: CreateLendingProfilePage,
  },
  editDefaultOriginators: {
    anon: false,
    path: "/lending-profile/edit-default-originators",
    title: "Edit default originators",
    view: EditDefaultOriginatorsPage,
  },
  editDefaultCriteria: {
    anon: false,
    path: "/lending-profile/edit-default-criteria",
    title: "Edit default criteria",
    view: EditDefaultCriteriaPage,
  },
  createLendingProfileSuccess: {
    anon: false,
    path: "/lending-profile/create/success",
    title: "Create lending profile success",
    view: CreateLendingProfileSuccessPage,
  },
  profile: {
    anon: false,
    path: "/my-account",
    title: "My Account",
    view: AccountPage,
  },
  home: {
    anon: false,
    path: "/",
    title: "Home",
    type: "PAGE_HOME",
    view: HomePage,
  },
  homedev: {
    anon: false,
    path: "/",
    title: "Home",
    type: "PAGE_HOME_DEV",
    view: HomePage,
  },
  notAuthorized: {
    anon: true,
    path: "unauthorized",
    title: "Not Authorized",
    type: "PAGE_NOT_AUTHORIZED",
    view: NotAuthorizedPage,
  },
  notFound: {
    anon: true,
    path: "*",
    title: "Page Not Found",
    type: "PAGE_NOT_FOUND",
    view: NotFoundPage,
  },
};

export default MainPages;
