import React from "react";
import Box from "@mui/material/Box";
import {
  Avatar,
  Backdrop,
  Button,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDispatch, useSelector } from "react-redux";
import { DrawerContent, openedMixin } from "./modal.styles";
import MuiDrawer from "@mui/material/Drawer";
import { lenderSelector, uiActions, uiSelectors } from "../../state";

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
}));

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 0, 4, 0),
  borderBottom: "1px solid #DDDDDD",
}));

export const ContactInfoModal = () => {
  const dispatch = useDispatch();
  const show = useSelector(uiSelectors.contactOpen);
  const {
    name,
    address,
    email,
    mobile,
    phone,
    img = undefined,
  } = useSelector(lenderSelector.currentContactInfo);

  const handleClose = () => {
    dispatch(uiActions.setContactOpen(false));
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={show}
        //onClick={handleClose}
      >
        <Drawer variant="permanent" open={show} anchor="right">
          <Button
            color="inherit"
            sx={{ width: 20, alignSelf: "end" }}
            onClick={handleClose}
          >
            <FontAwesomeIcon size={"lg"} icon={regular("times")} />
          </Button>
          <DrawerHeader>
            {img ? (
              <Avatar
                alt="client name"
                src="https://www.bitbean.com/assets/images/logo.svg"
                sx={{ width: 140, height: 140, m: 4 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 140,
                  height: 140,
                  m: 4,
                  backgroundColor: "secondary.main",
                }}
              >
                {name ? name.split(" ")[0][0] : ""}{" "}
                {name ? name.split(" ")[1][0] : ""}
              </Avatar>
            )}
            <Typography variant="subtitle1">{name}</Typography>
            <Typography variant="body2">{address}</Typography>
          </DrawerHeader>
          <DrawerContent>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography variant="h6">Email</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{email || "---"}</Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography variant="h6">Cell Phone</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{mobile || "---"}</Typography>
              </Grid>
            </Grid>

            <Grid container spacing={4}>
              <Grid item xs={4}>
                <Typography variant="h6">Phone</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{phone || "---"}</Typography>
              </Grid>
            </Grid>

            {address && (
              <Grid container spacing={4}>
                <Grid item xs={4}>
                  <Typography variant="h6">Address</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body2">{address}</Typography>
                </Grid>
              </Grid>
            )}
          </DrawerContent>
        </Drawer>
      </Backdrop>
    </Box>
  );
};
