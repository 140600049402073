import React from "react";
import { Button, Link, Typography } from "@mui/material";
// Local
import { AuthBox } from "./Auth.styles";
import { authActions, useAction } from "../../state";
import { RouterLink } from "../../lib";

export const RecoverySentPage = React.memo(
  /**
   *
   */
  function RecoverySentPage() {
    const recoverPassword = useAction(authActions.recoverPassword);
    const resendEmail = (e) => {
      recoverPassword();
    };
    return (
      <AuthBox>
        <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
          Forgot Password
        </Typography>
        <Typography variant="body1">
          We’ve sent you an email with a link and instructions to reset your
          password.
          <br />
          <br />
          Didn’t receive the email? Check your junk mail or have another one
          sent to you by clicking the button below.
        </Typography>

        <Button
          sx={{ mt: 2, mb: 2 }}
          variant="contained"
          type="button"
          onClick={resendEmail}
        >
          RESEND EMAIL
        </Button>
        <Link component={RouterLink} underline="none" to={`/auth/login`}>
          Back to sign in
        </Link>
      </AuthBox>
    );
  },
);
