import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TextField, Select } from "formik-mui";
import { Formik, Form, Field } from "formik";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
} from "@mui/material";
import {
  AppState,
  defaultFilters,
  lenderActions,
  uiActions,
  uiSelectors,
  useSelector,
} from "../../state";
import MultiSelectInput from "../inputs/MultiSelectInput";
import StatesMultiSelectInput from "../inputs/StatesMultiSelectInput";
import { FieldWrapper } from "./styles";
import * as yup from "yup";
import { NumberFormatter } from "../inputs/NumberFormatter";

const validationSchema = yup.object().shape(
  {
    loan_min: yup
      .number()
      .min(0, "Value cannot be a negative number")
      .transform((type, value) => {
        return isNaN(value) ? null : value === 0 ? null : value;
      })
      .when(["loan_max"], (loan_max: any, schema: any) => {
        return schema.test({
          test: (loan_min: any) => {
            if (!loan_max || !loan_min) return true;
            return loan_max >= loan_min;
          },
          message: `'Loan min' must be less than or equal to ${loan_max}`,
        });
      })
      .nullable(),
    loan_max: yup
      .number()
      .min(0, "Value cannot be a negative number")
      .transform((type, value) => {
        return isNaN(value) ? null : value === 0 ? null : value;
      })
      .when(["loan_min"], (loan_min: any, schema: any) => {
        return schema.test({
          test: (loan_max: any) => {
            if (!loan_min || !loan_max) return true;
            return loan_max >= loan_min;
          },
          message: `'Loan max' must be greater than or equal to ${loan_min}`,
        });
      })
      .nullable(true),
  },
  [["loan_min", "loan_max"]],
);

export const FiltersForm = () => {
  const dispatch = useDispatch();
  const { propertyTypeList, termList, loanTypeList } = useSelector(
    (state: AppState) => state.lists,
  );
  const filterValues = useSelector((state: AppState) => state.lender.filters);
  const [initialValues, setInitialValues] = useState(defaultFilters);
  const currentTabData = useSelector(uiSelectors.currentTabData);
  const currentTab = useSelector(uiSelectors.currentTab);

  useEffect(() => {
    setInitialValues(filterValues);
  }, [filterValues]);

  useEffect(() => {
    setInitialValues(defaultFilters);
    dispatch(lenderActions.setDealsFilters(defaultFilters));
    dispatch(lenderActions.setFilteredDeals(currentTabData));
    dispatch(lenderActions.setFilteredOtherDeals(currentTabData));
  }, [currentTabData, currentTab, dispatch]);

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(lenderActions.setDealsFilters(values));
    const {
      loan_min,
      loan_max,
      property_types,
      states,
      nationwide = false,
      min_term,
      max_term,
      loan_types,
    } = values;
    const _filteredDeals = new Set(currentTabData);
    _filteredDeals.forEach((deal) => {
      const _dealTerm =
        deal.term && deal.term !== "" ? deal.term : deal.term_months;
      const dealTerm =
        _dealTerm && _dealTerm !== ""
          ? _dealTerm.indexOf("Year") > -1
            ? parseInt(_dealTerm) * 12
            : parseInt(_dealTerm)
          : undefined;

      if (loan_min && deal.loan_amount < loan_min) {
        _filteredDeals.delete(deal);
      }
      if (loan_max && deal.loan_amount > loan_max) {
        _filteredDeals.delete(deal);
      }
      if (min_term && min_term !== "All") {
        const minTermFilter =
          min_term.indexOf("Year") > -1
            ? parseInt(min_term) * 12
            : parseInt(min_term);
        if (!dealTerm || dealTerm < minTermFilter) {
          _filteredDeals.delete(deal);
        }
      }
      if (max_term && max_term !== "All") {
        const maxTermFilter =
          max_term.indexOf("Year") > -1
            ? parseInt(max_term) * 12
            : parseInt(max_term);
        if (!dealTerm || dealTerm > maxTermFilter) {
          _filteredDeals.delete(deal);
        }
      }
      if (property_types.length) {
        if (
          !property_types.find((pt) => deal.property_type.includes(pt.value))
        ) {
          _filteredDeals.delete(deal);
        }
      }
      if (states.length && !nationwide) {
        if (!states.find((s) => s.abb === deal.address?.state)) {
          _filteredDeals.delete(deal);
        }
      }
      if (loan_types.length) {
        if (!loan_types.find((lt) => deal.loan_type.includes(lt.value))) {
          _filteredDeals.delete(deal);
        }
      }
    });
    // dispatch(lenderActions.setFilteredDeals(Array.from(_filteredDeals)));
    if (currentTab === "1")
      dispatch(lenderActions.setFilteredDeals(Array.from(_filteredDeals)));
    if (currentTab === "2")
      dispatch(lenderActions.setFilteredOtherDeals(Array.from(_filteredDeals)));
    dispatch(uiActions.setFiltersOpen(false));
    setSubmitting(false);
  };

  const handleReset = () => {
    setInitialValues(defaultFilters);
    if (currentTab === "1") {
      dispatch(lenderActions.setFilteredDeals(Array.from(currentTabData)));
      dispatch(lenderActions.setDealsFilters(defaultFilters));
    }
    if (currentTab === "2") {
      dispatch(lenderActions.setFilteredOtherDeals(Array.from(currentTabData)));
      dispatch(lenderActions.setDealsFilters(defaultFilters));
    }
    dispatch(uiActions.setFiltersOpen(false));
  };

  return (
    <Card
      sx={{
        boxShadow: "none",
        "& .input-number .MuiInputBase-root input::-webkit-outer-spin-button": {
          display: "none",
        },
        "& .input-number .MuiInputBase-root input::-webkit-inner-spin-button": {
          display: "none",
        },
        "& .input-number input[type=number]": {
          appearance: "textfield !important",
        },
        "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
          padding: 0,
        },
      }}
    >
      <CardContent sx={{ pt: 0 }}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values, setFieldValue, resetForm }) => (
            <Form style={{ display: "flex", flexDirection: "column" }}>
              {/* ToDO: get a list of opportunity status  */}
              {/* <FormControl variant="standard" sx={{ mb: 1 }}>

                <InputLabel shrink>Opportunity status</InputLabel>
                <Field
                  component={Select}
                  fullWidth
                  variant="outlined"
                  name="status"
                >
                  {?.map((obj: any) => (
                    <MenuItem key={obj.id} value={obj.value}>
                      {obj.value}
                    </MenuItem>
                  ))}
                </Field>
              </FormControl> */}

              <MultiSelectInput
                fieldName="property_types"
                tagOptions={propertyTypeList}
                label="Property Type"
                placeholder="Select a property type to add"
                values={values?.property_types}
                setFieldValue={setFieldValue}
              />
              <MultiSelectInput
                fieldName="loan_types"
                tagOptions={loanTypeList}
                label="Loan Type"
                placeholder="Select a loan type to add"
                values={values?.loan_types}
                setFieldValue={setFieldValue}
              />

              <StatesMultiSelectInput
                fieldName="states"
                values={values?.states}
                setFieldValue={setFieldValue}
              />

              {/* <FieldWrapper>
                <FormControl className="input-number" variant="standard">
                  <InputLabel shrink>Loan min</InputLabel>
                  <Field
                    component={TextField}
                    type="number"
                    name="loan_min"
                    variant="outlined"
                  />
                </FormControl>
                <FormControl className="input-number" variant="standard">
                  <InputLabel shrink>Loan max</InputLabel>
                  <Field
                    component={TextField}
                    type="number"
                    name="loan_max"
                    variant="outlined"
                  />
                </FormControl>
              </FieldWrapper> */}

              <FieldWrapper>
                <FormControl variant="standard">
                  <InputLabel shrink>Loan min</InputLabel>
                  <Field
                    component={TextField}
                    name="loan_min"
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatter,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel shrink>Loan max</InputLabel>
                  <Field
                    component={TextField}
                    name="loan_max"
                    variant="outlined"
                    InputProps={{
                      inputComponent: NumberFormatter,
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              </FieldWrapper>

              <FieldWrapper>
                <FormControl variant="standard">
                  <InputLabel shrink>Min term</InputLabel>
                  <Field component={Select} name="min_term" variant="outlined">
                    {termList?.map((obj: any) => (
                      <MenuItem key={obj.id} value={obj.value}>
                        {obj.value}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel shrink>Max term</InputLabel>
                  <Field component={Select} name="max_term" variant="outlined">
                    {termList?.map((obj: any) => (
                      <MenuItem key={obj.id} value={obj.value}>
                        {obj.value}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </FieldWrapper>
              <FieldWrapper sx={{ mt: 3 }}>
                <Button
                  variant="contained"
                  disabled={isSubmitting}
                  onClick={() => {
                    handleReset();
                    resetForm();
                  }}
                  color="inherit"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "text.primary",
                  }}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  sx={{
                    backgroundColor: "text.primary",
                  }}
                >
                  Apply filters
                </Button>
              </FieldWrapper>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};
