import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { history } from "../../lib";
import MainPages from "../main";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";

export const LoginPage = function LoginPage() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);

  //ToDo: deal with auth0 errors
  useEffect(() => {
    if (isAuthenticated && isLoggedIn) {
      history.push(MainPages.opportunities.path);
    } else {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoggedIn, loginWithRedirect]);

  return (
    <Typography variant="h2" sx={{ maxWidth: 350 }}>
      Please wait a moment while you are redirected...
    </Typography>
  );
};
