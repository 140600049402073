import { Action, Slice, ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import { AxiosRequestConfig } from "axios";
import { Deal } from "./deals/state";
// Local
import { AppState } from "./states";

export type { AppState };

export interface AppStateInfo extends Slice {
  persist?: boolean;
  preload?: boolean;
  purge?: boolean;
}
/**
 * Type to return from async actions (redux-thunk).
 * `R` describes the return value of the thunk.
 * `E` describes the extra argument type given to the action thunk, e.g.
 * `(dispatch, getState, extraArgument) => {}`
 */
export type AppThunk<R = Promise<void>, E = unknown> = ThunkAction<
  R,
  AppState,
  E,
  Action<string>
>;
/**
 * Dispatch function for this application (AppState).
 * `E` describes the extra argument type given to the action thunk, e.g.
 * `(dispatch, getState, extraArgument) => {}`
 */
export type AppThunkDispatch<E = unknown> = ThunkDispatch<
  AppState,
  E,
  Action<string>
>;

export type FormErrors = Record<string, any>;
export type Order = "asc" | "desc";

export interface HeadCell {
  id: keyof Deal;
  label: string;
}

export type User = {
  loanTrackerId?: number;
  isAdmin?: boolean;
  email?: string;
  gpPaid?: boolean;
  lenderPaid?: boolean;
  firstName?: string;
  lastName?: string;
  mobilePhone?: string;
  companyName?: string;
  companyId?: number;
  id?: number;
  isOnboarded?: boolean;
  isLender?: boolean;
  isLenderPaid?: boolean;
  isPremiumLender?: boolean;
};

export const fixedFloatingList = [
  { description: "Fixed", value: "fix", id: 1 },
  { description: "Floating", value: "float", id: 2 },
];

export const rateList = [
  { description: "Fixed", value: "Fixed", id: 1 },
  { description: "Variable", value: "Variable", id: 2 },
  { description: "Hybrid", value: "Hybrid", id: 3 },
];

export const ownerOccupiedAllowedList = [
  { description: "Required", value: 2 },
  { description: "Yes", value: 1 },
  { description: "No", value: 0 },
];

export interface LendingProfile {
  lend_param_id: number;
  profile_name?: any;
  bank_id: number;
  lender_type: number;
  states: string;
  property_types: string;
  min_loan: number;
  max_loan: number;
  nationwide: number;
  note: string;
  recourse: string;
  recourse_id?: any;
  min_ltv: string;
  max_ltv: number;
  rate: number;
  excluded?: any;
  is_removed: number;
  banker_id: number;
  first_look: number;
  last_updated: string;
  active: number;
  min_rate: number;
  max_rate: number;
  max_term?: any;
  min_dscr?: any;
  max_interest_only?: any;
  pre_payment_penalty?: any;
  ext_options: string;
  max_ltc: string;
  max_initial_term?: any;
  max_perm_after_construction?: any;
  max_amortization?: any;
  index: string;
  min_index: string;
  max_index: string;
  "5_yr": string;
  "7_yr": string;
  "10_yr": string;
  origination_fee: number;
  fixed_floating: number;
  max_ltv_non_recourse: number;
  local_borrower: string;
  going_in_debt_yield: string;
  going_in_dscr: string;
  stabilized_debt_yield: string;
  stabilized_dscr: string;
  loan_type_id?: any;
  bank_value: string;
  contact_id: number;
  lender_program_value?: any;
  execution_type_value?: any;
  loan_type_value?: any;
  execution_type_id: number;
  max_term_id: string;
  max_amortization_id: string;
  banker_value: string;
  origination_fee_string: string;
  min_rate_string: number;
  max_rate_string: number;
  min_ltv_string: number;
  max_ltv_string: number;
  property_types_ids: string;
  property_types_array: Propertytypesarray[];
  last_verified: string;
}

interface Propertytypesarray {
  id: string;
  property_type: string;
}

export const defaultContact = {
  name: "",
  address: "",
  email: "",
  mobile: "",
  phone: "",
  img: undefined,
};

export const defaultFilters = {
  // status: "",
  property_types: [],
  loan_min: "",
  loan_max: "",
  max_term: "",
  min_term: "",
  states: [],
  loan_types: [],
};

export interface RefeshTokenHelper {
  tokenGetter: undefined | (() => Promise<string>);
  isAuthenticated: undefined | boolean;
  token: undefined | string;
  config: (config: undefined | AxiosRequestConfig) => AxiosRequestConfig;
  refesh: () => Promise<void>;
  startTimer: any;
}
