import { useMediaQuery, createTheme } from "@mui/material";

/** Material-ui default theme, to copy from where necessary. */
// const defaults = createMuiTheme();

// #region Example to copy shadows from default theme.
/**
 * Override box shadows[3]. Must provide all 25 array elements...
 * - https://github.com/mui-org/material-ui/issues/8780
 * - https://gist.github.com/phacks/6c3c3a5f395f6e9660ae132c237250a3
 */
// const boxShadow = "0 0 4px -4px rgba(0,0,0,0.12), 0 0 8px 0 rgba(0,0,0,0.16)";
// const shadows = [...defaults.shadows];
// shadows[3] = boxShadow;
// #endregion

/**
 * Default theme for the app based on the
 * [Materiaul UI default theme](https://material-ui.com/customization/default-theme/)
 * with example overrides.
 */
export const defaultTheme = createTheme({
  palette: {
    text: { primary: "#03363D" },
    primary: { main: "#6FC0D5", contrastText: "#03363D", light: "#F1F8F7" },
    secondary: { main: "#1F9F8A", contrastText: "#FFFFFF" },
    error: {
      main: "rgb(233, 28, 28, 1)",
    },
  },
  typography: {
    fontFamily: "ABC Monument Grotesk",
    allVariants: {
      color: "#03363D",
    },
    h1: {
      fontFamily: "Gilroy",
      fontSize: 58,
      fontWeight: 300,
      letterSpacing: "1px",
      lineHeight: "67px",
    },
    //Page Titles:
    h2: {
      height: 36,
      fontSize: 26,
      paddingLeft: 14,
      fontWeight: 700,
      letterSpacing: 0,
      lineHeight: "29px",
      fontStyle: "normal",
    },

    h3: {
      fontSize: 24,
      fontWeight: 500,
      fontStyle: "normal",
    },

    h4: {
      fontSize: 22,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "24px",
      fontStyle: "normal",
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "22px",
    },
    h6: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: "19px",
    },
    subtitle1: {
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: "17px",
      fontWeight: 700,
      // opacity: 0.7,
    },
    subtitle2: {
      fontSize: 12,
      fontWeight: 700,
      letterSpacing: 0,
    },
    body1: {
      // opacity: 0.7,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: "22px",
    },
    body2: {
      height: 19,
      //opacity: 0.7,
      fontSize: 14,
      letterSpacing: 0,
    },
  },
  transitions: {
    easing: {
      // This is the most common easing curve.
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      // Objects enter the screen at full velocity from off-screen and
      // slowly decelerate to a resting point.
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      // Objects leave the screen at full velocity. They do not decelerate when off-screen.
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      // The sharp curve is used by objects that may return to the screen at any time.
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
  },

  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&.Mui-error": {
            whiteSpace: "break-spaces",
            wordBreak: "break-word",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
          fontSize: 16,
        },
        contained: {
          width: "100%",
          boxShadow: "none",
          height: 40,
          color: "#FFFFFF",
          backgroundColor: "#22AA94",
          letterSpacing: 0.45,
          borderRadius: 4,
          transition: "all .2s ease",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#22AA94",
            opacity: 0.75,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: 10,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#03363D",
          letterSpacing: 0,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "0px 0px 20px 20px",
          boxShadow: "0px 7px 15px #03363D1A",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          borderRadius: "0px 0px 20px 20px",
          boxShadow: "0px 7px 15px #03363D1A",
        },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: {
          width: "100%",
          paddingTop: 10,
          input: {
            letterSpacing: 0.4,
            color: "#03363D",
            padding: "7px 12px",
            borderRadius: "4px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: "1px solid #C9CCCF",
        },
        root: {
          // boxShadow: "inset 0px 3px 6px #B1BAC759",
          height: 36,
          borderRadius: 4,
          paddingLeft: 12,
          "&.Mui-disabled": {
            boxShadow: "inset 0 3px 6px 0 rgba(213,220,229,0.5)",
            "& .MuiOutlinedInput-notchedOutline": {
              opacity: ".02",
              color: "red",
              borderColor: "red",
            },
            "& .MuiInputAdornment-root": {
              opacity: 0.61,
            },
          },
        },
        multiline: {
          height: "auto",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        textColorPrimary: {
          color: "#03363D",
          textTransform: "capitalize",
          fontSize: 16,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: 10,
        },
      },
    },
  },

  // #region Example default props for any Material-ui components rendered with
  // this theme:
  // props: {
  //   MuiCard: {
  //     variant: "outlined",
  //   },
  // },
  // #endregion

  // Example to set custom shadows:
  // shadows,
});

/**
 * Returns `true` if the screen is sized for mobile. Depends on theme
 * breakpoints, otherwise this function would be defined in `lib/hooks.js`
 */
export function useMobile(size = "(max-width:600px)") {
  return useMediaQuery(size);
}
