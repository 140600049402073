import React from "react";
import { Backdrop, Container } from "@mui/material";
import { uiSelectors, useSelector } from "../../state";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import Loader from "../../assets/img/loader.gif";

function _DefaultLayout() {
  const loading = useSelector(uiSelectors.loading);

  return (
    <>
      <Container maxWidth="xl" disableGutters={true}>
        <div>
          <Outlet />
        </div>
      </Container>
      <Notification />
      {loading && (
        <Backdrop open={true}>
          <img
            style={{
              width: "128px",
              transition: "350ms ease",
            }}
            src={Loader}
            alt="Loader"
          />
        </Backdrop>
      )}
    </>
  );
}

export const DefaultLayout = React.memo(_DefaultLayout);
