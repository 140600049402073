import { useDispatch, useSelector } from "react-redux";
import { uiActions, uiSelectors } from "../../state";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { CloseIcon } from "../icons";

export const RemoveOriginatorModal = () => {
  const dispatch = useDispatch();
  const show = useSelector(uiSelectors.removeOriginatorPopupOpen);

  const handleClose = () => {
    dispatch(uiActions.setRemoveOriginatorPopupOpen(false));
  };

  const handleRemove = () => {
    // dispatch() remove originator action
    dispatch(uiActions.setRemoveOriginatorPopupOpen(false));
  };

  return (
    <Box>
      <Dialog open={show} onClose={handleClose}>
        <DialogTitle>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          Remove Originator?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleRemove} color="primary">
            Remove Originator
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
