import {
  arrayToEntityList,
  arrayToEntityPropTypeList,
  stringToArrayByComma,
} from "../../lib";
import { AppState, LendingProfile } from "../types";
import { createSelector } from "@reduxjs/toolkit";

const _state = (state: AppState) => state.lendingProfile;

const _lendingProfilesPropKeys = createSelector(_state, (lendingProfile) =>
  lendingProfile.lending_profiles?.length
    ? arrayToEntityPropTypeList(
        lendingProfile.lending_profiles.filter(
          (obj: LendingProfile) => !!obj.property_types_array,
        ),
      )
    : { entities: [] },
);

const _tabs = createSelector(
  _state,
  (lendingProfile) => lendingProfile.property_type_tabs ?? [],
);

const _activeLendingProfile = createSelector(_state, (lendingProfile) =>
  lendingProfile.activeLPId
    ? arrayToEntityList(lendingProfile.lending_profiles, "lend_param_id")
        .entities[lendingProfile.activeLPId] ?? {}
    : {
        lend_param_id: "",
        id: "",
        min_term: "",
        profile_name: "",
        lender_program_value:
          lendingProfile.activeDefaultLP?.lender_program_value ?? "",
        bank_id: "",
        lender_type: "",
        property_types:
          lendingProfile.activeDefaultLP?.property_types?.value ?? "",
        property_types_array: stringToArrayByComma(
          lendingProfile.activeDefaultLP?.property_types?.id,
        ),
        loan_type_value: lendingProfile.activeDefaultLP?.loan_type_value ?? "",
        min_loan: "",
        max_loan: "",
        nationwide: "",
        note: "",
        recourse: "",
        recourse_id: ["Not Specified"],
        min_ltv: "",
        max_ltv: "",
        rate: "",
        excluded: "",
        is_removed: "",
        banker_id: "",
        first_look: "",
        last_updated: "",
        active: "",
        min_rate: "",
        max_rate: "",
        max_term: "",
        min_dscr: "",
        max_interest_only: "",
        pre_payment_penalty: "",
        ext_options: "",
        max_ltc: "",
        max_initial_term: "",
        max_amortization: "",
        origination_fee: "",
        fixed_floating: "",
        max_ltv_non_recourse: "",
        loan_type_id: "",
        bank_value: "",
        contact_id: "",
        execution_type_value: "",
        execution_type_id: "",
        banker_value: "",
        states: [],
        asset_type: "",
        loan_min: "",
        loan_max: "",
        execution_type: "",
        loan_type: "",
        rate_type: "",
        min_amortization: "",
        max_ltv_recourse: "",
        recourse_required: "",
        prepay_penalty: "",
        owner_occupied: "",
        io_years: "",
        io_months: "",
        last_verified: "",
      },
);

export const lendingProfileSelector = {
  activeLendingProfile: _activeLendingProfile,

  activeLendingProfileId(state: AppState) {
    return state.lendingProfile.activeLPId;
  },

  lendingProfileByPropKeys: _lendingProfilesPropKeys,

  propTypeTabs: _tabs ?? [],

  activeTab(state: AppState) {
    return state.lendingProfile.activeTab;
  },

  activeSelectedTab(state: AppState) {
    return state.lendingProfile.activeSelectedTab;
  },

  newTabAdded(state: AppState) {
    return state.lendingProfile.newTabAdded;
  },

  lendingProfiles(state: AppState) {
    return state.lendingProfile.lending_profiles ?? [];
  },

  tabNamesCombined(state: AppState) {
    return state.lendingProfile.tabNamesCombined ?? [];
  },

  activeDefaultLP(state: AppState) {
    return state.lendingProfile.activeDefaultLP;
  },

  bulkEditInitialState(state: AppState) {
    const {
      lend_param_id: id = "",
      bank_id = "",
      lender_type = "",
      min_loan = null,
      max_loan = null,
      nationwide = false,
      banker_id = "",
      min_rate = null,
      max_rate = null,
      states = [],
      statesArr = [],
      rate_type = "",
      fixed_floating = "",
      contact_id = "",
    } = _activeLendingProfile(state);
    return {
      id,
      states,
      statesArr,
      min_rate,
      max_rate,
      rate_type,
      bank_id,
      lender_type,
      fixed_floating,
      min_loan,
      max_loan,
      nationwide,
      banker_id,
      contact_id,
    };
  },

  lendingProfileInitialState(state: AppState) {
    const {
      lend_param_id: id = "",
      profile_name = "",
      min_term = 0,
      bank_id = "",
      lender_type = "",
      property_types = "",
      min_loan = "",
      max_loan = "",
      nationwide = false,
      note = "",
      recourse = "",
      recourses = [],
      min_ltv = 0,
      max_ltv = 0,
      rate = "",
      banker_id = "",
      min_rate = "",
      max_rate = "",
      max_term = 0,
      min_dscr = 0,
      max_interest_only = "",
      pre_payment_penalty = "",
      max_ltc = "",
      max_amortization = 0,
      min_index = "",
      max_index = "",
      origination_fee = 0,
      fixed_floating = "",
      max_ltv_non_recourse = "",
      loan_type_id = "",
      contact_id = "",
      states = [],
      statesArr = [],
      asset_type = "",
      loan_type = "",
      rate_type = "",
      rateTypes = [],
      min_amortization = "",
      recourse_required = "",
      prepay_penalty = "",
      property_types_array = [],
      propertyTypes = [],
      recourse_id = ["Not Specified"],
      owner_occupied = "",
      max_term_id = "",
      originators = [],
      io_months = "",
      io_years = "",
      lender_program_value = "",
      loan_type_value = "",
      max_amortization_id = 0,
      pre_payment_penalty_id = "",
      last_verified = "",
      last_updated = "",
    } = _activeLendingProfile(state);
    return {
      id,
      profile_name,
      min_term,
      asset_type,
      loan_type,
      states,
      statesArr,
      min_rate: min_rate ? parseFloat(min_rate) : 0,
      max_rate: max_rate ? parseFloat(max_rate) : 0,
      rate_type,
      min_amortization,
      max_amortization,
      originators,
      max_amortization_id,
      pre_payment_penalty_id: pre_payment_penalty_id ?? "",
      max_ltv,
      recourse_required,
      min_dscr,
      prepay_penalty,
      rateTypes,
      origination_fee,
      fixed_floating: fixed_floating ?? "",
      max_ltv_non_recourse: max_ltv_non_recourse ?? null,
      loan_type_id,
      contact_id,
      propertyTypes,
      bank_id,
      lender_type,
      property_types,
      min_loan: min_loan ? parseFloat(min_loan) : "",
      max_loan: max_loan ? parseFloat(max_loan) : "",
      nationwide,
      note: note ?? "",
      recourse,
      recourse_id,
      recourses,
      min_ltv,
      rate,
      banker_id,
      max_term,
      max_interest_only,
      pre_payment_penalty,
      max_ltc,
      min_index,
      max_index,
      property_types_array,
      owner_occupied: owner_occupied ?? "",
      max_term_id: max_term_id ?? "",
      io_months,
      io_years,
      lender_program_value,
      loan_type_value,
      last_verified,
      last_updated: last_updated,
    };
  },

  lenderProfilesTabData(state: AppState) {
    return state.lendingProfile.activeTab &&
      state.lendingProfile.activeTab.value
      ? _lendingProfilesPropKeys(state).entities[
          state.lendingProfile.activeTab.value.replace(/\s/g, "")
        ]
      : [];
  },

  getProfilesByDefaultId(default_lending_id: number) {
    return (state: AppState) => {
      return state.lendingProfile.lending_profiles.filter(
        (lp: any) => lp.default_lending_id === default_lending_id,
      );
    };
  },

  executionTypeByTabKey(state: AppState) {
    return _tabs(state).reduce((prev, tab: any) => {
      const tabExecutions = new Set();
      tab.id
        ?.toString()
        .split(",")
        .forEach((pt) => {
          const etArr = state.lists.executionTypeMapping[pt]?.execution_list;
          if (!etArr || !Array.isArray(etArr)) {
            console.error("we have bad data");
          } else {
            etArr.forEach((et) => tabExecutions.add(et));
          }
        });
      prev[tab.id] = tabExecutions;
      return prev;
    }, {});
  },
};
