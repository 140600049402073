import { AppState, defaultContact } from "../types";

export const lenderSelector = {
  currentContactInfo(state: AppState) {
    return state.lender.currentContactInfo ?? defaultContact;
  },
  filters(state: AppState) {
    return state.lender.filters;
  },
};
