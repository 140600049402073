import React from "react";
import {
  Button,
  Link,
  TextField,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
// Local
import { Form, RouterLink, useFormik, yup } from "../../lib";
import { authActions, useAction } from "../../state";
import { AuthBox } from "./Auth.styles";

const validationSchema = yup.object({
  username: yup
    .string()
    //.email("Enter a valid email")
    .required("Username is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const LoginAfterResetPage = React.memo(
  /**
   *
   */
  function LoginPage() {
    const login = useAction(authActions._login);

    const [error, setError] = React.useState("");
    const frm = useFormik({
      initialValues: {
        username: "",
        password: "",
      },
      validationSchema,
      async onSubmit(values) {
        const success = await login(values);
        if (!success) {
          setError("Invalid username or password. Please try again.");
        }
      },
    });

    return (
      <AuthBox>
        <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
          Your password was reset!
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
          {error ? <span>{error}</span> : "Sign in again and you're good to go"}
        </Typography>
        <Form form={frm} sx={{ mt: 10, mb: 5 }}>
          <FormControl variant="standard">
            <InputLabel shrink>User name or email</InputLabel>
            <TextField
              autoFocus
              fullWidth
              id="username"
              name="username"
              value={frm.values.username}
              onChange={frm.handleChange}
              error={frm.touched.username && Boolean(frm.errors.username)}
              helperText={frm.touched.username && frm.errors.username}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel shrink>Password</InputLabel>
            <TextField
              fullWidth
              id="password"
              name="password"
              type="password"
              value={frm.values.password}
              onChange={frm.handleChange}
              error={frm.touched.password && Boolean(frm.errors.password)}
              helperText={frm.touched.password && frm.errors.password}
            />
          </FormControl>
          <Button variant="contained" type="submit" disabled={frm.isSubmitting}>
            Sign In
          </Button>
          <Link
            component={RouterLink}
            to={`/auth/forgot-password?username=${encodeURIComponent(
              frm.values.username,
            )}`}
          >
            Forgot Password?
          </Link>
        </Form>
      </AuthBox>
    );
  },
);
