import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { refeshTokenHelper } from "../../lib/utils";
import { User } from "../types";

export interface AuthState {
  token?: string;
  user?: User;
  userId?: number;
  userName?: string;
  userCompanyId?: number | string;
  roles?: number;
  isAdmin?: string;
  isLender?: string;
  isLenderPaid?: string;
  isPremiumLender?: string;
  isRateAdmin?: string;
  isTrial?: string;
  loanTrackerId?: string;
  activeConciergeOriginatorId?: undefined;
}

interface UpdatedValues {
  first_name: string;
  last_name: string;
}
const initialState: AuthState = {
  token: "",
  user: {},
  userId: 0,
  userName: "",
  userCompanyId: "",
  roles: 0,
  isAdmin: "",
  isLender: "",
  isLenderPaid: "",
  isPremiumLender: "",
  isRateAdmin: "",
  isTrial: "",
  loanTrackerId: "",
  activeConciergeOriginatorId: undefined,
};

export const auth = {
  persist: true,
  ...createSlice({
    name: "auth",
    initialState,
    reducers: {
      setAuthState(state, { payload }: PayloadAction<AuthState | undefined>) {
        if (!payload) {
          return initialState;
        }
        Object.assign(state, payload);
      },
      loadUserUpdatedInfo(state, { payload }: PayloadAction<UpdatedValues>) {
        const { first_name = "", last_name = "" } = payload;
        state.user = {
          ...state.user,
          firstName: first_name,
          lastName: last_name,
        };
      },
      loadActiveConciergeOriginatorId(state, { payload }: PayloadAction<any>) {
        state.activeConciergeOriginatorId = payload;
      },
      loadConciergeActiveBankId(state, { payload }: PayloadAction<number>) {
        state.userCompanyId = payload;
        state.user = {
          ...state.user,
          companyId: payload,
        };
      },
      setUsername(state, { data }: any) {
        return {
          ...state,
          userName: data,
        };
      },
      setToken(state, { payload }: any) {
        refeshTokenHelper.token = payload;
        return {
          ...state,
          token: payload,
        };
      },
    },
  }),
};
