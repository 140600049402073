import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, TextField, Typography } from "@mui/material";
import { authSelectors, lenderActions, listSelectors } from "../../state";
import { FlexBox } from "./styles";
import { useMobile } from "../../themes";
import { hotjar } from "react-hotjar";

export function AdminAccessDropdown() {
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const [value, setValue]: any = useState();
  const activeConciergeOriginatorId = useSelector(
    authSelectors.activeConciergeOriginatorId,
  );
  const conciergeBanksOriginators = useSelector(
    listSelectors.conciergeBanksOriginators,
  );

  useEffect(() => {
    const _value = conciergeBanksOriginators?.find(
      (li: any) => li.originator_id === activeConciergeOriginatorId,
    );
    setValue(_value);
  }, [activeConciergeOriginatorId, conciergeBanksOriginators]);

  const handleChange = (e, newValue) => {
    const originator_id = newValue ? newValue?.originator_id : undefined;
    const bank_id = newValue ? newValue?.bank_id : undefined;
    dispatch(lenderActions.updateConciergeOriginator(originator_id, bank_id));
    if (newValue) {
      hotjar.event(
        `Logged In As - ${newValue?.originator_id}: ${newValue?.bank_id} - Selected`,
      );
    } else {
      hotjar.event(`Logged In As - Clear Selection`);
    }
  };
  return (
    <FlexBox>
      <Typography variant="body1" sx={{ opacity: 0.7 }}>
        LOGGED IN AS
      </Typography>
      <Autocomplete
        style={{ width: isMobile ? 200 : 400 }}
        id="conciergeBankOriginators"
        options={conciergeBanksOriginators}
        value={value ?? ""}
        getOptionLabel={(option: any) =>
          typeof option === "string"
            ? option
            : `${option?.bank + ": " + option?.originator}`
        }
        isOptionEqualToValue={(option, value) => {
          return option?.originator_id === value?.originator_id;
        }}
        onChange={(e: any, value: any) => {
          handleChange(e, value);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id="conciergeBankOriginators"
            variant="outlined"
          />
        )}
        onClick={() => hotjar.event("Input Logged In As Clicked")}
        sx={{
          ".MuiOutlinedInput-root .MuiAutocomplete-input": {
            padding: "0 4px 6px 6px",
            marginTop: "-1px",
          },
        }}
      />
    </FlexBox>
  );
}
