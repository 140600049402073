import React from "react";
import {
  Link,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
// Local
import { Form, useFormik, useLocation, yup, RouterLink } from "../../lib";
import { authActions, useAction } from "../../state";
import { AuthBox } from "./Auth.styles";

const validationSchema = yup.object({
  username: yup
    .string()
    .email("Enter a valid email")
    .required("Username is required"),
});

export const ForgotPasswordPage = React.memo(
  /**
   *
   */
  function ForgotPasswordPage(props) {
    const recoverPassword = useAction(authActions.recoverPassword);
    const location = useLocation();

    const frm = useFormik({
      initialValues: {
        username: location.query.email || "",
      },
      validationSchema,
      async onSubmit(values) {
        await recoverPassword(values);
        // Navigation.go(AuthPages.recoverySent.path);
      },
    });

    return (
      <AuthBox>
        <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
          Forgot Password
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, mb: 2 }}>
          Enter your email and we will send you a link to reset your password
        </Typography>
        <Form form={frm} sx={{ mt: 10, mb: 5 }}>
          <FormControl variant="standard">
            <InputLabel shrink>Email</InputLabel>
            <TextField
              autoFocus
              fullWidth
              id="username"
              name="username"
              value={frm.values.username}
              onChange={frm.handleChange}
              error={frm.touched.username && Boolean(frm.errors.username)}
              helperText={frm.touched.username && frm.errors.username}
            />
          </FormControl>
          <Button
            variant="contained"
            type="submit"
            sx={{ mt: 2, mb: 2 }}
            disabled={frm.isSubmitting}
          >
            Reset Password
          </Button>
          <Link component={RouterLink} underline="none" to={`/auth/login`}>
            Back to sign in
          </Link>
        </Form>
      </AuthBox>
    );
  },
);
