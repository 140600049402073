import { AppState } from "../types";
import { UIViewState } from "./state";

export const uiSelectors = {
  addNewLP(state: AppState) {
    return state.ui.addNewLP;
  },

  addTab(state: AppState) {
    return state.ui.addTab;
  },

  currentTabData(state: AppState) {
    return state.ui.currentOpportunitiesTab === "1"
      ? state.deals.user_deals
      : state.deals.other_opportunities;
  },

  currentTab(state: AppState) {
    return state.ui.currentOpportunitiesTab;
  },

  currentLendingTab(state: AppState) {
    return state.ui.currentLendingTab;
  },

  contactOpen(state: AppState) {
    return state.ui.contactOpen;
  },

  editTab(state: AppState) {
    return state.ui.editTab;
  },

  editProfileModalOpen(state: AppState) {
    return state.ui.editProfileModalOpen;
  },

  isTableView(state: AppState) {
    return state.ui.isTableView;
  },

  lendingProfileOpen(state: AppState) {
    return state.ui.lendingProfileOpen;
  },

  loading(state: AppState) {
    return state.ui.loading;
  },

  menuOpen(state: AppState) {
    return state.ui.menuOpen;
  },

  notification(state: AppState) {
    return state.ui.notification;
  },

  quotesOpen(state: AppState) {
    return state.ui.quotesOpen;
  },

  showFilters(state: AppState) {
    return state.ui.filtersOpen;
  },

  flyoutOpen(state: AppState) {
    return state.ui.flyoutOpen;
  },

  bulkEdit(state: AppState) {
    return state.ui.bulkEdit;
  },

  bulkEditColumn(state: AppState) {
    return state.ui.bulkEditColumn;
  },

  bulkEditRow(state: AppState) {
    return state.ui.bulkEditRow;
  },

  bulkEditAll(state: AppState) {
    return state.ui.bulkEditAll;
  },
  removeOriginatorPopupOpen(state: AppState) {
    return state.ui.removeOriginatorPopupOpen;
  },

  // Views

  /** Dynamically creates a selector to select the given `view[name]`. */
  forView(name: keyof UIViewState) {
    return (state: AppState) => state.ui.view[name];
  },

  deleteCellOpen(state: AppState) {
    return state.ui.deleteCellOpen;
  },
};
