import { Box, Button, FormControl } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { H4Title } from "./styles";
import { hotjar } from "react-hotjar";
import { useNavigate } from "react-router-dom";
import AutocompleteOriginators from "../inputs/AutocompleteOriginators";
import { ErrorMessageWrapper } from "../lenderProfile/styles";
import {
  AppState,
  lendingProfileActions,
  useDispatch,
  useSelector,
} from "../../state";
import { TextField } from "formik-mui";

const DefaultOriginatorEditForm = () => {
  const [initialValues, setInitialValues] = useState({
    profileName: "",
    defaultOriginators: [] as any,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { designatedOriginatorList } = useSelector(
    (state: AppState) => state.lists,
  );
  const { activeTab } = useSelector((state: AppState) => state.lendingProfile);

  const handleSubmit = async (values: any) => {
    let originatorIds: any = [];

    // Get the ids of the originators selected in the form
    if (values.defaultOriginators.length > 0) {
      values.defaultOriginators.forEach((originator: any) => {
        originatorIds.push(originator.contact_id);
      });
    }

    const formValues = {
      profile_name: values.profileName,
      originators: originatorIds,
      is_editing_originators: true,
    };

    try {
      dispatch(
        lendingProfileActions.updateLendingDefaultProfile(
          formValues,
          activeTab.default_lending_id,
        ),
      );
    } catch (error) {
    } finally {
      setTimeout(() => {
        navigate("/lending-profile");
      }, 3000);
    }
  };

  useEffect(() => {
    if (activeTab) {
      let originatorsObject: any = [];
      if (activeTab.originators !== null) {
        originatorsObject = designatedOriginatorList.filter(
          (originator: any) => {
            return activeTab.originators
              .split(",")
              .map(Number)
              .includes(originator.contact_id);
          },
        );
      }

      setInitialValues({
        profileName: activeTab.profile_name,
        defaultOriginators: originatorsObject || [],
      });
    }
  }, [activeTab]);

  return (
    <Box
      sx={{
        paddingLeft: "26px",
        ".MuiFormHelperText-root": {
          color: "#757575",
          fontSize: "12px",
          margin: "4px 0 10px 0",
        },
        ".MuiFormControl-root": {
          margin: "0 0 5px 0",
          width: "100%",
          padding: 0,
          paddingTop: "0",
        },
        ".MuiFormControl-root-MuiTextField-root, .MuiFormControl-root": {
          margin: "0",
          padding: "0",
        },
        "p.Mui-error": {
          position: "absolute",
          bottom: "-30px",
          color: "rgb(233, 28, 28, 1)",
        },

        // "& .MuiFormControl-root .MuiAutocomplete-popper": {
        //   inset: "14px auto auto 0px !important",
        // },
        label: {
          color: "#03363D",
          lineHeight: "21px",
          fontSize: "12px",
          fontWeight: "700",
          transform: "none",
          position: "static",
          "&:empty": {
            display: "none",
          },
        },
      }}
    >
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <H4Title>Profile Name</H4Title>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "20px",
                position: "relative",
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  backgroundColor: "#6FC0D5",
                  height: "2px",
                  width: "214px",
                  bottom: "0",
                },
              }}
            >
              <FormControl
                style={{ padding: 0, width: 191 }}
                variant="standard"
              >
                <Field
                  sx={{
                    width: "191px",
                    paddingTop: 0,
                    marginBottom: "7px",
                    ".MuiInputBase-formControl": {
                      backgroundColor: "#fff",
                      padding: 0,
                    },
                    input: {
                      color: "#03363D",
                      fontSize: "14px",
                      padding: "6px 12px 8px",
                    },
                  }}
                  component={TextField}
                  name="profileName"
                />
              </FormControl>
              <Button
                variant="outlined"
                onClick={() => {
                  hotjar.event(
                    `Lending Profile Default Originator Edit Form Cancel - ${new Date(
                      Date.now(),
                    )
                      .toUTCString()
                      .replace(",", "")}`,
                  );
                  navigate("/lending-profile");
                }}
                sx={{
                  marginLeft: "auto",
                  border: "none",
                  color: "#1F9F8A",
                  fontWeight: "400",
                  marginRight: "27px",
                  borderWidth: "1px",
                  borderStyle: "solid",
                  borderColor: "transparent",
                  height: "46px",
                  "&:hover": {
                    borderColor: "#1F9F8A !important",
                  },
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                type="submit"
                onClick={() => {
                  hotjar.event(
                    `Lending Profile Default Originator Edit Form Save - ${new Date(
                      Date.now(),
                    )
                      .toUTCString()
                      .replace(",", "")}`,
                  );
                }}
                sx={{
                  width: "218px",
                  backgroundColor: "text.primary",
                  padding: "10px 0",
                  fontSize: "16px",
                  height: "46px",
                  flexShrink: 0,
                }}
              >
                Save Changes
              </Button>
            </Box>
            <Box
              sx={{
                paddingLeft: "52px",
                margin: "0 -21px 0 -50px",
                backgroundColor: "#fff",
                height: "calc(100vh - 192px)",
                "& .MuiFormControl-root .MuiInputLabel-root": {
                  top: "18px",
                },
              }}
            >
              <div>
                <H4Title
                  sx={{
                    paddingTop: "16px",
                  }}
                >
                  Default Originators
                </H4Title>
                <p
                  style={{
                    color: "#757575",
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "20px",
                    margin: "0 0 12px",
                  }}
                >
                  All originators added to this team will be assigned to every
                  lending combination you create.
                </p>
                {/* Default originators select goes here */}
                <FormControl>
                  <AutocompleteOriginators
                    name="defaultOriginators"
                    setFieldValue={setFieldValue}
                    values={values}
                    label="Default Originators"
                    options={designatedOriginatorList}
                    customLabelName={"contact_name"}
                    editDefaultOriginators={true}
                  />
                  <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
                    <ErrorMessage name="defaultOriginators" />
                  </ErrorMessageWrapper>
                </FormControl>
              </div>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default DefaultOriginatorEditForm;
