import React from "react";
import { NotFoundPage } from "../NotFoundPage";
import { useAuth0 } from "@auth0/auth0-react";
import { AppState, useSelector } from "../../state";
import {
  REACT_APP_LENDER_ADMIN_PORTAL,
  REACT_APP_AUTH0_CLAIM_DOMAIN,
} from "../../config";

export default function EditRatesPage() {
  const { isAuthenticated, user } = useAuth0();
  const isRateAdmin = user?.[`${REACT_APP_AUTH0_CLAIM_DOMAIN}/isRateAdmin`];
  const { token } = useSelector((state: AppState) => state.auth);
  return isAuthenticated ? (
    <>
      {isRateAdmin ? (
        <iframe
          title="editRates"
          id="popupMember"
          style={{
            width: "100%",
            height: "95vh",
            border: "none",
            overflow: "hidden",
          }}
          //${REACT_APP_LENDER_ADMIN_PORTAL}

          src={`${REACT_APP_LENDER_ADMIN_PORTAL}/editRates?token=${token}`}
        >
          {" "}
        </iframe>
      ) : (
        <NotFoundPage />
      )}
    </>
  ) : null;
}
