import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, Button, Grid, Slide, Tab, Tabs, Tooltip } from "@mui/material";
import {
  AppState,
  uiSelectors,
  uiActions,
  listActions,
  authSelectors,
  lendingProfileActions,
  lendingProfileSelector,
} from "../../state";
import { LendingProfileSideLayout } from "../../components/lenderProfile/LendingProfileSideLayout";
import LenderProfileCharts from "../../components/lenderProfile/LenderProfileCharts";
import { TabPanel } from "../../components/inputs/TabPanel";
import MainPages from "../../pages/main";
import { PageBox, SideBox, PageTitle, ContentBox } from "./styles";
import { AdminAccessDropdown } from "../../components/Admin/AdminAccessDropdown";
import { hotjar } from "react-hotjar";
import EmptyState from "../../components/Admin/EmptyState";
import { DefaultLendingSettings } from "../../components/defaultProfiles/DefaultLendingSettings";
import { DefaultLendingKeys } from "../../components/defaultProfiles/DefaultLendingKeys";

export const LendingProfilePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: AppState) => state.auth.user);
  const flyoutOpen = useSelector(uiSelectors.flyoutOpen);
  const tabs: [] = useSelector(lendingProfileSelector.propTypeTabs);
  const activeTab = useSelector(lendingProfileSelector.activeSelectedTab);
  const [activeTabSelected, setActiveTabSelected] = useState(activeTab);
  listActions.getExecutionTypeMapping();

  const activeConciergeOriginatorId = useSelector(
    authSelectors.activeConciergeOriginatorId,
  );

  const handleChange = (event: React.SyntheticEvent, newValue: any) => {
    dispatch(uiActions.setFlyoutOpen(false));
    dispatch(uiActions.setAddTab(false));
    dispatch(uiActions.setEditTab(false));
    dispatch(uiActions.setLendingProfileOpen(false));
    dispatch(uiActions.setBulkEdit(false));
    dispatch(uiActions.setBulkEditColumn(null));
    dispatch(uiActions.setBulkEditRow(null));
    dispatch(uiActions.setBulkEditAll(null));
    dispatch(uiActions.setCurrentLendingTab(tabs[newValue]));
    navigate(
      `${MainPages.lendingProfile.path}?activeTab=${JSON.stringify(
        tabs[newValue],
      )}`,
    );
    setActiveTabSelected(newValue);
    dispatch(lendingProfileActions.setActiveSelectedTab(newValue));
    hotjar.event(`Tab Lending profiles - ${tabs[newValue]["profile_name"]}`);
  };
  useEffect(() => {
    const _tabs: any = tabs[activeTabSelected];
    if (_tabs) {
      dispatch(lendingProfileActions.loadActiveTab(_tabs));
    }
  }, [activeTabSelected, dispatch, tabs]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const _activeTab: any = params.get("activeTab");
    if (_activeTab) {
      const _parsedActiveTab = JSON.parse(_activeTab);
      dispatch(lendingProfileActions.loadActiveTab(_parsedActiveTab));
      const index = tabs.findIndex((t: any) => t.id === _parsedActiveTab.id);
      // setActiveTabSelected(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleAddTabClick = () => {
    hotjar.event(
      `Lending Profile Create Form Open - ${new Date(Date.now())
        .toUTCString()
        .replace(",", "")}`,
    );
    navigate(MainPages.editDefaultOriginators.path);
    dispatch(uiActions.setFlyoutOpen(false));
    dispatch(uiActions.setAddTab(false));
    dispatch(uiActions.setEditTab(false));
    dispatch(uiActions.setBulkEditColumn(null));
    dispatch(uiActions.setBulkEditRow(null));
    dispatch(uiActions.setBulkEdit(false));
    dispatch(uiActions.setBulkEditAll(null));
    navigate(MainPages.createLendingProfile.path);
  };

  useEffect(() => {
    dispatch(listActions.getLendingProfileLists());
    dispatch(lendingProfileActions.getLendingProfileData());
    dispatch(listActions.getDesignatedOriginatorList());
  }, [dispatch, activeConciergeOriginatorId]);

  const handleCreateLendingProfileClick = () => {
    navigate(MainPages.createLendingProfile.path);
  };

  return (
    <PageBox>
      <ContentBox flyoutOpen={flyoutOpen}>
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          sx={{ pr: 2 }}
          alignItems="center"
        >
          <Grid
            item
            container
            lg={6}
            md={10}
            xs={12}
            alignItems="center"
            direction="row"
          >
            <PageTitle
              sx={{
                paddingLeft: "26px",
                fontFamily: "Gilroy",
                fontSize: "28px",
                fontWeight: 700,
              }}
              variant="h2"
            >
              Lending Profiles
            </PageTitle>
          </Grid>
          {user?.isAdmin && <AdminAccessDropdown />}
        </Grid>
        {tabs.length > 0 ? (
          <>
            <Box
              sx={{
                position: "relative",
                margin: "0 -24px",
                padding: "0 49px",
                borderBottom: 1,
                borderColor: "divider",
                display: "flex",
              }}
            >
              <Tabs
                value={activeTabSelected}
                onChange={handleChange}
                aria-label="lending-profile-tabs"
                variant="scrollable"
                scrollButtons="auto"
              >
                {tabs.map((tab: any, index) => (
                  <Tooltip title={tab.profile_name} key={index}>
                    <Tab
                      value={index}
                      sx={{
                        minWidth: 80,
                        maxWidth: 220,
                        textOverflow: "ellipsis",
                        display: "inline-block",
                        whiteSpace: "nowrap",
                        flexDirection: "row",
                        padding: "12px 5px",
                        marginRight: "20px",
                      }}
                      label={
                        tab.profile_name
                        // tab.value?.split(",")?.length === 2
                        //   ? tab.value?.replace(",", " &")
                        //   : tab.value
                      }
                    />
                  </Tooltip>
                ))}
              </Tabs>
              <Button
                sx={{
                  padding: "0",
                  marginLeft: "-20px",
                  minWidth: "55px",
                }}
                onClick={handleAddTabClick}
              >
                <FontAwesomeIcon
                  size="sm"
                  color="#023E46"
                  icon={solid("plus-circle")}
                />
              </Button>
            </Box>
            {/* <EditTabButtonState /> */}

            {tabs.map((key, index) => (
              <TabPanel value={activeTabSelected} index={index} key={index}>
                <>
                  <DefaultLendingSettings />
                  <LenderProfileCharts />
                  <DefaultLendingKeys />
                </>
              </TabPanel>
            ))}
          </>
        ) : (
          <EmptyState
            title="No Lending Profiles"
            subTitle="It looks like there are no lending profiles created for this lender. 
To create your first profile, click below."
            lendingProfile={true}
            onButtonClick={handleCreateLendingProfileClick}
          />
        )}
      </ContentBox>

      <Slide direction="left" in={flyoutOpen} mountOnEnter unmountOnExit>
        <SideBox>
          <LendingProfileSideLayout />
        </SideBox>
      </Slide>
    </PageBox>
  );
};
