import React, { useState } from "react";
import { LoanSizingComponent } from "./LoanSizingComponent";
import { LoanStructureComponent } from "./LoanStructureComponent";
import { OriginationFeesNotesComponent } from "./OriginationFeesNotesComponent";
import { RateSpreadComponent } from "./RateSpreadComponent";
import { useFormikContext } from "formik";

export const PermanentDebtQuoteForm = ({
  isTermYears,
  isIOYears,
  setIsIOYears,
  setIsTermYears,
  setIsExtYears,
  isExtYears,
  emptyIt,
}) => {
  const { values }: any = useFormikContext();

  //const [isSecondPeriod, setSecondPeriod] = useState(false);
  const [indexFloor, setIndexFloor] = useState(values.index_floor);
  const [rateFloor, setRateFloor] = useState(values.rate_floor);

  return (
    <>
      <LoanSizingComponent
        type={"permanent"}
        emptyIt={emptyIt}
        setIsTermYears={setIsTermYears}
        setIsIOYears={setIsIOYears}
        setIsExtYears={setIsExtYears}
        setIndexFloor={setIndexFloor}
        setRateFloor={setRateFloor}
      />

      <LoanStructureComponent
        isTermYears={isTermYears}
        isIOYears={isIOYears}
        setIsIOYears={setIsIOYears}
        setIsTermYears={setIsTermYears}
        type={"Permanent Debt"}
      />
      <RateSpreadComponent
        isExtYears={isExtYears}
        setIsExtYears={setIsExtYears}
        //isSecondPeriod={isSecondPeriod}
        //setSecondPeriod={setSecondPeriod}
        indexFloor={indexFloor}
        setIndexFloor={setIndexFloor}
        rateFloor={rateFloor}
        setRateFloor={setRateFloor}
      />

      <OriginationFeesNotesComponent />
    </>
  );
};
