import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { BottomNavigationAction, BottomNavigation } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MainPages from "../../pages/main";
import { BottomBox } from "./MainLayout.styles";

export default function MobileBottomNavigation() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);

  return (
    <BottomBox elevation={3}>
      <BottomNavigation
        sx={{ backgroundColor: "#f9f9fb" }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          navigate(
            newValue === 0
              ? MainPages.opportunities.path
              : MainPages.lendingProfile.path,
          );
        }}
      >
        <BottomNavigationAction
          label="Quote Opportunities"
          icon={
            <FontAwesomeIcon
              size={"1x"}
              icon={regular("tachometer-alt-fastest")}
            />
          }
        />
        <BottomNavigationAction
          label="My lending profile"
          icon={<FontAwesomeIcon size={"1x"} icon={solid("list")} />}
        />
      </BottomNavigation>
    </BottomBox>
  );
}
