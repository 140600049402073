import { yup } from "./forms";

export const lendingProfileValidationSchema = yup.object().shape({
  states: yup.array().min(1, "States is a required field"),
  propertyTypes: yup.array().min(1, "Property Types is a required field"),
  min_loan: yup
    .number()
    .typeError("'Loan min' must be a number")
    .min(0, "'Loan min' must be greater than or equal to 0")
    .max(
      yup.ref("max_loan"),
      "'Loan Min' must be less than or equal to 'Loan Max'",
    )
    .nullable()
    .required("Loan Min is a required field"),
  max_loan: yup
    .number()
    .typeError("'Loan max' must be a number")
    .min(
      yup.ref("min_loan"),
      "'Loan Max' must be greater than or equal to 'Loan Min'",
    )
    .nullable()
    .required("Loan Max is a required field"),
  max_ltv: yup
    .number()
    .typeError("'Max LTV' must be a number")
    .min(0, "'Max LTV' must be greater than or equal to 0")
    .nullable(),
  min_rate: yup
    .number()
    .typeError("'Min Rate' must be a number")
    .min(0, "'Min Rate' must be greater than or equal to 0")
    .nullable()
    .when("max_rate", {
      is: (exists) => !!exists,
      then: yup
        .number()
        .typeError("'Min Rate' must be a number")
        .min(0, "'Min Rate' must be greater than or equal to 0")
        .max(
          yup.ref("max_rate"),
          "'Min Rate' must be less than or equal to 'Max Rate'",
        ),
      otherwise: yup.number().nullable(),
    }),
  max_rate: yup
    .number()
    .typeError("'Max Rate' must be a number")
    .min(
      yup.ref("min_rate"),
      "'Max Rate' must be greater than or equal to 'Min Rate'",
    )
    .nullable(),
  origination_fee: yup
    .number()
    .typeError("'Origination Fee' must be a number")
    .min(0, "'Origination Fee' must be greater than or equal to 0")
    .nullable(),
  io_years: yup
    .number()
    .typeError("'Interest Only' must be a number")
    .min(0, "'Interest Only' must be greater than or equal to 0")
    .nullable(),
  io_months: yup
    .number()
    .typeError("'Interest Only' must be a number")
    .min(0, "'Interest Only' must be greater than or equal to 0")
    .nullable(),
});

export const lendingProfileCreationValidationSchema = yup.object().shape({
  profileName: yup.string().required("Profile Name is a required field"),
  propertyTypes: yup.array().min(1, "Property Types is a required field"),
  loanTypes: yup.array().min(1, "Loan Types is a required field"),
  states: yup.array().min(1, "States is a required field"),
  executionTypes: yup.array().min(1, "Execution Types is a required field"),
  minLoan: yup
    .number()
    .typeError("'Loan min' must be a number")
    .min(0, "'Loan min' must be greater than or equal to 0")
    .max(
      yup.ref("maxLoan"),
      "'Loan Min' must be less than or equal to 'Loan Max'",
    )
    .nullable()
    .required("Loan Min is a required field"),
  maxLoan: yup
    .number()
    .typeError("'Loan max' must be a number")
    .min(
      yup.ref("minLoan"),
      "'Loan Max' must be greater than or equal to 'Loan Min'",
    )
    .nullable()
    .required("Loan Max is a required field"),
  maxLTV: yup
    .number()
    .typeError("'Max LTV' must be a number")
    .min(0, "'Max LTV' must be greater than or equal to 0")
    .nullable(),
  //.required("Max LTV is a required field"),
  minRate: yup
    .number()
    .typeError("'Min Rate' must be a number")
    .min(0, "'Min Rate' must be greater than or equal to 0")
    .nullable()
    .when("maxRate", {
      is: (exists) => !!exists,
      then: yup
        .number()
        .typeError("'Min Rate' must be a number")
        .min(0, "'Min Rate' must be greater than or equal to 0")
        .max(
          yup.ref("maxRate"),
          "'Min Rate' must be less than or equal to 'Max Rate'",
        ),
      otherwise: yup.number().nullable(),
    }),
  maxRate: yup
    .number()
    .typeError("'Max Rate' must be a number")
    .min(
      yup.ref("minRate"),
      "'Max Rate' must be greater than or equal to 'Min Rate'",
    )
    .nullable(),
  originationFee: yup
    .number()
    .typeError("'Origination Fee' must be a number")
    .min(0, "'Origination Fee' must be greater than or equal to 0")
    .nullable(),
  interestOnlyYears: yup
    .number()
    .typeError("'Interest Only' must be a number")
    .min(0, "'Interest Only' must be greater than or equal to 0")
    .nullable(),
  interestOnlyMonths: yup
    .number()
    .typeError("'Interest Only' must be a number")
    .min(0, "'Interest Only' must be greater than or equal to 0")
    .nullable(),
});

export const editDefaultCriteriaValidationSchema = yup.object().shape({
  profileName: yup.string().required("Profile Name is a required field"),
  propertyTypes: yup.array().min(1, "Property Types is a required field"),
  loanTypes: yup.array().min(1, "Loan Types is a required field"),
  states: yup.array().min(1, "States is a required field"),
  executionTypes: yup.array().min(1, "Execution Types is a required field"),
  minLoan: yup
    .number()
    .typeError("'Loan min' must be a number")
    .min(0, "'Loan min' must be greater than or equal to 0")
    .max(
      yup.ref("maxLoan"),
      "'Loan Min' must be less than or equal to 'Loan Max'",
    )
    .nullable()
    .required("Loan Min is a required field"),
  maxLoan: yup
    .number()
    .typeError("'Loan max' must be a number")
    .min(
      yup.ref("minLoan"),
      "'Loan Max' must be greater than or equal to 'Loan Min'",
    )
    .nullable()
    .required("Loan Max is a required field"),
  maxLTV: yup
    .number()
    .typeError("'Max LTV' must be a number")
    .min(0, "'Max LTV' must be greater than or equal to 0")
    .nullable(),
  minRate: yup
    .number()
    .typeError("'Min Rate' must be a number")
    .min(0, "'Min Rate' must be greater than or equal to 0")
    .nullable()
    .when("maxRate", {
      is: (exists) => !!exists,
      then: yup
        .number()
        .typeError("'Min Rate' must be a number")
        .min(0, "'Min Rate' must be greater than or equal to 0")
        .max(
          yup.ref("maxRate"),
          "'Min Rate' must be less than or equal to 'Max Rate'",
        ),
      otherwise: yup.number().nullable(),
    }),
  maxRate: yup
    .number()
    .typeError("'Max Rate' must be a number")
    .min(
      yup.ref("minRate"),
      "'Max Rate' must be greater than or equal to 'Min Rate'",
    )
    .nullable(),
  originationFee: yup
    .number()
    .typeError("'Origination Fee' must be a number")
    .min(0, "'Origination Fee' must be greater than or equal to 0")
    .nullable(),
  interestOnlyYears: yup
    .number()
    .typeError("'Interest Only' must be a number")
    .min(0, "'Interest Only' must be greater than or equal to 0")
    .nullable(),
  interestOnlyMonths: yup
    .number()
    .typeError("'Interest Only' must be a number")
    .min(0, "'Interest Only' must be greater than or equal to 0")
    .nullable(),
});
