import React, { useEffect, useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { hotjar } from "react-hotjar";
import { REACT_APP_HOTJAR_HJSV, REACT_APP_HOTJAR_ID } from "../../config";

const SomethingWentWrong = props => {
  useEffect(() => {
    if (REACT_APP_HOTJAR_ID && REACT_APP_HOTJAR_HJSV) {
      hotjar.initialize(+REACT_APP_HOTJAR_ID, +REACT_APP_HOTJAR_HJSV);
    }
  }, []);
  const token = props.token;
  useEffect(() => {
    hotjar.event(`Something went wrong in the lender-portal CTA. Token: ${token}`);
  }, [token])
  const useStyles = useMemo(() => {
    return makeStyles(() => ({
      root: {
        width: "hug(300px)",
        height: "hug(36px)",
        left: "16px",
        flex: 'none',
        order: 0,
        flexGrow: 0,
        gap: "40px",
      },
    }));
  }, []);
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
    >
      <Typography
        variant={"h3"}
        sx={{
          fontFamily: "Gilroy",
          fontWeight: 700,
          fontSize: "28px",
          lineHeight: "36px",
          textAlign: "center",
          color: "#03363D",
          textTransform: "none"
        }}>
        Something went wrong
      </Typography>
      <Typography
        sx={{
          width: "hug(332px)",
          height: "hug(96px)",
          fontFamily: "ABC Monument Grotesk",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          textAlign: "center",
          color: "#757575",
          flex: "none",
          order: 1,
          flexGrow: 0,
          textTransform: "none"
        }}
        variant={"body1"}
        mt={"8px"}>
        We’re not sure what happened but we ran into an error while processing your request.
      </Typography>
      <Typography
        sx={{
          width: "hug(332px)",
          height: "hug(96px)",
          fontFamily: "ABC Monument Grotesk",
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          textAlign: "center",
          color: "#757575",
          flex: "none",
          order: 1,
          flexGrow: 0,
          textTransform: "none"
        }}
        variant={"body1"}
        mt={8}>
        Please try submitting one more time.
      </Typography>
    </Box>
  );
};

export default SomethingWentWrong;