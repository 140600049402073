import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LendingProfile } from "../types";

export interface LendingProfileState {
  activeTab: any;
  newTabAdded: string | undefined;
  lending_profiles: LendingProfile[];
  activeLPId: undefined | number;
  activeSelectedTab: number;
  property_type_tabs: [];
  tabNamesCombined: [];
  activeDefaultLP: any;
}

const initialState: LendingProfileState = {
  activeDefaultLP: {},
  activeLPId: undefined,
  activeTab: { id: "", value: "" },
  activeSelectedTab: 0,
  newTabAdded: undefined,
  lending_profiles: [],
  property_type_tabs: [],
  tabNamesCombined: [],
};

export const lendingProfile = {
  ...createSlice({
    name: "lendingProfile",
    initialState,
    reducers: {
      setLendingProfileState(
        state,
        { payload }: PayloadAction<LendingProfileState | undefined>,
      ) {
        if (!payload) {
          return initialState;
        }
        Object.assign(state, payload);
      },
      loadActiveLendingProfileId(state, { payload }: PayloadAction<any>) {
        state.activeLPId = payload;
      },
      loadLendingProfiles(state, { payload }: PayloadAction<any>) {
        state.lending_profiles = payload;
      },
      loadPropertyTypeTabs(state, { payload }: PayloadAction<any>) {
        state.property_type_tabs = payload;
        state.tabNamesCombined = payload
          ?.map((pt) => pt.profile_name?.replace(/\s/g, ""))
          ?.join(",");
      },
      loadActiveTab(state, { payload }: PayloadAction<any>) {
        state.activeTab = payload;
      },
      newTabAdded(state, { payload }: PayloadAction<string | undefined>) {
        state.newTabAdded = payload;
      },
      loadAddLendingProfileDefaults(state, { payload }: PayloadAction<any>) {
        state.activeDefaultLP = payload;
      },
      setActiveSelectedTab(state, { payload }: PayloadAction<number>) {
        state.activeSelectedTab = payload;
      },
    },
  }),
  persist: true,
};
