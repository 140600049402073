import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { RootStyle } from "./HomePage.styles";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { Navigation } from "../../lib";
import MainPages from ".";
import AuthPages from "../auth";
import { useAuth0 } from "@auth0/auth0-react";

function _HomePage() {
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const { isAuthenticated } = useAuth0();
  useEffect(() => {
    if (isLoggedIn && isAuthenticated) {
      Navigation.go(MainPages.opportunities.path);
    } else {
      Navigation.go(AuthPages.login.path);
    }
  }, [isLoggedIn, isAuthenticated]);
  return (
    <RootStyle title="Home | GParency" id="move_top">
      <Button
        sx={{
          color: "#000",
          backgroundColor: "#E8E8E8",
          position: "absolute",
          width: 150,
          top: 60,
          right: 60,
          zIndex: 99,
        }}
      >
        <Link to="/auth/login">Login</Link>
      </Button>
    </RootStyle>
  );
}

export const HomePage = React.memo(_HomePage);
