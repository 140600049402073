import { Box, Button, Grid, styled, Typography } from "@mui/material";
import React from "react";
import leftG from "../assets/img/G-gradient-left.png";
import rightG from "../assets/img/G-gradient-right.png";
import companyLogo from "../assets/img/company-logo.svg";
import { Navigation } from "../lib";
import MainPages from "./main";
import AuthPages from "./auth";

export const Container = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    minHeight: "100vh",
    background: "linear-gradient(90deg, #E2F6F3 0%, #DCF8FF 100%)",
    alignItems: "center",
    justifyContent: "center",
    margin: -24,
    marginLeft: -96,
    marginTop: -40,
    zIndex: 10000,
    position: "fixed",
    width: "100%",
    textAlign: "center",
  },
}));

export const NotFoundPage = React.memo(
  /**
   *
   */
  function NotFoundPage() {
    return (
      <Container>
        <Grid container sx={{ minHeight: "100vh" }}>
          <Grid item xs={3} md={4}>
            <img
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "22vw",
              }}
              src={leftG}
              alt="big-g"
            />
          </Grid>
          <Grid item xs={6} md={4} sx={{ mt: 11 }}>
            <img src={companyLogo} width="240px" alt="GParency" />
            <Typography
              variant="h1"
              sx={{ fontSize: 180, mb: 4, mt: 12, lineHeight: "140px" }}
            >
              404
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 400 }}>
              Hmm, we can't find that page
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => Navigation.go(MainPages.opportunities.path)}
              sx={{ maxWidth: 240, marginTop: 10 }}
            >
              Go to Opportunities
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => Navigation.go(AuthPages.marketplace.path)}
              sx={{ maxWidth: 240, marginTop: 10, marginLeft: 2 }}
            >
              Go to the Marketplace &reg;
            </Button>
          </Grid>
          <Grid item md={4} xs={3}>
            <img
              style={{
                position: "absolute",
                right: 0,
                bottom: -80,
                width: "22vw",
              }}
              src={rightG}
              alt="big-g"
            />
          </Grid>
        </Grid>
      </Container>
    );
  },
);
