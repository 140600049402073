/**
 * @file This file is used to normalize environment variables and provide
 * intellisense/autocomplete for them. Import your settings from this file
 * instead of directly from `process.env`.
 */

/** Common environment names. */
const Environments = {
  development: "development",
  production: "production",
};

/** Name of the current environment. */
const NODE_ENV = process.env.NODE_ENV || Environments.development;
/** Host name used to detect production mode. */
const REACT_APP_PROD_HOSTNAME = process.env.REACT_APP_PROD_HOSTNAME;
/** True if the app is in development mode. */
const __DEV__ = NODE_ENV === Environments.development;
/**
 * True if the app is in production mode.
 * NOTE: We don't use Environments.production to test for this
 * because Create-React-App uses "production" for every other non-development
 * environment.
 */
const __PROD__ = window.location.hostname === REACT_APP_PROD_HOSTNAME;
const COLLAPSIBLE_DRAWER = process.env.COLLAPSIBLE_DRAWER || true;
/** Base URL of the API. */
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
/** URL to the company website. */
const REACT_APP_COMPANY_SITE_URL = process.env.REACT_APP_COMPANY_SITE_URL;
/** DSN URL for the error telemetry API. */
const REACT_APP_ERRORS_DSN = process.env.REACT_APP_ERRORS_DSN;
/** True to report errors during development. */
const REACT_APP_ERRORS_DEV = process.env.REACT_APP_ERRORS_DEV === "true";
/** Key to store redux state under localStorage. */
const REACT_APP_PERSIST_KEY = process.env.REACT_APP_PERSIST_KEY;
/** Site title string. */
const REACT_APP_SITE_TITLE = process.env.REACT_APP_SITE_TITLE;
/** Package version string from the package.json file. */
const REACT_APP_VERSION = process.env.REACT_APP_VERSION;

const REACT_APP_API_KEY = process.env.REACT_APP_API_KEY;

const REACT_APP_SECRET_ID = process.env.REACT_APP_SECRET_ID;

const REACT_APP_MUI_LICENSE = process.env.REACT_APP_MUI_LICENSE;

const REACT_APP_AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";
const REACT_APP_AUTH0_SCOPE = process.env.REACT_APP_AUTH0_SCOPE ?? "";
const REACT_APP_AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";
const REACT_APP_AUTH0_ISSUER = process.env.REACT_APP_AUTH0_ISSUER ?? "";
const REACT_APP_AUTH0_CLAIM_DOMAIN =
  process.env.REACT_APP_AUTH0_CLAIM_DOMAIN ?? "";

const REACT_APP_AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ?? "";

const REACT_APP_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN ?? "";

const REACT_APP_MARKETPLACE = process.env.REACT_APP_MARKETPLACE ?? "https://gparency.com";


const REACT_APP_HOTJAR_ID = process.env.REACT_APP_HOTJAR_ID ?? undefined;
const REACT_APP_HOTJAR_HJSV = process.env.REACT_APP_HOTJAR_HJSV ?? undefined;
const REACT_APP_CRM_CTA_URL = process.env.REACT_APP_CRM_CTA_URL ?? "";
const REACT_APP_LENDER_ADMIN_PORTAL =
  process.env.REACT_APP_LENDER_ADMIN_PORTAL ?? "";

//GATEWAYS
const DIRECTORY_BASE_URL =
  process.env.REACT_APP_DIRECTORY_BASE_URL ??
  "https://c24j5nnin9.execute-api.us-east-1.amazonaws.com/Prod";
const LENDER_BASE_URL =
  process.env.REACT_APP_LENDER_BASE_URL ??
  "https://l4dzsmo5we.execute-api.us-east-1.amazonaws.com/Prod";
const LOOKUP_BASE_URL =
  process.env.REACT_APP_LOOKUP_BASE_URL ??
  "https://hastml49w6.execute-api.us-east-1.amazonaws.com/Prod";

const PROFILE_BASE_URL =
  process.env.REACT_APP_PROFILE_BASE_URL ??
  "https://rf0sco6g5b.execute-api.us-east-1.amazonaws.com/Prod";

const MODE = process.env.REACT_APP_MODE;
export {
  __DEV__,
  __PROD__,
  NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_COMPANY_SITE_URL,
  REACT_APP_ERRORS_DSN,
  REACT_APP_ERRORS_DEV,
  REACT_APP_PERSIST_KEY,
  REACT_APP_API_KEY,
  REACT_APP_SECRET_ID,
  REACT_APP_PROD_HOSTNAME,
  REACT_APP_SITE_TITLE,
  REACT_APP_VERSION,
  COLLAPSIBLE_DRAWER,
  REACT_APP_MUI_LICENSE,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_SCOPE,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_AUTH0_ISSUER,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_HOTJAR_ID,
  REACT_APP_HOTJAR_HJSV,
  DIRECTORY_BASE_URL,
  LENDER_BASE_URL,
  LOOKUP_BASE_URL,
  PROFILE_BASE_URL,
  MODE,
  REACT_APP_CRM_CTA_URL,
  REACT_APP_AUTH0_CLAIM_DOMAIN,
  REACT_APP_LENDER_ADMIN_PORTAL,
  REACT_APP_MARKETPLACE,
  REACT_APP_COOKIE_DOMAIN
};
