import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
export const PartEmptyKey = () => {
  return (
    <Box
      className="part-empty-key"
      sx={{
        position: "relative",
        display: "inline-block",
        padding: "0 8px",
        height: "18px",
        backgroundColor: "#E0E0E0",
        borderRadius: "10px",
        marginRight: "33px",
      }}
    >
      <FontAwesomeIcon
        style={{
          margin: "0 0 2px 0",
          width: "10px",
          height: "14px",
          pointerEvents: "none",
        }}
        color="#757575"
        size={"1x"}
        icon={solid("empty-set")}
      />

      <Box
        sx={{
          position: "absolute",
          top: "-61px",
          left: "50%",
          transform: "translateX(-50%)",
          backgroundColor: "#03363D",
          color: "#fff",
          fontSize: "14px",
          lineHeight: "16px",
          padding: "14px 13px",
          fontWeight: "400",
          opacity: "0",
          visibility: "hidden",
          pointerEvents: "none",
          transition: ".3s ease",
          textTransform: "initial",
          "&::before": {
            position: "absolute",
            bottom: "-7px",
            left: "50%",
            transform: "translateX(-50%)",
            content: '""',
            display: "block",
            width: 0,
            height: 0,
            borderWidth: "8px 4.5px 0 4.5px",
            borderColor: "#03363D transparent transparent transparent",
            borderStyle: "solid",
          },
        }}
      >
        No lending criteria. Click to create.
      </Box>
    </Box>
  );
};
