import React, { memo } from "react";
import { Grid } from "@mui/material";
import DefaultProfileCreateForm from "../../components/defaultProfiles/DefaultProfileCreateForm";
import { ContentBox, PageBox, PageTitle } from "./styles";
import { RemoveOriginatorModal } from "../../components/modals/RemoveOriginatorModal";
import { AdminAccessDropdown } from "../../components/Admin/AdminAccessDropdown";
import { AppState, useSelector } from "../../state";

export const CreateLendingProfilePage = memo(
  function CreateLendingProfilePage() {
    const user = useSelector((state: AppState) => state.auth.user);

    return (
      <PageBox>
        <ContentBox>
          <Grid
            container
            justifyContent="space-between"
            direction="row"
            sx={{ pr: 2 }}
            alignItems="center"
          >
            <Grid
              item
              container
              lg={6}
              md={10}
              xs={12}
              alignItems="center"
              direction="row"
            >
              <PageTitle
                sx={{
                  paddingLeft: "26px",
                  marginBottom: "27px",
                  fontFamily: "Gilroy",
                  fontSize: "28px",
                  fontWeight: 700,
                }}
                variant="h2"
              >
                Create A Lending Profile
              </PageTitle>
            </Grid>
            {user?.isAdmin && <AdminAccessDropdown />}
          </Grid>
          <RemoveOriginatorModal />

          <DefaultProfileCreateForm />
        </ContentBox>
      </PageBox>
    );
  },
);
