import { styled } from "@mui/material/styles";

export const RootStyle = styled(`div`)(({ theme }) => ({
  display: "flex",
  height: "100vh",
  backgroundColor: theme.palette.primary.light,
  [theme.breakpoints.up("sm")]: {
    flexDirection: "row",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-between",
    flexDirection: "column",
  },
}));

export const LeftPanel = styled(`div`)(({ theme }) => ({
  padding: 16,
  [theme.breakpoints.up("sm")]: {
    maxWidth: "50vw",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 24,
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const Container = styled(`div`)(({ theme }) => ({
  width: "100vw",
  display: "flex",
  flexDirection: "row",
  height: "80vh",
  maxWidth: 1360,
  margin: "0 auto",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    gap: "20px",
  },
}));

export const RightPanel = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  zIndex: 100,
  [theme.breakpoints.up("xs")]: {
    height: "auto",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
  },
}));

export const SmallContainer = styled(`div`)(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.up("sm")]: {
    maxWidth: 300,
  },
}));
