import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Chip, FormControl, InputLabel, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useState } from "react";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface AutocompleteOriginatorsProps {
  options: any;
  setFieldValue: Function;
  isRequired?: boolean;
  customLabelName?: string;
  values?: any;
  name: string;
  label?: string;
  handleOriginatorDelete?: Function;
  editDefaultOriginators?: boolean;
  editOriginatorsSlideOut?: boolean;
}

export default function AutocompleteOriginators({
  options,
  setFieldValue,
  isRequired,
  name,
  values,
  customLabelName,
  label,
  handleOriginatorDelete,
  editDefaultOriginators,
  editOriginatorsSlideOut,
}: AutocompleteOriginatorsProps) {
  const [expandedPills, setExpandedPills] = useState(false);

  return (
    <FormControl
      sx={{
        marginTop: "-15px",
        input: {
          // position: "absolute",
          // top: "-36px",
          width: "374px !important",
          border: "1px solid #C9CCCF",
          padding: "4px 12px 7px !important",
        },
        ".MuiAutocomplete-root": {
          position: "relative",
          marginTop: "15px !important",
          label: {
            position: "absolute",
            top: 8,
            left: 12,
            pointerEvents: "none",
            color: "#757575",
            fontSize: "14px",
            fontWeight: 400,
            ".MuiTypography-caption": {
              position: "relative",
              top: "5px",
            },
            "& + div": {
              paddingRight: "0px !important",
            },
          },
        },
        // ".MuiInputBase-formControl": {
        //   position: "absolute",
        //   top: "47px",
        // },
        ".MuiInputBase-root-MuiInput-root": {
          marginTop: 0,
          alignItems: "flex-start",
          flexDirection: "column-reverse",
        },
        ".autocomplete .MuiInput-root": {
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column-reverse",
          paddingRight: "0",
          "&::before, &::after": {
            display: "none",
          },
        },

        ".autocomplete-alt": {
          marginTop: "5px !important",
        },

        ".autocomplete-alt .MuiInput-root": {
          marginTop: "11px",
          width: "400px",
        },

        ".autocomplete-slideout": {
          marginTop: "5px!important",
        },
        ".autocomplete-slideout .MuiInput-root": {
          marginTop: "1px",

          input: {
            width: "379px!important",
          },

          "& .MuiAutocomplete-endAdornment": {
            right: "10px",
          },

          "& .MuiFormControl-root": {
            paddingTop: "0",
          },
        },

        ".autocomplete-slideout label": {
          top: "12px !important",
        },

        ".MuiFormLabel-filled": {
          opacity: 0,
        },
        ".MuiAutocomplete-popper": {
          transform: "translate(1px, 81px) !important",
          inset: "-17px auto auto 0px !important",
        },
        // ".MuiAutocomplete-option[aria-selected='true'], .MuiAutocomplete-option.Mui-focused":
        //   {
        //     backgroundColor: "rgba(150, 204, 212, 0.15) !important",
        //   },

        "& .autocomplete-alt + .MuiAutocomplete-popper": {
          inset: "-8px auto auto 0px !important",
        },

        ".autocomplete-slideout + .MuiAutocomplete-popper": {
          inset: "-20px auto auto 0px !important",
        },

        ".MuiAutocomplete-endAdornment": {
          top: "5px",
          right: "10px",
          svg: {
            width: "13px",
            height: "14px",
          },
        },
        ".MuiChip-deleteIcon": {
          width: "12px",
          height: "13px",
          color: "#03363D !important",
        },

        "& .MuiCheckbox-root": {
          padding: "7px 8px 8px",
          margin: "0 !important",

          svg: {
            color: "#b5b5b5",
          },
          "&.Mui-checked svg": {
            color: "#03363d",
          },
        },

        ".MuiAutocomplete-paper": {
          backgroundColor: "#fff !important",
        },

        ".MuiFormControl-root": {
          paddingTop: "0px !important",
          marginTop: "-14px",
        },
      }}
    >
      <InputLabel shrink>
        {label}{" "}
        {isRequired && (
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        )}
      </InputLabel>
      <Autocomplete
        sx={{
          paddingTop: "0",
          "& > div": {
            paddingTop: "0 !important",
          },
        }}
        multiple
        // open={true}
        className={
          editDefaultOriginators
            ? "autocomplete autocomplete-alt"
            : editOriginatorsSlideOut
            ? "autocomplete autocomplete-slideout"
            : "autocomplete"
        }
        popupIcon={
          <FontAwesomeIcon
            color="#757575"
            size={"1x"}
            icon={regular("chevron-down")}
          />
        }
        clearIcon={false}
        limitTags={7}
        id="checkboxes-tags-demo"
        options={options}
        value={values[name]}
        disableCloseOnSelect
        onChange={(event, value, reason, details) => {
          setFieldValue(name, value);
        }}
        disablePortal={true}
        renderTags={(tagValue, getTagProps) => {
          const displayedTags = expandedPills ? tagValue : tagValue.slice(0, 2);
          if (tagValue.length < 3) setExpandedPills(false);
          return (
            <div className="autocomplete-pils-wrapper">
              {displayedTags.map(
                (option: any, index) =>
                  option.id !== "new" && (
                    <Chip
                      label={option.contact_name}
                      deleteIcon={
                        <FontAwesomeIcon size={"1x"} icon={regular("close")} />
                      }
                      {...getTagProps({ index })}
                    />
                  ),
              )}
              {!expandedPills && tagValue.length > 2 && (
                <Chip
                  label={`+${tagValue.length - 2}`}
                  onClick={() => setExpandedPills(true)}
                  className="expand-more-chip MuiAutocomplete-tag"
                  //custom icon, not delete icon
                  icon={<FontAwesomeIcon icon={regular("angle-down")} />}
                  sx={{
                    flexDirection: "row-reverse",
                    "& svg ": {
                      fontSize: "16px",
                    },
                  }}
                />
              )}
            </div>
          );
        }}
        getOptionLabel={(option: any) => option[customLabelName || "value"]}
        renderOption={(props, option, { selected }) => {
          // if (option.id === "new") {
          //   return (
          //     <span
          //       onClick={(e: any) => {
          //         console.log("clicked on new, stopping propagation");
          //         e.stopPropagation();
          //       }}
          //       {...props}
          //     >
          //       + Create New Originator
          //     </span>
          //   );
          // } else {
          return (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={values[name].find(
                  (originator: any) =>
                    originator.contact_id == option.contact_id,
                )}
              />
              {option[customLabelName || "value"]}
              {/* {option.id !== "new" && (
                <span
                  onClick={(e: any) => {
                    e.stopPropagation();
                    handleOriginatorDelete && handleOriginatorDelete(option.id);
                  }}
                >
                  delete
                </span>
              )} */}
            </li>
          );
          // }
        }}
        style={{ marginTop: "5px" }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              label="Begin typing to search or select from the list"
              variant="standard"
            />
          );
        }}
      />
    </FormControl>
  );
}
