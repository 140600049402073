import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, Button } from "@mui/material";
import { useMobile } from "../../themes";
import companyIcon from "../../assets/img/MoveIconSVG.svg";
import { hotjar } from "react-hotjar";

interface PopupDiscoverProps {
  closePopupCallback: Function;
  moveQuotesCallback: Function;
}

export const PopupDiscover = ({
  closePopupCallback,
  moveQuotesCallback,
}: PopupDiscoverProps) => {
  const isMobile = useMobile();
  return (
    <Box
      sx={{
        zIndex: "999999 !important",
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",

        "& .popup-discover": {
          maxWidth: "548px",
          width: "100%",
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
          position: "fixed",
          left: "50%",
          transform: "translate(-50%, -50%)",
          top: "50%",
          padding: `${isMobile ? "50px 20px" : "75px 90px"}`,
        },
        "& .MuiSlider-thumb": {
          borderRadius: "1px",
        },

        "& .poopup-discover__title": {
          color: "#03363D",
          fontFamily: "ABC Monument Grotesk",
          fontSize: "16px",
          fontWeight: "bold",
          letterSpacing: "0",
          lineHeight: "17px",
          textAlign: "center",
          marginBottom: `${isMobile ? "25px" : "32px"}`,
        },

        "& .MuiButtonBase-root.popup-discover-close": {
          marginLeft: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          right: "24px",
          top: "25px",
          transition: "350ms ease",
          padding: "0",
          minWidth: "auto",
          "& svg": {
            heght: "16px",
          },
          ":hover": {
            backgroundColor: "#fff",
            color: "#1F9F8A !important",
          },
        },

        "& .poopup-discover__icon": {
          display: "flex",
          justifyContent: "center",
          margin: `${isMobile ? "0 auto 30px" : "0 auto 48px"}`,
          height: `${isMobile ? "80px" : "133px"}`,

          "& svg": {
            height: `${isMobile ? "80px" : "133px"}`,
          },
        },
        "& .popup-discover__btns": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        },

        "& .popup-discover__btn-green": {
          height: "34px!important",
          width: "141px!important",
          borderRadius: "4px!important",
          backgroundColor: "#03363D !important",
          color: "#FFFFFF !important",
          fontFamily: "ABC Monument Grotesk",
          fontSize: "14px !important",
          fontWeight: "500",
          letterSpacing: "0",
          lineHeight: "14px",
          textAlign: "center",
          transition: "350ms ease",
          marginBottom: "16px",
          ":hover": {
            backgroundColor: "rgb(77, 134, 149)!important",
          },
        },

        "& .popup-discover__btn-transparent": {
          color: "#1F9F8A !important",
          fontFamily: "ABC Monument Grotesk",
          fontSize: "14px",
          fontWeight: "500",
          letterspacing: "0",
          lineHeight: "14px",
          transition: "350ms ease",
          ":hover": {
            color: "rgb(77, 134, 149)!important",
          },
        },
        "& .popup-discover__overlay": {
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          opacity: "0.09",
          backgroundColor: "#040707",
        },
      }}
    >
      <div className="popup-discover__overlay"></div>
      <div className="popup-discover">
        <Button
          className="popup-discover-close"
          color="inherit"
          onClick={() => {
            closePopupCallback();
            hotjar.event("Move to Active Quotes Popup Closed");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={regular("times")} />
        </Button>
        <h2 className="poopup-discover__title">
          Move the selected opportunities to Active Quotes?
        </h2>
        <div className="poopup-discover__icon">
          {/* <IconPopup /> */}
          <img src={companyIcon} alt="logo" />
        </div>
        <div className="popup-discover__btns">
          <Button
            className="popup-discover__btn-green"
            onClick={() => {
              hotjar.event("Button Move To Active Quotes Confirm Clicked");
              moveQuotesCallback();
            }}
          >
            Yes, Please Move
          </Button>
          <Button
            className="popup-discover__btn-transparent"
            onClick={() => {
              hotjar.event("Cancel Button Move to Active Quotes Clicked");
              closePopupCallback();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Box>
  );
};
