import React, { useEffect } from "react";
import { Typography } from "@mui/material";
// Local
import { authActions, useAction } from "../../state";
import { AuthPages } from "./";
import { useAuth0 } from "@auth0/auth0-react";

export const LogoutPage = () => {
  const _logout = useAction(authActions.logout);
  const { isAuthenticated, error, user, isLoading, logout } = useAuth0();

  useEffect(() => {
    _logout().then(() => {
      logout({ returnTo: `${window.location.origin}${AuthPages.login.path}` });
    });
  }, [_logout, error, isAuthenticated, isLoading, logout, user]);

  return <Typography variant="h2">Logging out...</Typography>;
};
