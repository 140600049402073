import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { Navigation, renderPageRoutes } from "./lib";
import { AuthLayout, MainLayout } from "./layouts";
import { MainPages } from "./pages/main";
import { AuthPages } from "./pages/auth";
import { HistoryRouter } from "./lib/routing/HistoryRouter";
import { hotjar } from "react-hotjar";
import { REACT_APP_HOTJAR_HJSV, REACT_APP_HOTJAR_ID } from "./config";
import { useAuth0 } from "@auth0/auth0-react";
import { AppState, authActions, useAction } from "./state";
import { useSelector } from "react-redux";
import jwt_decode from "jwt-decode";

export const App = React.memo(function App() {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });

  const { isAuthenticated } = useAuth0();
  const _login = useAction(authActions._login);
  const token = useSelector((state: AppState) => state.auth.token);
  var decoded: any = token ? jwt_decode(token) : "";
  const localUser = React.useMemo(
    () => ({
      isLender: decoded["https://auth.gparency.com/isLender"],
      isLenderPaid: decoded["https://auth.gparency.com/isLenderPaid"],
      isPremiumLender: decoded["https://auth.gparency.com/isPremiumLender"],
    }),
    [decoded],
  );
  useEffect(() => {
    if (isAuthenticated) {
      const { isLender } = localUser;
      (async () => {
        await _login(isAuthenticated);
        isLender
          ? Navigation.go(MainPages.opportunities?.path)
          : Navigation.go(MainPages.join?.path);
      })();
    }
    if (REACT_APP_HOTJAR_ID && REACT_APP_HOTJAR_HJSV) {
      hotjar.initialize(+REACT_APP_HOTJAR_ID, +REACT_APP_HOTJAR_HJSV);
    }
  }, [_login, isAuthenticated, localUser]);

  return (
    <HistoryRouter>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          {renderPageRoutes(MainPages)}
        </Route>
        <Route path="/auth" element={<AuthLayout />}>
          {renderPageRoutes(AuthPages)}
        </Route>
      </Routes>
    </HistoryRouter>
  );
});
