import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";

interface MultipleSelectCheckmarksProps {
  name: string;
  label: string;
  field?: any;
  form?: any;
  placeholder?: string;
  values: string[];
  icon?: boolean;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => void;
  options: any;
  customLabelName?: string;
  isRequired?: boolean;
}

export default function MultipleSelectCheckmarks({
  name: inputName,
  label,
  placeholder,
  field,
  form,
  values,
  setFieldValue,
  options,
  customLabelName,
  isRequired = false,
  icon = false,
}: MultipleSelectCheckmarksProps) {
  const handleChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    const dropdownName = field ? field?.name : inputName;
    if (dropdownName === "states") {
      const states = options
        .map((s) => s.state)
        .filter((s) => !["Nationwide", "All"].includes(s));
      if (value.includes("All")) {
        form.setFieldValue(field ? field?.name : inputName, states);
      } else if (value.includes("Nationwide")) {
        form.setFieldValue(field ? field?.name : inputName, ["Nationwide"]);
      } else {
        form.setFieldValue(
          field ? field?.name : inputName,
          typeof value === "string" ? value.split(",") : value,
        );
      }
    } else if (dropdownName === "propertyTypes") {
      const propertyTypes = options
        .map((s) => s.value)
        .filter((s) => !["All", "Uncheck All"].includes(s));
      if (value.includes("All")) {
        form.setFieldValue(field ? field?.name : inputName, propertyTypes);
      } else if (value.includes("Uncheck All")) {
        form.setFieldValue(field ? field?.name : inputName, []);
      } else {
        form.setFieldValue(
          field ? field?.name : inputName,
          typeof value === "string" ? value.split(",") : value,
        );
      }
    } else {
      form.setFieldValue(
        field ? field?.name : inputName,
        typeof value === "string" ? value.split(",") : value,
      );
    }
  };

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          width: 300,
        }}
        className={`${icon ? "has-dropdown-icon" : ""}`}
      >
        <InputLabel shrink>
          {label}{" "}
          {isRequired && (
            <Typography
              color={"error"}
              variant={"caption"}
              component={"span"}
              style={{ fontSize: 20 }}
            >
              {" "}
              *
            </Typography>
          )}
        </InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          name={field ? field?.name : inputName}
          multiple
          placeholder={placeholder}
          value={field.value ? field.value : []}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => {
            if (selected.length === options.length) return "All Selected";
            if (selected.length === 0 && placeholder) return placeholder;
            return selected.join(", ");
          }}
          sx={{
            marginTop: "8px",

            "& legend": {
              display: "none",
            },

            "& fieldset": {
              height: "36px",
            },

            "& .MuiInputBase-input": {
              marginTop: "-8px",
            },
          }}
        >
          {options.map((option) => {
            const value = customLabelName
              ? option[customLabelName]
              : option.value;
            return (
              <MenuItem
                sx={{
                  margin: "5px 0",
                  padding: "4px 16px 4px 8px",
                  fontSize: 14,
                }}
                key={option.id}
                value={value}
              >
                <Checkbox
                  sx={{
                    padding: 0,
                    marginRight: "8px",
                    borderRadius: "4px",
                    "&.Mui-checked": {
                      color: "#03363D",
                    },
                  }}
                  checked={field.value && field.value.indexOf(value) > -1}
                />
                <ListItemText primary={value} />
              </MenuItem>
            );
          })}
        </Select>
        {icon && (
          <FontAwesomeIcon
            color="#757575"
            size={"1x"}
            style={{ marginLeft: 8, bottom: 13 }}
            icon={regular("chevron-down")}
          />
        )}
      </FormControl>
    </div>
  );
}
