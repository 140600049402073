import { Button, Grid, Paper, TextField, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const EmptyStateContainer = styled(`div`)(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "10px",
  padding: 24,
  marginTop: "calc(100vh / 2 - 250px)",
}));

export const FormResetButton = styled(Button)(({ theme }) => ({
  width: "45%",
  alignSelf: "front",
  backgroundColor: "white",
  color: theme.palette.text.primary,
  marginTop: 8,
  marginLeft: 10,
  border: `2px solid ${theme.palette.text.primary}`,
}));

export const FieldWrapper = styled(`div`)(({ theme }) => ({
  display: "flex",
  jsutifyContent: "space-between",
  gap: "16px",
  marginTop: 8,
}));

export const LastSavedWrapper = styled(`div`)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "16px",
  margin: 0,
}));

export const FlexBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "16px",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    gap: "5px",
  },
}));
export const AdminAccessAutoComplete = styled(TextField)(({ theme }) => ({
  minWidth: 400,
  [theme.breakpoints.down("md")]: {
    minWidth: "100%",
  },
}));

export const FormButton = styled(Button)(({ theme }) => ({
  width: "50%",
  alignSelf: "end",
  backgroundColor: theme.palette.text.primary,
  marginTop: 8,
}));

export const DetailCard = styled(Paper)(({ theme }) => ({
  padding: 16,
  [theme.breakpoints.up("sm")]: {
    minHeight: 262,
    margin: 16,
  },
}));

export const SearchBox = styled(TextField)(({ theme }) => ({
  color: "#B1BAC7",
  maxWidth: 300,
  [theme.breakpoints.down("sm")]: {
    maxWidth: "100%",
  },
}));

export const ClientGrid = styled(Grid)(({ theme }) => ({
  borderLeft: "1px solid #DDDDDD",
  paddingLeft: 80,
  [theme.breakpoints.down("sm")]: {
    borderLeft: "none",
    paddingLeft: 0,
    paddingTop: 30,
    borderTop: "1px solid #DDDDDD",
  },
}));
export const GridBorder = styled(Grid)(({ theme }) => ({
  borderRight: "1px solid #00373E",
  [theme.breakpoints.down("sm")]: {
    borderRight: "none",
    borderBottom: "1px solid #00373E",
  },
}));

export const PaperCard = styled(Paper)(({ theme }) => ({
  padding: 16,
  marginTop: 8,
  [theme.breakpoints.up("sm")]: {
    minHeight: 106,
    marginTop: 16,
    marginBottom: 8,
    alignItems: "center",
    display: "flex",
  },
}));

export const QuoteGridContainer = styled(Grid)(({ theme }) => ({
  alignItems: "self-start",
  textAlign: "center",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    textAlign: "start",
  },
}));

export const AddressGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    marginBottom: 8,
  },
}));

export const FormContainer = styled(`div`)({
  overflowY: "auto",
  display: "flex",
  flexDirection: "column",
  height: `calc(100vh - 120px)`,
  paddingRight: 8,
  "&::-webkit-scrollbar": {
    width: 4,
  },
  "&::-webkit-scrollbar-track": {
    border: "1px solid #E6E6E6",
    borderRadius: 10,
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "rgb(224 224 224)",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#eaeaea",
    borderRadius: 10,
  },
});
