import { createSelector } from "@reduxjs/toolkit";
import { refeshTokenHelper } from "../../lib";
import { AppState } from "../types";

// //TODO: adjust for project
// const ROLES = {
//   SUPER_ADMIN: "super_admin",
//   ADMIN: "admin",
// };

const _state = (state: AppState) => state.auth;
const _userId = createSelector(_state, (state) => state.userId ?? 0);
const _userName = createSelector(_state, (state) => state.userName ?? "");
// const _roles = (state: AppState) => state.auth.roles ?? []
const _userInfo = (state: AppState) =>
  state.auth.user || {
    loanTrackerId: undefined,
    isAdmin: false,
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    companyName: "",
    isLender: "",
    isLenderPaid: "",
    isPremiumLender: "",
    isRateAdmin: "",
    isTrial: "",
    subscription_expiry: "",
  };

export const authSelectors = {
  isLoggedIn: (state: AppState) => {
    return !!state.auth.token && refeshTokenHelper.isAuthenticated;
  },

  /** Selector used for routing */
  // route: createSelector(_isLoggedIn, _roles, (isLoggedIn, roles) => ({
  //   isAdmin: roles.includes(ROLES.SUPER_ADMIN),
  //   isLoggedIn,
  //   roles,
  // })),
  /** Selector used for getting the entire auth state. */
  state: createSelector(_userId, _userName, (userId, userName) => ({
    userId,
    userName,
  })),
  activeConciergeOriginatorId(state: AppState) {
    return state.auth.activeConciergeOriginatorId ?? "";
  },
  userId: _userId,
  userName: _userName,
  userInfo: _userInfo,
};

export const AuthSelectors = authSelectors;
