import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button, Grid, Link, Typography } from "@mui/material";
import { ContactInfoModal } from "../modals/ContactInfoModal";
import {
  NumberFormatDisplay,
  PercentFormatDisplay,
} from "../inputs/Formatters";
import { dealSelector, lenderActions, uiActions } from "../../state";
import { AddressGrid, ClientGrid, DetailCard } from "./styles";
import { hotjar } from "react-hotjar";

export default function DealsDetailsCard() {
  const dispatch = useDispatch();
  const activeDeal = useSelector(dealSelector.activeDeal);
  const {
    id,
    address,
    property_type,
    loan_amount,
    ltv,
    term,
    term_months,
    amortization,
    account_rep,
    transaction_type,
    projected_value,
    prepayment_penalty,
    is_portfolio,
    portfolio_name,
    is_quoted,
    loan_type,
    is_rewarded,
    client,
  } = activeDeal;

  const handleContactClick = (data) => {
    dispatch(lenderActions.getContactInfo(id, data.id));
  };

  const handleDrawerOpen = () => {
    hotjar.event("Quote Deal Button Clicked");
    dispatch(uiActions.setQuotesOpen(true));
  };

  return (
    <>
      <DetailCard>
        <Grid container justifyContent="space-between" sx={{ p: 2 }}>
          <AddressGrid container item xs={12} md={8} spacing={4}>
            {/* <Grid item>
              <iframe
                title="google-map-location"
                src={`https://maps.google.com/maps?width=100%25&height=600&hl=en&q=${address?.street_number}${address?.street_name}+${address?.city}+${address?.state}+${address?.zip}&t=&z=14&ie=UTF8&iwloc=B&output=embed`}
                style={{
                  border: 0,
                }}
                allowFullScreen
              ></iframe>
            </Grid> */}
            <Grid item>
              {!!is_portfolio ? (
                <Typography gutterBottom variant="h5" component="div">
                  {portfolio_name}{" "}
                  <FontAwesomeIcon
                    color="#6FC0D5"
                    size={"1x"}
                    style={{ marginLeft: 8 }}
                    icon={regular("suitcase")}
                  />
                </Typography>
              ) : (
                <>
                  <Typography gutterBottom variant="h5" component="div">
                    {address?.street_number} {address?.street_name}
                  </Typography>
                  <Typography variant="h5" gutterBottom>
                    {address?.city} {address?.state} {address?.zip}
                  </Typography>
                </>
              )}

              <Link
                color={"secondary.main"}
                sx={{ textDecoration: "none", fontWeight: 500 }}
                target="_blank"
                href={`https://maps.google.com?q=${address?.street_number}+${address?.street_name}+${address?.city}+${address?.state}+${address?.zip}&output=classic`}
              >
                View on google maps
              </Link>
            </Grid>
          </AddressGrid>
          <Grid>
            {!!is_quoted ? (
              <>
                {!!is_rewarded ? (
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    <FontAwesomeIcon
                      size={"1x"}
                      style={{ marginRight: 10 }}
                      icon={solid("trophy-alt")}
                    />{" "}
                    You won this deal
                  </Typography>
                ) : (
                  <Typography variant="body1">
                    <FontAwesomeIcon
                      size={"1x"}
                      style={{ marginRight: 10 }}
                      icon={solid("check")}
                    />{" "}
                    You already quoted this deal
                  </Typography>
                )}
              </>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                sx={{ height: 40, width: 180 }}
                onClick={handleDrawerOpen}
              >
                Quote deal
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ p: 2 }}>
          <Grid container item xs={12} md={8}>
            <Grid
              container
              spacing={4}
              justifyContent="space-between"
              sx={{ mb: 1, pb: 1 }}
            >
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2"> Property Type</Typography>
                <Typography variant="body1">
                  {property_type || "---"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2">Loan Type</Typography>
                <Typography variant="body1">{loan_type || "---"}</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2"> Projected value</Typography>
                <Typography variant="body1">
                  <NumberFormatDisplay value={projected_value || "---"} />
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2"> Loan amount</Typography>
                <Typography variant="body1">
                  <NumberFormatDisplay value={loan_amount || "---"} />
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2"> LTV</Typography>
                <Typography variant="body1">
                  {ltv ? <PercentFormatDisplay value={ltv} /> : "---"}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4} justifyContent="space-between">
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2"> Transaction Type</Typography>
                <Typography variant="body1">
                  {transaction_type || "---"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2"> Term</Typography>
                <Typography variant="body1">
                  {term || term_months || "---"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2"> Amortization</Typography>
                <Typography variant="body1">{amortization || "N/A"}</Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant="subtitle2">Prepay penalty</Typography>
                <Typography variant="body1">
                  {prepayment_penalty || "---"}
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}></Grid>
            </Grid>
          </Grid>
          <ClientGrid container item xs={12} md={4}>
            {/* <Grid
              container
              xs={12}
              md={12}
              item
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{ borderBottom: "1px solid #DDDDDD", mb: 1, pb: 1 }}
            >
              <Grid item xs={6} md={6}>
                <Typography variant="subtitle2"> Client</Typography>
                <Typography variant="body1">
                  {client?.first_name + " " + client?.last_name}
                </Typography>
              </Grid>
              <Button onClick={(e) => handleContactClick(client)}>
                Contact info
              </Button>
            </Grid> */}
            {transaction_type === "GPARENCY" && (
              <>
                <Grid
                  container
                  xs={12}
                  md={12}
                  item
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ borderBottom: "1px solid #DDDDDD", mb: 1, pb: 1 }}
                >
                  <Grid item xs={6} md={6}>
                    <Typography variant="subtitle2"> Borrower</Typography>
                    <Typography variant="body1">
                      {client?.first_name + " " + client?.last_name}
                    </Typography>
                  </Grid>
                  <Button
                    sx={{ color: "#22AA94" }}
                    onClick={(e) => handleContactClick(client)}
                  >
                    Contact info
                  </Button>
                </Grid>
              </>
            )}
            <Grid
              container
              item
              xs={12}
              md={12}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6} md={6}>
                <Typography variant="subtitle2">
                  GPARENCY Account Rep
                </Typography>
                <Typography variant="body1">
                  {account_rep?.first_name + " " + account_rep?.last_name}
                </Typography>
              </Grid>
              <Button
                sx={{ color: "#22AA94" }}
                onClick={(e) => handleContactClick(account_rep)}
              >
                Contact info
              </Button>
            </Grid>
          </ClientGrid>
        </Grid>
      </DetailCard>

      <ContactInfoModal />
    </>
  );
}
