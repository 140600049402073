import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";

export const PageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const ButtonTitle = styled(Button)(({ theme }) => ({
  marginLeft: 16,
  [theme.breakpoints.down("sm")]: {
    marginBottom: 16,
  },
}));

export const ContentBox = styled(Box, {
  shouldForwardProp: (prop) => {
    return prop !== "flyoutOpen";
  },
})<{ flyoutOpen?: boolean }>(({ theme, flyoutOpen }) => ({
  width: "100%",
  maxWidth: !flyoutOpen ? "100%" : "calc(100vw - 550px)",
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
  },
}));
export const PageTitle = styled(Typography)(({ theme }) => ({
  marginRight: 32,
  height: 29,
}));

export const SideBox = styled(Box)(({ theme }) => ({
  marginTop: -40,
  marginRight: -24,
  marginBottom: -24,
  [theme.breakpoints.down("md")]: {
    margin: 0,
  },
}));

export const TableColumnHeader = styled(Box)(({ theme }) => ({
  backgroundColor: "transparent",
  transition: "0.1s ease-in",
  svg: {
    position: "relative",
    zIndex: 1,
    opacity: 0,
    marginLeft: 4,
    transition: "0.1s ease-in",
    padding: 6,
    color: "#496E73",
    cursor: "pointer",
    top: "5.5px",
    fontSize: 10,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  "&:before": {
    content: '""',
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "transparent",
    transition: "0.1s ease-in",
  },
  "&:hover": {
    svg: {
      opacity: "1 !important",
    },
    "div.column-header-cta.MuiBox-root": {
      pointerEvents: "all",
      opacity: "1",
    },
    "&:before": {
      backgroundColor: "#e0e0e0",
    },
  },
  "div.column-header-cta.MuiBox-root": {
    position: "absolute",
    top: "0",
    right: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "81px ",
    backgroundColor: "#CFCFCF",
    color: "#757575",
    fontSize: "14px",
    fontWeight: 500,
    opacity: "0",
    cursor: "pointer !important",
    transition: "all .3s ease",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#22AA94",
      svg: {
        color: "#fff !important",
      },
    },
  },
}));

export const TableColumnHeaderText = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  fontWeight: 500,
  fontSize: 12,
  letterSpacing: "0.4px",
  position: "relative",
  zIndex: 1,
  pointerEvents: "none",
  color: "rgba(3, 54, 61, 0.721758)",
}));

export const TableRowFirstColumn = styled(Box)(({ theme }) => ({
  "&:hover": {
    svg: {
      opacity: "1 !important",
    },
  },
}));

export const TableCellCTA = styled(Box)(({ theme }) => ({
  display: "inline-flex",
  alignItems: "center",
  fontWeight: 500,
  fontSize: 12,
  letterSpacing: "0.4px",
  position: "absolute",
  right: "0",
  height: "100%",
  zIndex: 1,
  color: "#757575",
  backgroundColor: "#CFCFCF",
  padding: "18px 20px",
  cursor: "pointer",
  transition: "0.2s ease",
  opacity: "0",
  pointerEvents: "none",
  svg: {
    pointerEvents: "none",
    margin: "0 0 3px 6px !important",
  },
  "&:hover": {
    backgroundColor: "#22AA94",
    color: "#fff",
    svg: {
      color: "#fff",
    },
  },
}));
