import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
} from "@mui/material";
import UserAvatar from "./UserAvatar";
import ProfileMenuOptions from "./ProfileMenuOptions";
import { useAuth0 } from "@auth0/auth0-react";

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isAuthenticated, user } = useAuth0();

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback((hjEvent?: boolean) => {
    setAnchorEl(null);
  }, []);

  return isAuthenticated ? (
    <>
      <ListItemButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ pl: 0, pr: 0 }}
      >
        <ListItemIcon sx={{ padding: "0px 21px" }}>
          <UserAvatar />
        </ListItemIcon>

        <ListItemText
          sx={{
            fontSize: 14,
            display: "block",
            "& .MuiListItemText-primary": {
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            },
          }}
          primary={`${user?.given_name} ${user?.family_name}`}
        />
        <ListItemIcon sx={{ padding: "0px 16px", minWidth: 10 }}>
          <FontAwesomeIcon size={"1x"} icon={regular("angle-up")} />
        </ListItemIcon>
      </ListItemButton>
      <Menu
        id="admin-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={() => handleClose(true)}
      >
        <ProfileMenuOptions handleClose={handleClose} />
      </Menu>
    </>
  ) : null;
};

export default ProfileMenu;
