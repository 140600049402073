import { Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { lendingProfileSelector, uiActions, uiSelectors } from "../../state";
import { LendingProfileForm } from "./LendingProfileForm";
import { EditBtn, SideDrawerPaper } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { hotjar } from "react-hotjar";
import { DeleteCellDataPopup } from "../defaultProfiles/DeleteCellDataPopup";

export const LendingProfileSideLayout = () => {
  const dispatch = useDispatch();
  const activeLP = useSelector(lendingProfileSelector.activeLendingProfile);
  const activeDefaultLP = useSelector(lendingProfileSelector.activeDefaultLP);
  const addTab = useSelector(uiSelectors.addTab);
  const editTab = useSelector(uiSelectors.editTab);
  const bulkEdit = useSelector(uiSelectors.bulkEdit);
  const deleteCellOpen = useSelector(uiSelectors.deleteCellOpen);

  return (
    <SideDrawerPaper
      sx={{
        border: "1px solid #E0E0E0",
        top: "-1px",
        ".MuiPaper-root-MuiCard-root": {
          borderRadius: 0,
        },

        "& .delete-cell-data": {
          zIndex: 10,
        },
      }}
      className="lending-profile"
    >
      {deleteCellOpen && (
        <DeleteCellDataPopup
          loanType={activeLP.loan_type_value}
          lenderProgram={activeLP.lender_program_value}
          deleteCellOpen={deleteCellOpen}
        />
      )}
      <Button
        color="inherit"
        onClick={() => {
          hotjar.event(
            `Lending Profile Cell Edit Form Close + ${
              activeLP.lender_program_value
            } + ${activeLP.loan_type_value} - ${new Date(Date.now())
              .toUTCString()
              .replace(",", "")}`,
          );
          // Close flyout and annul all states
          dispatch(uiActions.setFlyoutOpen(false));
          dispatch(uiActions.setAddTab(false));
          dispatch(uiActions.setEditTab(false));
          dispatch(uiActions.setBulkEditColumn(null));
          dispatch(uiActions.setBulkEditRow(null));
          dispatch(uiActions.setBulkEdit(false));
          dispatch(uiActions.setBulkEditAll(null));
          hotjar.event(`Button Close Lending Profile Flyout`);
        }}
        style={{
          padding: 10,
          cursor: "pointer",
          position: "absolute",
          right: 10,
          top: 10,
        }}
      >
        <FontAwesomeIcon
          size={"lg"}
          icon={regular("times")}
          style={{
            pointerEvents: "none",
            color: "#757575",
            transition: "0.1s ease-in",
            marginLeft: 0,
            width: 15,
            height: 24,
          }}
        />
      </Button>

      <Typography variant="h3" sx={{ pl: 2, pb: 2 }}>
        {addTab
          ? "Add a New Profile"
          : editTab
          ? "Edit tab"
          : bulkEdit
          ? "Bulk Edit"
          : ""}
      </Typography>
      <Typography variant="h5">EDITING</Typography>
      <Typography variant="h3">{activeLP.loan_type_value}</Typography>
      <Typography
        sx={{
          position: "relative",
          marginBottom: "28px",
          "&::before": {
            content: "''",
            position: "absolute",
            display: "block",
            height: "1px",
            width: "calc(100% + 40px)",
            backgroundColor: "#E0E0E0",
            left: "-20px",
            bottom: "-10px",
          },
        }}
        variant="h4"
      >
        {activeLP.lender_program_value}
      </Typography>
      <EditBtn
        sx={{
          fontSize: "12px",
          color: "#22AA94",
          position: "absolute",
          top: 77,
          right: 28,
          padding: "5px 8px",
          lineHeight: "23px",
          "&.disabled": {
            pointerEvents: "none",
            color: "#A2ACBA",
            svg: {
              color: "#A2ACBA",
            },
          },
        }}
        className={`${
          activeDefaultLP.form_profile_data?.default_lending_id
            ? ""
            : "disabled"
        }`}
        onClick={() => dispatch(uiActions.setDeleteCellOpen(true))}
      >
        Delete{" "}
        <FontAwesomeIcon
          size={"1x"}
          icon={regular("trash-can")}
          style={{ width: 14, height: 16, marginLeft: 8, zIndex: 1 }}
        />
      </EditBtn>
      <LendingProfileForm />
    </SideDrawerPaper>
  );
};
