import React from "react";
import { Grid } from "@mui/material";
import { ContentBox, PageBox, PageTitle } from "./styles";
import DefaultOriginatorEditForm from "../../components/defaultProfiles/DefaultOriginatorEditForm";

export const EditDefaultOriginatorsPage = () => {
  return (
    <PageBox>
      <ContentBox>
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          sx={{ pr: 2 }}
          alignItems="center"
        >
          <Grid
            item
            container
            lg={6}
            md={10}
            xs={12}
            alignItems="center"
            direction="row"
          >
            <PageTitle
              sx={{
                paddingLeft: "26px",
                marginBottom: "27px",
                fontFamily: "Gilroy",
                fontSize: "28px",
                fontWeight: 700,
              }}
              variant="h2"
            >
              Edit Default Originators
            </PageTitle>
          </Grid>
        </Grid>
        <DefaultOriginatorEditForm />
      </ContentBox>
    </PageBox>
  );
};
