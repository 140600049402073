import React from "react";
import NumberFormat from "react-number-format";

export function NumberFormatDisplay({ value, scale = 2 }) {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      decimalScale={scale}
      prefix={"$"}
      thousandSeparator={","}
    />
  );
}

export function PercentFormatDisplay({ value, scale = 2 }) {
  return (
    <NumberFormat
      value={value}
      displayType="text"
      decimalScale={scale}
      suffix={"%"}
    />
  );
}

export function DateFormatDisplay({ value }) {
  return <>{new Date(value).toLocaleDateString()}</>;
}
