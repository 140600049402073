import React, { useMemo } from "react";
import { useMemoUserUnsecuredJwtCTA } from "../../hooks/CTA/useMemoUserUnsecuredJwtCTA";
import { useSelector } from "react-redux";
import { authSelectors } from "../../state";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import { Navigation } from "../../lib";
import AuthPages from "../auth";

export function JoinPage() {
  const userFromState = useSelector(authSelectors.userInfo);
  const keysToExclude = useMemo(
    () => [
      "isAdmin",
      "gpPaid",
      "isLender",
      "companyId",
      "companyName",
      "lenderPaid",
    ],
    [],
  );
  const [CTA] = useMemoUserUnsecuredJwtCTA(
    userFromState,
    keysToExclude,
    "LENDER_PORTAL_DISCOVER_OTHER_OPPORTUNITIES",
  );
  const { user } = useAuth0();
  const given_name = user?.given_name ?? "";
  const family_name = user?.family_name ?? "";

  return (
    <div className="paper" style={{ textAlign: "center" }}>
      <h2>Want to see Deal Flow and Qualified Quoting Opportunities?</h2>
      <h4>
        If you`re not registered as a lender, or you`re having trouble accessing
        the Lender Portal,
        <br /> please fill out the form below and we`ll get you access!
      </h4>
      {/* <Button
        variant="contained"
        color="secondary"
        onClick={() => Navigation.go(AuthPages.marketplace.path)}
        sx={{ maxWidth: 440, marginTop: 0, marginLeft: 2 }}
      >
        Looking for our Marketplace &reg;, Click here!
      </Button> */}
      <div
        className="cta-iframe-container"
        style={{
          width: "590px",
          margin: "auto",
          height: "630px",
          textAlign: "center",
        }}
      >
        <iframe
          src={`${CTA.url}/?token=${CTA.token}&utm_source=lender.gparency.com&type=join&where-from=LENDER_PORTAL_DISCOVER_OTHER_OPPORTUNITIES&given_name=${given_name}&family_name=${family_name}`}
          title={"Interested In Becoming A Member?"}
          width="590"
          height="630"
          frameBorder="0"
          style={{
            overflow: "hidden",
            width: "100%",
          }}
        />
      </div>
    </div>
  );
}
