import React from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import { Typography, FormControl, InputLabel } from "@mui/material";
import { FormPercentInput } from "../../inputs/FormPercentInput";
import { FieldWrapper } from "../styles";

export const OriginationFeesNotesComponent = () => {
  return (
    <>
      <FieldWrapper>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "28px",
          }}
        >
          Origination Fees
        </Typography>
      </FieldWrapper>
      <FieldWrapper>
        <FormPercentInput label="Origination Fee" fieldName="bank_fee" />
        <FormPercentInput label="Exit Fee" fieldName="bank_fee_out" />
      </FieldWrapper>
      {/* {isSecondPeriod ? (
        <FieldWrapper>
          <FormPercentInput
            widthHalf={true}
            label={`Extension Fee`}
            fieldName="extension_fee"
          />
        </FieldWrapper>
      ) : null} */}
      <FormControl variant="standard">
        <InputLabel shrink>Notes</InputLabel>
        <Field
          component={TextField}
          sx={{ height: "auto" }}
          placeholder="Enter Notes"
          multiline
          rows={3}
          variant="outlined"
          name="new_note"
        />
      </FormControl>
    </>
  );
};
