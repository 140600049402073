import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Grid,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { StyledChip } from "./InputStyles";
import { hotjar } from "react-hotjar";

export default function MultiSelectInput({
  tagOptions,
  fieldName,
  setFieldValue,
  values = [],
  placeholder,
  label,
  isRequired = false,
}) {
  const [value, setValue] = useState(values);

  const onDelete = (id) => () => {
    const _values = value.filter((v: any) => v.id !== id);
    setValue(_values);
    setFieldValue(fieldName, _values);

    const removedName: any = value.filter((v: any) => v.id === id);
    hotjar.event(`Multiselect Property Type - ${removedName[0].value} Removed`);
  };

  const handleChange = (e, newValue) => {
    setFieldValue(fieldName, newValue);
    setValue(newValue);
    hotjar.event(
      `Multiselect Property Type - ${e.target["innerText"]} Clicked`,
    );
  };

  useEffect(() => {
    if (Array.isArray(values)) {
      setValue(values);
    }
  }, [values]);

  return (
    <>
      <FormControl variant="standard" sx={{ mb: 1 }}>
        <InputLabel shrink>
          {label}{" "}
          {isRequired && (
            <Typography
              color={"error"}
              variant={"caption"}
              component={"span"}
              style={{ fontSize: 20 }}
            >
              {" "}
              *
            </Typography>
          )}
        </InputLabel>
        <Autocomplete
          multiple
          id={fieldName}
          value={value}
          onChange={handleChange}
          options={tagOptions}
          getOptionLabel={(option: any) => option?.value}
          renderTags={() => null}
          popupIcon={<KeyboardArrowDownIcon />}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder={placeholder}
            />
          )}
        />
      </FormControl>
      <Grid container>
        {value?.map((v: any) => (
          <StyledChip key={v.id} label={v.value} onDelete={onDelete(v.id)} />
        ))}
      </Grid>
    </>
  );
}
