import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, Button } from "@mui/material";
import { useMobile } from "../../themes";
import companyIcon from "../../assets/img/MoveIconSVG.svg";
import { hotjar } from "react-hotjar";

interface popupRemovingActiveDealModalProps {
  closePopupCallback: Function;
  removeDealCallback: Function;
}

export const PopupRemoveDealFromActive = ({
  closePopupCallback,
  removeDealCallback,
}: popupRemovingActiveDealModalProps) => {
  const isMobile = useMobile();
  return (
    <Box
      sx={{
        zIndex: "999999 !important",
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",

        "& .popup-remove": {
          maxWidth: "548px",
          width: "100%",
          borderRadius: "4px",
          backgroundColor: "#FFFFFF",
          position: "fixed",
          left: "50%",
          transform: "translate(-50%, -50%)",
          top: "50%",
          padding: `${isMobile ? "50px 20px" : "75px 90px"}`,
        },
        "& .MuiSlider-thumb": {
          borderRadius: "1px",
        },

        "& .poopup-remove__title": {
          position: "relative",
          color: "#03363D",
          fontFamily: "ABC Monument Grotesk",
          fontSize: "16px",
          fontWeight: "bold",
          letterSpacing: "0",
          lineHeight: "17px",
          textAlign: "center",
          height: "22px",
          width: "429px",
          left: "calc(50% - 429px/2 - 0.5px)",
          top: "calc(50% - 22px/2 - 131px)",
          marginBottom: `${isMobile ? "25px" : "32px"}`,
        },

        "& .MuiButtonBase-root.popup-remove-close": {
          marginLeft: "auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "absolute",
          right: "24px",
          top: "25px",
          transition: "350ms ease",
          padding: "0",
          minWidth: "auto",
          "& svg": {
            heght: "16px",
          },
          ":hover": {
            backgroundColor: "#fff",
            color: "#1F9F8A !important",
          },
        },

        "& .poopup-remove__icon": {
          display: "flex",
          justifyContent: "center",
          margin: `${isMobile ? "0 auto 30px" : "0 auto 48px"}`,
          height: `${isMobile ? "80px" : "133px"}`,

          "& svg": {
            height: `${isMobile ? "80px" : "133px"}`,
          },
        },
        "& .popup-remove__btns": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        },

        "& .popup-remove__btn-green": {
          height: "42px!important",
          width: "191px!important",
          borderRadius: "4px!important",
          backgroundColor: "#03363D !important",
          color: "#FFFFFF !important",
          fontFamily: "ABC Monument Grotesk",
          fontSize: "14px !important",
          fontWeight: "500",
          letterSpacing: "0",
          lineHeight: "14px",
          textAlign: "center",
          transition: "350ms ease",
          marginBottom: "16px",
          ":hover": {
            backgroundColor: "rgb(77, 134, 149)!important",
          },
        },

        "& .popup-remove__btn-transparent": {
          color: "#1F9F8A !important",
          fontFamily: "ABC Monument Grotesk",
          fontSize: "14px",
          fontWeight: "500",
          letterspacing: "0",
          lineHeight: "14px",
          transition: "350ms ease",
          ":hover": {
            color: "rgb(77, 134, 149)!important",
          },
        },
        "& .popup-remove__overlay": {
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          opacity: "0.09",
          backgroundColor: "#040707",
        },
      }}
    >
      <div className="popup-remove__overlay"></div>
      <div className="popup-remove">
        <Button
          className="popup-remove-close"
          color="inherit"
          onClick={() => {
            closePopupCallback();
            hotjar.event("Remove Deal From Active Quotes Popup Closed");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={regular("times")} />
        </Button>
        <h2 className="poopup-remove__title">
          Remove the selected opportunity from your Active Quotes?
        </h2>
        <div className="poopup-remove__icon">
          <img src={companyIcon} alt="logo" />
        </div>
        <div className="popup-remove__btns">
          <Button
            className="popup-remove__btn-green"
            onClick={() => {
              hotjar.event("Confirm Button Clicked To Remove Active Deal");
              removeDealCallback();
            }}
          >
            Yes, Please Remove
          </Button>
          <Button
            className="popup-remove__btn-transparent"
            onClick={() => {
              hotjar.event("Cancel Button Clicked Not To Remove Active Deal");
              closePopupCallback();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Box>
  );
};
