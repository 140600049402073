import React, { useEffect, useState } from "react";
import { Button, Grid, InputAdornment } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Tooltip } from "@mui/material";
import { format } from "date-fns";
import {
  GridRenderCellParams,
  GridRowId,
  GridRowSpacingParams,
} from "@mui/x-data-grid";
import { ContactInfoModal } from "../modals/ContactInfoModal";
import { dealActions, dealSelector, lenderActions } from "../../state";
import { NumberFormatDisplay } from "../inputs/Formatters";
import { PopupDiscover } from "../../components/modals/PopupDiscover";
import {
  AddressStyle,
  AddressTableCell,
  TableWrapper,
  StyledDataGrid,
} from "./CustomizedTable.styles";
import { hotjar } from "react-hotjar";
import FilterButton from "./FilterButton";
import { SearchBox } from "./styles";
import { matchSorter } from "match-sorter";
import EmptyState from "./EmptyState";

export default function OtherOpportunitiesGrid() {
  const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
  const [moveQuotesPopupActive, setMoveQuotesPopupActive] = useState(false);
  const other_opportunities = useSelector(dealSelector.filteredOtherDealsArray);
  const [otherOpportunitiesRows, setOtherOpportunitiesRows] = useState<
    typeof other_opportunities
  >([]);
  const [showSearchEmptyText, setShowSearchEmptyText] = useState("");

  const dispatch = useDispatch();

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: 15,
    };
  }, []);

  const handleContactClick = (data) => {
    dispatch(lenderActions.getContactInfo(data.id, data.client.id));
  };

  const handleMoveQuotesButton = () => {
    // TODO
    const selectedRows = other_opportunities.filter((row) =>
      selectedRowIds.includes(row.id),
    );

    setSelectedRowIds([]);
    setMoveQuotesPopupActive(false);

    for (const row of selectedRows) {
      dispatch(dealActions.moveToActiveQuotes(row.id));
    }
  };

  useEffect(() => {
    setOtherOpportunitiesRows(other_opportunities);
  }, [other_opportunities]);

  const handleSearch = (e) => {
    const _otherOpportunitiesRows = matchSorter(
      other_opportunities,
      e.target.value,
      {
        keys: [
          "portfolio_name",
          "address.city",
          "address.state",
          "address.zip",
          "client.first_name",
          "client.last_name",
          "property_type",
          "loan_amount",
          "loan_type",
          "deal_type",
          "term",
          "amortization",
          "transaction_type",
          "rate_type",
          { threshold: matchSorter.rankings.CONTAINS, key: "activity_date" },
        ],
      },
    );
    setOtherOpportunitiesRows(_otherOpportunitiesRows);
    if (_otherOpportunitiesRows.length <= 0) {
      setShowSearchEmptyText(e.target.value);
    } else {
      setShowSearchEmptyText("");
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <SearchBox
          placeholder="Search deals"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon color="#B1BAC7" icon={regular("search")} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />

        <FilterButton />
      </Grid>
      <TableWrapper style={{ flexGrow: 1 }}>
        {showSearchEmptyText ? (
          <EmptyState title={`No results found for "${showSearchEmptyText}"`} />
        ) : (
          <StyledDataGrid
            autoHeight={true}
            disableColumnMenu={true}
            sx={{
              "& .MuiDataGrid-row": {
                "&:hover": {
                  backgroundColor: "white",
                },
              },
              "& .MuiCheckbox-root.Mui-checked": {
                color: "#03363D !important",
              },
              "& .MuiDataGrid-cellCheckbox": {
                outline: "none",
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none !important",
                outlineWidth: "0 !important",
                outlineOffset: "0 !important",
              },
              "&.MuiDataGrid-root .MuiDataGrid-row.Mui-selected": {
                backgroundColor: "#fff !important",
              },
            }}
            disableSelectionOnClick={true}
            rowsPerPageOptions={[5, 10, 20]}
            disableColumnSelector={true}
            initialState={{
              pagination: {
                pageSize: 10,
              },
            }}
            getRowSpacing={getRowSpacing}
            rowHeight={90}
            pagination={true}
            checkboxSelectionVisibleOnly={true}
            rows={otherOpportunitiesRows}
            checkboxSelection
            hideFooterSelectedRowCount
            onSelectionModelChange={(ids) => {
              setSelectedRowIds(ids);
              hotjar.event("Checkbox Clicked Other Opportunities Table");
            }}
            selectionModel={selectedRowIds}
            columns={[
              {
                field: "address",
                headerName: "Property/portfolio location",
                headerClassName: "header",
                minWidth: 200,

                flex: 2,
                renderCell: (params: GridRenderCellParams<any>) => (
                  <AddressTableCell>
                    <AddressStyle>
                      {params.row.is_portfolio ? (
                        <Tooltip title="Portfolio">
                          <span>
                            {params.row.portfolio_name}{" "}
                            <FontAwesomeIcon
                              color="#6FC0D5"
                              size={"1x"}
                              style={{ marginLeft: 8 }}
                              icon={regular("suitcase")}
                            />
                          </span>
                        </Tooltip>
                      ) : (
                        params.row.address?.city +
                        " " +
                        params.row.address?.state +
                        ", " +
                        params.row.address?.zip
                      )}
                    </AddressStyle>
                  </AddressTableCell>
                ),
              },
              {
                field: "property_type",
                headerName: "Property type",
                headerClassName: "header",
                flex: 1,
              },
              {
                field: "loan_amount",
                headerName: "Loan Amount",
                headerClassName: "header",
                flex: 1,
                renderCell: (params: GridRenderCellParams<any>) => (
                  <NumberFormatDisplay value={params.row.loan_amount} />
                ),
              },
              {
                field: "loan_type",
                headerName: "Loan Type",
                headerClassName: "header",
                flex: 1,
              },
              {
                field: "deal_type",
                headerName: "Deal Type",
                headerClassName: "header",
                flex: 1,
              },
              {
                field: "term",
                headerName: "Term",
                headerClassName: "header",
                renderCell: (params: GridRenderCellParams<any>) => (
                  <span>{params.row.term || params.row.term_months}</span>
                ),
                flex: 1,
              },
              {
                field: "amortization",
                headerName: "Amortization",
                headerClassName: "header",
                flex: 1,
              },

              {
                field: "transaction_type",
                headerName: "Transaction type",
                headerClassName: "header",
                flex: 1,
              },

              {
                field: "activity_date",
                headerName: "Request Date",
                headerClassName: "header",
                flex: 1,
                renderCell: (params: GridRenderCellParams<any>) => (
                  <>
                    {params.row.activity_date !== "0000-00-00 00:00:00" &&
                    params.row.activity_date
                      ? format(new Date(params.row.activity_date), "MM/dd/yyyy")
                      : ""}
                  </>
                ),
              },

              {
                field: "rate_type",
                headerName: "Rate type",
                headerClassName: "header",
                flex: 1,
              },
              {
                field: "client",
                headerName: "Contact info",
                headerClassName: "header",
                flex: 1,
                renderCell: (params: GridRenderCellParams<any>) => (
                  <Button
                    sx={{
                      color: "primary.main",
                    }}
                    onClick={(e) => handleContactClick(params.row)}
                  >
                    {params.row.client?.first_name}{" "}
                    {params.row.client?.last_name}
                  </Button>
                ),
              },
            ]}
          />
        )}
      </TableWrapper>
      <Button
        className={`move-to-active-quotes-btn ${
          selectedRowIds.length > 0 && "active"
        }`}
        onClick={() => {
          hotjar.event("Button Move to Active Quotes Clicked");
          setMoveQuotesPopupActive(true);
        }}
      >
        Move to Active Quotes
        <span>{selectedRowIds.length > 0 && `(${selectedRowIds.length})`}</span>
      </Button>
      <ContactInfoModal />

      {/* Confirm popup Lender's Intent To Move */}
      {moveQuotesPopupActive && (
        <PopupDiscover
          closePopupCallback={setMoveQuotesPopupActive}
          moveQuotesCallback={handleMoveQuotesButton}
        />
      )}
    </>
  );
}
