import React from "react";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDispatch } from "react-redux";
import { uiActions } from "../../state";
import { EmptyStateContainer } from "./styles";
import { hotjar } from "react-hotjar";

export default function QuotesEmptyState() {
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    hotjar.event("Quote Deal Button Clicked");
    dispatch(uiActions.setQuotesOpen(true));
  };

  return (
    <EmptyStateContainer>
      <FontAwesomeIcon
        style={{ padding: 20 }}
        color="#6FC0D5"
        size={"3x"}
        icon={solid("empty-set")}
      />
      <Typography variant="h4">No quotes were submitted yet</Typography>
      <Typography variant="body1" sx={{ opacity: 1 }}>
        Get that first impression advantage, be the first to quote
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleDrawerOpen}
        sx={{ width: 140, height: 40, m: 3 }}
      >
        Quote deal
      </Button>
    </EmptyStateContainer>
  );
}
