import { AppThunk } from "..";
import { apis } from "../../lib/gateways";
import { authClient } from "../../lib/api";
import { uiActions } from "../states";
import { AppState } from "../types";
import { lendingProfile } from "./state";

const { actions } = lendingProfile;

export const lendingProfileActions = {
  ...actions,
  getLendingProfileData(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { lending_profiles } = getState().lendingProfile;
      !lending_profiles?.length && dispatch(uiActions.setLoading(true));
      const { data } = await authClient.get(apis.GetLendingProfiles());
      if (data.isResponse && data?.data) {
        const { profiles = [], tabs = [] } = data.data;
        if (Array.isArray(profiles) && Array.isArray(tabs)) {
          dispatch(lendingProfileActions.loadLendingProfiles(profiles));
          dispatch(lendingProfileActions.loadPropertyTypeTabs(tabs));
        }
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(uiActions.showError("failed to get lending profiles"));
        dispatch(uiActions.setLoading(false));
      }
    };
  },
  updateLendingProfile(values, id): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.post(apis.SaveLendingProfile, values);
      dispatch(uiActions.setLoading(false));
      if (data.isResponse) {
        await dispatch(lendingProfileActions.updatedProfileSuccess());
      } else {
        dispatch(
          uiActions.showError(
            `${
              data?.message ? data.message : "Failed to update lending profile"
            }`,
          ),
        );
      }
    };
  },

  verifyProfile(defaultLendingId, executionTypeId, loanTypeId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const last_verified = new Date()
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");
      const values = { last_verified };
      const { data } = await authClient.put(
        apis.UpdateSingleCell(defaultLendingId, executionTypeId, loanTypeId),
        values,
      );
      if (data.isResponse) {
        await dispatch(lendingProfileActions.verifyProfileSuccess());
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(
          uiActions.showError(
            `${
              data?.message ? data.message : "Failed to verify lending profile"
            }`,
          ),
        );
        dispatch(uiActions.setLoading(false));
      }
    };
  },

  bulkEditUpdate(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.put(
        apis.UpdateBulkEditProfile(),
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (data.isResponse) {
        await dispatch(lendingProfileActions.updatedBulkEditProfileSuccess());
      } else {
        dispatch(
          uiActions.showError(
            `${
              data?.message ? data.message : "Failed to bulk update the profile"
            }`,
          ),
        );
      }
    };
  },

  saveNewLendingProfile(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.post(apis.AddNewLendingProfile, values);
      dispatch(uiActions.setLoading(false));
      if (data.isResponse) {
        await dispatch(lendingProfileActions.updatedProfileSuccess());
      } else {
        dispatch(
          uiActions.showError(
            `${
              data?.message ? data.message : "Failed to save lending profile"
            }`,
          ),
        );
      }
    };
  },

  createDefaultLendingProfile(values): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.post(
        apis.CreateLendingDefaultProfile,
        values,
      );
      dispatch(uiActions.setLoading(false));
      if (data.isResponse) {
        await dispatch(lendingProfileActions.updatedProfileSuccess());
      } else {
        dispatch(
          uiActions.showError(
            `${
              data?.message ? data.message : "Failed to save lending profile"
            }`,
          ),
        );
      }
    };
  },

  updateLendingDefaultProfile(values, defaultLendingId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.put(
        apis.UpdateLendingDefaultProfile(defaultLendingId),
        values,
      );
      if (data.isResponse) {
        await dispatch(
          lendingProfileActions.updatedProfileSuccess(
            "Profile updated successfully",
          ),
        );
      } else {
        dispatch(
          uiActions.showError(
            `${
              data?.message ? data.message : "Failed to update lending profile"
            }`,
          ),
        );
        dispatch(uiActions.setLoading(false));
      }
    };
  },

  updateSingleCell(
    values,
    defaultLendingId,
    executionTypeId,
    loanTypeId,
  ): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.put(
        apis.UpdateSingleCell(defaultLendingId, executionTypeId, loanTypeId),
        values,
      );
      if (data.isResponse) {
        await dispatch(lendingProfileActions.updatedProfileSuccess("", true));
        dispatch(uiActions.setLoading(false));
      } else {
        dispatch(
          uiActions.showError(
            `${data?.message ? data.message : "Failed to update single cell"}`,
          ),
        );
      }
    };
  },

  deleteSingleCell(defaultLendingId, executionTypeId, loanTypeId): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.delete(
        apis.DeleteSingleCell(defaultLendingId, executionTypeId, loanTypeId),
      );
      dispatch(uiActions.setLoading(false));
      if (data.isResponse) {
        await dispatch(lendingProfileActions.updatedProfileSuccess("", true));
        await dispatch(uiActions.setDeleteCellOpen(false));
        await dispatch(uiActions.setFlyoutOpen(false));
      } else {
        dispatch(
          uiActions.showError(
            `${data?.message ? data.message : "Failed to delete single cell"}`,
          ),
        );
      }
    };
  },

  saveNewTab(values, newTabName): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.post(apis.AddNewLendingProfile, values);
      dispatch(uiActions.setLoading(false));
      if (data.isResponse) {
        await dispatch(lendingProfileActions.updatedProfileSuccess());
        dispatch(lendingProfileActions.newTabAdded(newTabName));
        setTimeout(() => {
          dispatch(lendingProfileActions.newTabAdded(undefined));
        }, 200);
        dispatch(uiActions.refreshOpenModalState(false));
      } else {
        dispatch(
          uiActions.showError(
            `${data?.message ? data.message : "Failed to add new tab"}`,
          ),
        );
      }
    };
  },
  EditTab(values, newTabName): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { user } = getState().auth;
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.put(
        apis.EditTab(user?.loanTrackerId),
        values,
      );
      if (data.isResponse) {
        await dispatch(lendingProfileActions.updatedProfileSuccess());
        dispatch(lendingProfileActions.newTabAdded(newTabName));
        setTimeout(() => {
          dispatch(lendingProfileActions.newTabAdded(undefined));
        }, 200);
        dispatch(uiActions.refreshOpenModalState(false));
      } else {
        dispatch(
          uiActions.showError(
            `${data?.message ? data.message : "Failed to edit tab"}`,
          ),
        );
      }
      dispatch(uiActions.setLoading(false));
    };
  },

  updatedProfileSuccess(
    customMessage?: string,
    reloadProfileData?: boolean,
  ): AppThunk {
    return async (dispatch) => {
      reloadProfileData &&
        (await dispatch(lendingProfileActions.getLendingProfileData()));
      dispatch(
        uiActions.showSuccess(
          customMessage ? customMessage : "Profile saved successfully",
        ),
      );
      dispatch(uiActions.setLendingProfileOpen(false));
    };
  },
  verifyProfileSuccess(): AppThunk {
    return async (dispatch) => {
      await dispatch(uiActions.setLendingProfileOpen(false));
      await dispatch(lendingProfileActions.getLendingProfileData());
      dispatch(uiActions.showSuccess("Profile verified successfully"));
    };
  },

  updatedBulkEditProfileSuccess(): AppThunk {
    return async (dispatch) => {
      await dispatch(lendingProfileActions.getLendingProfileData());
      dispatch(uiActions.showSuccess("Bulk data updated successfully"));
      dispatch(uiActions.setLendingProfileOpen(false));
    };
  },
};
