import React from "react";
import { IconButton, Snackbar, Alert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { uiActions, useAction, useSelector } from "../state";
import { RootStateOrAny } from "react-redux";
const Notification = () => {
  const notification = useSelector(
    (state: RootStateOrAny) => state.ui.notification,
  );
  const hideNotification = useAction(uiActions.hideNotification);

  return (
    <Snackbar
      open={!!notification}
      onClose={hideNotification}
      autoHideDuration={notification?.duration || 5000}
      message={notification?.message}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      {notification?.variant && (
        <Alert
          sx={{ height: 48 }}
          severity={notification.variant}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={hideNotification}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        >
          {notification.message}
        </Alert>
      )}
    </Snackbar>
  );
};
export default Notification;
