import React from "react";
import {
  //
  Button,
  FormControl,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
//Assets
// Local
import { Form, Navigation, useFormik, useLocation, yup } from "../../lib";
import { authActions, useAction } from "../../state";
import AuthPages from ".";
import { AuthBox } from "./Auth.styles";

const validationSchema = yup.object({
  password1: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  password2: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const ConfirmAccountPage = React.memo(
  /**
   *
   */
  function ConfirmAccountPage() {
    const confirmAccount = useAction(authActions.confirmAccount);
    const location = useLocation();

    const frm = useFormik({
      initialValues: {
        password1: "",
        password2: "",
      },
      validationSchema,
      async onSubmit(values) {
        // TODO: Validate passwords. See mailpake/prime client-app ResetPasswordPage.jsx
        await confirmAccount({
          newPassword: values.password1,
          email: location.query.email,
          token: location.query.token,
        });
        Navigation.replace(AuthPages.login);
      },
    });

    return (
      <AuthBox>
        <Typography variant="h5" sx={{ mt: 1, mb: 1 }}>
          Create new password
        </Typography>
        <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>
          Its a good idea to use a strong password that you're not using
          elsewhere
        </Typography>
        <Form form={frm} sx={{ mt: 10, mb: 5 }}>
          <FormControl variant="standard">
            <InputLabel shrink>New password</InputLabel>
            <TextField
              fullWidth
              id="password1"
              name="password1"
              type="password"
              autoComplete="new-password"
              value={frm.values.password1}
              onChange={frm.handleChange}
              error={frm.touched.password1 && Boolean(frm.errors.password1)}
              helperText={frm.touched.password1 && frm.errors.password1}
            />
          </FormControl>
          <FormControl variant="standard">
            <InputLabel shrink>Re-enter new password</InputLabel>
            <TextField
              fullWidth
              id="password2"
              name="password2"
              type="password"
              autoComplete="reenter-new-password"
              value={frm.values.password2}
              onChange={frm.handleChange}
              error={frm.touched.password2 && Boolean(frm.errors.password2)}
              helperText={frm.touched.password2 && frm.errors.password2}
            />
          </FormControl>

          <Button variant="contained" type="submit" disabled={frm.isSubmitting}>
            Reset password
          </Button>
        </Form>
      </AuthBox>
    );
  },
);
