import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ListsState {
  conciergeBanksOriginators: [];
  lendingPrograms: [];
  termList: [];
  recourseList: [];
  prepayList: [];
  interestOnlyList: [];
  reservesList: [];
  prePaymentPenaltyList: [];
  amortizationList: [];
  states: [];
  propertyTypeList: [];
  executionList: any[];
  dealLoanTypeList: [];
  loanTypeList: any[];
  minDscrList: [];
  executionTypeMapping: {};
  designatedOriginatorList: [];
  indexTypes: any[];
}

const initialState: ListsState = {
  conciergeBanksOriginators: [],
  lendingPrograms: [],
  termList: [],
  recourseList: [],
  prepayList: [],
  interestOnlyList: [],
  reservesList: [],
  prePaymentPenaltyList: [],
  amortizationList: [],
  states: [],
  executionList: [],
  propertyTypeList: [],
  dealLoanTypeList: [],
  minDscrList: [],
  executionTypeMapping: {},
  designatedOriginatorList: [],
  indexTypes: [],
  loanTypeList: [],
};

export const lists = {
  ...createSlice({
    name: "lists",
    initialState,
    reducers: {
      setLendingProgramsList(state, { payload }: PayloadAction<any>) {
        state.lendingPrograms = payload;
      },
      setTermList(state, { payload }: PayloadAction<any>) {
        state.termList = payload;
      },
      setRecourseList(state, { payload }: PayloadAction<any>) {
        state.recourseList = payload;
      },
      setPrepayList(state, { payload }: PayloadAction<any>) {
        state.prepayList = payload;
      },
      setInterestOnlyList(state, { payload }: PayloadAction<any>) {
        state.interestOnlyList = payload;
      },
      setReservesList(state, { payload }: PayloadAction<any>) {
        state.reservesList = payload;
      },
      setPrePaymentPenaltyList(state, { payload }: PayloadAction<any>) {
        state.prePaymentPenaltyList = payload;
      },
      setPropertyTypeList(state, { payload }: PayloadAction<any>) {
        state.propertyTypeList = payload;
      },
      setAmortizationList(state, { payload }: PayloadAction<any>) {
        state.amortizationList = payload;
      },
      setStates(state, { payload }: PayloadAction<any>) {
        state.states = payload;
      },
      setExecutionTypeList(state, { payload }: PayloadAction<any>) {
        state.executionList = payload;
      },
      setDealLoanTypeList(state, { payload }: PayloadAction<any>) {
        state.dealLoanTypeList = payload;
      },
      setLoanTypeList(state, { payload }: PayloadAction<any>) {
        state.loanTypeList = payload;
      },
      setMinDscrList(state, { payload }: PayloadAction<any>) {
        state.minDscrList = payload;
      },
      loadConciergeBanksOriginators(state, { payload }: PayloadAction<any>) {
        state.conciergeBanksOriginators = payload;
      },
      setExecutionTypeMappingList(state, { payload }: PayloadAction<any>) {
        state.executionTypeMapping = payload;
      },
      setDesignatedOriginatorList(state, { payload }: PayloadAction<any>) {
        state.designatedOriginatorList = payload;
      },
      setIndexType(state, { payload }: PayloadAction<any>) {
        state.indexTypes = payload;
      },
    },
  }),
  persist: true,
};
