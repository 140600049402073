import { Paper, Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const StyledProDataGrid = styled(DataGridPro)({
  overflowY: "auto",
  marginTop: 16,
  marginBottom: 32,
  boxSizing: "border-box",
  border: "1px solid #E6E6E6",
  borderRadius: 10,
  backgroundColor: "#FFFFFF",
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 700,
  },
  "& .MuiDataGrid-columnSeparator": { display: "none" },
  "& .MuiDataGrid-columnHeaders": {
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 0.4,
    lineHeight: 13,
    textTransform: "uppercase",
    color: "rgba(3,54,61,0.72)",
  },
  "& .MuiDataGrid-pinnedColumnHeaders": {
    boxSizing: "border-box",
    border: "1px solid #E6E6E6",
    boxShadow: "0 2px 4px 0 #EAEAEA",
    borderBottom: "none",
  },
  "& .MuiDataGrid-pinnedColumns": {
    boxSizing: "border-box",
    border: "1px solid #E6E6E6",
    borderTop: "none",
    borderBottom: "none",
    fontWeight: 500,
    fontSize: 14,
    boxShadow: "0 2px 4px 0 #EAEAEA",
  },
  "& .MuiDataGrid-row": {
    cursor: "pointer",
    "&.Mui-selected": {
      backgroundColor: "inherit",
    },
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
    {
      outline: "none",
    },
  "& .MuiDataGrid-virtualScroller": {
    "&::-webkit-scrollbar": {
      width: 20,
      height: 10,
    },
    "&::-webkit-scrollbar-track": {
      marginLeft: 151,
      borderLeft: "1px solid #E6E6E6",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "rgb(224 224 224)",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#eaeaea",
      borderRadius: 10,
    },
  },
});

export const StyledChartTable = styled(DataGridPro)(({ theme }) => ({
  marginTop: 16,
  boxSizing: "border-box",
  border: "1px solid #E6E6E6",
  borderRadius: 10,
  backgroundColor: "#FFFFFF",
  overflow: "visible",
  "& .MuiDataGrid-columnHeaderDraggableContainer": {
    cursor: "context-menu!important",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer .MuiBox-root": {
    cursor: "context-menu !important",
  },
  "& .MuiDataGrid-columnHeader": {
    position: "relative",
    outline: "none !important",
    cursor: "context-menu",
  },
  "& .MuiDataGrid-columnHeader:first-of-type": {
    outline: "none !important",
    cursor: "context-menu",
    "& .MuiBox-root": {
      cursor: "context-menu",
      ":hover": {
        cursor: "context-menu",
        "&::before": {
          backgroundColor: "#fff",
        },
      },
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    overflow: "visible !important",
  },
  "& .cell-disabled": {
    backgroundColor: "#eee",
    pointerEvents: "none",
  },
  "& .column-bulk-selected": {
    backgroundColor: "#1f9f8a",
    color: "white !important",
    "& .MuiBox-root": {
      color: "white",
      opacity: "1 !important",
      ":before": {
        backgroundColor: "transparent",
      },
    },
    svg: {
      color: "white !important",

      ":hover": {
        color: "#03363db8 !important",
      },
    },
    "&:not(.MuiDataGrid-columnHeader)": {
      ":hover": {
        color: "#03363D !important",
        svg: {
          color: "#03363D !important",
        },
      },
    },
  },
  "& div.MuiDataGrid-cell": {
    padding: 0,
    outline: "none !important",
    overflow: "visible",
  },
  // Edit bulk whole table
  "&.bulk-edit-all": {
    color: "white !important",
    transition: "350ms ease",
    opacity: "1 !important",
    ":hover": {
      color: "#03363D !important",
    },
    "& .MuiBox-root": {
      color: "white !important",
      transition: "350ms ease",
      opacity: "1 !important",
      " :hover": {
        ".MuiBox-root~svg": {
          color: "#03363D !important",
          transition: "350ms ease",
          " :hover": {
            color: "#1F9F8A!important",
          },
        },
        ".MuiBox-root": {
          color: "#03363D !important",
        },
      },
    },
    "&.MuiDataGrid-columnHeader": {
      ".MuiBox-root:hover": {
        "&.MuiBox-root": {
          color: "#03363D !important",
        },
      },
    },
    "& .MuiDataGrid-cell:first-of-type": {
      color: "#03363D !important",
      transition: "350ms ease",
    },
    "& .MuiBox-root~svg": {
      color: "white !important",
      transition: "350ms ease",
      ":hover": {
        color: "#03363D !important",
      },
    },
    "& .MuiDataGrid-cell": {
      color: "white !important",
      backgroundColor: "rgba(31, 159, 138, 0.74)",
      transition: "350ms ease",
      ":hover": {
        color: "#03363db8!important",
        ".MuiBox-root": {
          color: "#03363db8!important",
        },
      },
      button: {
        color: "#fff !important",
        opacity: "1 !important",
        transition: "350ms ease",
        ":hover": {
          color: "#03363D !important",
        },
      },
    },

    "& .MuiDataGrid-columnHeaders": {
      color: "white !important",
      backgroundColor: "#1f9f8a",
    },
  },
  // Row edit bulk
  "& .MuiBox-root svg": {
    color: "#757575",
    padding: "0",
    margin: "-12px 0 0 6px",
    width: "14px",
    height: "12px",
    transition: "350ms ease",
    cursor: "pointer",

    ":hover": {
      color: "#1f9f8a !important",
    },
  },
  "& .MuiBox-root svg:hover": {
    color: "#1f9f8a !important",
  },
  "& div.MuiDataGrid-cell.row-bulk-edit svg": {
    color: "#fff !important",
    opacity: "1 !important",
    ":hover": {
      color: "#03363db8 !important",
    },
  },
  "& div.MuiDataGrid-cell.row-bulk-edit": {
    backgroundColor: "#1f9f8a",
    color: "white !important",
  },
  "& div.MuiDataGrid-cell.row-bulk-edit.fa-edit": {
    color: "white !important",
    "&:hover": {
      color: "#03363db8 !important",
    },
  },
  "& div.MuiDataGrid-cell.row-bulk-edit~div": {
    backgroundColor: "rgba(31, 159, 138, 0.74)",
    color: "#fff !important",
    opacity: "1 !important",
  },
  "& div.MuiDataGrid-cell.row-bulk-edit~div>button": {
    color: "#fff !important",
    opacity: "1 !important",
    ":hover": {
      color: "#03363db8 !important",
    },
  },
  "&.svg-inline--fa.fa-edit": {
    ":hover": {
      color: "#1F9F8A!important",
    },
  },
  // Row edit bulk end
  "& .MuiDataGrid-columnSeparator": { display: "none" },
  "& .MuiDataGrid-row": {
    "& .MuiDataGrid-cell": {
      "&:first-of-type": {
        paddingLeft: "10px",
        transition: "350ms ease",
        fontWeight: 600,
        ":hover": {
          backgroundColor: "#E0E0E0",
          color: "rgba(3, 54, 61, 0.721758) !important",
          // svg: {
          //   opacity: "1 !important",
          //   color: "#03363db8 !important",
          //   ":hover": {
          //     color: "#1F9F8A!important",
          //   },
          // },
        },
      },
    },
    cursor: "context-menu",
    ":hover": {
      backgroundColor: "inherit",
    },
  },
}));

export const SideDrawerPaper = styled(Paper)(({ theme }) => ({
  minHeight: "100vh",
  maxHeight: "100vh",
  boxSizing: "border-box",
  padding: "20px",
  width: 468,
  borderRadius: "44px 0 0 44px",
  backgroundColor: "#FFFFFF",
  position: "fixed",
  right: 0,
  [theme.breakpoints.down("md")]: {
    width: "100%",
    padding: "12px 0px",
  },
}));

export const ErrorMessageWrapper = styled(Typography)(({ theme }) => ({
  color: "rgb(233, 28, 28, 1)",
  marginTop: -8,
  fontWeight: 400,
  paddingLeft: 8,
}));

export const GPPrimaryButton = styled(Button)({
  fontSize: 14,
  lineHeight: "20px",
  backgroundColor: "#03363D",
  borderRadius: "6px",
  color: "#ffffff",
  "&:hover": {
    backgroundColor: "#22AA94",
  },
});

export const EditBtn = styled(Button)({
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  backgroundColor: "transparent",
  color: "#1F9F8A",
  marginLeft: 8,
  padding: "4px 2px",
  "& svg": {
    width: "14px",
    height: "12px",
    color: "#22AA94",
    marginLeft: "6px",
    marginBottom: "1px",
  },
  "&:hover": {
    textDecoration: "underline",
    backgroundColor: "#CEECE8",
  },
});

export const DefaultGroup = styled(Box)({
  padding: 0,
  color: "#757575",
  lineHeight: "20px",
  fontSize: "14px",
  marginBottom: "9px",
  "& span": {
    display: "block",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    color: "#757575",
    marginBottom: 8,
  },
  "& b": {
    color: "#03363D",
    marginRight: 6,
  },
});

export const DefaultRow = styled(Box)({
  display: "flex",
  "& > div": {
    paddingRight: "20px",
    maxWidth: "190px",
    width: "100%",
  },
  // "& > div:first-child": {
  //   minWidth: 180,
  // },
  // "& > div:nth-child(2)": {
  //   width: 220,
  // },
  // "& > div:nth-child(3)": {
  //   width: 230,
  // },
  // "& > div:nth-child(4)": {
  //   width: 180,
  // },
});
