import React from "react";
import { styled } from "@mui/material/styles";
import { Paper, IconButton, PaperProps } from "@mui/material";
import { useDispatch, uiActions } from "../../state";
import companyLogo from "../../assets/img/company-logo.svg";
import UserAvatar from "./UserAvatar";

interface AppBarProps extends PaperProps {
  open?: boolean;
}

const PaperBar = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  borderRadius: 0,
  padding: 8,
  width: "100vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#F9F9FB",
  borderBottom: "1px solid #D4E3E1",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

export default function MobileTopBar() {
  const dispatch = useDispatch();
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };

  return (
    <>
      <PaperBar elevation={1}>
        <img
          src={companyLogo}
          width={150}
          style={{ paddingLeft: 14 }}
          alt="logo"
        />
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
        >
          <UserAvatar />
        </IconButton>
      </PaperBar>
    </>
  );
}
