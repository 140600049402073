import React, { useCallback, useEffect } from "react";
import companyLogo from "../../assets/img/company-logo.svg";
import Notification from "../../components/Notification";
import { Outlet } from "react-router-dom";
import {
  RootStyle,
  LeftPanel,
  RightPanel,
  Container,
  SmallContainer,
} from "./AuthLayout.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  authActions,
  authSelectors,
  uiSelectors,
  useAction,
} from "../../state";
import { Navigation, useLocation } from "../../lib";
import { Backdrop, Typography } from "@mui/material";
import bigG from "../../assets/img/G-gradient.png";
import { AppState, useAuth0 } from "@auth0/auth0-react";
import { MainPages } from "../../pages/main";
import { AuthPages } from "../../pages/auth";
import { refeshTokenHelper } from "../../lib/utils";
import { useMobile } from "../../themes";
import Loader from "../../assets/img/loader.gif";
import jwt_decode from "jwt-decode";

export const AuthLayout = React.memo(function AuthLayout() {
  const location = useLocation();
  const isMobile = useMobile();
  const isLoggedIn = useSelector(authSelectors.isLoggedIn);
  const _login = useAction(authActions._login);
  const loading = useSelector(uiSelectors.loading);
  const dispatch = useDispatch();
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  refeshTokenHelper.tokenGetter = getAccessTokenSilently;
  refeshTokenHelper.isAuthenticated = isAuthenticated;
  const getToken = useCallback(async () => {
    const token = await getAccessTokenSilently();
    dispatch(authActions.setToken(token));
  }, [dispatch, getAccessTokenSilently]);
  const token = useSelector((state: AppState) => state.auth.token);
  var decoded: any = token ? jwt_decode(token) : "";
  const localUser = React.useMemo(
    () => ({
      isLender: decoded["https://auth.gparency.com/isLender"],
      isLenderPaid: decoded["https://auth.gparency.com/isLenderPaid"],
      isPremiumLender: decoded["https://auth.gparency.com/isPremiumLender"],
    }),
    [decoded],
  );
  const { isLender } = localUser;
  useEffect(() => {
    if (location.pathname.includes("logged-out")) {
      return;
    }
    (async () => {
      //if authenticated and has a token (aka isLoggedIn) call our login
      if (isAuthenticated && isLoggedIn) {
        const loggedIn = await _login(isAuthenticated);
        loggedIn && isLender
          ? Navigation.go(MainPages.opportunities.path)
          : Navigation.go(MainPages.join.path);
      } else if (isAuthenticated) {
        Navigation.go(AuthPages.processing.path);
        getToken();
      }
    })();
  }, [_login, getToken, isLender, isAuthenticated, isLoggedIn, location.pathname]);

  return (
    <RootStyle>
      <Container>
        <LeftPanel>
          <SmallContainer>
            <img width="270px" alt="Company logo" src={companyLogo} />
            <Typography variant="h1" sx={{ mt: 4 }}>
              The Future of Better Lending
            </Typography>
          </SmallContainer>
        </LeftPanel>
        <RightPanel>
          <Outlet />
        </RightPanel>
        <img
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: `${isMobile ? "60vw" : "30vw"}`,
          }}
          src={bigG}
          alt="big-g"
        />
      </Container>

      <Notification />
      {loading && (
        <Backdrop open={true}>
          <img
            style={{
              width: "128px",
              transition: "350ms ease",
            }}
            src={Loader}
            alt="Loader"
          />
        </Backdrop>
      )}
    </RootStyle>
  );
});
