import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  lendingProfileActions,
  lendingProfileSelector,
  uiActions,
} from "../../state";
export const DeleteCellDataPopup = ({
  loanType,
  lenderProgram,
  deleteCellOpen,
}: any) => {
  const dispatch = useDispatch();

  const activeDefaultLP = useSelector(lendingProfileSelector.activeDefaultLP);

  const handleCellDelete = () => {
    if (activeDefaultLP.form_profile_data?.default_lending_id)
      dispatch(
        lendingProfileActions.deleteSingleCell(
          activeDefaultLP.form_profile_data.default_lending_id,
          activeDefaultLP.execution_type_id,
          activeDefaultLP.loan_type_id,
        ),
      );
  };

  return (
    <Box
      className="delete-cell-data"
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(0, 0, 0, 0.15)",
        }}
        onClick={() => dispatch(uiActions.setDeleteCellOpen(false))}
      ></Box>
      <Box
        className="delete-cell-data"
        sx={{
          position: "fixed",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "#fff",
          borderRadius: "12px",
          width: "578px",
          maxWidth: "80vw",
          padding: "60px 43px 18px",
          color: "#03363D",
          textAlign: "center",
          boxShadow: "0px 17px 30px rgba(0, 0, 0, 0.45)",
          h3: {
            fontWeight: 500,
            fontSize: "20px",
            lineHeight: "26px",
            marginBottom: "24px",
            textTransform: "capitalize",
          },
          span: {
            display: "block",
            color: "#757575",
            fontSize: "16px",
            lineHeight: "24px",
          },
        }}
      >
        <Button
          color="inherit"
          style={{
            position: "absolute",
            top: "10px",
            right: "8px",
            width: "44px",
            height: "40px",
            minWidth: 0,
          }}
          onClick={() => dispatch(uiActions.setDeleteCellOpen(false))}
        >
          <FontAwesomeIcon
            style={{
              color: "#757575",
              width: "10px",
              height: "16px",
            }}
            size={"1x"}
            icon={regular("close")}
          />
        </Button>
        <Typography variant="h3">Delete Cell Data?</Typography>
        <Typography variant="caption" sx={{ pl: 0 }}>
          Are you sure you wish to delete all data for:{" "}
          <b>
            {loanType}, {lenderProgram}
          </b>
          ?
        </Typography>
        <Typography variant="caption" sx={{ pl: 0 }}>
          You can’t undo this action.
        </Typography>
        <Button
          onClick={handleCellDelete}
          variant="contained"
          sx={{
            marginTop: "60px",
            width: 158,
            padding: "14px 13px 11px",
            fontSize: "14px",
            backgroundColor: "text.primary",
            marginBottom: "10px",
            height: "42px",
          }}
        >
          <FontAwesomeIcon
            style={{
              color: "#fff",
              width: "14px",
              height: "16px",
              marginRight: "8px",
            }}
            size={"1x"}
            icon={regular("trash-can")}
          />
          Delete Cell Data
        </Button>
        <br />
        <Button
          variant="outlined"
          sx={{
            width: 158,
            borderColor: "transparent",
            color: "#1F9F8A",
            fontWeight: 400,
            fontSize: "16px",
            marginBottom: "2px",
            height: "42px",
            "&:hover": {
              borderColor: "#1F9F8A",
            },
          }}
          onClick={() => dispatch(uiActions.setDeleteCellOpen(false))}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
