import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { listActions } from "../../state";
import { StyledChip } from "./InputStyles";
import { hotjar } from "react-hotjar";

export default function StatesMultiSelectInput({
  fieldName,
  setFieldValue,
  values = [],
  isRequired = false,
}) {
  const [value, setValue]: any = useState(values);
  const dispatch = useDispatch();
  const { states } = useSelector((state: RootStateOrAny) => state.lists);
  const [statesArray, setStatesArray]: any = useState([]);
  const nationwideOption = { id: 0, state: "Nationwide" };

  useEffect(() => {
    dispatch(listActions.getStates());
  }, [dispatch]);

  useEffect(() => {
    const _array = [{ id: 0, state: "Nationwide" }, ...states];
    setStatesArray(_array);
  }, [states]);

  const onDelete = (id) => () => {
    if (id === 0) {
      const _array = [nationwideOption, ...states];
      setStatesArray(_array);
    }
    const stateArrvalues = value.filter((v: any) => v.id !== id);
    setValue(stateArrvalues);
    setFieldValue(fieldName, stateArrvalues);

    const removedName = value.filter((v: any) => v.id === id);
    hotjar.event(`Multiselect States - ${removedName[0].state} Removed`);
  };

  const handleChange = (e, newValue) => {
    if (newValue.filter((s) => s.state === "Nationwide")?.length) {
      setFieldValue("nationwide", true);
      setStatesArray([nationwideOption]);
      setValue([nationwideOption]);
      setFieldValue(fieldName, [nationwideOption]);
    } else {
      setFieldValue("nationwide", false);
      const _array = [nationwideOption, ...states];
      setStatesArray(_array);
      setValue(newValue);
      setFieldValue(fieldName, newValue);
    }
    hotjar.event(`"Multiselect States - ${e.target["innerText"]} Clicked`);
  };

  useEffect(() => {
    setValue(values);
    if (values?.filter((s: any) => s.state === "Nationwide")?.length) {
      setStatesArray([nationwideOption]);
    } else {
      const _array = [nationwideOption, ...states];
      setStatesArray(_array);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <>
      <FormControl variant="standard" sx={{ mb: 1 }}>
        <InputLabel shrink>
          Location
          {isRequired && (
            <Typography
              color={"error"}
              variant={"caption"}
              component={"span"}
              style={{ fontSize: 20 }}
            >
              {" "}
              *
            </Typography>
          )}
        </InputLabel>
        <Autocomplete
          multiple
          id={fieldName}
          value={value}
          onChange={handleChange}
          options={statesArray}
          getOptionLabel={(option: any) => option?.state}
          renderTags={() => null}
          popupIcon={<KeyboardArrowDownIcon />}
          isOptionEqualToValue={(option: any, value: any) =>
            option?.id === value?.id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select a state or states to add"
            />
          )}
        />
      </FormControl>
      <Grid container>
        {value?.map((v: any) => (
          <StyledChip key={v.id} label={v.state} onDelete={onDelete(v.id)} />
        ))}
      </Grid>
    </>
  );
}
