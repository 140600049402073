import React from "react";
import NumberFormat from "react-number-format";
import { useSelector } from "react-redux";
import { uiSelectors } from "../../state";

export const NumberFormatter = React.forwardRef((props: any, ref) => {
  const { onChange, name, ...rest } = props;
  const bulkEditColumn = useSelector(uiSelectors.bulkEditColumn);

  return (
    <NumberFormat
      getInputRef={ref}
      thousandSeparator={true}
      decimalScale={0}
      name={name}
      {...rest}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: bulkEditColumn
              ? values.value == null || values.value === ""
                ? null
                : Number(values.value)
              : Number(values.value),
          },
        });
      }}
    />
  );
});
