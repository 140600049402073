import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const AuthBox = styled(Box)({
  minHeight: 375,
  width: 414,
  borderRadius: 8,
  backgroundColor: "#FFFFFF",
  boxShadow: "0 2px 4px 5px rgba(13,13,13,0.07)",
  padding: 28,
});
