import React from "react";
import { TabBox } from "./InputStyles";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function _TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <TabBox>
          <div>{children}</div>
        </TabBox>
      )}
    </div>
  );
}

export const TabPanel = React.memo(_TabPanel);
