import React from "react";
import { useSelector, AppState, dealSelector } from "../../../state";
import { Typography } from "@mui/material";
import { FieldWrapper } from "../styles";
import { TermField } from ".";
import { InterestOnlyField } from "./InterestOnlyField";
import { DropdownFormInput } from "../../inputs/DropdownFormInput";
import { shallowEqual } from "react-redux";

export const LoanStructureComponent = ({
  type,
  isTermYears,
  isIOYears,
  setIsIOYears,
  setIsTermYears,
}) => {
  const {
    recourseList,
    prePaymentPenaltyList,
    amortizationList,
    executionList,
  } = useSelector((state: AppState) => state.lists);

  const activeDeal = useSelector(dealSelector.activeDeal, shallowEqual);

  return (
    <>
      <FieldWrapper>
        <Typography
          variant="h3"
          sx={{
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "28px",
          }}
        >
          Loan Structure
        </Typography>
      </FieldWrapper>
      <DropdownFormInput
        label="Execution"
        fieldName="lender_profile"
        list={executionList}
        isRequired={false}
      />
      <FieldWrapper>
        <TermField setIsTermYears={setIsTermYears} isTermYears={isTermYears} />
        <InterestOnlyField setIsIOYears={setIsIOYears} isIOYears={isIOYears} />
      </FieldWrapper>
      {activeDeal.loan_type === "Permanent Debt" ? (
        <>
          <FieldWrapper>
            <DropdownFormInput
              label="Amortization"
              fieldName="amortization_id"
              list={amortizationList}
              isRequired={true}
            />
            <DropdownFormInput
              label="Prepayment Penalty"
              fieldName="prepay_type"
              list={prePaymentPenaltyList}
              isRequired={true}
            />
          </FieldWrapper>
          <FieldWrapper sx={{ width: "48%" }}>
            <DropdownFormInput
              label="Recourse"
              fieldName="recourse"
              list={recourseList}
              isRequired={true}
            />
          </FieldWrapper>
        </>
      ) : activeDeal.loan_type === "Construction" ||
        activeDeal.loan_type === "Bridge" ? (
        <FieldWrapper>
          <DropdownFormInput
            label="Prepayment Penalty"
            fieldName="prepay_type"
            list={prePaymentPenaltyList}
            isRequired={true}
          />
          <DropdownFormInput
            label="Recourse"
            fieldName="recourse"
            list={recourseList}
            isRequired={true}
          />
        </FieldWrapper>
      ) : null}
    </>
  );
};
