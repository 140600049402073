import React, { memo, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import DealsDetailsTab from "../../components/Admin/DealsDetailsTab";
import DealsDetailsCard from "../../components/Admin/DealsDetailsCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Navigation, useLocation } from "../../lib";
import { QuoteDealDrawer } from "../../components/modals/QuoteDealModal";
import { dealActions, useDispatch } from "../../state";
import { ButtonTitle } from "./styles";

function _detailsPage() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const id = location.params.id;
    if (id) {
      dispatch(dealActions.loadActiveDealId(Number(id)));
    }
  }, [dispatch, location.params.id]);

  return (
    <Box sx={{ minHeight: `calc(100vh - 65px)` }}>
      <ButtonTitle color="inherit" onClick={(e) => Navigation.goBackHandler(e)}>
        <FontAwesomeIcon size={"1x"} icon={regular("arrow-left")} />{" "}
        <Typography
          variant="subtitle1"
          sx={{ pl: 1, textTransform: "uppercase", lineHeight: 0 }}
        >
          Back to Opportunities
        </Typography>
      </ButtonTitle>

      <DealsDetailsCard />
      <DealsDetailsTab />
      <QuoteDealDrawer />
    </Box>
  );
}

export const OpportunityDetailPage = memo(_detailsPage);
