import { styled } from "@mui/material/styles";

export const H4Title = styled("h4")({
  fontSize: "18px",
  margin: "0",
  color: "#03363D",
  lineHeight: "28px",
  fontWeight: "500",
  span: {
    color: "rgb(233, 28, 28, 1)",
  },
});
