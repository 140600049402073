import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  fixedFloatingList,
  ownerOccupiedAllowedList,
  lendingProfileSelector,
  AppState,
} from "../../state";
import { TextField, Select } from "formik-mui";
import { ErrorMessage, Field, FormikProps, useFormikContext } from "formik";
import { NumberFormatter } from "../inputs/NumberFormatter";
import { PercentFormatter } from "../inputs/PercentFormatter";
import { DropdownFormInput } from "../inputs/DropdownFormInput";
import { FieldWrapper } from "../Admin/styles";
import { EditBtn, ErrorMessageWrapper } from "./styles";
import { hotjar } from "react-hotjar";
import MultiSelect from "../inputs/MultiSelect";
import AutocompleteOriginators from "../inputs/AutocompleteOriginators";
import { YearsMonthsDropdown } from "../inputs/YearsMonthsDropdown";
import RequiredAsterisk from "../inputs/RequiredAsterisk";

export const LendingProfileFields = ({
  handleApplyOrigDefaults,
  handleApplyLendingDefaults,
}: any) => {
  const { setFieldValue, values }: FormikProps<any> = useFormikContext();
  const [isIOYears, setIsIOYears] = useState(false);

  const { designatedOriginatorList } = useSelector(
    (state: AppState) => state.lists,
  );
  const {
    propertyTypeList,
    termList,
    prePaymentPenaltyList,
    amortizationList,
    recourseList,
    minDscrList,
    states,
  } = useSelector((state: AppState) => state.lists);
  const activeDefaultLP: any = useSelector(
    lendingProfileSelector.activeDefaultLP,
  );

  useEffect(() => {
    if (
      activeDefaultLP.form_profile_data?.io_years ||
      activeDefaultLP.form_profile_data?.interest_only_years
    ) {
      setIsIOYears(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDefaultLP]);

  return (
    <Box
      sx={{
        "& fieldset:not(.notes-wrapper fieldset)": {
          height: "36px",
        },

        // "& input": {
        //   padding: "0px 12px 8px",
        // },

        "& label": {
          top: "-5px",
        },

        ".MuiSelect-select": {
          padding: "2px 27px 9px 0!important",
          fontSize: "14px",
        },

        ".rate-recourse-wrapper *, .pre-payment-occupied-wrapper *": {
          width: "100%",

          "& .MuiInputBase-input": {
            marginTop: "-3px",
            paddingLeft: "0 !important",
            fontSize: "14px",
          },

          "& .has-chevron-icon": {
            "&::before, &::after": {
              top: "41px",
            },
          },
        },

        ".rate-recourse-wrapper": {
          width: "100%",

          "& .has-chevron-icon": {
            "&::before, &::after": {
              top: "41px",
            },
          },
        },

        ".pre-payment-occupied-wrapper": {
          width: "100%",

          "& .has-chevron-icon": {
            "&::before, &::after": {
              top: "41px",
            },
          },

          "& .owner-occupied.has-chevron-icon": {
            "&::before, &::after": {
              top: "33px",
            },
          },
        },

        "& .MuiInputAdornment-root": {
          marginTop: "-8px",
        },

        "& .MuiInputAdornment-root + input": {
          paddingLeft: "0",
          paddingBottom: "15px",
        },

        "MuiFormControl-root-MuiTextField-root input": {
          padding: "1px 12px 7px",
        },

        ".min-max-rate-wrapper input": {
          padding: "0 0 7px",
        },

        ".max-term-amortization-wrapper .MuiSelect-select": {
          padding: "0px 0 7px !important",
        },

        ".max-min-ltv-dscr-wrapper input": {
          padding: "1px 0 8px ",
        },

        ".max-min-ltv-dscr-wrapper .MuiSelect-select": {
          padding: "1px 0 8px !important",
        },

        ".origination-interest-wrapper input": {
          padding: "0 0 7px",
        },

        ".MuiFormControl-root": {
          margin: "0",
          legend: {
            display: "none",
          },
        },

        ".MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input": {
          padding: "0",
        },

        ".has-chevron-icon": {
          position: "relative",
          ".MuiSvgIcon-root, svg": {
            display: "none",
          },
          "&::before, &::after": {
            position: "absolute",
            top: "33px",
            content: '""',
            width: "9px",
            height: "1px",
            backgroundColor: "#666666",
            pointerEvents: "none",
          },
          "&::before": {
            transform: "rotate(45deg)",
            right: "16px",
          },
          "&::after": {
            transform: "rotate(-45deg)",
            right: "10px",
          },
        },

        ".owner-occupied": {
          marginTop: "7px",
          "& #mui-component-select-owner_occupied": {
            padding: "3px 0 10px !important",
          },
        },

        ".notes-wrapper": {
          marginTop: "15px",
        },

        ".MuiInputAdornment-root p": {
          color: "#757575",
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "28px",
          color: "#03363D",
          marginBottom: "-4px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        Originators
        <EditBtn
          onClick={() => handleApplyOrigDefaults(setFieldValue)}
          sx={{
            padding: "2px 8px",
            color: "#1F9F8A",
            marginRight: "-6px",
            fontSize: "12px",
          }}
        >
          Apply Defaults
        </EditBtn>
      </Typography>
      <FormControl
        sx={{
          ".MuiInputLabel-animated": {
            transform: "none !important",
          },
          ".MuiAutocomplete-popper": {
            transform: "translate(1px, 72px) !important",
          },
          "& .MuiFormControl-root .MuiInputLabel-root": {
            top: "21px",
          },
        }}
      >
        <AutocompleteOriginators
          name="originators"
          setFieldValue={setFieldValue}
          // isRequired
          values={values}
          options={designatedOriginatorList}
          customLabelName={"contact_name"}
          editOriginatorsSlideOut={true}
        />
        <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
          <ErrorMessage name="defaultOriginators" />
        </ErrorMessageWrapper>
      </FormControl>
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "28px",
          color: "#03363D",
          marginBottom: "19px",
          marginTop: "16px",
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
        }}
      >
        Lending Criteria
        <EditBtn
          onClick={() => {
            setIsIOYears(
              activeDefaultLP.property_types.interest_only_years ? true : false,
            );
            handleApplyLendingDefaults(setFieldValue);
          }}
          sx={{
            padding: "2px 8px",
            color: "#1F9F8A",
            marginRight: "-6px",
            fontSize: "12px",
          }}
        >
          Apply Defaults
        </EditBtn>
      </Typography>
      <FormControl
        sx={{
          padding: "0",
          ".MuiFormControl-root": {
            width: "100%",
            margin: "0",
            marginBottom: "15px",
          },
          label: {
            top: "-18px !important",
          },
          ".MuiSvgIcon-root": {
            display: "none",
          },
          svg: {
            position: "absolute",
            right: "10px",
            top: "25px",
          },
          "#demo-multiple-checkbox": {
            padding: 0,
            margin: "-2px 0 0 0",
          },
        }}
      >
        <Field
          component={MultiSelect}
          name={"propertyTypes"}
          options={[
            {
              list_type_id: "",
              value: "All",
              description: "",
            },
            ...propertyTypeList,
            {
              list_type_id: "",
              value: "Uncheck All",
              description: "",
            },
          ]}
          placeholder="Select a property type to add"
          label="Property Type(s)"
          isRequired
          icon={true}
        />
        <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
          <ErrorMessage name="propertyTypes" />
        </ErrorMessageWrapper>
        <Field
          component={MultiSelect}
          label="State(s)"
          name={"states"}
          options={[
            { id: 0, state: "Nationwide" },
            { id: 9999, state: "All" },
            ...states,
          ]}
          placeholder="Select state(s) to add"
          customLabelName="state"
          isRequired
          icon={true}
          sx={{
            marginTop: "5px",
          }}
        />
        <ErrorMessageWrapper sx={{ pb: 1 }} variant="subtitle2">
          <ErrorMessage name="states" />
        </ErrorMessageWrapper>
      </FormControl>
      <FieldWrapper>
        <FormControl variant="standard">
          <InputLabel shrink style={{ top: "-16px" }}>
            Loan min
            <RequiredAsterisk />
          </InputLabel>
          <Field
            component={TextField}
            name="min_loan"
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatter,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onClick={() => hotjar.event("Input Loan min Clicked")}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink style={{ top: "-16px" }}>
            Loan max <RequiredAsterisk />
          </InputLabel>
          <Field
            component={TextField}
            name="max_loan"
            variant="outlined"
            InputProps={{
              inputComponent: NumberFormatter,
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            onClick={() => hotjar.event("Input Loan Max Clicked")}
          />
        </FormControl>
      </FieldWrapper>

      <FieldWrapper className="max-min-ltv-dscr-wrapper">
        <FormControl variant="standard">
          <InputLabel shrink>Max LTV</InputLabel>
          <Field
            component={TextField}
            variant="outlined"
            name="max_ltv"
            placeholder="Enter Max LTV"
            InputProps={{
              inputComponent: PercentFormatter,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onClick={() => hotjar.event("Input Max LTV Clicked")}
          />
        </FormControl>

        <FormControl
          variant="standard"
          className="has-chevron-icon"
          sx={{
            paddingTop: 0,
            label: {
              marginTop: "-10px",
              top: "5px",
            },
          }}
        >
          <DropdownFormInput
            label="Min DSCR"
            fieldName="min_dscr"
            list={minDscrList}
            isRequired={false}
            handleClick={() => hotjar.event("Input Min DSCR Clicked")}
          />
        </FormControl>
      </FieldWrapper>

      <FieldWrapper className="min-max-rate-wrapper">
        <FormControl variant="standard">
          <InputLabel shrink>Min Rate</InputLabel>
          <Field
            component={TextField}
            variant="outlined"
            name="min_rate"
            placeholder="0"
            InputProps={{
              inputComponent: PercentFormatter,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onClick={() => hotjar.event("Input Min Rate Clicked")}
          />
        </FormControl>
        <FormControl variant="standard">
          <InputLabel shrink>Max Rate</InputLabel>
          <Field
            component={TextField}
            variant="outlined"
            name="max_rate"
            InputProps={{
              inputComponent: PercentFormatter,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onClick={() => hotjar.event("Input Max Rate Clicked")}
          />
        </FormControl>
      </FieldWrapper>
      <FieldWrapper className="rate-recourse-wrapper">
        <FormControl variant="standard" className="has-chevron-icon">
          <Field
            component={MultiSelect}
            name={"rateTypes"}
            options={fixedFloatingList}
            placeholder="Select Rate Type"
            label="Rate Type"
            customLabelName="description"
            icon={true}
          />
        </FormControl>
        <FormControl variant="standard" className="has-chevron-icon">
          <Field
            component={MultiSelect}
            name={"recourse_id"}
            options={recourseList}
            placeholder="Select Recourse"
            label="Recourse"
            icon={true}
          />
        </FormControl>
      </FieldWrapper>
      <FieldWrapper className="max-term-amortization-wrapper">
        <FormControl variant="standard" className="has-chevron-icon">
          <InputLabel shrink>Max Term</InputLabel>
          <Field
            component={Select}
            name="max_term_id"
            variant="outlined"
            onClick={(e) => {
              hotjar.event(
                `Dropdown Max Term - ${e.target["innerText"]} Selected`,
              );
            }}
          >
            {termList?.map((obj: any) => (
              <MenuItem key={obj.id} value={Number(obj.id)}>
                {obj.value}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <FormControl variant="standard" className="has-chevron-icon">
          <InputLabel shrink>Max Amortization</InputLabel>
          <Field
            component={Select}
            variant="outlined"
            name="max_amortization_id"
            onClick={(e) => {
              hotjar.event(
                `Dropdown Max Amortization - ${e.target["innerText"]} Selected`,
              );
            }}
          >
            {amortizationList?.map((obj: any) => (
              <MenuItem key={obj.id} value={obj.id}>
                {obj.value}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
      </FieldWrapper>

      <FieldWrapper className="pre-payment-occupied-wrapper">
        <FormControl variant="standard" className="has-chevron-icon">
          <Field
            component={MultiSelect}
            name={"pre_payment_penalty_id"}
            options={prePaymentPenaltyList}
            placeholder="Select Pre Payment Penalty"
            label="Pre Payment Penalty"
            icon={true}
          />
        </FormControl>
        <FormControl
          variant="standard"
          className="has-chevron-icon owner-occupied"
        >
          <InputLabel shrink>Owner Occupied Allowed</InputLabel>
          <Field
            component={Select}
            variant="outlined"
            name="owner_occupied"
            onClick={(e) => {
              hotjar.event(
                `Dropdown Owner Occupied Allowed - ${e.target["innerText"]} Selected`,
              );
            }}
          >
            {ownerOccupiedAllowedList?.map(
              (obj: { value: number; description: string }) => (
                <MenuItem key={obj.value} value={obj.value}>
                  {obj.description}
                </MenuItem>
              ),
            )}
          </Field>
        </FormControl>
      </FieldWrapper>
      <FieldWrapper className="origination-interest-wrapper">
        <FormControl variant="standard">
          <InputLabel shrink>Origination Fee</InputLabel>
          <Field
            component={TextField}
            variant="outlined"
            name="origination_fee"
            InputProps={{
              inputComponent: PercentFormatter,
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            onClick={() => hotjar.event("Input Origination Fee Clicked")}
          />
        </FormControl>
        <FormControl variant="standard" className="has-chevron-icon">
          <InputLabel shrink>Interest Only</InputLabel>
          <Field
            component={TextField}
            name={isIOYears ? "io_years" : "io_months"}
            variant="outlined"
            sx={{ "& .MuiInputBase-root": { pr: 0 } }}
            InputProps={{
              inputComponent: NumberFormatter,
              endAdornment: (
                <FormControl
                  variant="standard"
                  sx={{ padding: "0", margin: "0" }}
                >
                  <YearsMonthsDropdown
                    value={isIOYears}
                    onChange={() => {
                      setIsIOYears(!isIOYears);
                      hotjar.event(
                        `Input Origination Fee - ${
                          isIOYears ? "Month" : "Years"
                        } - Selected`,
                      );
                    }}
                  />
                </FormControl>
              ),
            }}
            onClick={() => hotjar.event("Input Interest Only Clicked")}
          />
        </FormControl>
      </FieldWrapper>
      <FormControl
        variant="standard"
        sx={{ marginBottom: "70px" }}
        className="notes-wrapper"
      >
        <InputLabel shrink>Notes</InputLabel>
        <Field
          component={TextField}
          sx={{
            height: "120px",
            ".MuiInputBase-root-MuiOutlinedInput-root": {
              padding: "8px 12px",
            },
            ".MuiOutlinedInput-root": {
              padding: "8px 12px",
            },
            textarea: {
              height: "120px !important",
              padding: "0",
              "&::placeholder": {
                color: "#757575",
                fontSize: "14px",
                opacity: "1",
              },
            },
          }}
          placeholder="Enter Additional Notes Here..."
          multiline
          rows={3}
          variant="outlined"
          name="note"
          helperText="Max. 500 Characters. All notes are public facing."
          onClick={() => hotjar.event("Input Notes Clicked")}
          inputProps={{ maxLength: 500 }}
        />
      </FormControl>
    </Box>
  );
};
