import { Box, Chip } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledChip = styled(Chip)(({ theme }) => ({
  height: 28,
  borderRadius: 20,
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.contrastText,
  margin: theme.spacing(0, 2, 1, 0),
  "& .MuiChip-deleteIcon": {
    color: theme.palette.secondary.contrastText,
    height: 16,
    width: 16,
  },
}));

export const TabBox = styled(Box)(({ theme }) => ({
  padding: "0 24px 2px",
  [theme.breakpoints.down("sm")]: {
    padding: 8,
  },
}));
