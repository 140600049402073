import React from "react";
import NumberFormat from "react-number-format";

export const QuoteFormPercentFormatter = React.forwardRef((props: any, ref) => {
  const { inputRef, onBlur, name, value, ...rest } = props;

  return (
    <NumberFormat
      getInputRef={ref}
      // suffix={"%"}
      value={value}
      thousandSeparator={true}
      decimalScale={value >= 100 ? 0 : 2}
      name={name}
      maxLength={value >= 100 ? 2 : null}
      {...rest}
      onBlur={( e ) => {
        onBlur({
          target: {
            name: name,
            value:  e.target.value == null || e.target.value === ""
              ? null
              : Number(e.target.value),
          },
        });
      }}
    />
  );
});
