import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Backdrop, Button, Typography, Box } from "@mui/material";
import { QuoteDealForm } from "../Admin/QuoteDealForm";
import { dealSelector, uiActions, uiSelectors } from "../../state";
import { Drawer, DrawerHeader } from "./modal.styles";

export const QuoteDealDrawer = () => {
  const dispatch = useDispatch();
  const quotesOpen = useSelector(uiSelectors.quotesOpen);
  const activeDeal = useSelector(dealSelector.activeDeal);

  const handleDrawerClose = () => {
    dispatch(uiActions.setQuotesOpen(false));
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={quotesOpen}
        //onClick={setShow(false)}
      >
        <Drawer variant="permanent" open={quotesOpen} anchor="right">
          <DrawerHeader>
            <Typography variant="h3">
              {activeDeal.is_quoted ? "Edit my quote" : "Quote deal"}
            </Typography>
            <Button color="inherit" onClick={handleDrawerClose}>
              <FontAwesomeIcon size={"lg"} icon={regular("times")} />
            </Button>
          </DrawerHeader>
          <QuoteDealForm />
        </Drawer>
      </Backdrop>
    </Box>
  );
};
