import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Box, Button } from "@mui/material";
import { useMobile } from "../../themes";
import { hotjar } from "react-hotjar";
import SomethingWentWrong from "../errors/SomethingWentWrong";

interface PopupCTAUnpaidLenderModalProps {
  url: string | undefined;
  token: string | {};
  closePopupCallback: Function;
}

export const PopupCTAUnpaidLenderModal = ({
  url,
  token,
  closePopupCallback,
}: PopupCTAUnpaidLenderModalProps) => {
  const isMobile = useMobile();
  return (
    <Box
      sx={{
        zIndex: "999999 !important",
        position: "fixed",
        width: "100%",
        height: "100%",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        background: "rgba(0, 0, 0, 0.32)",

        "& .popup-cta": {
          width: "600px",
          height: "312px",
          borderRadius: "10px",
          backgroundColor: "#FFFFFF",
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
          top: "50%",
          padding: `${isMobile ? "50px 20px" : "75px 90px"}`,
        },
        "& .MuiSlider-thumb": {
          borderRadius: "1px",
        },

        "& .MuiButtonBase-root.popup-cta-close": {
          marginLeft: "auto",
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          transition: "350ms ease",
          padding: "0",
          minWidth: "auto",
          ":hover": {
            backgroundColor: "#fff",
          },
        },

        "& .popup-cta__overlay": {
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          opacity: "0.09",
          backgroundColor: "#040707",
        },
      }}
    >
      <div className="popup-cta__overlay"></div>
      <div className="popup-cta">
        {token && token !== "" && token !== undefined && token !== null ? (
          <iframe
            src={`${url}/?token=${token}&type=lenderJoin`}
            title={"Interested In Becoming A Member?"}
            width="600"
            height="312"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              border: "0",
            }}
          />
        ) : (
          <SomethingWentWrong token={token} />
        )}
        <Button
          sx={{
            position: "absolute",
            top: "40px",
            right: "24px",
          }}
          className="popup-cta-close"
          color="inherit"
          onClick={() => {
            closePopupCallback();
            hotjar.event("Unpaid lender closed CTA");
          }}
        >
          <FontAwesomeIcon size={"lg"} icon={regular("times")} />
        </Button>
      </div>
    </Box>
  );
};
