import { AppState } from "../types";

export const listSelectors = {
  lendingPrograms(state: AppState) {
    return state.lists.lendingPrograms;
  },
  terms(state: AppState) {
    return state.lists.termList;
  },
  recourseList(state: AppState) {
    return state.lists.recourseList;
  },
  prePaymentPenaltyList(state: AppState) {
    return state.lists.prePaymentPenaltyList;
  },
  amortizationList(state: AppState) {
    return state.lists.amortizationList;
  },
  propertyTypes(state: AppState) {
    return state.lists.propertyTypeList;
  },
  states(state: AppState) {
    return state.lists.states;
  },
  executionTypeList(state: AppState) {
    return state.lists.executionList;
  },
  executionTypeMapping(state: AppState) {
    return state.lists.executionTypeMapping;
  },
  designatedOriginatorList(state: AppState) {
    return state.lists.designatedOriginatorList;
  },
  conciergeBanksOriginators(state: AppState) {
    return state.lists.conciergeBanksOriginators ?? [];
  },
};
