import {
  lightFormat as dateFormatter,
  isValid,
  parseISO,
  compareAsc,
} from "date-fns";
import { authActions, Order, RefeshTokenHelper, store } from "../state";

export { arrayMoveMutable as arrayMove } from "array-move";

const dateInputKeyFilter = [
  "Backspace",
  "Delete",
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
];

/**
 * Returns `value + addValue` if `value` is truthy, else `defaultValue`.
 * @param {any} value Value to evaluate.
 * @param {any} addValue Added to `value` if `value` not falsey.
 * @param {any} defaultValue The default value to return if `value` is falsey.
 */
export function addIf(value, addValue, defaultValue = "") {
  return value ? value + addValue : defaultValue;
}
/**
 * Returns an entity list from the given array.
 * @param {any[]} arr
 * @param {string} idField
 * @returns {{ids:number[],entities:{[id:string]:any}}}
 */
export function arrayToEntityList(arr, idField = "id") {
  const list = { ids: [], entities: {} };
  return arr.reduce((list, item) => {
    const id = item[idField];
    list.ids.push(id);
    list.entities[id] = item;
    return list;
  }, list);
}

export function arrayToEntityPropTypeList(arr) {
  const list = { ids: [], entities: {} };
  return arr.reduce((list, item) => {
    const id = item["property_types"].replace(/\s/g, "");
    list.ids.push(id);
    if (!list.entities[id]) {
      list.entities[id] = {
        permanent_debt: [],
        construction: [],
        bridge: [],
      };
    }
    if (item.loan_type_value === "Permanent Debt")
      list.entities[id].permanent_debt.push(item);
    else if (item.loan_type_value === "Construction")
      list.entities[id].construction.push(item);
    else if (item.loan_type_value === "Bridge")
      list.entities[id].bridge.push(item);
    return list;
  }, list);
}
/** Returns 'yes' if `bool` is true, otherwise 'no'. */
export function boolYesNo(bool) {
  return bool ? "yes" : "no";
}
/**
 * Returns a click handler to show a confirmation then call the given handler.
 * @param {() => void} handler
 * @param {string} message `"Are you sure?"`
 */
export function confirmClickThen(handler, message = "Are you sure?") {
  return (...args) => {
    if (window.confirm(message)) {
      handler(...args);
    }
  };
}
/**
 * Crops the given `text` if it's longer than the `max` length.
 * Optionally adds a suffix to the cropped text.
 * @param {string} text
 * @param {number} max
 * @param {string} [suffix]
 */
export function cropText(text, max, suffix = "...") {
  if (text?.length > max) {
    return text.substr(0, max) + suffix;
  }
  return text;
}
/** Returns todays local date as a string, formatted as a US date by default. */
export function dateTodayLocal(format = "MM/dd/yyyy") {
  return dateFormatter(new Date(), format);
}
/** Returns todays local date as a string, formatted as an ISO date. */
export function dateTodayLocalISO() {
  return dateTodayLocal("yyyy-MM-dd");
}
/** Returns todays UTC date as a string in ISO format. */
export function dateTodayISO() {
  return new Date().toISOString().split("T")[0];
}
/**
 * Simple debounce function
 * @param {Function} fn Function to call after the `delay`.
 * @param {number} delay Time in milliseconds.
 */
export function debounce(fn, delay) {
  let timeoutId;
  return (...args) => {
    clearInterval(timeoutId);
    timeoutId = setTimeout(fn, delay, ...args);
  };
}
/**
 * Converts a decimal percentage to an integer percentage.
 * @param {number} value
 */
export function decimalToPercent(value) {
  return parseFloat(value) * 100;
}
/** An empty function. */
export function emptyHandler() {}
/**
 * Allows only the arrow keys to change a native date input.
 * @param {React.KeyboardEvent<HTMLInputElement>} e
 */
export function filterDateInputKeys(e) {
  if (dateInputKeyFilter.includes(e.key)) {
    e.preventDefault();
    e.stopPropagation();
  }
}
/**
 * @template T
 * @param {T[]} items
 * @param {any} id
 */
export function findById(items, id) {
  return items.find((it) => it.id === id);
}
/**
 * @template T
 * @param {T[]} items
 * @param {any} uid
 */
export function findByUid(items, uid) {
  return items.find((it) => it.uid === uid);
}
/**
 * @template T
 * @param {T[]} items
 * @param {string} fieldName
 * @param {any} value
 */
export function findByField(items, fieldName, value) {
  return items.find((it) => it[fieldName] === value);
}
/**
 * Finds the earliest ISO formatted date property in an object array.
 * @param {Record<string,string>[]} objects
 * @param {string} propName
 */
export function findLowestISODateProp(objects, propName) {
  const sorted = objects
    .map((it) => {
      const value = it[propName];
      return {
        value,
        valueAsDate: parseISO(value),
      };
    })
    .sort((a, b) => compareAsc(a.valueAsDate, b.valueAsDate));
  return sorted[0]?.value;
}
/** Flattens nested objects and arrays into a single dimension object.
 * See https://stackoverflow.com/questions/54896928/flattening-the-nested-object-in-javascript
 */
export function flatten(obj, prefix = "", res = {}) {
  return Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`;
    if (typeof val === "object") {
      flatten(val, `${k}.`, r);
    } else {
      res[k] = val;
    }
    return r;
  }, res);
}
/**
 * Formats `amount` in standard USD format.
 * - This was used instead of `Intl.NumberFormat` since the polyfill for that is
 * huge and we don't want to use a third-party polyfill.io service for a
 * financial app.
 * - See https://stackoverflow.com/a/149099/16387
 * - Removed decimal option.
 * - Added dollar sign option.
 * - Converted options to a single object argument.
 * @param {number} amount
 * @param {{decimalCount:number,decimalIfNotWhole:boolean,dollarSign:string,thousands:string}} [options]
 * @param {number} [options.decimalCount] Number of decimals to display. (`2`)
 * @param {boolean} [options.decimalIfNotWhole] If should only show decimal if not a whole dollar amount
 * @param {string} [options.dollarSign] Dollar sign to display. (`"$"`)
 * @param {string} [options.thousands] Thousands separator. (`","`)
 */
export function formatDecimal(amount) {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
}

/**
 * Formats the given `date` to the given `format` (`"MM/dd/yyyy"`).
 * **WARNING: If you provide `date` as an ISO string without a timezone
 * specifier, this function will convert that date to UTC time.**
 */
export function formatDate(date, format = "MM/dd/yyyy") {
  if (!date) {
    return "";
  }
  var d = new Date(date);
  if (!isValid(d)) {
    return "";
  }
  return dateFormatter(d, format);
}
/** Formats the given `date` to ISO-8601 date format.
 * **WARNING: If you provide `date` as an ISO string without a timezone
 * specifier, this function will convert that date to UTC time.**
 */
export function formatDateISO(date, format = "yyyy-MM-dd") {
  if (!date) {
    return "";
  }
  var d = new Date(date);
  if (!isValid(d)) {
    return "";
  }
  return dateFormatter(d, format);
}
export function formatDateLong(date, format = "LLLL dd, yyyy") {
  if (!date) {
    return "";
  }
  var d = new Date(date);
  if (!isValid(d)) {
    return "";
  }
  return dateFormatter(d, format);
}
/**
 * **WARNING: If you provide `date` as an ISO string without a timezone
 * specifier, this function will convert that date to UTC time.**
 */
export function formatDateTime(datetime, format = "MM/dd/yyyy h:mm aa") {
  return formatDate(datetime, format);
}

export function formatFullName({ firstName, middleName, lastName }) {
  if (middleName) {
    return `${firstName} ${middleName} ${lastName}`;
  }
  return `${firstName} ${lastName}`;
}
export function formatFirstLastName({ firstName, lastName }) {
  return `${firstName} ${lastName}`;
}
export function formatFirstLastNameInitials({ firstName, lastName }) {
  return (
    firstName.substr(0, 1).toUpperCase() + lastName.substr(0, 1).toUpperCase()
  );
}

export function formatHours(hours, suffix = "") {
  return (hours || 0).toFixed(2) + suffix;
  // return (hours || 0).toString() + suffix;
}
/**
 * Formats an ISO formatted `date` string (`"yyyy-mm-dd"`) as a local US date
 * (`"MM/dd/yyyy"`) without changing timezone, unlike `formatDate`.
 * @param {string} [isoDate]
 */
export function formatISODate(isoDate) {
  if (!isoDate || !isoDate.split) {
    return "";
  }
  const parts =
    // Split by "T" first, in case there is a time following the date.
    isoDate
      .split("T")[0]
      // Split by dash to get date parts.
      .split("-");
  if (parts.length < 3 || parts[0] === "0000") {
    return "";
  }
  return `${parts[1]}/${parts[2]}/${parts[0]}`;
}

export function formatTimeForInput(datetime, format = "HH:mm") {
  return formatDate(datetime, format);
}
/**
 * Returns the ordinal indicator text (e.g. 1st, 2nd, etc) for any number.
 * See https://english.stackexchange.com/questions/192804
 * @param {number} [num]
 */
export function formatOrdinal(num) {
  return `${num}${
    num % 10 === 1 && num % 100 !== 11
      ? "st"
      : num % 10 === 2 && num % 100 !== 12
      ? "nd"
      : num % 10 === 3 && num % 100 !== 13
      ? "rd"
      : "th"
  }`;
}
export function formatPercent(value, options?) {
  let decimalCount = 2;
  if (options) {
    if (options.decimalCount) decimalCount = options.decimalCount;
  }
  if (isNaN(value)) {
    return "";
  }
  return `${value.toFixed(decimalCount)}%`;
}
/** @param {string} value The phone number. */
export function formatPhone(value) {
  var cleaned = getPhoneNumbersOnly(value);
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}
/** @param {string} value */
export function getPhoneNumbersOnly(value) {
  return ("" + (value || "")).replace(/\D/g, "");
}
/**
 * Returns true if the given `date` is a valid `Date` object.
 * @param {Date} date
 */
export function isDateValid(d) {
  return d instanceof Date && !isNaN(d.getTime());
}
/** True if the given `str` is 'yes'. (Case insensitive) */
export function isYes(str) {
  return ("" + str).toLowerCase() === "yes" ? true : false;
}
/**
 * Converts the given value to lower camel case.
 * @param {string} value
 */
export function lowerCamelCase(value) {
  if (!value) {
    return "";
  }
  return value.substr(0, 1).toLowerCase() + value.substr(1);
}
/**
 * Converts the column name to a title - remove underscores & make first letter uppercase.
 * @param {string} value
 */
export function columnToTitle(column) {
  if (!column) {
    return "";
  }
  return column
    .split("_")
    .map((i) => i.substr(0, 1).toUpperCase() + i.substr(1))
    .join(" ");
}
/**
 * Returns an array of values from a map of values, by key.
 * The opposite of `arrayToObjById`.
 * @param {{ [key:string]:any }} obj Map of values by key.
 */
export function mapToArray(obj) {
  return Object.keys(obj).map((key) => obj[key]);
}
/**
 * Returns the given string value with numbers masked by an asterisk, if
 * `shouldMask` is true.
 * @param {boolean} shouldMask
 * @param {string} value
 */
export function maskNumbersIf(shouldMask, value) {
  return shouldMask ? ("" + value).replace(/[0-9]/g, "*") : value;
}
/**
 * Masks all characters up to the last 4.
 * @param {string} value
 * @param {number} [maskLen] Optional number of mask characters. If passed, this
 * number will be used instead of detecting how many characters came before the
 * last 4.
 */
export function maskUpToLast4(value, maskLen) {
  value = "" + value;
  var lengthBeforeLast4 = Math.max(0, value.length - 4);
  var last4 = value.substr(lengthBeforeLast4);
  var mask = "*".repeat(maskLen || lengthBeforeLast4);
  return mask + last4;
}

export function replaceNullProps(props, replace = "") {
  const newProps = {};
  Object.keys(props).forEach((prop) => {
    const value = props[prop];
    newProps[prop] = value === null ? replace : value;
  });
  return newProps;
}
/** Function that simply returns it's given argument. */
export function returnArg(arg) {
  return arg;
}
/** Function that returns true. */
export function returnTrue() {
  return true;
}
/**
 * Returns a CSS `hsl` color string hashed from the given `str`.
 * @param {string} str The input string.
 * @param {number} saturation Percentage of saturation (`0 - 100`).
 * Use a value around `30` for pastels.
 * @param {number} lightness Percentage of lightness (`0 - 100`).
 * Use a value around `80` for pastels.
 *
 * @see https://medium.com/%40pppped/compute-an-arbitrary-color-for-user-avatar-starting-from-his-username-with-javascript-cd0675943b66
 * @see https://codepen.io/sergiopedercini/pen/RLJYLj/
 */
export function stringToHslColor(str, saturation, lightness) {
  const { length } = str || "";
  let hash = 0;
  for (let i = 0; i < length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = hash % 360;
  return `hsl(${color},${saturation}%,${lightness}%)`;
}
/**
 * Returns a pastel CSS `hsl` color string hashed from the given `str`.
 * @param {string} str The input string.
 * @see `stringToHslColor`
 */
export function stringToHslPastel(str) {
  return stringToHslColor(str, 30, 80);
}
/**
 * Asynchronously waits for the given amount of time in `ms`.
 * @param {number} [ms] Time to wait, in milliseconds.
 */
export function timeoutAsync(ms = 0) {
  return new Promise((resolve, reject) => {
    setTimeout(resolve, ms);
  });
}
/**
 * Returns the first `collection` property value that matches `predicate`.
 * @template TCollection
 * @param {TCollection} collection
 * @param {(Pick<TCollection, keyof TCollection>)=>boolean} predicate
 * @returns {Pick<TCollection, keyof TCollection>}
 */
export function find(collection, predicate) {
  const key = Object.keys(collection).find((key) => predicate(collection[key]));
  return key !== undefined ? collection[key] : undefined;
}
/**
 * Maps over `obj` keys and returns values from the given `map` function.
 * @template T
 * @template R
 * @param {Record<string,T>} obj
 * @param {(value:T,key:string,obj:Record<string,T>)=>R} map
 * @returns {R}
 */
export function mapValues(obj, map) {
  return Object.keys(obj).map((key) => map(obj[key], key, obj));
}
/**
 * Reduce function for objects. Transforms `obj` to a new `accumulator` object
 * using the given `map` function.
 * @template T
 * @template {T} R
 * @param {Record<string,T>} obj
 * @param {(value:T,key:string,obj:Record<string,T>)=>R} map
 * @param {Record<string,T>} [accumulator]
 * @returns {Record<string,R>}
 */
export function transform(obj, map, accumulator = {}) {
  return Object.keys(obj).reduce((accumulator, key) => {
    accumulator[key] = map(obj[key], key, obj);
    return accumulator;
  }, accumulator);
}
/**
 * Converts `array` to a new object keyed by the given `key`.
 * @example reduceBy([{id:1},{id:2}],"id") // returns { 1:{id:1}, 2:{id:2} }
 * @example reduceBy(["a", "b"]) // returns { 0: "a", 1: "b" }
 * @template T
 * @param {T[]} [array] An array of values to convert.
 * @param {keyof T} [key] For an array of objects, key to use. If ommited, the
 * array index is used as the key.
 * @param {Record<string,T>} [obj] Optional object to convert into.
 * @returns {Record<string,T>}
 */
export function reduceBy(array, key, obj = {}) {
  if (!array) {
    return [];
  }
  return array.reduce((obj, it, i) => {
    let prop = key !== undefined ? it[key] : i;
    obj[prop] = it;
    return obj;
  }, obj);
}
export function shallowEqualsObj(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  var aKeys = Object.keys(objA);
  var bKeys = Object.keys(objB);
  var len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  for (var i = 0; i < len; i++) {
    var key = aKeys[i];

    if (
      objA[key] !== objB[key] ||
      !Object.prototype.hasOwnProperty.call(objB, key)
    ) {
      return false;
    }
  }

  return true;
}
/** Returns true if the given object, array or string value is empty. */
export function isEmpty(value) {
  return !value || Object.keys(value).length === 0;
}
/**
 * Returns true if any of the given object, array or string values are empty.
 */
export function allEmpty(...values) {
  const { length } = values;
  for (let i = 0; i < length; i++) {
    const value = values[i];
    if (!isEmpty(value)) {
      return false;
    }
  }
  return true;
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stringToArrayByComma(string) {
  if (typeof string === "string") {
    return string.split(",")?.map((obj) => obj);
  } else {
    return "";
  }
}

export const refeshTokenHelper: RefeshTokenHelper = {
  tokenGetter: undefined,
  isAuthenticated: undefined,
  token: undefined,
  config: (config) => {
    const _config = {
      ...config,
      headers: config?.headers ?? {},
    };
    if (refeshTokenHelper.token) {
      _config.headers.Authorization = `Bearer ${refeshTokenHelper.token}`;
    }
    return _config;
  },
  refesh: async () => {
    if (refeshTokenHelper.tokenGetter) {
      const token = await refeshTokenHelper.tokenGetter();
      store.dispatch(authActions.setToken(token));
      refeshTokenHelper.token = token;
    }
  },
  startTimer: () => {
    setInterval(() => {
      if (!refeshTokenHelper.token) return;
      refeshTokenHelper.refesh();
    }, 5 * 60 * 1000);
  },
};
refeshTokenHelper.startTimer();

export function snakeToCamelCase(value: string) {
  return value
    .replace(/_([a-z])/g, (_, char) => "_" + char.toUpperCase())
    .replace(/_/g, "");
}

export const getPropertyTypeLabelById = (id: number, propertyTypes) => {
  return propertyTypes.find((propertyType: any) => propertyType.value === id)
    ?.label;
};

export const getOriginatorNameFromId = (id: number | string, originators) => {
  return originators.find((originator: any) => originator.id === id)
    ?.contact_name;
};

export const getLabelsFromIds = (
  ids: any,
  options: any[],
  id_name: any = "id",
  label_name: any = "value",
) => {
  if (!options || !ids) return "";
  return options
    .filter((option) => {
      return (
        ids
          .split(",")
          .map(Number)
          .find((id: number) => id === option[id_name]) ?? false
      );
    })
    .map((o) => o[label_name]);
};

export const getLabelFromId = (
  id: number,
  options: any[],
  id_name: any,
  label_name: any,
) => {
  return options.find((option) => option[id_name] === id)?.[label_name];
};

export const getYearsMonthsLabel = (value: number, isYears: boolean) => {
  if (!value) return "";
  if (isYears) {
    return `${value} Year(s)`;
  } else {
    return `${value} Month(s)`;
  }
};

export const findIds = (
  originalArray: any,
  anotherArray: any,
  label = "value",
  id = "id",
) => {
  if (!anotherArray) return [];
  // Create an empty array to store the matching ids
  const matchingIds: any = [];

  // Loop through the original array
  originalArray.forEach((item: any) => {
    // Check if the label of this item exists in the anotherArray
    const matchingItem = anotherArray.find((anotherItem) => {
      if (label === "contact_name") return anotherItem[label] === item[label];
      return anotherItem === item[label];
    });
    if (matchingItem) {
      // If there's a matching item, add its id to the matchingIds array
      matchingIds.push(item[id]);
    }
  });

  // Return the matchingIds array
  return matchingIds;
};
