import { Field } from "formik";
import { Select } from "formik-mui";
import { FormControl, InputLabel, MenuItem, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface DropdownFormInputProps {
  label: any;
  fieldName: any;
  list: any;
  isDisabled?: boolean;
  isRequired?: boolean;
  handleClick?: any;
}

export function DropdownFormInput({
  label,
  fieldName,
  list,
  isDisabled = false,
  isRequired = false,
  handleClick = () => {},
}: DropdownFormInputProps): any {
  return (
    <FormControl variant="standard">
      <InputLabel shrink>
        {label}{" "}
        {isRequired && (
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        )}
      </InputLabel>
      <Field
        component={Select}
        variant="outlined"
        name={fieldName}
        displayEmpty
        IconComponent={KeyboardArrowDownIcon}
      >
        <MenuItem
          disabled
          hidden
          style={{ display: "none" }}
          key={-0}
          value={""}
        >
          <span style={{ color: "#a9a9a9" }}>
            {label === "Rate Type" ? "Select" : "Enter"} {label}
          </span>
        </MenuItem>
        {list?.map((obj: any) =>
          fieldName === "rate_type_name" ? (
            <MenuItem key={obj.value} value={obj.description}>
              {obj.description}
            </MenuItem>
          ) : fieldName === "min_dscr_id" ||
            fieldName === "min_dscr_stabilized_id" ? (
            <MenuItem key={obj.value} value={obj.id}>
              {obj.value}
            </MenuItem>
          ) : fieldName === "quote_index" ? (
            <MenuItem key={obj.name} value={obj.rate}>
              {obj.name}
            </MenuItem>
          ) : (
            <MenuItem key={obj.id} value={obj.id}>
              {obj.value}
            </MenuItem>
          ),
        )}
      </Field>
    </FormControl>
  );
}
