import { createSelector } from "@reduxjs/toolkit";
import { arrayToEntityList } from "../../lib";
import { AppState } from "../types";

const _state = (state: AppState) => state.deals;

const _activeDeal = createSelector(_state, (deals) =>
  deals.activeDealId
    ? arrayToEntityList(deals.user_deals).entities[deals.activeDealId]
    : {
        account_rep: {},
        activity_date: "",
        address: {},
        amortization: "",
        bank_fee: "",
        bank_fee_out: "",
        bank_name: "",
        client: {},
        competing_quotes: [],
        documents: [],
        dy_in: "",
        extension_fee: "",
        is_quoted: 0,
        loan_amount: 0,
        ltc: "",
        ltv: "",
        ltv_stabilized: "",
        min_dscr_stabilized_id: "",
        min_dy_stabilized: "",
        number_of_option: "",
        option: "",
        option_months: "",
        deal_display_status: "",
        prepayment_penalty: "",
        rate_floor: "",
        index_floor: "",
        adjustment_spread: 0,
        adjustment_index: "",
        product: "",
        projected_value: "",
        property_type: "",
        requested_amount: 0,
        reserves_id: "",
        term: "",
        term_months: "",
        transaction_type: "",
        quoted_amount: "",
      },
);

export const dealSelector = {
  activeDeal: _activeDeal ?? {},

  userDealsCount(state: AppState) {
    return state.deals.user_deals.length;
  },
  userDeals(state: AppState) {
    return arrayToEntityList(state.deals.user_deals).entities;
  },
  competingQuotes(state: AppState) {
    return _activeDeal(state).competing_quotes ?? [];
  },
  documents(state: AppState) {
    return _activeDeal(state).documents?.flatMap((doc) => doc.info) ?? [];
  },
  filteredDealsArray(state: AppState) {
    return state.lender.filtered_deals;
  },
  filteredOtherDealsArray(state: AppState) {
    return state.lender.filtered_other_deals;
  },
  quoteDealInitialState(state: AppState) {
    const {
      amortization_id = "",
      bank_fee = "",
      bank_fee_out = "",
      deal_id = "",
      dy_in = "",
      extension_fee = "",
      id: quote_id = "",
      quote_index = "",
      index_today_rate = "",
      io_months = "",
      io_years = "",
      lender_profile = "",
      loan_amount = 0,
      ltc = "",
      ltv = "",
      ltv_stabilized = "",
      manual_index_rate = null,
      min_dscr_id = "",
      min_dy_stabilized = "",
      min_dscr_stabilized_id = "",
      new_note = "",
      number_of_option = "",
      option = 0,
      option_months = 0,
      deal_display_status = "",
      prepay_type = "",
      projected_value = "",
      rate = 0,
      rate_floor = "",
      index_floor = "",
      adjustment_spread = 0,
      adjustment_index = "",
      rate_type = "",
      rate_type_name = "",
      recourse = "",
      reserves_id = "",
      requested_amount = 0,
      spread = "",
      spread_type = "",
      term = "",
      term_months = "",
      quoted_amount = "",
    } = _activeDeal(state);
    return {
      amortization_id: amortization_id ?? "",
      bank_fee: bank_fee === null || bank_fee.startsWith("0") ? "" : bank_fee,
      bank_fee_out:
        bank_fee_out === null || bank_fee_out.startsWith("0")
          ? ""
          : bank_fee_out,
      deal_id,
      dy_in,
      extension_fee:
        extension_fee === 0 || extension_fee === null ? "" : extension_fee,
      index_today_rate:
        index_today_rate === 0 || index_today_rate === null
          ? ""
          : index_today_rate,
      quote_index,
      io_months: io_months ? io_months.split(" ")[0] : "",
      io_years: io_years ? io_years.split(" ")[0] : "",
      lender_profile,
      loan_amount: quoted_amount || loan_amount,
      ltc,
      ltv,
      ltv_stabilized:
        ltv_stabilized === 0 || ltv_stabilized === null ? "" : ltv_stabilized,
      manual_index_rate,
      min_dscr_id: min_dscr_id ?? "",
      min_dy_stabilized,
      min_dscr_stabilized_id: min_dscr_stabilized_id ?? "",
      new_note,
      number_of_option,
      option: option === 0 || option === null ? "" : option,
      option_months:
        option_months === 0 || option_months === null ? "" : option_months,
      deal_display_status,
      prepay_type: prepay_type === 0 ? "" : prepay_type,
      projected_value:
        projected_value === 0 || projected_value === null
          ? ""
          : projected_value,
      quote_id,
      quoted_amount,
      requested_amount,
      rate,
      rate_floor: rate_floor ?? "",
      index_floor: index_floor ?? "",
      adjustment_spread:
        adjustment_spread === 0 || adjustment_spread === null
          ? ""
          : adjustment_spread,
      adjustment_index,
      rate_type,
      rate_type_name,
      recourse: recourse ?? "",
      reserves_id,
      spread: spread === 0 || spread === null ? "" : spread,
      spread_type,
      term: term && term !== "" ? parseInt(term) : "",
      term_months:
        term_months && term_months !== "" ? parseInt(term_months) : "",
      total_cost:
        quoted_amount === 0 || ltc === null
          ? ""
          : new Intl.NumberFormat().format(+(quoted_amount * (ltc / 100))),
    };
  },
};
