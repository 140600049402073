import React from "react";
import NumberFormat from "react-number-format";

export const QuoteFormNumberFormatter = React.forwardRef((props: any, ref) => {
  const { onChange, name, ...rest } = props;

  return (
    <NumberFormat
      getInputRef={ref}
      thousandSeparator={true}
      decimalScale={0}
      name={name}
      {...rest}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: (values.value == null || values.value === "" || values.value === "0" ) ? null : Number(values.value),
          },
        });
      }}
    />
  );
});