import * as React from "react";
import { styled } from "@mui/material/styles";
import { SwipeableDrawer, Box, IconButton } from "@mui/material";
import { uiActions, uiSelectors, useDispatch, useSelector } from "../../state";
import { CloseIcon } from "../../components";
import companyLogo from "../../assets/img/company-logo.svg";
import ProfileMenuOptions from "./ProfileMenuOptions";

const Drawer = styled(SwipeableDrawer)({
  "& .MuiDrawer-paperAnchorTop": {
    borderRadius: 0,
  },
});

export default function MobileDrawer() {
  const dispatch = useDispatch();
  const menuOpen = useSelector(uiSelectors.menuOpen);

  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };

  return (
    <Box>
      <Drawer
        anchor="top"
        open={menuOpen}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      >
        <Box
          sx={{
            p: 1,
            pt: 3,
            mb: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <img
            src={companyLogo}
            width={200}
            style={{ paddingLeft: 14 }}
            alt="logo"
          />
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={handleDrawerClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <ProfileMenuOptions handleClose={handleDrawerClose} />
      </Drawer>
    </Box>
  );
}
