import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";

export const AddressStyle = styled(`div`)(({ theme }) => ({
  height: 22,
  fontSize: 16,
  fontWeight: 700,
  // maxWidth: 300,
  overflow: "hidden",
  textOverflow: "ellipsis",
  boxSizing: "border-box",
}));

export const AddressTableCell = styled(`div`)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  minWidth: 0,
}));

export const MultiLineWrapper = styled(`div`)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  paddingLeft: 10,
  boxSizing: "border-box",
  minWidth: 0,
}));

export const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  backgroundColor: "#F9F9FB",
  overflowX: "auto",
  border: "none",
  borderTop: "1px solid #D4E3E1",
  "& .MuiDataGrid-row": {
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "10px",
    boxShadow: "none",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "none",
  },
  "& .MuiDataGrid-columnSeparator": { display: "none" },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
    {
      outline: "none",
    },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "none",
  },
}));

export const TableWrapper = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    minWidth: 700,
    backgroundColor: "#F9F9FB",
    flexGrow: 1,
    display: "flex",
    marginTop: 20,
    "& .header": {
      height: 17,
      color: "rgba(3,54,61,0.72)",
      fontSize: 12,
      letterSpacing: 0.4,
      lineHeight: "17px",
      textTransform: "uppercase",
      ":focus-within": {
        outline: "none",
      },
      "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 700,
      },
    },
  },
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    //  minWidth: "100%",
  },
}));
