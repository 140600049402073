import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { Card, CardContent } from "@mui/material";
import {
  listActions,
  useDispatch,
  useSelector,
  dealSelector,
  dealActions,
} from "../../state";
import { BridgeTypeQuoteForm } from "./QuoteForms/BridgeTypeQuoteForm";
import { PermanentDebtQuoteForm } from "./QuoteForms/PermanentDebtQuoteForm";
import { ConstructionQuoteForm } from "./QuoteForms/ConstructionQuoteForm";
import { shallowEqual } from "react-redux";
import { hotjar } from "react-hotjar";
import { FormButton, FormContainer, FormResetButton } from "./styles";

const permValidationSchema = yup.object().shape(
  {
    quote_id: yup.number().integer().required(),
    quoted_amount: yup
      .string()
      .test(
        "quoted_amount",
        "Must be greater than $0",
        function (quoted_amount: any) {
          const commaless = String(this.parent.quoted_amount).replaceAll(
            ",",
            "",
          );
          return typeof +commaless === "number" && +commaless > 0;
        },
      )
      .required("Must be greater than $0"),
    ltv: yup
      .number()
      .positive()
      //.nullable()
      .min(0.009, "Must be greater than 0%")
      .required("Must be greater than 0%")
      .typeError("Must be greater than 0%"),
    min_dscr_id: yup.string().ensure().required("Please select a Min DSCR"),
    term: yup
      .number()
      .test("term", "Please enter a term", function (term: any) {
        const { isTermYears } = this.parent;
        return !isTermYears || (isTermYears && parseInt(term) > 0);
      })
      .typeError("Please enter a term"),
    term_months: yup
      .number()
      .test("term_months", "Please enter a term", function (term_months: any) {
        const { isTermYears } = this.parent;
        return isTermYears || (!isTermYears && parseInt(term_months) > 0);
      })
      .typeError("Please enter a term"),
    io_years: yup
      .number()
      .nullable()
      .min(0, "Interest must beddddddd greater than or equal to 0"),
    //.typeError("Interest muklllklst be greater than or equal to 0"),
    io_months: yup
      .number()
      .nullable()
      .min(0, "Interest must beddd greater than or equal to 0")
      .typeError("Interest must be akknkn number"),
    // these two validations are causing submit to not fire.
    amortization_id: yup
      .number()
      .min(0, "Please select an amortization")
      .required("Please select an amortization")
      .typeError("Please select an amortization"),
    // .test("term", "NA is not allowed", function (item) {
    //   return (
    //     this.parent.term === this.parent.io_years ||
    //     this.parent.term === this.parent.io_months / 12 ||
    //     this.parent.term_months === this.parent.io_months ||
    //     this.parent.term_months === this.parent.io_years * 12 ||
    //     (this.parent.term !== this.parent.io_years &&
    //       this.parent.term !== this.parent.io_months / 12 &&
    //       this.parent.term_months !== this.parent.io_months &&
    //       this.parent.term_months !== this.parent.io_years * 12 &&
    //       this.parent.amortization_id !== 0)
    //   );
    // }),
    prepay_type: yup
      .number()
      .min(1, "Please select a prepayment penalty")
      .required("Please select a prepayment penalty"),
    recourse: yup
      .number()
      .min(0, "Please select a recourse")
      .required("Please select a recourse"),
    rate_type_name: yup.string().required("Please select a rate type"),
    rate: yup.number().when(["quote_index", "index_today_rate", "spread"], {
      is: (quote_index, index_today_rate, spread) =>
        !quote_index &&
        (!index_today_rate || parseFloat(index_today_rate) <= 0) &&
        (!spread || parseFloat(spread) <= 0),
      then: yup
        .number()
        .min(1, "Must be greater than 0%")
        .required("Must be greater than 0%"),
    }),
    number_of_option: yup
      .number()
      .nullable()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test("number_of_option", "Must be greater than 0", function (item) {
        return (
          (this.parent.rate_type_name === "Hybrid" &&
            this.parent.number_of_option > 0) ||
          this.parent.rate_type_name !== "Hybrid"
        );
      })
      .typeError("Must be greater than 0"),
    option: yup
      .number()
      .nullable()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test("option", "Must be greater than 0", function (option: any) {
        const { isExtYears } = this.parent;

        return (
          (this.parent.rate_type_name === "Hybrid" && !isExtYears) ||
          (this.parent.rate_type_name === "Hybrid" &&
            isExtYears &&
            parseInt(option) > 0) ||
          this.parent.rate_type_name !== "Hybrid"
        );
      })
      .min(0, "Must be greater than 0")
      .typeError("Must be greater than 0"),
    option_months: yup
      .number()
      .nullable()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test(
        "option_months",
        "Must be greater than 0",
        function (option_months: any) {
          const { isExtYears } = this.parent;

          return (
            (this.parent.rate_type_name === "Hybrid" && isExtYears) ||
            (this.parent.rate_type_name === "Hybrid" &&
              !isExtYears &&
              parseInt(option_months) > 0) ||
            this.parent.rate_type_name !== "Hybrid"
          );
        },
      )
      .min(0, "Must be greater than 0.")

      .typeError("Must be greater than 0"),
    spread: yup
      .number()
      .min(0, "Must be greater than 0")
      .nullable()
      .typeError("Must be greater than 0"),
    adjustment_index: yup
      .string()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test(
        "adjustment_index",
        "Please enter an adjustment index",
        function (item) {
          return (
            this.parent.rate_type_name !== "Hybrid" ||
            (item === undefined || item === null
              ? false
              : this.parent.rate_type_name === "Hybrid" &&
                this.parent.adjustment_index.length > 0)
          );
        },
      )
      .typeError("Please enter an adjustment index"),
    adjustment_spread: yup
      .number()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test("adjustment_spread", "Must be greater than 0%", function (item) {
        return (
          this.parent.rate_type_name !== "Hybrid" ||
          (item === undefined || item === null
            ? false
            : this.parent.rate_type_name === "Hybrid" &&
              +this.parent.adjustment_spread > 0)
        );
      })
      .typeError("Must be greater than 0%"),
    index_floor: yup
      .number()
      .nullable()
      .test("index_floor", "Must be greater than 0", function (item) {
        return this.parent.rate_type_name !== "Hybrid" ||
          item === undefined ||
          item === null ||
          this.parent.index_floor > 0
          ? true
          : false;
      })
      .typeError("Must be greater than 0"),
    rate_floor: yup
      .number()
      .nullable()
      .test("rate_floor", "Must be greater than 0", function (item) {
        return this.parent.rate_type_name !== "Hybrid" ||
          item === undefined ||
          item === null ||
          this.parent.rate_floor > 0
          ? true
          : false;
      })
      .typeError("Must be greater than 0"),
    bank_fee: yup
      .number()
      .min(0, "'Origination Fee' must be greater than or equal to 0")
      .nullable()
      .typeError("'Origination Fee' must be a number"),
    bank_fee_out: yup
      .number()
      .min(0, "'Exit Fee' must be greater than or equal to 0")
      .nullable()
      .typeError("'Exit Fee' must be a number"),
    extension_fee: yup
      .number()
      .min(0, "'Extension Fee' must be greater than or equal to 0")
      .nullable()
      .typeError("'Extension Fee' must be a number"),
  },
  [
    ["rate", "quote_index"],
    ["term", "term_months"],
    ["rate", "index_today_rate"],
    ["index_today_rate", "quote_index"],
  ],
);

const constValidationSchema = yup.object().shape(
  {
    quote_id: yup.number().integer().required(),
    quoted_amount: yup
      //.number()
      .string()
      .test(
        "quoted_amount",
        "Must be greater than $0",
        function (quoted_amount: any) {
          const commaless = String(this.parent.quoted_amount).replaceAll(
            ",",
            "",
          );
          return typeof +commaless === "number" && +commaless > 0;
        },
      ),
    ltc: yup
      .number()
      .positive()
      //.nullable()
      .min(0.009, "Must be greater than 0%")
      .required("Must be greater than 0%")
      .typeError("Must be greater than 0%"),
    term: yup
      .number()
      .test("term", "Please enter a term", function (term: any) {
        const { isTermYears } = this.parent;
        return !isTermYears || (isTermYears && parseInt(term) > 0);
      })
      .typeError("Please enter a term"),
    term_months: yup
      .number()
      .test("term_months", "Please enter a term", function (term_months: any) {
        const { isTermYears } = this.parent;
        return isTermYears || (!isTermYears && parseInt(term_months) > 0);
      })
      .typeError("Please enter a term"),
    io_years: yup
      .number()
      .nullable()
      .min(0, "Interest must beddddddd greater than or equal to 0"),
    //.typeError("Interest muklllklst be greater than or equal to 0"),
    io_months: yup
      .number()
      .nullable()
      .min(0, "Interest must beddd greater than or equal to 0")
      .typeError("Interest must be akknkn number"),
    // these two validations are causing submit to not fire.
    prepay_type: yup
      .number()
      .min(1, "Please select a prepayment penalty")
      .required("Please select a prepayment penalty"),
    recourse: yup
      .number()
      .min(0, "Please select a recourse")
      .required("Please select a recourse"),
    rate_type_name: yup.string().required("Please select a rate type"),
    rate: yup.number().when(["quote_index", "index_today_rate", "spread"], {
      is: (quote_index, index_today_rate, spread) =>
        !quote_index &&
        (!index_today_rate || parseFloat(index_today_rate) <= 0) &&
        (!spread || parseFloat(spread) <= 0),
      then: yup
        .number()
        .min(1, "Must be greater than 0%")
        .required("Must be greater than 0%"),
    }),
    // number_of_option: yup
    //   .number()
    //   .nullable()
    //   //.min(0, "'Index Floor' must be greater than or equal to 0")
    //   .test("number_of_option", "Must be greater than 0", function (item) {
    //     return (
    //       (this.parent.rate_type_name === "Hybrid" &&
    //         this.parent.number_of_option > 0) ||
    //       this.parent.rate_type_name !== "Hybrid"
    //     );
    //   })
    //   .typeError("Must be greater than 0"),
    // option: yup
    //   .number()
    //   .nullable()
    //   //.min(0, "'Index Floor' must be greater than or equal to 0")
    //   .test("option", "Must be greater than 0", function (option: any) {
    //     const { isExtYears } = this.parent;

    //     return (
    //       (this.parent.rate_type_name === "Hybrid" && !isExtYears) ||
    //       (this.parent.rate_type_name === "Hybrid" &&
    //         isExtYears &&
    //         parseInt(option) > 0) ||
    //       this.parent.rate_type_name !== "Hybrid"
    //     );
    //   })
    //   .min(0, "Must be greater than 0")
    //   .typeError("Must be greater than 0"),
    // option_months: yup
    //   .number()
    //   .nullable()
    //   //.min(0, "'Index Floor' must be greater than or equal to 0")
    //   .test(
    //     "option_months",
    //     "Must be greater than 0",
    //     function (option_months: any) {
    //       const { isExtYears } = this.parent;

    //       return (
    //         (this.parent.rate_type_name === "Hybrid" && isExtYears) ||
    //         (this.parent.rate_type_name === "Hybrid" &&
    //           !isExtYears &&
    //           parseInt(option_months) > 0) ||
    //         this.parent.rate_type_name !== "Hybrid"
    //       );
    //     },
    //   )
    //   .min(0, "Must be greater than 0")

    //   .typeError("Must be greater than 0"),
    spread: yup
      .number()
      .min(0, "Must be greater than 0")
      .nullable()
      .typeError("'Gross Spread' must be a number"),
    // adjustment_index: yup
    //   .string()
    //   //.min(0, "'Index Floor' must be greater than or equal to 0")
    //   .test(
    //     "adjustment_index",
    //     "Please enter an adjustment index",
    //     function (item) {
    //       return (
    //         this.parent.rate_type_name !== "Hybrid" ||
    //         (item === undefined || item === null
    //           ? false
    //           : this.parent.rate_type_name === "Hybrid" &&
    //             this.parent.adjustment_index.length > 0)
    //       );
    //     },
    //   )
    //   .typeError("Please enter an adjustment index"),
    // adjustment_spread: yup
    //   .number()
    //   //.min(0, "'Index Floor' must be greater than or equal to 0")
    //   .test("adjustment_spread", "Must be greater than 0%", function (item) {
    //     return (
    //       this.parent.rate_type_name !== "Hybrid" ||
    //       (item === undefined || item === null
    //         ? false
    //         : this.parent.rate_type_name === "Hybrid" &&
    //           +this.parent.adjustment_spread > 0)
    //     );
    //   })
    //   .typeError("Must be greater than 0%"),
    // index_floor: yup
    //   .number()
    //   .nullable()
    //   .test("index_floor", "Must be greater than 0", function (item) {
    //     return this.parent.rate_type_name !== "Hybrid" ||
    //       item === undefined ||
    //       item === null ||
    //       this.parent.index_floor > 0
    //       ? true
    //       : false;
    //   })
    //   .typeError("Must be greater than 0"),
    // rate_floor: yup
    //   .number()
    //   .nullable()
    //   .test("rate_floor", "Must be greater than 0", function (item) {
    //     return this.parent.rate_type_name !== "Hybrid" ||
    //       item === undefined ||
    //       item === null ||
    //       this.parent.rate_floor > 0
    //       ? true
    //       : false;
    //   })
    //   .typeError("Must be greater than 0"),
    bank_fee: yup
      .number()
      .min(0, "'Origination Fee' must be greater than or equal to 0")
      .nullable()
      .typeError("'Origination Fee' must be a number"),
    bank_fee_out: yup
      .number()
      .min(0, "'Exit Fee' must be greater than or equal to 0")
      .nullable()
      .typeError("'Exit Fee' must be a number"),
    extension_fee: yup
      .number()
      .min(0, "'Extension Fee' must be greater than or equal to 0")
      .nullable()
      .typeError("'Extension Fee' must be a number"),
  },
  [
    ["rate", "quote_index"],
    ["term", "term_months"],
    ["rate", "index_today_rate"],
    ["index_today_rate", "quote_index"],
  ],
);

const bridgeValidationSchema = yup.object().shape(
  {
    quote_id: yup.number().integer().required(),
    quoted_amount: yup
      //.number()
      .string()
      .test(
        "quoted_amount",
        "Must be greater than $0",
        function (quoted_amount: any) {
          const commaless = String(this.parent.quoted_amount).replaceAll(
            ",",
            "",
          );
          return typeof +commaless === "number" && +commaless > 0;
        },
      ),
    ltc: yup
      .number()
      .positive()
      //.nullable()
      .min(0.009, "Must be greater than 0%")
      .required("Must be greater than 0%")
      .typeError("Must be greater than 0%"),
    term: yup
      .number()
      .test("term", "Please enter a term", function (term: any) {
        const { isTermYears } = this.parent;
        return !isTermYears || (isTermYears && parseInt(term) > 0);
      })
      .typeError("Please enter a term"),
    term_months: yup
      .number()
      .test("term_months", "Please enter a term", function (term_months: any) {
        const { isTermYears } = this.parent;
        return isTermYears || (!isTermYears && parseInt(term_months) > 0);
      })
      .typeError("Please enter a term"),
    io_years: yup
      .number()
      .nullable()
      .min(0, "Interest must beddddddd greater than or equal to 0"),
    //.typeError("Interest muklllklst be greater than or equal to 0"),
    io_months: yup
      .number()
      .nullable()
      .min(0, "Interest must beddd greater than or equal to 0")
      .typeError("Interest must be akknkn number"),
    // these two validations are causing submit to not fire.
    prepay_type: yup
      .number()
      .min(1, "Please select a prepayment penalty")
      .required("Please select a prepayment penalty"),
    recourse: yup
      .number()
      .min(0, "Please select a recourse")
      .required("Please select a recourse"),
    rate_type_name: yup.string().required("Please select a rate type"),
    rate: yup.number().when(["quote_index", "index_today_rate", "spread"], {
      is: (quote_index, index_today_rate, spread) =>
        !quote_index &&
        (!index_today_rate || parseFloat(index_today_rate) <= 0) &&
        (!spread || parseFloat(spread) <= 0),
      then: yup
        .number()
        .min(1, "Must be greater than 0%")
        .required("Must be greater than 0%"),
    }),
    number_of_option: yup
      .number()
      .nullable()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test("number_of_option", "Must be greater than 0", function (item) {
        return (
          (this.parent.rate_type_name === "Hybrid" &&
            this.parent.number_of_option > 0) ||
          this.parent.rate_type_name !== "Hybrid"
        );
      })
      .typeError("Must be greater than 0"),
    option: yup
      .number()
      .nullable()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test("option", "Must be greater than 0", function (option: any) {
        const { isExtYears } = this.parent;

        return (
          (this.parent.rate_type_name === "Hybrid" && !isExtYears) ||
          (this.parent.rate_type_name === "Hybrid" &&
            isExtYears &&
            parseInt(option) > 0) ||
          this.parent.rate_type_name !== "Hybrid"
        );
      })
      .min(0, "Must be greater than 0")
      .typeError("Must be greater than 0"),
    option_months: yup
      .number()
      .nullable()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test(
        "option_months",
        "Must be greater than 0",
        function (option_months: any) {
          const { isExtYears } = this.parent;

          return (
            (this.parent.rate_type_name === "Hybrid" && isExtYears) ||
            (this.parent.rate_type_name === "Hybrid" &&
              !isExtYears &&
              parseInt(option_months) > 0) ||
            this.parent.rate_type_name !== "Hybrid"
          );
        },
      )
      .min(0, "Must be greater than 0")

      .typeError("Must be greater than 0"),
    spread: yup
      .number()
      .min(0, "Must be greater than 0")
      .nullable()
      .typeError("'Gross Spread' must be a number"),
    adjustment_index: yup
      .string()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test(
        "adjustment_index",
        "Please enter an adjustment index",
        function (item) {
          return (
            this.parent.rate_type_name !== "Hybrid" ||
            (item === undefined || item === null
              ? false
              : this.parent.rate_type_name === "Hybrid" &&
                this.parent.adjustment_index.length > 0)
          );
        },
      )
      .typeError("Please enter an adjustment index"),
    adjustment_spread: yup
      .number()
      //.min(0, "'Index Floor' must be greater than or equal to 0")
      .test("adjustment_spread", "Must be greater than 0%", function (item) {
        return (
          this.parent.rate_type_name !== "Hybrid" ||
          (item === undefined || item === null
            ? false
            : this.parent.rate_type_name === "Hybrid" &&
              +this.parent.adjustment_spread > 0)
        );
      })
      .typeError("Must be greater than 0%"),
    index_floor: yup
      .number()
      .nullable()
      .test("index_floor", "Must be greater than 0", function (item) {
        return this.parent.rate_type_name !== "Hybrid" ||
          item === undefined ||
          item === null ||
          this.parent.index_floor > 0
          ? true
          : false;
      })
      .typeError("Must be greater than 0"),
    rate_floor: yup
      .number()
      .nullable()
      .test("rate_floor", "Must be greater than 0", function (item) {
        return this.parent.rate_type_name !== "Hybrid" ||
          item === undefined ||
          item === null ||
          this.parent.rate_floor > 0
          ? true
          : false;
      })
      .typeError("Must be greater than 0"),
    bank_fee: yup
      .number()
      .min(0, "'Origination Fee' must be greater than or equal to 0")
      .nullable()
      .typeError("'Origination Fee' must be a number"),
    bank_fee_out: yup
      .number()
      .min(0, "'Exit Fee' must be greater than or equal to 0")
      .nullable()
      .typeError("'Exit Fee' must be a number"),
    extension_fee: yup
      .number()
      .min(0, "'Extension Fee' must be greater than or equal to 0")
      .nullable()
      .typeError("'Extension Fee' must be a number"),

    projected_value: yup
      .number()
      .min(0, "Must be greater than $0")
      .nullable()
      .typeError("Must be greater than $0"),
    ltv_stabilized: yup
      .number()
      .min(0, "Must be greater than 0%")
      .nullable()
      .typeError("Must be greater than 0%"),
  },
  [
    ["rate", "quote_index"],
    ["term", "term_months"],
    ["rate", "index_today_rate"],
    ["index_today_rate", "quote_index"],
  ],
);

export const QuoteDealForm = () => {
  const dispatch = useDispatch();
  const activeDeal = useSelector(dealSelector.activeDeal, shallowEqual);
  const initialValues = useSelector(
    dealSelector.quoteDealInitialState,
    shallowEqual,
  );
  const [isTermYears, setIsTermYears] = useState(true);
  const [isIOYears, setIsIOYears] = useState(true);
  const [isExtYears, setIsExtYears] = useState(true);
  const [values, setValues] = useState({ ...initialValues, isTermYears });
  const [emptyIt, setEmptyIt] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  // const validateSchema = getYupValidationSchema("quoteForm");

  // const handleReset = (resetForm) => {
  //   resetForm({ values: initialValues });
  // };

  useEffect(() => {
    setValues({ ...initialValues, isTermYears });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  // this is setting bank_fee to null because it's coming as a default "0.000" from the backend.
  // FormPercentInput in OriginationFeesNotesComponent will not render the placeholder correctly.
  // Business requires an empty value in the field instead of 0.00.
  // if (values.bank_fee === "0.000") {
  //   values.bank_fee = null;
  // }

  function onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
      document.getElementById("form")?.focus();
    }
  }

  const handleSubmit = (values, { setSubmitting }) => {
    if (isTermYears) {
      values.term_months = 0;
    } else {
      values.term = 0;
    }
    if (isIOYears) {
      values.io_months = 0;
    } else {
      values.io_years = 0;
    }
    if (isExtYears) {
      values.option_months = 0;
    } else {
      values.option = 0;
    }

    values.quoted_amount = +values.quoted_amount;

    // if there was an index type selected don't return rate
    if (values.quote_index) {
      delete values.rate;
    } else if (values.rate) {
      values.quote_index = null;
      values.spread = null;
      values.index_today_rate = null;
      //always delete spread_type if rate was entered except if user chose variable
      if (values.rate_type !== 2) {
        delete values.spread_type;
      }
    }

    //ToDo: check why select value is turning to string
    values.lender_profile = Number(values.lender_profile);
    values.prepay_type = Number(values.prepay_type);
    values.recourse = Number(values.recourse);
    delete values.isIOYears;
    if (activeDeal.loan_type === "Construction") {
      delete values.ltv;
      delete values.amortization_id;
      delete values.dy_in;
    }
    if (activeDeal.loan_type === "Bridge") {
      delete values.ltv;
    }
    delete values.total_cost;
    const { rate_type_name, ...payload } = values;
    delete payload.rate_type_name;
    delete payload.isTermYears;
    delete payload.isExtYears;
    delete payload.loan_amount;
    dispatch(dealActions.saveQuote(payload));
    setSubmitting(false);
    setSubmitDisabled(false);
  };

  useEffect(() => {
    if (initialValues.io_years) {
      setIsIOYears(true);
    }
  }, [initialValues]);

  useEffect(() => {
    dispatch(listActions.getQuoteDealLists());
  }, [dispatch]);
  if (!initialValues || !Object.keys(initialValues).length) return null;
  return (
    <Card sx={{ boxShadow: "none" }}>
      <CardContent sx={{ pt: 0, pb: 0 }}>
        <Formik
          // enableReinitialize={true}
          initialValues={values}
          // validateOnChange={false}
          // validateOnBlur={false}
          validationSchema={
            activeDeal.loan_type === "Permanent Debt"
              ? permValidationSchema
              : activeDeal.loan_type === "Construction"
              ? constValidationSchema
              : bridgeValidationSchema
          }
          onSubmit={(a, b) => {
            setSubmitDisabled(true);
            handleSubmit(a, b);
          }}
        >
          {({ isSubmitting, resetForm }) => (
            <Form
              id="form"
              style={{ display: "flex", flexDirection: "column" }}
              onKeyDown={onKeyDown}
            >
              <FormContainer>
                {activeDeal.loan_type === "Bridge" ? (
                  <BridgeTypeQuoteForm
                    setIsExtYears={setIsExtYears}
                    isExtYears={isExtYears}
                    setIsIOYears={setIsIOYears}
                    setIsTermYears={setIsTermYears}
                    isIOYears={isIOYears}
                    isTermYears={isTermYears}
                    emptyIt={emptyIt}
                  />
                ) : (
                  <>
                    {activeDeal.loan_type === "Construction" ? (
                      <ConstructionQuoteForm
                        setIsExtYears={setIsExtYears}
                        isExtYears={isExtYears}
                        setIsIOYears={setIsIOYears}
                        setIsTermYears={setIsTermYears}
                        isIOYears={isIOYears}
                        isTermYears={isTermYears}
                        emptyIt={emptyIt}
                      />
                    ) : (
                      <PermanentDebtQuoteForm
                        setIsIOYears={setIsIOYears}
                        setIsTermYears={setIsTermYears}
                        isIOYears={isIOYears}
                        isTermYears={isTermYears}
                        setIsExtYears={setIsExtYears}
                        isExtYears={isExtYears}
                        emptyIt={emptyIt}
                      />
                    )}
                  </>
                )}
              </FormContainer>
              {!activeDeal.is_quoted && (
                <FormResetButton
                  onClick={() => {
                    resetForm();
                    setEmptyIt(true);
                    setTimeout(() => {
                      setEmptyIt(false);
                    }, 100);
                  }}
                >
                  Reset
                </FormResetButton>
              )}
              <FormButton
                variant="contained"
                type="submit"
                id="submitBttn"
                disabled={submitDisabled}
                sx={{
                  width: "45%",
                  marginTop: !activeDeal.is_quoted ? "-42px" : "10px",
                  left: "-15px",
                }}
                onClick={() => {
                  hotjar.event("Submit quote button clicked");
                }}
              >
                {activeDeal.is_quoted ? "Update quote" : "Submit quote"}
              </FormButton>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};
