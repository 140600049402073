import * as React from "react";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import Menu from "./Menu";
import { uiActions, uiSelectors, useDispatch, useSelector } from "../../state";
import companyText from "../../assets/img/logo-text.svg";
import companyIcon from "../../assets/img/company-icon.svg";
import { ListItemIcon } from "@mui/material";

export const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  borderRadius: "0 36px 36px 0",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  borderRadius: "0 36px 36px 0",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  marginLeft: 0,
  alignItems: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const dispatch = useDispatch();
  const menuOpen = useSelector(uiSelectors.menuOpen);

  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const handleDrawerOpen = () => {
    dispatch(uiActions.setMenuOpen(true));
  };
  return (
    <Box>
      <Drawer
        variant="permanent"
        open={menuOpen}
        onMouseEnter={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
      >
        <DrawerHeader
          sx={{
            mb: 2,
            mt: 3,
          }}
        >
          <ListItemIcon sx={{ padding: "0px 18px" }}>
            <img height={38} src={companyIcon} alt="logo" />
          </ListItemIcon>

          <img width={145} src={companyText} alt="logo" />
        </DrawerHeader>
        <Menu />
      </Drawer>
    </Box>
  );
}
