import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ContentBox, PageBox, PageTitle } from "./styles";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const CreateLendingProfileSuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/lending-profile", { replace: true });
    }, 3000);
  }, [navigate]);

  return (
    <PageBox>
      <ContentBox>
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          sx={{ pr: 2 }}
          alignItems="center"
        >
          <Grid
            item
            container
            lg={6}
            md={10}
            xs={12}
            alignItems="center"
            direction="row"
          >
            <PageTitle
              sx={{
                paddingLeft: "26px",
                fontFamily: "Gilroy",
                fontSize: "28px",
                fontWeight: 700,
              }}
              variant="h2"
            >
              Create A Lending Profile
            </PageTitle>
          </Grid>
        </Grid>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "26vh",
          }}
        >
          <FontAwesomeIcon
            color="#22AA94"
            size={"3x"}
            style={{ width: 45, height: 49, marginBottom: 21 }}
            icon={regular("clipboard-check")}
          />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "22px",
              lineHeight: "30px",
            }}
          >
            Lending Profile Created Successfully
          </Typography>
        </Box>
      </ContentBox>
    </PageBox>
  );
};
