import React, { useCallback, useEffect } from "react";
import { Typography } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import {
  authActions,
  authSelectors,
  useAction,
  useSelector,
} from "../../state";
import { refeshTokenHelper } from "../../lib/utils";

export const AuthenticatePage = () => {
  const ourLogout = useAction(authActions.logout);
  const setToken = useAction(authActions.setToken);
  const {
    isAuthenticated,
    getAccessTokenSilently,
    logout,
    isLoading,
    loginWithRedirect,
  } = useAuth0();
  refeshTokenHelper.tokenGetter = getAccessTokenSilently;
  const authStates = useSelector(authSelectors.state);

  const getToken = useCallback(async () => {
    const token = await getAccessTokenSilently();
    setToken(token);
  }, [getAccessTokenSilently, setToken]);

  useEffect(() => {
    if (isAuthenticated) {
      getToken();
    } else if (!isLoading) {
      ourLogout();
      loginWithRedirect();
    } else {
      // console.log("still loading auth0");
    }
  }, [
    authStates,
    getToken,
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    logout,
    ourLogout,
  ]);

  return <Typography variant="h2">Authenticating...</Typography>;
};
