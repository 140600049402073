import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
} from "@mui/material";
import {
  useDispatch,
  useSelector,
  lenderActions,
  authSelectors,
} from "../../state";
import { TextField } from "formik-mui";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { FieldWrapper } from "./styles";
import { hotjar } from "react-hotjar";

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  // email: yup
  //   .string()
  //   .email("Enter a valid email")
  //   .required("Email is required"),
});

export const PersonalInfoForm = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector(authSelectors.userInfo);
  const [initialValues, setInitialValues]: any = useState({
    firstName: "",
    lastName: "",
    // email: "",
  });

  useEffect(() => {
    setInitialValues(userInfo);
  }, [userInfo]);

  const handleSubmit = (values, { setSubmitting }) => {
    const { firstName, lastName } = values;
    dispatch(
      lenderActions.updateUserPersonalInfo({
        first_name: firstName,
        last_name: lastName,
      }),
    );
    setSubmitting(false);
    hotjar.event("Button Save Personal Info");
  };

  return (
    <Card>
      <CardContent sx={{ pt: 0 }}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({
            isSubmitting,
            errors,
            touched,
            values,
            handleChange,
            setFieldValue,
          }) => (
            <Form>
              <FieldWrapper>
                <FormControl variant="standard">
                  <InputLabel shrink>First name</InputLabel>
                  <Field
                    component={TextField}
                    fullWidth
                    variant="outlined"
                    id="firstName"
                    name="firstName"
                    error={touched.firstName && Boolean(errors.firstName)}
                    helperText={touched.firstName && errors.firstName}
                    onClick={() => hotjar.event("Input First Name Click")}
                  />
                </FormControl>
                <FormControl variant="standard">
                  <InputLabel shrink>Last name</InputLabel>
                  <Field
                    component={TextField}
                    fullWidth
                    name="lastName"
                    required
                    error={touched.lastName && Boolean(errors.lastName)}
                    helperText={touched.lastName && errors.lastName}
                    onClick={() => hotjar.event("Input Last Name Click")}
                  />
                </FormControl>
              </FieldWrapper>

              <FormControl variant="standard" sx={{ mt: 1 }}>
                <InputLabel shrink>Email address</InputLabel>
                <Field
                  component={TextField}
                  fullWidth
                  type="email"
                  name="email"
                  disabled
                  onClick={() => hotjar.event("Input Email Click")}
                  // error={touched.email && Boolean(errors.email)}
                  // helperText={touched.email && errors.email}
                />
              </FormControl>

              <Grid
                container
                columns={2}
                sx={{ mt: 3 }}
                justifyContent="flex-end"
              >
                <Grid item xs={1}>
                  <Button
                    sx={{ backgroundColor: "text.primary", height: 48 }}
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};
