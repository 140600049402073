import React, { useEffect } from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { MainPages } from "../../pages/main";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, ListItemButton, styled } from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import { useMobile } from "../../themes";
import { uiActions, useDispatch } from "../../state";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { hotjar } from "react-hotjar";
import { AppState, useAuth0 } from "@auth0/auth0-react";
import { REACT_APP_AUTH0_CLAIM_DOMAIN } from "../../config";
import { useSelector } from "react-redux";

const menuItems = [
  {
    label: "Quote Opportunities",
    Icon: (
      <FontAwesomeIcon size={"1x"} icon={regular("tachometer-alt-fastest")} />
    ),
    activeUrl: MainPages.opportunities.path,
    to: MainPages.opportunities.path,
  },
  {
    label: "My Lending Profile",
    activeUrl: MainPages.lendingProfile.path,
    Icon: <FontAwesomeIcon size={"1x"} icon={solid("list")} />,
    to: MainPages.lendingProfile.path,
  },
];

const IconWrapper = styled("div")(({ theme }) => ({
  borderRadius: "50%",
  width: 30,
  height: 30,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export default function Menu() {
  const { isAuthenticated, user } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMobile();
  const dispatch = useDispatch();
  const isRateAdmin = user?.[`${REACT_APP_AUTH0_CLAIM_DOMAIN}/isRateAdmin`];
  const authState = useSelector((state: AppState) => state.auth);
  useEffect(() => {
    if (
      isRateAdmin &&
      !menuItems.find((item) => {
        return item.label === "Edit Interest Rates";
      })
    ) {
      menuItems.push({
        label: "Edit Interest Rates",
        activeUrl: MainPages.editRates.path,
        Icon: <FontAwesomeIcon size={"1x"} icon={solid("percent")} />,
        to: MainPages.editRates.path,
      });
    }
  }, [isRateAdmin]);

  const handleDrawerClose = () => {
    dispatch(uiActions.setMenuOpen(false));
  };
  const itemButtonClick = (to, label) => {
    if (isMobile) {
      handleDrawerClose();
    }
    navigate(to);
    hotjar.event(`Link Main Navigation ${label}`);
  };
  return isAuthenticated ? (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Box>
          <List>
            {menuItems.map(({ label, Icon, to, activeUrl }) => {
              const toUrl = authState.isLender ? to : MainPages.join.path;
              return (
                <ListItemButton
                  onClick={() => itemButtonClick(toUrl, label)}
                  key={label}
                  sx={{ pl: 0, mb: 2 }}
                >
                  <ListItemIcon
                    sx={{
                      color: location.pathname.includes(activeUrl)
                        ? "secondary.contrastText"
                        : "",
                      padding: "0px 21px",
                    }}
                  >
                    <IconWrapper
                      sx={{
                        backgroundColor: location.pathname.includes(activeUrl)
                          ? "secondary.main"
                          : "",
                      }}
                    >
                      {Icon}
                    </IconWrapper>
                  </ListItemIcon>
                  <ListItemText
                    primary={label}
                    sx={{
                      color: location.pathname.includes(activeUrl)
                        ? "#1F9F8A"
                        : "text.primary",
                    }}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Box>
        <Box>
          <List>
            <ProfileMenu />
          </List>
        </Box>
      </Box>
    </>
  ) : null;
}
