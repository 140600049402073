import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { TextField } from "formik-mui";
import { Field, useFormikContext } from "formik";
import { FieldWrapper } from "../styles";
import { DropdownFormInput } from "../../inputs/DropdownFormInput";
import { AppState, useSelector } from "../../../state";
//import { QuoteFormDollarFormatter } from "./Formatters/QuoteFormDollarFormatter";
import { QuoteFormPercentFormatter } from "./Formatters/QuoteFormPercentFormatter";
import { dealSelector } from "../../../state";
import { shallowEqual } from "react-redux";
import { makeStyles } from "@mui/styles";

export const LoanSizingComponent = (props) => {
  const regex = /[^0-9]/g;
  const { setFieldValue, values, handleChange }: any = useFormikContext();
  const staticQuotedAmount =
    values.quoted_amount !== undefined &&
    values.quoted_amount !== null &&
    values.quoted_amount !== "" &&
    (values.quoted_amount !== 0 || values.quoted_amount !== "0")
      ? values.quoted_amount
      : values.loan_amount;

  var formatQuotedAmount = (value) => {
    value = String(value).replaceAll(regex, "");
    const newr = new Intl.NumberFormat().format(+value);
    setQuotedAmount(newr);
    setFieldValue("quoted_amount", value);
  };

  const activeDeal = useSelector(dealSelector.activeDeal, shallowEqual);
  const [isProjValAtStabDisabled, setIsProjValAtStabDisabled] = useState(
    !(
      values.ltv_stabilized > 1 &&
      values.ltv_stabilized <= 100 &&
      values.ltv_stabilized !== ""
    ),
  );

  const [quotedAmount, setQuotedAmount] = useState(
    String(new Intl.NumberFormat().format(staticQuotedAmount)),
  );

  const { minDscrList } = useSelector((state: AppState) => state.lists);
  const [isEstimatedValueDisabled, setIsEstimatedValueDisabled] =
    useState(true);
  const [isTotalCostDisabled, setIsTotalCostDisabled] = useState(true);
  const [projVal, setProjVal] = useState(
    formatCurrency(values.projected_value),
  );

  const [projValAtStab, setProjValAtStab] = useState(values.projected_value);
  const [ltvAtStab, setLtvAtStab] = useState(values.ltv_stabilized);
  const [minDy, setMinDy] = useState(values.min_dy_stabilized);
  const [minDyGoingIn, setMinDyGoingIn] = useState(values.dy_in);
  const [ltv, setLTV] = useState(values.ltv);
  const [ltc, setLTC] = useState(values.ltc);

  const initialValues = useSelector(
    dealSelector.quoteDealInitialState,
    shallowEqual,
  );

  useEffect(() => {
    if (initialValues.term_months !== 0 && initialValues.term_months !== "") {
      props.setIsTermYears(false);
      setFieldValue("isTermYears", false);
      setFieldValue("term", "");
    } else {
      props.setIsTermYears(true);
      setFieldValue("isTermYears", true);
      setFieldValue("term_months", "");
    }
    if (initialValues.io_months) {
      props.setIsIOYears(false);
    } else {
      props.setIsIOYears(true);
    }
    if (initialValues.option_months === "") {
      props.setIsExtYears(true);
      setFieldValue("isExtYears", true);
    } else {
      props.setIsExtYears(false);
      setFieldValue("isExtYears", false);
    }
    setFieldValue("option_months", initialValues.option_months || "");
    setFieldValue("option", initialValues.option || "");
    setLTV(initialValues.ltv || "");
    setLTC(initialValues.ltc || "");
    setProjValAtStab(initialValues.projected_value || "");
    setLtvAtStab(initialValues.ltv_stabilized || "");
    setMinDy(initialValues.min_dy_stabilized || "");
    setMinDyGoingIn(initialValues.dy_in || "");
    setFieldValue("ltv", initialValues.ltv || "");
    setFieldValue("total_cost", initialValues.total_cost || "");
    setFieldValue("projected_value", initialValues.projected_value || "");
    setProjVal(formatCurrency(initialValues.projected_value) || "");
    formatQuotedAmount(staticQuotedAmount);
    setFieldValue("spread", initialValues.spread || "");
    setFieldValue("index_today_rate", initialValues.index_today_rate || "");
    setFieldValue("projected_value", initialValues.projected_value || "");
    setFieldValue("ltc", initialValues.ltc || "");
    setFieldValue("ltv_stabilized", initialValues.ltv_stabilized || "");
    setFieldValue(
      "min_dscr_stabilized_id",
      initialValues.min_dscr_stabilized_id || "",
    );
    setFieldValue("min_dy_stabilized", initialValues.min_dy_stabilized || "");
    setFieldValue("dy_in", initialValues.dy_in || "");
    setFieldValue("rate", initialValues.rate || "");
    setFieldValue("index_floor", initialValues.index_floor);
    setFieldValue("rate_floor", initialValues.rate_floor);
    props.setIndexFloor(initialValues.index_floor || "");
    props.setRateFloor(initialValues.rate_floor || "");
    setFieldValue("bank_fee", initialValues.bank_fee || "");
    setFieldValue("bank_fee_out", initialValues.bank_fee_out || "");
    setFieldValue("min_dscr_id", initialValues.min_dscr_id || "");
    setFieldValue("rate_type", initialValues.rate_type || "");
    setFieldValue("spread_type", initialValues.spread_type || "");
    setFieldValue("rate_type_name", initialValues.rate_type_name || "");
    setFieldValue("rate_type", initialValues.rate_type || "");
    setFieldValue("lender_profile", initialValues.lender_profile || "");
    // setFieldValue("term_months", initialValues.term_months || "");
    // setFieldValue("term", initialValues.term || "");
    setFieldValue("adjustment_spread", initialValues.adjustment_spread || "");
    setFieldValue("adjustment_index", initialValues.adjustment_index || "");
    setFieldValue("number_of_option", initialValues.number_of_option || "");
    setFieldValue("io_months", initialValues.io_months || "");
    setFieldValue("io_years", initialValues.io_years || "");
    if (initialValues.number_of_option) {
      setFieldValue("rate_type", 3);
      setFieldValue("rate_type_name", "Hybrid");
    }
    setIsEstimatedValueDisabled(true);
    setIsTotalCostDisabled(true);
    setFieldValue("extension_fee", initialValues.extension_fee || "");
    setFieldValue("recourse", initialValues.recourse || "");
    setFieldValue("prepay_type", initialValues.prepay_type || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.emptyIt]);

  function formatCurrency(value) {
    value = String(value).replaceAll(",", "");
    return +value !== 0
      ? new Intl.NumberFormat().format(Math.round(+value))
      : "";
  }

  useEffect(() => {
    setIsEstimatedValueDisabled(!values.ltv);
    setIsTotalCostDisabled(!values.ltc);
  }, [values]);

  const onQuotedAmountBlur = (newValue) => {
    if (!ltv) return; //prevent divide by zero error

    formatQuotedAmount(newValue);

    let value = Number(newValue.replaceAll(",", ""));
    const calculatedValue = value / (ltv / 100);
    setFieldValue("projected_value", Math.round(calculatedValue));
    setProjVal(formatCurrency(String(Math.round(calculatedValue))));
  };

  const onQuotedAmountConstuctBlur = (newValue) => {
    if (!ltc) return; //prevent divide by zero error

    newValue = Number(newValue.replaceAll(regex, ""));
    const calculatedValue = newValue / (ltc / 100);
    setFieldValue("total_cost", formatCurrency(Math.round(calculatedValue)));

    formatQuotedAmount(newValue);
  };

  const onProjectedValueBlur = (newValue) => {
    if (ltv !== "") {
      newValue = Number(newValue.replaceAll(regex, ""));
      formatQuotedAmount(Math.round(newValue * (ltv / 100)));
      setFieldValue("projected_value", newValue);
    }
  };

  const useStyles = makeStyles(() => ({
    input: {
      background: "#FAFBFB",
      color: "#D2D5D8",
    },
  }));
  const classes = useStyles();

  const onTotalCostBlur = (newValue) => {
    if (ltc !== "") {
      newValue = Number(newValue.replaceAll(regex, ""));
      formatQuotedAmount(Math.round(newValue * (ltc / 100)));
    }
  };

  const onLTVBlur = (newValue) => {
    let quote = values.quoted_amount;
    if (!quote) {
      if (!newValue) {
        setFieldValue("projected_value", "");
        setProjVal("");
      }
      return;
    }

    const calculatedValue = quote / (newValue / 100);
    setFieldValue("projected_value", Math.round(calculatedValue));
    setProjVal(formatCurrency(Math.round(calculatedValue)));
  };

  const onLTCBlur = (newValue) => {
    let quote = values.quoted_amount;
    if (!quote) {
      if (!newValue) {
        setFieldValue("total_cost", "");
      }
      return;
    }

    const calculatedValue = quote / (newValue / 100);
    setFieldValue("total_cost", formatCurrency(Math.round(calculatedValue)));
  };

  const labelByType = {
    permanent: {
      quotedAmount: (
        <InputLabel shrink>
          Quoted Amount
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        </InputLabel>
      ),
      estimatedValue: (
        <InputLabel shrink disabled={isEstimatedValueDisabled}>
          Estimated Value
        </InputLabel>
      ),
      ltv: (
        <InputLabel shrink>
          LTV
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        </InputLabel>
      ),
      minDSCR: (
        <>
          <DropdownFormInput
            label="Min DSCR"
            fieldName="min_dscr_id"
            list={minDscrList}
            isRequired={true}
          />
        </>
      ),
    },
    construction: {
      quotedAmount: (
        <InputLabel shrink>
          Quoted Amount
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        </InputLabel>
      ),
      totalCost: (
        <InputLabel
          shrink
          sx={isTotalCostDisabled ? { color: "#8C9196" } : { color: "#03363D" }}
        >
          Total Cost
        </InputLabel>
      ),
      ltc: (
        <InputLabel shrink>
          LTC
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        </InputLabel>
      ),
      projValAtStabilization: (
        <InputLabel shrink>Projected Value at Stabilization</InputLabel>
      ),
      maxLtvAtStabilization: (
        <InputLabel shrink>Max LTV at Stabilization</InputLabel>
      ),
      minDSCRAtStabilization: (
        <>
          <DropdownFormInput
            label="Min DSCR at Stabilization"
            fieldName="min_dscr_stabilized_id"
            list={minDscrList}
          />
        </>
      ),
      minDebtAtStabilization: (
        <InputLabel shrink>Min Debt Yield at Stabilization</InputLabel>
      ),
    },
    bridge: {
      quotedAmount: (
        <InputLabel shrink>
          Quoted Amount
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        </InputLabel>
      ),
      totalCost: (
        <InputLabel
          shrink
          sx={isTotalCostDisabled ? { color: "#8C9196" } : { color: "#03363D" }}
        >
          Total Cost
        </InputLabel>
      ),
      ltc: (
        <InputLabel shrink>
          LTC
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        </InputLabel>
      ),
      projValAtStabilization: (
        <InputLabel shrink>
          Projected Value at Stabilization{" "}
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        </InputLabel>
      ),
      maxLtvAtStabilization: (
        <InputLabel shrink>
          Max LTV at Stabilization{" "}
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        </InputLabel>
      ),
      minDSCRAtStabilization: (
        <>
          <DropdownFormInput
            label="Min DSCR at Stabilization"
            fieldName="min_dscr_stabilized_id"
            list={minDscrList}
          />
        </>
      ),
      minDebtAtStabilization: (
        <InputLabel shrink>Min Debt Yield at Stabilization</InputLabel>
      ),

      minDSCRGoingIn: (
        <DropdownFormInput
          label="Min DSCR Going In"
          fieldName="min_dscr_id"
          list={minDscrList}
        />
      ),
      minDebtGoingIn: <InputLabel shrink>Min Debt Yield Going In</InputLabel>,
    },
  };

  return (
    <>
      {activeDeal.loan_type === "Permanent Debt" ? (
        <>
          <FieldWrapper>
            <Typography
              variant="h3"
              sx={{
                fontFamily: "ABC Monument Grotesk",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "28px",
                color: "#03363D",
              }}
            >
              Loan Sizing
            </Typography>
          </FieldWrapper>
          <FieldWrapper>
            <FormControl variant="standard">
              {labelByType[props.type].quotedAmount}
              <Field
                component={TextField}
                name="quoted_amount"
                value={quotedAmount}
                inputProps={{
                  maxLength: 12,
                }}
                onBlur={(e) => {
                  onQuotedAmountBlur(e.target.value);
                }}
                onChange={(e) => {
                  //formatQuotedAmount(+e.target.value);
                  formatQuotedAmount(e.target.value);
                }}
                InputProps={{
                  //inputComponent: QuoteFormDollarFormatter,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl variant="standard">
              {/* LTV */}
              {labelByType[props.type].ltv}
              <Field
                component={TextField}
                name="ltv"
                placeholder="Enter LTV"
                value={ltv}
                inputProps={{
                  maxLength: 12,
                }}
                onBlur={() => {
                  onLTVBlur(ltv);
                  ltv
                    ? setIsEstimatedValueDisabled(false)
                    : setIsEstimatedValueDisabled(true);
                }}
                onChange={(e) => {
                  let ltvEvent = parseFloat(e.target.value);
                  if (!isNaN(ltvEvent) && ltvEvent >= 1 && ltvEvent <= 100) {
                    setLTV(ltvEvent);
                    setFieldValue("ltv", ltvEvent);
                    handleChange({
                      ...e,
                      target: { ...e.target, value: ltvEvent },
                    });
                  } else if (ltvEvent < 1 && ltvEvent > 100) {
                    setLTV(ltv);
                    setFieldValue("ltv", ltv);
                    handleChange({ ...e, target: { ...e.target, value: ltv } });
                  } else if (isNaN(ltvEvent)) {
                    setLTV("");
                    setFieldValue("ltv", "");
                    handleChange({ ...e, target: { ...e.target, value: "" } });
                  }
                }}
                InputProps={{
                  inputComponent: QuoteFormPercentFormatter,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </FieldWrapper>

          <FieldWrapper>
            <FormControl variant="standard">
              {/* Estimated Value / Projected Value at Stabilization / Max LTV at Stabilization */}
              {labelByType[props.type].estimatedValue}
              <Field
                component={TextField}
                name="projected_value"
                placeholder="Enter Estimated Value"
                disabled={isEstimatedValueDisabled}
                value={projVal}
                inputProps={{
                  maxLength: 12,
                }}
                sx={{ width: labelByType[props.type].minDSCR ? "100%" : "50%" }}
                onBlur={(e) => {
                  onProjectedValueBlur(e.target.value);
                  //handleChange(e);
                }}
                onChange={(e) => {
                  setProjVal(formatCurrency(e.target.value));
                }}
                InputProps={{
                  className: isEstimatedValueDisabled ? classes.input : false,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </FormControl>
            {props.type !== "construction" && labelByType[props.type].minDSCR}
          </FieldWrapper>
        </>
      ) : null}
      {activeDeal.loan_type === "Construction" ||
      activeDeal.loan_type === "Bridge" ? (
        <>
          <FieldWrapper>
            <Typography
              variant="h3"
              sx={{
                fontFamily: "ABC Monument Grotesk",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "28px",
                color: "#03363D",
              }}
            >
              Loan Sizing
            </Typography>
          </FieldWrapper>
          <FieldWrapper>
            <FormControl variant="standard">
              {labelByType[props.type].quotedAmount}
              <Field
                component={TextField}
                name="quoted_amount"
                value={quotedAmount}
                inputProps={{
                  maxLength: 12,
                }}
                onBlur={(e) => {
                  onQuotedAmountConstuctBlur(e.target.value);
                  if (
                    !isNaN(+values.quoted_amount) &&
                    +values.quoted_amount > 0 &&
                    !isNaN(+values.ltv_stabilized) &&
                    +values.ltv_stabilized > 0
                  ) {
                    setProjValAtStab(
                      formatCurrency(
                        values.quoted_amount / (values.ltv_stabilized / 100),
                      ),
                    );
                    setFieldValue(
                      "projected_value",
                      formatCurrency(
                        values.quoted_amount / (values.ltv_stabilized / 100),
                      ),
                    );
                  }
                }}
                onChange={(e) => {
                  formatQuotedAmount(e.target.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl variant="standard">
              {labelByType[props.type].ltc}
              <Field
                component={TextField}
                name="ltc"
                placeholder="Enter LTC"
                value={ltc}
                inputProps={{
                  maxLength: 12,
                }}
                onBlur={() => {
                  onLTCBlur(ltc);
                  ltc
                    ? setIsTotalCostDisabled(false)
                    : setIsTotalCostDisabled(true);
                }}
                onChange={(e) => {
                  let ltcEvent = parseFloat(e.target.value);
                  if (!isNaN(ltcEvent) && ltcEvent >= 1 && ltcEvent <= 100) {
                    setLTC(ltcEvent);
                    setFieldValue("ltc", ltcEvent);
                    handleChange({
                      ...e,
                      target: { ...e.target, value: ltcEvent },
                    });
                  } else if (ltcEvent < 1 && ltcEvent > 100) {
                    setLTC(ltc);
                    setFieldValue("ltc", ltc);
                    handleChange({ ...e, target: { ...e.target, value: ltc } });
                  } else if (isNaN(ltcEvent)) {
                    setLTC("");
                    setFieldValue("ltc", "");
                    handleChange({ ...e, target: { ...e.target, value: "" } });
                  }
                }}
                InputProps={{
                  inputComponent: QuoteFormPercentFormatter,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </FieldWrapper>

          <FieldWrapper>
            <FormControl variant="standard">
              {/* Estimated Value / Projected Value at Stabilization / Max LTV at Stabilization */}
              {labelByType[props.type].totalCost}
              <Field
                component={TextField}
                placeholder="Enter Total Cost"
                disabled={isTotalCostDisabled}
                name="total_cost"
                value={values.total_cost}
                inputProps={{
                  maxLength: 12,
                }}
                sx={{ width: labelByType[props.type].minDSCR ? "100%" : "50%" }}
                onBlur={(e) => {
                  onTotalCostBlur(e.target.value);
                }}
                onChange={(e) => {
                  setFieldValue("total_cost", formatCurrency(e.target.value));
                }}
                InputProps={{
                  className: isTotalCostDisabled ? classes.input : false,
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </FieldWrapper>
          <FieldWrapper>
            <FormControl variant="standard">
              {labelByType[props.type].projValAtStabilization}
              <Field
                component={TextField}
                name="projected_value"
                value={projValAtStab}
                disabled={isProjValAtStabDisabled}
                placeholder="Enter Projected Value at Stabilization"
                inputProps={{
                  maxLength: 12,
                }}
                onBlur={(e) => {
                  let val = values.quoted_amount / values.projected_value;
                  let ltvEvent = Number(val.toFixed(2));
                  if (!isNaN(ltvEvent)) {
                    setLtvAtStab(ltvEvent);
                    setFieldValue("ltv_stabilized", ltvEvent);
                    handleChange({
                      ...e,
                      target: { ...e.target, value: ltvEvent },
                    });
                  }
                }}
                onChange={(e) => {
                  if (
                    !isNaN(Number(String(e.target.value).replaceAll(",", "")))
                  ) {
                    setProjValAtStab(formatCurrency(e.target.value));
                    setFieldValue(
                      "projected_value",
                      Number(e.target.value.replaceAll(regex, "")),
                    );
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl variant="standard">
              {labelByType[props.type].maxLtvAtStabilization}
              <Field
                component={TextField}
                name="ltv_stabilized"
                placeholder="Enter Max LTV at Stabilization"
                value={ltvAtStab}
                onBlur={() => {
                  if (
                    !isNaN(+values.ltv_stabilized) &&
                    +values.ltv_stabilized > 0
                  ) {
                    setIsProjValAtStabDisabled(false);
                    if (
                      !isNaN(+values.quoted_amount) &&
                      +values.quoted_amount > 0
                    ) {
                      setProjValAtStab(
                        formatCurrency(
                          values.quoted_amount / (values.ltv_stabilized / 100),
                        ),
                      );
                      setFieldValue(
                        "projected_value",
                        Number(
                          values.quoted_amount / (values.ltv_stabilized / 100),
                        ),
                      );
                    }
                  } else {
                    setIsProjValAtStabDisabled(true);
                  }
                }}
                onChange={(e) => {
                  let ltvEvent = parseFloat(e.target.value);
                  if (!isNaN(ltvEvent) && ltvEvent >= 1 && ltvEvent <= 100) {
                    setLtvAtStab(ltvEvent);
                    setFieldValue("ltv_stabilized", ltvEvent);
                    handleChange({
                      ...e,
                      target: { ...e.target, value: ltvEvent },
                    });
                  } else if (ltvEvent < 1 || ltvEvent > 100) {
                    setLtvAtStab(ltvAtStab);
                    setFieldValue("ltv_stabilized", ltvAtStab);
                    handleChange({
                      ...e,
                      target: { ...e.target, value: ltvAtStab },
                    });
                  } else if (isNaN(ltvEvent)) {
                    setLtvAtStab("");
                    setFieldValue("ltv_stabilized", "");
                    handleChange({ ...e, target: { ...e.target, value: "" } });
                  }
                }}
                InputProps={{
                  inputComponent: QuoteFormPercentFormatter,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </FieldWrapper>
          {activeDeal.loan_type === "Bridge" ? (
            <FieldWrapper>
              {labelByType[props.type].minDSCRGoingIn}
              <FormControl variant="standard">
                {labelByType[props.type].minDebtGoingIn}
                <Field
                  component={TextField}
                  name="dy_in"
                  placeholder="Enter Min Debt Yield Going In"
                  value={minDyGoingIn}
                  onChange={(e) => {
                    let minDebt = parseFloat(e.target.value);
                    if (!isNaN(minDebt) && minDebt >= 1 && minDebt <= 100) {
                      setMinDyGoingIn(minDebt);
                      setFieldValue("dy_in", minDebt);
                      handleChange({
                        ...e,
                        target: { ...e.target, value: minDebt },
                      });
                    } else if (minDebt < 1 || minDebt > 100) {
                      setMinDyGoingIn(minDyGoingIn);
                      setFieldValue("dy_in", minDyGoingIn);
                      handleChange({
                        ...e,
                        target: { ...e.target, value: minDyGoingIn },
                      });
                    } else if (isNaN(minDebt)) {
                      setMinDyGoingIn("");
                      setFieldValue("dy_in", "");
                      handleChange({
                        ...e,
                        target: { ...e.target, value: "" },
                      });
                    }
                  }}
                  InputProps={{
                    inputComponent: QuoteFormPercentFormatter,
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </FieldWrapper>
          ) : null}
          <FieldWrapper>
            {labelByType[props.type].minDSCRAtStabilization}
            <FormControl variant="standard">
              {labelByType[props.type].minDebtAtStabilization}
              <Field
                component={TextField}
                name="min_dy_stabilized"
                placeholder="Enter Min Debt Yield at Stabilization"
                value={minDy}
                onChange={(e) => {
                  let minDebt = parseFloat(e.target.value);
                  if (!isNaN(minDebt) && minDebt >= 1 && minDebt <= 100) {
                    setMinDy(minDebt);
                    setFieldValue("min_dy_stabilized", minDebt);
                    handleChange({
                      ...e,
                      target: { ...e.target, value: minDebt },
                    });
                  } else if (minDebt < 1 || minDebt > 100) {
                    setMinDy(minDy);
                    setFieldValue("min_dy_stabilized", minDy);
                    handleChange({
                      ...e,
                      target: { ...e.target, value: minDy },
                    });
                  } else if (isNaN(minDebt)) {
                    setMinDy("");
                    setFieldValue("min_dy_stabilized", "");
                    handleChange({ ...e, target: { ...e.target, value: "" } });
                  }
                }}
                InputProps={{
                  inputComponent: QuoteFormPercentFormatter,
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </FormControl>
          </FieldWrapper>
        </>
      ) : null}
    </>
  );
};
