import { AppThunk, lendingProfileActions } from "..";
import { authClient } from "../../lib/api";
import { apis } from "../../lib/gateways";
import { authActions, dealActions, listActions, uiActions } from "../states";
import { AppState } from "../types";
import { lender } from "./state";

const { actions } = lender;

export const lenderActions = {
  ...actions,
  getContactInfo(dealId, contactId): AppThunk {
    return async (dispatch, getState: any) => {
      const { user } = getState().auth;
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.get(
        apis.getContactInfo(user.loanTrackerId, dealId, contactId),
      );
      if (data.isResponse) {
        dispatch(lenderActions.setCurrentContactInfo(data.data));
        dispatch(uiActions.setContactOpen(true));
      } else {
        dispatch(uiActions.showError("Failed to get contact information"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  getConciergeBanksOriginators(): AppThunk {
    return async (dispatch) => {
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.get(apis.GetConciergeBanksOriginators);
      if (data.isResponse) {
        dispatch(listActions.loadConciergeBanksOriginators(data.data));
      } else {
        dispatch(listActions.loadConciergeBanksOriginators([]));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateUserPersonalInfo(values): AppThunk {
    return async (dispatch, getState: any) => {
      const { user } = getState().auth;
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.put(
        apis.updateUserPersonInfo(user.loanTrackerId),
        values,
      );
      if (data.isResponse) {
        dispatch(uiActions.showSuccess("Personal info updated successfully"));
        dispatch(authActions.loadUserUpdatedInfo(values));
        dispatch(uiActions.setEditProfileModalOpen(false));
      } else {
        dispatch(uiActions.showError("Update personal info failed"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
  updateConciergeOriginator(originatorId, bankId): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      dispatch(dealActions.setDealState(undefined));
      dispatch(lendingProfileActions.setLendingProfileState(undefined));
      const { user, userCompanyId } = getState().auth;
      dispatch(uiActions.setLoading(true));
      const { data } = await authClient.post(
        apis.UpdateConciergeOriginatorId(originatorId ?? user?.loanTrackerId),
        {},
      );
      if (data.isResponse) {
        dispatch(authActions.loadConciergeActiveBankId(bankId));
        dispatch(authActions.loadActiveConciergeOriginatorId(originatorId));
      } else {
        dispatch(uiActions.showError("Oops something went wrong"));
      }
      dispatch(uiActions.setLoading(false));
    };
  },
};
