import React from "react";
import { Field } from "formik";
import { TextField } from "formik-mui";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  Typography,
} from "@mui/material";
import { PercentFormatter } from "./PercentFormatter";

interface FormPercentInputProps {
  label: any;
  fieldName: any;
  isDisabled?: boolean;
  isRequired?: boolean;
  onChange?: Function;
  overrideStyle?: any;
  InputPropKey?: any;
  InputPropVal?: any;
  widthHalf?: any;
}
export function FormPercentInput({
  label,
  fieldName,
  isDisabled = false,
  isRequired = false,
  onChange = () => {},
  InputPropKey = false,
  InputPropVal = false,
  widthHalf = false,
}: FormPercentInputProps): any {
  return (
    <FormControl variant="standard">
      <InputLabel shrink>
        {label}
        {isRequired ? (
          <Typography
            color={"error"}
            variant={"caption"}
            component={"span"}
            style={{ fontSize: 20 }}
          >
            {" "}
            *
          </Typography>
        ) : null}
      </InputLabel>
      <Field
        placeholder={`Enter ${label}`}
        onBlur={onChange}
        component={TextField}
        variant="outlined"
        name={fieldName}
        disabled={isDisabled}
        sx={{
          color: "#03363D",
          width: widthHalf ? "48%" : "100%",
        }}
        InputProps={{
          [InputPropKey]: InputPropVal,
          inputComponent: PercentFormatter,
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </FormControl>
  );
}
