import React from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Drawer, DrawerHeader } from "./modal.styles";
import { PersonalInfoForm } from "../Admin/PersonalInfoForm";
import { uiActions, uiSelectors, useDispatch, useSelector } from "../../state";
import { hotjar } from "react-hotjar";

export const EditAccountInfoModal = () => {
  const openUserProfileModal = useSelector(uiSelectors.editProfileModalOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(uiActions.setEditProfileModalOpen(false));
    hotjar.event("Button Personal Info Modal Close");
  };
  return (
    <Box>
      <Drawer variant="permanent" open={openUserProfileModal} anchor="right">
        <DrawerHeader>
          <Typography variant="h3">Edit personal info</Typography>
          <Button color="inherit" onClick={handleClose}>
            <FontAwesomeIcon size={"lg"} icon={regular("times")} />
          </Button>
        </DrawerHeader>
        <PersonalInfoForm />
      </Drawer>
    </Box>
  );
};
