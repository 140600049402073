import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Button, Typography, Box } from "@mui/material";
import { listActions, uiActions, uiSelectors, useSelector } from "../../state";
import { FiltersForm } from "../Admin/FiltersForm";
import { Drawer, DrawerHeader } from "./modal.styles";

export const FiltersDrawer = () => {
  const dispatch = useDispatch();
  const show = useSelector(uiSelectors.showFilters);

  const handleClose = () => {
    dispatch(uiActions.setFiltersOpen(false));
  };

  useEffect(() => {
    dispatch(listActions.getFilterLists());
  }, [dispatch]);

  return (
    <Box>
      <Drawer variant="permanent" open={show} anchor="right">
        <DrawerHeader>
          <Typography variant="h3">Filters</Typography>
          <Button color="inherit" onClick={handleClose}>
            <FontAwesomeIcon size={"lg"} icon={regular("times")} />
          </Button>
        </DrawerHeader>
        <FiltersForm />
      </Drawer>
    </Box>
  );
};
