import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: UIState = {
  addNewLP: false,
  addTab: false,
  contactOpen: false,
  editProfileModalOpen: false,
  editTab: false,
  filtersOpen: false,
  isTableView: false,
  lendingProfileOpen: false,
  loading: false,
  menuOpen: false,
  notification: undefined,
  quotesOpen: false,
  flyoutOpen: false,
  bulkEdit: false,
  bulkEditColumn: null,
  bulkEditRow: null,
  bulkEditAll: null,
  removeOriginatorPopupOpen: false,
  view: {},
  currentOpportunitiesTab: "1",
  currentLendingTab: null,
  deleteCellOpen: false,
};

export const ui = {
  ...createSlice({
    name: "ui",
    initialState,
    reducers: {
      hideNotification(state) {
        state.notification = undefined;
      },
      setLoading(state, { payload }: PayloadAction<boolean>) {
        state.loading = payload;
      },
      setView(state, { payload }: PayloadAction<Partial<UIViewState>>) {
        state.view = {
          ...state.view,
          ...payload,
        };
      },
      setCurrentTab(state, { payload }: PayloadAction<string>) {
        state.currentOpportunitiesTab = payload;
      },
      setCurrentLendingTab(state, { payload }: PayloadAction<any>) {
        state.currentLendingTab = payload;
      },
      showNotification(state, { payload }: PayloadAction<UINotification>) {
        if (!payload.duration) {
          payload.duration = 4000;
        }
        state.notification = payload;
      },
      setMenuOpen(state, { payload }: PayloadAction<boolean>) {
        state.menuOpen = payload;
      },
      setFiltersOpen(state, { payload }: PayloadAction<boolean>) {
        state.filtersOpen = payload;
      },
      setQuotesOpen(state, { payload }: PayloadAction<boolean>) {
        state.quotesOpen = payload;
      },
      setContactOpen(state, { payload }: PayloadAction<boolean>) {
        state.contactOpen = payload;
      },

      setLendingProfileOpen(state, { payload }: PayloadAction<boolean>) {
        state.lendingProfileOpen = payload;
      },
      setisTableView(state, { payload }: PayloadAction<boolean>) {
        state.isTableView = payload;
      },
      setAddTab(state, { payload }: PayloadAction<boolean>) {
        state.addTab = payload;
      },
      setEditTab(state, { payload }: PayloadAction<boolean>) {
        state.editTab = payload;
      },
      addNewLP(state, { payload }: PayloadAction<boolean>) {
        state.addNewLP = payload;
      },
      refreshOpenModalState(state, { payload }: PayloadAction<boolean>) {
        state.addNewLP = payload;
        state.editTab = payload;
        state.addTab = payload;
        state.lendingProfileOpen = payload;
      },
      setEditProfileModalOpen(state, { payload }: PayloadAction<boolean>) {
        state.editProfileModalOpen = payload;
      },
      setFlyoutOpen(state, { payload }: PayloadAction<boolean>) {
        state.flyoutOpen = payload;
      },
      setBulkEdit(state, { payload }: PayloadAction<boolean>) {
        state.bulkEdit = payload;
      },
      setBulkEditColumn(state, { payload }: PayloadAction<string | null>) {
        state.bulkEditColumn = payload;
      },
      setBulkEditRow(state, { payload }: PayloadAction<string | null>) {
        state.bulkEditRow = payload;
      },
      setBulkEditAll(state, { payload }: PayloadAction<string | null>) {
        state.bulkEditAll = payload;
      },
      setRemoveOriginatorPopupOpen(state, { payload }: PayloadAction<boolean>) {
        state.removeOriginatorPopupOpen = payload;
      },
      setDeleteCellOpen(state, { payload }: PayloadAction<boolean>) {
        state.deleteCellOpen = payload;
      },
    },
  }),
  persist: false,
};

export interface UINotification {
  message: string;
  variant?: "error" | "warning" | "info" | "success";
  duration?: number;
}

export interface UIState {
  addNewLP: boolean;
  addTab: boolean;
  contactOpen: boolean;
  editProfileModalOpen: boolean;
  editTab: boolean;
  filtersOpen: boolean;
  isTableView: boolean;
  lendingProfileOpen: boolean;
  loading: boolean;
  menuOpen: boolean;
  notification?: UINotification;
  quotesOpen: boolean;
  flyoutOpen: boolean;
  bulkEdit: boolean;
  bulkEditColumn: string | null;
  bulkEditRow: string | null;
  bulkEditAll: string | null;
  view: UIViewState;
  currentOpportunitiesTab: string;
  currentLendingTab: any;
  removeOriginatorPopupOpen: boolean;
  deleteCellOpen: boolean;
}

export type UIViewType = boolean | string | Record<string, any> | undefined;

export interface UIViewState {
  // vehicleSpecsDialog?: UIViewType;
}
