import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { matchSorter } from "match-sorter";
import { GridRenderCellParams, GridRowSpacingParams } from "@mui/x-data-grid";
import { Button, Grid, InputAdornment, Tooltip } from "@mui/material";
import { Navigation } from "../../lib";
import MainPages from "../../pages/main";
import { uiActions, dealSelector, dealActions } from "../../state";
import {
  AddressStyle,
  MultiLineWrapper,
  AddressTableCell,
  TableWrapper,
  StyledDataGrid,
} from "./CustomizedTable.styles";
import { NumberFormatDisplay } from "../inputs/Formatters";
import EmptyState from "./EmptyState";
import FilterButton from "./FilterButton";
import { SearchBox } from "./styles";
import { hotjar } from "react-hotjar";
import { PopupRemoveDealFromActive } from "../modals/PopupRemoveActiveDealModal";

export default function MyOpportunitiesGrid() {
  const dispatch = useDispatch();
  const user_deals = useSelector(dealSelector.filteredDealsArray);
  const [userDealsRows, setUserDealsRows] = useState<typeof user_deals>([]);
  const [showSearchEmptyText, setShowSearchEmptyText] = useState("");
  const [isPopupRemoveDeal, setPopupRemoveDeal] = useState(false);
  const [dealId, setDealId] = useState(0);

  const getRowSpacing = React.useCallback((params: GridRowSpacingParams) => {
    return {
      top: 15,
    };
  }, []);

  const handleBtnClick = (event: React.MouseEvent<unknown>, rowData: any) => {
    hotjar.event("Quote Button Clicked");
    dispatch(dealActions.loadActiveDealId(rowData.id));
    event.stopPropagation();
    if (!rowData.is_quoted) {
      dispatch(uiActions.setQuotesOpen(true));
    }
    Navigation.go(MainPages.opportunityDetails.path.replace(":id", rowData.id));
  };
  const handleRemoveDeal = async () => {
    setPopupRemoveDeal(false);
    let passedDeal = { quote_status: "3", quote_id: dealId, is_rejected: "1" };
    await dispatch(dealActions.removeDealFromOpportunities(passedDeal));
    dispatch(dealActions.getUserDeals());
  };

  const handleRowClick = (event: any) => {
    dispatch(dealActions.loadActiveDealId(Number(event.id)));
    Navigation.go(MainPages.opportunityDetails.path.replace(":id", event.id));
  };

  useEffect(() => {
    setUserDealsRows(user_deals);
  }, [user_deals]);

  const handleSearch = (e) => {
    const _userDealsRows = matchSorter(user_deals, e.target.value, {
      keys: [
        "portfolio_name",
        "address.street_number",
        "address.street_name",
        "address.city",
        "address.state",
        "address.zip",
        "client.first_name",
        "client.last_name",
        "property_type",
        "loan_amount",
        "loan_type",
        "deal_type",
        "term",
        "amortization",
        "transaction_type",
        "rate_type",
        { threshold: matchSorter.rankings.CONTAINS, key: "date_sent" },
      ],
    });
    setUserDealsRows(_userDealsRows);
    if (_userDealsRows.length <= 0) {
      setShowSearchEmptyText(e.target.value);
    } else {
      setShowSearchEmptyText("");
    }
  };

  const styledDataGridColumns = [
    {
      field: "address",
      headerName: "Address | Client",
      headerClassName: "header",
      minWidth: 250,
      flex: 2,
      renderCell: (params: GridRenderCellParams<any>) => (
        <AddressTableCell>
          {/* <Avatar sx={{ width: 30, height: 30 }}>H</Avatar> */}
          <MultiLineWrapper>
            <AddressStyle>
              {params.row.is_portfolio ? (
                <Tooltip title="Portfolio">
                  <span>
                    {params.row.portfolio_name}{" "}
                    <FontAwesomeIcon
                      color="#6FC0D5"
                      size={"1x"}
                      style={{ marginLeft: 8 }}
                      icon={regular("suitcase")}
                    />
                  </span>
                </Tooltip>
              ) : (
                <Tooltip
                  title={
                    params.row.address?.street_number +
                    " " +
                    params.row.address?.street_name +
                    ", " +
                    params.row.address?.city +
                    " " +
                    params.row.address?.state +
                    " " +
                    params.row.address?.zip
                  }
                >
                  <span>
                    {params.row.address?.street_number +
                      " " +
                      params.row.address?.street_name +
                      ", " +
                      params.row.address?.city +
                      " " +
                      params.row.address?.state +
                      " " +
                      params.row.address?.zip}
                  </span>
                </Tooltip>
              )}
            </AddressStyle>
            {params.row.transaction_type === "GPARENCY" && (
              <>
                {params.row.client?.first_name} {params.row.client?.last_name}
              </>
            )}
          </MultiLineWrapper>
        </AddressTableCell>
      ),
    },
    {
      field: "property_type",
      headerName: "Property type",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "loan_amount",
      headerName: "Loan Amount",
      headerClassName: "header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <NumberFormatDisplay value={params.row.loan_amount} />
      ) /*{
        if(!params.row.is_quoted){
          return (<NumberFormatDisplay value={params.row.borrower_loan_amount} />);
        }
        return (<NumberFormatDisplay value={ params.row.loan_amount } />);
      }*/,
    },
    {
      field: "loan_type",
      headerName: "Loan type",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "deal_type",
      headerName: "Deal type",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "term",
      headerName: "Term",
      headerClassName: "header",
      renderCell: (params: GridRenderCellParams<any>) => (
        <span>{params.row.term || params.row.term_months}</span>
      ),
      flex: 1,
    },
    {
      field: "amortization",
      headerName: "Amortization",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "transaction_type",
      headerName: "Transaction type",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "rate_type_name",
      headerName: "Rate type",
      headerClassName: "header",
      flex: 1,
    },
    {
      field: "date_sent",
      headerName: "Sent to me",
      headerClassName: "header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <>
          {params.row.date_sent !== "0000-00-00 00:00:00" &&
          params.row.date_sent
            ? format(new Date(params.row.date_sent), "MM/dd/yyyy")
            : ""}
        </>
      ),
    },
    {
      field: "quote",
      headerName: "",
      headerClassName: "header",
      flex: 1,
      renderCell: (params: GridRenderCellParams<any>) => (
        <Button
          sx={{
            height: 40,
            width: 95,
            backgroundColor: "primary.light",
            color: "#000000",
            "&:hover": {
              backgroundColor: "primary.light",
              color: "primary.main",
            },
          }}
          onClick={(e) => handleBtnClick(e, params.row)}
          variant="contained"
        >
          <span style={{ maxWidth: "95px", textAlign: "center" }}>
            {params.row.deal_display_status === "Borrower Review" ? (
              <>
                <div style={{ lineHeight: "1.1" }}>Borrower</div>
                <div style={{ lineHeight: "1.1" }}>Review</div>
              </>
            ) : (
              params.row.deal_display_status
            )}
          </span>
        </Button>
      ),
    },
    {
      field: "remove-btn",
      headerName: "",
      headerClassName: "remove-btn",
      renderCell: (params: GridRenderCellParams<any>) => {
        if (!params.row.is_quoted) {
          return (
            <Button
              sx={{
                height: 40,
                width: 95,
                backgroundColor: "transparent",
                color: "#000000",
                "&:hover": {
                  backgroundColor: "transparent",
                  color: "#03363D",
                },
              }}
              onClick={(e) => {
                hotjar.event(
                  "Trash Button Clicked To Remove Deal From Active Quotes",
                );
                setPopupRemoveDeal(true);
                setDealId(params.row.id);
                e.stopPropagation();
              }}
              variant="contained"
            >
              <FontAwesomeIcon
                icon={solid("trash-can")}
                width={14}
                height={16}
              />
            </Button>
          );
        }

        return (
          <Button
            sx={{
              height: 40,
              width: 95,
              backgroundColor: "transparent",
              color: "#000000",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#A2ACBA",
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            variant="contained"
          >
            <FontAwesomeIcon
              color={"rgba(162, 172, 186, 1)"}
              icon={solid("trash-can")}
              width={14}
              height={16}
            />
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <SearchBox
          placeholder="Search deals"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon color="#B1BAC7" icon={regular("search")} />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />

        <FilterButton />
      </Grid>

      <TableWrapper sx={{ mt: 1 }}>
        {showSearchEmptyText ? (
          <EmptyState title={`No results found for "${showSearchEmptyText}"`} />
        ) : (
          <StyledDataGrid
            autoHeight={true}
            sx={{
              "& .MuiDataGrid-row": {
                cursor: "pointer",
              },
            }}
            disableColumnMenu={true}
            rowsPerPageOptions={[5, 10, 20]}
            onRowClick={handleRowClick}
            initialState={{
              pagination: {
                pageSize: 10,
              },
            }}
            getRowSpacing={getRowSpacing}
            rowHeight={90}
            pagination={true}
            rows={userDealsRows}
            columns={styledDataGridColumns}
          />
        )}
      </TableWrapper>
      {isPopupRemoveDeal && (
        <PopupRemoveDealFromActive
          closePopupCallback={setPopupRemoveDeal}
          removeDealCallback={handleRemoveDeal}
        />
      )}
    </>
  );
}
