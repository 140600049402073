import { useMemo } from 'react';
import jwtEncode from "jwt-encode";
import { REACT_APP_CRM_CTA_URL } from "../../config";

/**
 * `useMemoUserUnsecuredJwtCTA` is a custom hook to generate a JWT token and CTA object for the user.
 * @param {Object} user - the user object in state.auth.user.
 * @param {Array} keysToExclude - list of keys to exclude from the user object
 * @param {String} referrer - the referrer string to be added to the CTA object
 * @returns {Array} - An array containing the CTA object
 *
 * The hook first filters out unwanted keys from the user object using the keysToExclude array.
 * Then it generates an unsecured JWT token with the filtered user object as the payload.
 * The token includes issuer, audience, issued at, and expiration time values.
 * It then uses the unsecured JWT token to create the CTA object, which includes the referrer, token, and url.
 * Finally, it returns the CTA object in an array.
 */
export function useMemoUserUnsecuredJwtCTA(user, keysToExclude, referrer) {
  const userToken = useMemo(() => {
    return Object.keys(user).reduce((acc, key) => {
      if (!keysToExclude.includes(key) && key !== "loanTrackerId") {
        acc[key] = user[key];
      }
      if(key === "loanTrackerId"){
        acc["userId"] = user["loanTrackerId"];
      }
      return acc;
    }, {});
  }, [user,keysToExclude]);

  const unsecuredJwt = useMemo(() => {
    const currentTime = Math.floor(Date.now() / 1000);
    const expirationTime = currentTime + (2 * 60 * 60); // 2 hours from now
    const payload = {
      ...userToken,
      referrer: referrer,
      iss: 'lender-portal',
      aud: 'cta-app',
      iat: currentTime,
      exp: expirationTime
    };
    return jwtEncode(payload, '');
  }, [referrer, userToken]);

  const CTA = useMemo(() => {
    return {
      url: REACT_APP_CRM_CTA_URL,
      token: unsecuredJwt,
    };
  }, [unsecuredJwt]);

  return [CTA];
}