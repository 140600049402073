import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import { refeshTokenHelper } from "./utils";

/** Client for making authenticated API calls. */
export const authClient = {
  async delete(url: string, config?: AxiosRequestConfig) {
    await refeshTokenHelper.refesh();
    return handleAuthResponse(
      apiClient.delete(url, refeshTokenHelper.config(config)),
    );
  },
  async download(url: string, fileName: string, config?: AxiosRequestConfig) {
    await refeshTokenHelper.refesh();
    return handleAuthResponse(
      apiClient
        .get(`${url}?getSignedUrl=1`, {
          method: "GET",
          responseType: "json",
          ...refeshTokenHelper.config(config),
        })
        .then((response) => {
          const url = response.data.data.url;
          window.open(url);
          return response;
        }),
    );
  },
  async get<T = any>(url: string, config?: AxiosRequestConfig) {
    await refeshTokenHelper.refesh();
    return handleAuthResponse(
      apiClient.get<T>(url, refeshTokenHelper.config(config)),
    );
  },
  async post<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    await refeshTokenHelper.refesh();
    return handleAuthResponse(
      apiClient.post<T>(url, data, refeshTokenHelper.config(config)),
    );
  },
  async put<T = any>(url: string, data: any, config?: AxiosRequestConfig) {
    await refeshTokenHelper.refesh();
    return handleAuthResponse(
      apiClient.put<T>(url, data, refeshTokenHelper.config(config)),
    );
  },
};

/** Connection used to make authorized, authenticated API calls. */
export let apiClient: AxiosInstance;

export function createApiClient(token: undefined | string = undefined) {
  const config: AxiosRequestConfig = {
    headers: {},
  };
  if (!config.headers) {
    throw new Error("Invalid Api Config");
  }
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers["Content-Type"] = "application/json";
  // config.headers["app-name"] = "lender";
  apiClient = axios.create(config);
}

createApiClient();
/**
 * @param promise
 */
async function handleAuthResponse<T = any>(
  promise: Promise<AxiosResponse<T>>,
): Promise<AxiosResponse<T>> {
  let error: AxiosError<T> | undefined;
  promise = promise.catch((err) => {
    return err.response;
  });
  const res = await promise;
  if (error) {
    console.error({ error, res });
  }
  // this should be handled in the api
  // if (res.status === 401) {
  //   if (res.config.url && res.config.url.includes("/me")) {
  //     window.location.href = "https://gparency.com";
  //   } else {
  //     window.location.href = "/logged-out";
  //   }
  // }
  // if (res.status === 403 && res.config.url && res.config.url.includes("/me")) {
  //   window.location.href = "/logged-out";
  // }

  if (res?.status && res.status === 200 && res.data) {
    res.data["isResponse"] = true;
  }
  return res;
}
