/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import {
  GridColumns,
  GridRenderCellParams,
  GridRowParams,
} from "@mui/x-data-grid";
import {
  uiActions,
  uiSelectors,
  lendingProfileActions,
  lendingProfileSelector,
  AppState,
} from "../../state";
import { StyledChartTable } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  TableCellCTA,
  TableColumnHeader,
  TableColumnHeaderText,
  TableRowFirstColumn,
} from "../../pages/main/styles";
import { hotjar } from "react-hotjar";
import { PartEmptyKey } from "../defaultProfiles/PartEmptyKey";
import { PartDefaultLendingCriteria } from "../defaultProfiles/PartDefaultLendingCriteria";
import { PartCustomLendingCriteria } from "../defaultProfiles/PartCustomLendingCriteria";
import { PartDefaultLendingTeam } from "../defaultProfiles/PartDefaultLendingTeam";
import { PartCustomLendingTeam } from "../defaultProfiles/PartCustomLendingTeam";
import { PartNoLendingTeam } from "../defaultProfiles/PartNoLendingTeam";

export default function LenderProfileCharts() {
  const dispatch = useDispatch();
  const { executionList } = useSelector((state: AppState) => state.lists);

  const activeTab = useSelector(lendingProfileSelector.activeTab);
  const addTab = useSelector(uiSelectors.addTab);
  const editTab = useSelector(uiSelectors.editTab);
  const bulkEditColumn = useSelector(uiSelectors.bulkEditColumn);
  const bulkEditRow = useSelector(uiSelectors.bulkEditRow);
  const bulkEditAll = useSelector(uiSelectors.bulkEditAll);
  const flyoutOpen = useSelector(uiSelectors.flyoutOpen);
  const lendingProfiles = useSelector(
    lendingProfileSelector.getProfilesByDefaultId(activeTab.default_lending_id),
  );

  const distinctProfiles = {};
  lendingProfiles.forEach((profile) => {
    distinctProfiles[`${profile.lender_type},${profile.loan_type_id}`] =
      profile;
  });
  const distinctProfilesArray = Object.values(distinctProfiles);

  const [activeCell, setActiveCell] = useState({
    id: -1,
    loanType: "Construction",
  });

  const handleOpenNewLPProfile = useCallback(
    (data, execution, loanType, loan_type_id) => {
      dispatch(lendingProfileActions.loadActiveLendingProfileId(null));
      dispatch(uiActions.addNewLP(true));
      const execution_type_id = executionList.find(
        (item) => item.value === execution,
      ).id;
      dispatch(
        lendingProfileActions.loadAddLendingProfileDefaults({
          lender_program_value: execution,
          loan_type_value: loanType,
          property_types: activeTab,
          form_profile_data: data,
          loan_type_id,
          execution_type_id,
        }),
      );
    },
    [activeTab, dispatch],
  );

  useEffect(() => {
    if (!flyoutOpen)
      setActiveCell({
        id: -1,
        loanType: "Construction",
      });
  }, [flyoutOpen]);

  const preFillForm = useCallback(
    (data, execution, loan_type, loan_type_id) => {
      setTimeout(() => {
        if (addTab) {
          dispatch(uiActions.setAddTab(false));
        }
        if (editTab) {
          dispatch(uiActions.setEditTab(false));
        }
        handleOpenNewLPProfile(data, execution, loan_type, loan_type_id);
      }, 250);
    },
    [addTab, dispatch, editTab, handleOpenNewLPProfile],
  );

  const handleCellClick = (
    data,
    execution,
    loan_type: "Bridge" | "Permanent Debt" | "Construction",
    loan_type_id,
  ) => {
    setActiveCell({
      id: -1,
      loanType: loan_type,
    });
    preFillForm(data, execution, loan_type, loan_type_id);
  };

  const isActiveCell = useCallback(
    (rowId, loanType: "Bridge" | "Permanent Debt" | "Construction") => {
      return (
        activeCell?.id === rowId &&
        activeCell?.loanType === loanType &&
        !bulkEditAll
      );
    },
    [activeCell?.id, activeCell?.loanType, bulkEditAll],
  );

  // const handleHeaderClick = (obj, e) => {
  //   if (e.target.className.includes("table-cell-cta")) {
  //     handleCellClick(
  //       null,
  //       activeTab,
  //       obj.field === "execution" ? obj.value : obj.field,
  //     );
  //     dispatch(
  //       uiActions.setFlyoutOpen(bulkEditColumn === obj.field ? false : true),
  //     );
  //     dispatch(uiActions.setAddTab(false));
  //     dispatch(uiActions.setEditTab(false));
  //     dispatch(uiActions.setLendingProfileOpen(false));
  //     dispatch(uiActions.setBulkEditAll(null));
  //     dispatch(
  //       uiActions.setBulkEdit(bulkEditColumn === obj.field ? false : true),
  //     );
  //   }
  // };

  const columns: GridColumns = [
    {
      field: "execution",
      renderHeader: () => (
        <TableColumnHeader>
          <TableColumnHeaderText>EXECUTION</TableColumnHeaderText>
        </TableColumnHeader>
      ),
      flex: 1,
      sortable: false,
      cellClassName: (params) => {
        return params.value === bulkEditRow ? "row-bulk-edit" : "";
      },
      renderCell: (params: GridRenderCellParams<any>) => {
        return <TableRowFirstColumn>{params.value}</TableRowFirstColumn>;
      },
    },
    {
      field: "construction",
      headerName: "Construction",
      renderHeader: () => (
        <TableColumnHeader>
          <TableColumnHeaderText>CONSTRUCTION</TableColumnHeaderText>
        </TableColumnHeader>
      ),
      headerClassName: `${
        bulkEditColumn === "construction" ? "column-bulk-selected" : ""
      }`,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            color="secondary"
            className={`${
              bulkEditColumn === "construction" ? "column-bulk-selected" : ""
            } ${
              isActiveCell(params.row?.id, "Construction") &&
              !addTab &&
              !editTab &&
              !bulkEditRow
                ? "active"
                : ""
            }`}
            sx={{
              justifyContent: "flex-start",
              height: "100%",
              width: "100%",
              borderRadius: 0,
              paddingLeft: "10px",
              cursor: "context-menu",
              "&.MuiButton-textSecondary": {
                color: "text.primary",
              },
              fontSize: 14,
              span: {
                "& + svg": {
                  marginTop: "-4px !important",
                },
              },
              ".part-no-lending-team, .part-empty-key, .part-default-lending-team, .part-custom-lending-team, .part-default-lending-criteria, .part-custom-lending-criteria":
                {
                  marginRight: "4px",
                  "&:hover": {
                    div: {
                      opacity: "1",
                      visibility: "visible",
                    },
                  },
                },
              "&:hover": {
                backgroundColor: "#e0e0e0",
                svg: {
                  opacity: "1 !important",
                },
                ".table-cell-cta": {
                  opacity: "1",
                  pointerEvents: "all",
                },
              },
              ".part-default-lending-team": {
                backgroundColor: "#CEECE8",
                svg: {
                  color: "#22AA94 !important",
                  cursor: "initial !important",
                },
              },
              ".part-custom-lending-team": {
                backgroundColor: "#DDE4F4",
                svg: {
                  color: "#3366CC !important",
                  cursor: "initial !important",
                },
              },
              ".table-cell-editing": {
                opacity: "0",
                pointerEvents: "none",
                fontWeight: "500",
                fontSize: "14px",
                marginLeft: "auto",
                transition: "unset",
                color: "#fff",
              },
              "&.active": {
                backgroundColor: "#22AA94",
                ".table-cell-cta": {
                  display: "none",
                },
                ".table-cell-editing": {
                  opacity: "1",
                },
              },
            }}
            fullWidth
            onClick={(e: any) => {
              if (e.target.className.includes("table-cell-cta")) {
                handleCellClick(
                  params.row.construction ? params.row.construction : null,
                  params.row.execution,
                  "Construction",
                  6,
                );
                dispatch(uiActions.setBulkEdit(false));
                dispatch(uiActions.setBulkEditColumn(null));
                dispatch(uiActions.setBulkEditRow(null));
                dispatch(uiActions.setBulkEditAll(null));
                dispatch(uiActions.setFlyoutOpen(true));
                setActiveCell({
                  id: params.row.id,
                  loanType: "Construction",
                });
                hotjar.event(`Button Single Cell Edit Flyout Open`);
              }
            }}
          >
            {params.row.construction?.lend_param_id ? (
              <>
                {params.row.construction?.is_default_criteria == 1 ? (
                  <PartDefaultLendingCriteria />
                ) : (
                  <PartCustomLendingCriteria />
                )}
                {params.row.construction?.originators == null ? (
                  <PartNoLendingTeam />
                ) : params.row.construction?.is_default_originators == 1 ? (
                  <PartDefaultLendingTeam />
                ) : (
                  <PartCustomLendingTeam />
                )}
              </>
            ) : (
              <PartEmptyKey />
            )}

            <TableCellCTA
              className="table-cell-cta"
              onClick={() =>
                hotjar.event(
                  `Lending Profile Cell Edit Form Open + ${
                    params.row.execution
                  } + Construction - ${new Date(Date.now())
                    .toUTCString()
                    .replace(",", "")}`,
                )
              }
            >
              Edit
              <FontAwesomeIcon icon={solid("edit")} />
            </TableCellCTA>

            <span className="table-cell-editing">Editing</span>
          </Button>
        );
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "bridge",
      headerName: "Bridge",
      renderHeader: () => (
        <TableColumnHeader>
          <TableColumnHeaderText>BRIDGE</TableColumnHeaderText>
        </TableColumnHeader>
      ),
      headerClassName: `${
        bulkEditColumn === "bridge" ? "column-bulk-selected" : ""
      }`,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            color="secondary"
            className={`${
              bulkEditColumn === "bridge" ? "column-bulk-selected" : ""
            } ${params.formattedValue?.execution_type_id} ${
              isActiveCell(params.row?.id, "Bridge") &&
              !addTab &&
              !editTab &&
              !bulkEditRow
                ? "active"
                : ""
            }`}
            sx={{
              justifyContent: "flex-start",
              height: "100%",
              width: "100%",
              borderRadius: 0,
              paddingLeft: "10px",
              cursor: "context-menu",
              "&.MuiButton-textSecondary": {
                borderRadius: 0,
                color: "text.primary",
              },
              fontSize: 14,
              span: {
                "& + svg": {
                  marginTop: "-4px !important",
                },
              },
              ".part-no-lending-team, .part-empty-key, .part-default-lending-team, .part-custom-lending-team, .part-default-lending-criteria, .part-custom-lending-criteria":
                {
                  marginRight: "4px",
                  "&:hover": {
                    div: {
                      opacity: "1",
                      visibility: "visible",
                    },
                  },
                },
              "&:hover": {
                backgroundColor: "#e0e0e0",
                svg: {
                  opacity: "1 !important",
                },
                ".table-cell-cta": {
                  opacity: "1",
                  pointerEvents: "all",
                },
              },
              ".part-default-lending-team": {
                backgroundColor: "#CEECE8",
                svg: {
                  color: "#22AA94 !important",
                  cursor: "initial !important",
                },
              },
              ".part-custom-lending-team": {
                backgroundColor: "#DDE4F4",
                svg: {
                  color: "#3366CC !important",
                  cursor: "initial !important",
                },
              },
              ".table-cell-editing": {
                opacity: "0",
                pointerEvents: "none",
                fontWeight: "500",
                fontSize: "14px",
                marginLeft: "auto",
                transition: "unset",
                color: "#fff",
              },
              "&.active": {
                backgroundColor: "#22AA94",
                ".table-cell-cta": {
                  display: "none",
                },
                ".table-cell-editing": {
                  opacity: "1",
                },
              },
            }}
            fullWidth
            onClick={(e: any) => {
              if (e.target.className.includes("table-cell-cta")) {
                handleCellClick(
                  params.row.bridge ? params.row.bridge : null,
                  params.row.execution,
                  "Bridge",
                  5,
                );
                dispatch(uiActions.setBulkEdit(false));
                dispatch(uiActions.setBulkEditColumn(null));
                dispatch(uiActions.setBulkEditRow(null));
                dispatch(uiActions.setBulkEditAll(null));
                dispatch(uiActions.setFlyoutOpen(true));
                setActiveCell({
                  id: params.row.id,
                  loanType: "Bridge",
                });
                hotjar.event(`Button Single Cell Edit Flyout Open`);
              }
            }}
          >
            {params.row.bridge?.lend_param_id ? (
              <>
                {params.row.bridge?.is_default_criteria == 1 ? (
                  <PartDefaultLendingCriteria />
                ) : (
                  <PartCustomLendingCriteria />
                )}
                {params.row.bridge?.originators == null ? (
                  <PartNoLendingTeam />
                ) : params.row.bridge?.is_default_originators == 1 ? (
                  <PartDefaultLendingTeam />
                ) : (
                  <PartCustomLendingTeam />
                )}
              </>
            ) : (
              <PartEmptyKey />
            )}

            <TableCellCTA
              className="table-cell-cta"
              onClick={() =>
                hotjar.event(
                  `Lending Profile Cell Edit Form Open + ${
                    params.row.execution
                  } + Bridge - ${new Date(Date.now())
                    .toUTCString()
                    .replace(",", "")}`,
                )
              }
            >
              Edit
              <FontAwesomeIcon icon={solid("edit")} />
            </TableCellCTA>

            <span className="table-cell-editing">Editing</span>
          </Button>
        );
      },
      flex: 1,
      sortable: false,
    },
    {
      field: "permanent",
      headerName: "Permanent",
      renderHeader: () => (
        <TableColumnHeader>
          <TableColumnHeaderText>PERMANENT</TableColumnHeaderText>
        </TableColumnHeader>
      ),
      headerClassName: `${
        bulkEditColumn === "permanent" ? "column-bulk-selected" : ""
      }`,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Button
            color="secondary"
            className={`${
              bulkEditColumn === "permanent" ? "column-bulk-selected" : ""
            } ${
              isActiveCell(params.row?.id, "Permanent Debt") &&
              !addTab &&
              !editTab &&
              !bulkEditRow
                ? "active"
                : ""
            }`}
            sx={{
              width: "100%",
              height: "100%",
              justifyContent: "flex-start",
              borderRadius: 0,
              paddingLeft: "10px",
              cursor: "context-menu",
              "&.MuiButton-textSecondary": {
                color: "text.primary",
              },
              fontSize: 14,
              span: {
                "& + svg": {
                  marginTop: "-4px !important",
                },
              },
              "&:hover": {
                opacity: "1",
                backgroundColor: "#e0e0e0",
                svg: {
                  opacity: "1 !important",
                },
                ".table-cell-cta": {
                  opacity: "1",
                  pointerEvents: "all",
                },
              },
              ".part-default-lending-team": {
                backgroundColor: "#CEECE8",
                svg: {
                  color: "#22AA94 !important",
                  cursor: "initial !important",
                },
              },
              ".part-custom-lending-team": {
                backgroundColor: "#DDE4F4",
                svg: {
                  color: "#3366CC !important",
                  cursor: "initial !important",
                },
              },
              ".part-no-lending-team, .part-empty-key, .part-default-lending-team, .part-custom-lending-team, .part-default-lending-criteria, .part-custom-lending-criteria":
                {
                  marginRight: "4px",
                  "&:hover": {
                    div: {
                      opacity: "1",
                      visibility: "visible",
                    },
                  },
                },
              ".table-cell-editing": {
                opacity: "0",
                pointerEvents: "none",
                fontWeight: "500",
                fontSize: "14px",
                marginLeft: "auto",
                transition: "unset",
                color: "#fff",
              },
              "&.active": {
                backgroundColor: "#22AA94",
                ".table-cell-cta": {
                  display: "none",
                },
                ".table-cell-editing": {
                  opacity: "1",
                },
              },
            }}
            fullWidth
            onClick={(e: any) => {
              if (e.target.className.includes("table-cell-cta")) {
                handleCellClick(
                  params.row.permanent_debt ? params.row.permanent_debt : null,
                  params.row.execution,
                  "Permanent Debt",
                  1,
                );
                dispatch(uiActions.setBulkEdit(false));
                dispatch(uiActions.setBulkEditColumn(null));
                dispatch(uiActions.setBulkEditRow(null));
                dispatch(uiActions.setBulkEditAll(null));
                dispatch(uiActions.setFlyoutOpen(true));
                setActiveCell({
                  id: params.row.id,
                  loanType: "Permanent Debt",
                });
                hotjar.event(`Button Single Cell Edit Flyout Open`);
              }
            }}
          >
            {params.row.permanent_debt?.lend_param_id ? (
              <>
                {params.row.permanent_debt?.is_default_criteria == 1 ? (
                  <PartDefaultLendingCriteria />
                ) : (
                  <PartCustomLendingCriteria />
                )}
                {params.row.permanent_debt?.originators == null ? (
                  <PartNoLendingTeam />
                ) : params.row.permanent_debt?.is_default_originators == 1 ? (
                  <PartDefaultLendingTeam />
                ) : (
                  <PartCustomLendingTeam />
                )}
              </>
            ) : (
              <PartEmptyKey />
            )}

            <TableCellCTA
              className="table-cell-cta"
              onClick={() =>
                hotjar.event(
                  `Lending Profile Cell Edit Form Open + ${
                    params.row.execution
                  } + Permanent - ${new Date(Date.now())
                    .toUTCString()
                    .replace(",", "")}`,
                )
              }
            >
              Edit
              <FontAwesomeIcon icon={solid("edit")} />
            </TableCellCTA>

            <span className="table-cell-editing">Editing</span>
          </Button>
        );
      },
      flex: 1,
      sortable: false,
    },
  ];

  const data = executionList.map((executionType, index) => {
    return {
      id: index,
      execution: executionType.value,
      construction: distinctProfilesArray.find(
        (profile: any) =>
          profile.lender_type == executionType.id && profile.loan_type_id == 6,
      ),
      bridge: distinctProfilesArray.find(
        (profile: any) =>
          profile.lender_type == executionType.id && profile.loan_type_id == 5,
      ),
      permanent_debt: distinctProfilesArray.find(
        (profile: any) =>
          profile.lender_type == executionType.id && profile.loan_type_id == 1,
      ),
    };
  });

  return (
    <Box>
      <StyledChartTable
        hideFooter
        autoHeight
        rows={data}
        disableColumnMenu
        columns={columns}
        headerHeight={65}
        rowHeight={51}
        className={bulkEditAll ? "bulk-edit-all" : ""}
        isRowSelectable={(params: GridRowParams) => false}
      />
    </Box>
  );
}
