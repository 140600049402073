import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Deal } from "../deals/state";
import { defaultContact, defaultFilters } from "../types";

export interface Contact {
  name: string;
  address: string;
  email: string;
  mobile: string;
  phone: string;
  img: string | undefined;
}

export interface LenderState {
  currentContactInfo: Contact;
  filters: typeof defaultFilters;
  filtered_deals: Deal[];
  filtered_other_deals: Deal[];
}

const initialState: LenderState = {
  currentContactInfo: defaultContact,
  filters: defaultFilters,
  filtered_deals: [],
  filtered_other_deals: [],
};

export const lender = {
  ...createSlice({
    name: "lender",
    initialState,
    reducers: {
      setCurrentContactInfo(state, { payload }: PayloadAction<any>) {
        state.currentContactInfo = payload;
      },
      setDealsFilters(state, { payload }: PayloadAction<any>) {
        state.filters = payload;
      },
      setFilteredDeals(state, { payload }: PayloadAction<any>) {
        state.filtered_deals = payload;
      },
      setFilteredOtherDeals(state, { payload }: PayloadAction<any>) {
        state.filtered_other_deals = payload;
      }
    },
  }),
  persist: false,
};
