import {
  DIRECTORY_BASE_URL,
  LENDER_BASE_URL,
  LOOKUP_BASE_URL,
  REACT_APP_API_URL,
  PROFILE_BASE_URL,
} from "../config";

const baseUrls = {
  default: REACT_APP_API_URL,
  directory: DIRECTORY_BASE_URL,
  lender: LENDER_BASE_URL,
  lookup: LOOKUP_BASE_URL,
  profile: PROFILE_BASE_URL,
};

export const apis = {
  //lookup
  ExecutionTypeList: `${baseUrls.lookup}/execution-types`,
  LoanTypeList: `${baseUrls.lookup}/loan-types`,
  PrepayTypeList: `${baseUrls.lookup}/prepay-types`,
  PropertyTypeList: `${baseUrls.lookup}/property-types`,
  StateList: `${baseUrls.lookup}/states`,
  RecourseList: `${baseUrls.lookup}/recourse`,
  MaxTermList: `${baseUrls.lookup}/max-terms`,
  DealLoanTypesList: `${baseUrls.lookup}/deal-loan-types`,
  MinDscrList: `${baseUrls.lookup}/min-dscr`,
  MaxInterestOnlyList: `${baseUrls.lookup}/max-interest-only`,
  MaxAmortizationList: `${baseUrls.lookup}/max-amortization`,
  LendingTypesList: `${baseUrls.lookup}/lending-types`,
  SimpleRateSheet: `${baseUrls.lookup}/simple-rate-sheet`,
  ExecutionTypeMapping: `${baseUrls.lookup}/property-types-execution-mapping`,
  BanksBankersList: (bankId) => `${baseUrls.lookup}/banks-bankers/${bankId}`,

  // lender //deals
  GetCompetingQuotes: (dealId, loanTrackerId = undefined) =>
    `${baseUrls.lender}/lender/competing-quotes/${dealId}`,
  GetGoogleDoc: (id) => `${baseUrls.lender}/lender/get-google-doc/${id}`,
  GetDealDocuments: (id) => `${baseUrls.lender}/lender/get-documents/${id}`,
  GetMyDeals: `${baseUrls.lender}/lender/my-deals`,
  GetOtherDeals: `${baseUrls.lender}/lender/other-deals`,
  SaveQuote: (loanTrackerId, quoteId) => `${baseUrls.lender}/lender/save-quote`,
  MoveToActiveQuotes: (quoteId) =>
    `${baseUrls.lender}/lender/move-to-active-quote/${quoteId}`,

  GetConciergeBanksOriginators: `${baseUrls.lender}/lender/concierge/banks/originators`,
  UpdateConciergeOriginatorId: (id) =>
    `${baseUrls.lender}/lender/concierge/originator/${id}`,

  //lender //lending profiles
  GetLendingProfiles: () => `${baseUrls.lender}/lender/lending-profiles`,
  AddNewLendingProfile: `${baseUrls.lender}/lender/add-lending`,
  CreateLendingDefaultProfile: `${baseUrls.lender}/lender/add-lending-default`,
  UpdateLendingDefaultProfile: (defaultLendingId) =>
    `${baseUrls.lender}/lender/${defaultLendingId}/default-lending-profiles`,
  SaveLendingProfile: `${baseUrls.lender}/lender/lending-profiles`,
  DeleteSingleCell: (defaultLendingId, executionTypeId, loanTypeId) =>
    `${baseUrls.lender}/lender/${defaultLendingId}/${executionTypeId}/${loanTypeId}/lending-profiles`,
  UpdateSingleCell: (defaultLendingId, executionTypeId, loanTypeId) =>
    `${baseUrls.lender}/lender/${defaultLendingId}/${executionTypeId}/${loanTypeId}/lending-profiles`,

  UpdateBulkEditProfile: () =>
    `${baseUrls.lender}/lender/lending-profiles/bulk`,
  updateUserPersonInfo: (loanTrackerId) => `${baseUrls.lender}/lender`,
  getContactInfo: (loanTrackerId, dealId, contactId) =>
    `${baseUrls.lender}/lender/deal/${dealId}/contact-info/${contactId}`,

  EditTab: (loanTrackerId) => `${baseUrls.lender}/lender/lending-profiles-tabs`,

  //profile
  GetUserDetails: `${baseUrls.profile}/me`,
  GetUserProfile: (email) => `${baseUrls.profile}/profile?email=${email}`,
};
