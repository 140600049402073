import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDispatch } from "react-redux";
import { uiActions } from "../../state";
import {
  DateFormatDisplay,
  NumberFormatDisplay,
  PercentFormatDisplay,
} from "../inputs/Formatters";
import { GridBorder, PaperCard, QuoteGridContainer } from "./styles";
import { AppState, useSelector } from "../../state";

export default function QuotesCard({ data, canEdit = false }) {
  const { minDscrList } = useSelector((state: AppState) => state.lists);
  const {
    term,
    amortization,
    ltv,
    loan_amount,
    quoted_amount,
    is_rewarded,
    projected_value,
    bank_name,
    prepayment_penalty,
    rate,
    term_months,
    bank_fee,
    recourse_value,
    issue_date,
  } = data;
  const dispatch = useDispatch();

  const handleEditQuote = () => {
    dispatch(uiActions.setQuotesOpen(true));
  };

  const minDscrObj: any = minDscrList?.find(
    (obj: any) => obj?.id === data?.min_dscr_id,
  );

  return (
    <PaperCard>
      <QuoteGridContainer container spacing={1} columns={21}>
        <Grid item xs={18} md={2} alignSelf="center">
          <Typography variant="h5">
            {!!is_rewarded && (
              <FontAwesomeIcon
                size={"1x"}
                color="#6FC0D5"
                style={{ marginRight: 10 }}
                icon={solid("trophy-alt")}
              />
            )}{" "}
            {bank_name}
          </Typography>
        </Grid>
        <GridBorder item xs={18} md={2}>
          <Typography variant="subtitle2">Rate</Typography>
          <Typography variant="h3" sx={{ opacity: ".7" }}>
            {rate ? <PercentFormatDisplay value={rate} scale={3} /> : "---"}
          </Typography>
        </GridBorder>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Origination Fee</Typography>
          <Typography variant="body1">
            {bank_fee ? (
              <PercentFormatDisplay value={bank_fee} scale={3} />
            ) : (
              "---"
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Projected value</Typography>
          <Typography variant="body1">
            {projected_value ? (
              <NumberFormatDisplay value={projected_value} />
            ) : (
              "---"
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Quoted amount</Typography>
          <Typography variant="body1">
            {quoted_amount ? (
              <NumberFormatDisplay value={quoted_amount} />
            ) : loan_amount ? (
              <NumberFormatDisplay value={loan_amount} />
            ) : (
              "---"
            )}
          </Typography>
        </Grid>

        <Grid item xs={6} md={1}>
          <Typography variant="subtitle2">LTV</Typography>
          <Typography variant="body1">
            {ltv ? <PercentFormatDisplay value={ltv} /> : "---"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={1}>
          <Typography variant="subtitle2">Min DSCR</Typography>
          <Typography variant="body1">
            {minDscrObj ? (
              <PercentFormatDisplay value={minDscrObj.value} />
            ) : (
              "---"
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Term</Typography>
          <Typography variant="body1">
            {term || term_months || "---"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Amortization</Typography>
          <Typography variant="body1">{amortization || "N/A"}</Typography>
        </Grid>
        <Grid item xs={6} md={1}>
          <Typography variant="subtitle2">Recourse</Typography>
          <Typography variant="body1">
            {recourse_value ? recourse_value : "---"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Prepay penalty</Typography>
          <Typography variant="body1">{prepayment_penalty || "---"}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Issue date</Typography>
          <Typography variant="body1">
            {issue_date ? <DateFormatDisplay value={issue_date} /> : "---"}
          </Typography>
        </Grid>
      </QuoteGridContainer>
      {!is_rewarded && canEdit && (
        <Button onClick={handleEditQuote}>
          <FontAwesomeIcon color="#A2ACBA" icon={solid("edit")} />
        </Button>
      )}
    </PaperCard>
  );
}
