import React, { useEffect } from "react";
import { FormControl, InputLabel, Typography } from "@mui/material";
import { Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { YearsMonthsDropdown } from "../../inputs/YearsMonthsDropdown";
import { useSelector } from "react-redux";
import { dealSelector } from "../../../state";
import { QuoteFormNumberFormatter } from "./Formatters/QuoteFormNumberFormatter";

export const TermField = ({ isTermYears, setIsTermYears }) => {
  const { setFieldValue }: any = useFormikContext();
  const initialValues = useSelector(dealSelector.quoteDealInitialState);

  useEffect(() => {
    if (initialValues.term_months !== 0 && initialValues.term_months !== "") {
      setIsTermYears(false);
      setFieldValue("isTermYears", false);
      setFieldValue("term", "");
    } else {
      setIsTermYears(true);
      setFieldValue("isTermYears", true);
      setFieldValue("term_months", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleChange = (e) => {
    if (e.target.value) {
      setFieldValue("term_months", 0);
    } else {
      setFieldValue("term", "");
    }
    setIsTermYears(e.target.value);
    setFieldValue("isTermYears", e.target.value);
  };
  return (
    <FormControl variant="standard">
      <InputLabel shrink>
        Term
        <Typography
          color={"error"}
          variant={"caption"}
          component={"span"}
          style={{ fontSize: 20 }}
        >
          {" "}
          *
        </Typography>
      </InputLabel>
      <Field
        component={TextField}
        name={isTermYears ? "term" : "term_months"}
        placeholder="Enter Term"
        defaultValue=""
        sx={{ "& .MuiInputBase-root": { pr: 0 } }}
        variant="outlined"
        InputProps={{
          inputComponent: QuoteFormNumberFormatter,
          endAdornment: (
            <FormControl
              variant="standard"
              sx={{
                paddingTop: "0",
                paddingLeft: !isTermYears ? "15px" : "30px",
              }}
            >
              <YearsMonthsDropdown
                value={isTermYears}
                onChange={handleToggleChange}
              />
            </FormControl>
          ),
        }}
      />
    </FormControl>
  );
};
