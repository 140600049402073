import React, { memo } from "react";
import { Grid, Typography } from "@mui/material";
import {
  DateFormatDisplay,
  NumberFormatDisplay,
  PercentFormatDisplay,
} from "../inputs/Formatters";
import { GridBorder, PaperCard, QuoteGridContainer } from "./styles";

export default memo(function BlurQuotesCard() {


  const RandomStringGenerator = () => {
    const generateRandomString = (length) => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    };

    return generateRandomString(Math.floor(Math.random() * (10 - 1 + 1)) + 1);
  };

  const data = {
    term: Math.floor(Math.random() * (10 - 1 + 1)) + 1,
    amortization: Math.floor(Math.random() * (30 - 15 + 1)) + 15,
    ltv: Math.random().toFixed(2),
    loan_amount: Math.floor(Math.random() * (1000000 - 100000 + 1)) + 100000,
    projected_value: Math.floor(Math.random() * (500000 - 100000 + 1)) + 100000,
    bank_name: 'Bank ' + RandomStringGenerator(),
    prepayment_penalty: Math.random().toFixed(2),
    rate: Math.random().toFixed(2),
    term_months: Math.floor(Math.random() * (60 - 12 + 1)) + 12,
    bank_fee: Math.random().toFixed(2),
    recourse_value: Math.floor(Math.random() * (100000 - 10000 + 1)) + 10000,
    min_dscr: Math.random().toFixed(2),
    issue_date: new Date().toString()
  };

  return (
    <PaperCard style={{ filter: 'blur(8px)', backdropFilter: 'blur(8px)', backgroundColor: 'rgba(34, 170, 148, 0.13)', userSelect: 'none',}}>
      <QuoteGridContainer container spacing={1} columns={21}>
        <Grid item xs={18} md={2} alignSelf="center">
          <Typography variant="h5">
            {" "}
            { data.bank_name }
          </Typography>
        </Grid>
        <GridBorder item xs={18} md={2}>
          <Typography variant="subtitle2">Rate</Typography>
          <Typography variant="h3" sx={{ opacity: ".7" }}>
            {data.rate ? <PercentFormatDisplay value={data.rate} scale={3} /> : "---"}
          </Typography>
        </GridBorder>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Origination Fee</Typography>
          <Typography variant="body1">
            {data.bank_fee ? (
              <PercentFormatDisplay value={data.bank_fee} scale={3} />
            ) : (
              "---"
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Projected value</Typography>
          <Typography variant="body1">
            {data.projected_value ? (
              <NumberFormatDisplay value={data.projected_value} />
            ) : (
              "---"
            )}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Loan amount</Typography>
          <Typography variant="body1">
            {data.loan_amount ? <NumberFormatDisplay value={data.loan_amount} /> : "---"}
          </Typography>
        </Grid>

        <Grid item xs={6} md={1}>
          <Typography variant="subtitle2">LTV</Typography>
          <Typography variant="body1">
            {data.ltv ? <PercentFormatDisplay value={data.ltv} /> : "---"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={1}>
          <Typography variant="subtitle2">Min DSCR</Typography>
          <Typography variant="body1">
            {data.min_dscr ? <PercentFormatDisplay value={data.min_dscr} /> : "---"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Term</Typography>
          <Typography variant="body1">
            {data.term || data.term_months || "---"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Amortization</Typography>
          <Typography variant="body1">{data.amortization || "N/A"}</Typography>
        </Grid>
        <Grid item xs={6} md={1}>
          <Typography variant="subtitle2">Recourse</Typography>
          <Typography variant="body1">
            {data.recourse_value ? data.recourse_value : "---"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Prepay penalty</Typography>
          <Typography variant="body1">{data.prepayment_penalty || "---"}</Typography>
        </Grid>
        <Grid item xs={6} md={2}>
          <Typography variant="subtitle2">Issue date</Typography>
          <Typography variant="body1">
            {data.issue_date ? <DateFormatDisplay value={data.issue_date} /> : "---"}
          </Typography>
        </Grid>
      </QuoteGridContainer>
    </PaperCard>
  );
});