import { Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { EmptyStateContainer } from "./styles";
import { AppState, useSelector } from "../../state";

interface EmptyStateProps {
  title?: string;
  subTitle?: string;
  lendingProfile?: boolean;
  onButtonClick?: () => void;
}

export default function EmptyState({
  title = "",
  subTitle = "",
  lendingProfile = false,
  onButtonClick,
}: EmptyStateProps) {
  const companyId = useSelector((state: AppState) => state.auth.userCompanyId);

  return (
    <EmptyStateContainer>
      <FontAwesomeIcon
        style={{ padding: 20 }}
        color="#22AA94"
        size={"3x"}
        icon={solid("empty-set")}
      />
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        {title}
      </Typography>
      {subTitle && (
        <Typography
          variant="body1"
          sx={{
            opacity: 1,
            width: lendingProfile ? "450px" : "auto",
            textAlign: lendingProfile ? "center" : "initial",
          }}
        >
          {subTitle}
        </Typography>
      )}
      {lendingProfile && companyId && (
        <Button
          sx={{ width: 140, padding: 0, marginTop: "20px" }}
          variant="contained"
          onClick={onButtonClick}
        >
          Create A Profile
        </Button>
      )}
    </EmptyStateContainer>
  );
}
