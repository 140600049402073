import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import AuthPages from "../../pages/auth";
import MainPages from "../../pages/main";
import { hotjar } from "react-hotjar";

const ProfileMenuOptions = ({ handleClose }) => {
  return (
    <>
      <MenuList sx={{ maxWidth: "100%", width: 200 }}>
        <MenuItem
          key={2}
          onClick={() => {
            handleClose();
            hotjar.event(`Link Profile Menu My Account`);
          }}
          component={Link}
          to={MainPages.profile.path}
        >
          <ListItemIcon>
            <FontAwesomeIcon size={"1x"} icon={solid("user-alt")} />
          </ListItemIcon>
          <ListItemText primary="My account" />
        </MenuItem>
        <a href="/auth/logout">
          <MenuItem
            sx={{ pt: 2 }}
            onClick={() => {
              handleClose();
              hotjar.event(`Link Profile Menu Log out`);
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon size={"1x"} icon={solid("sign-out")} />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </MenuItem>
        </a>
      </MenuList>
    </>
  );
};

export default ProfileMenuOptions;
