import { auth, AuthState } from "./auth/state";
import { ui, UIState } from "./ui/state";
import { lender, LenderState } from "./lender/state";
import { deals, DealState } from "./deals/state";
import { lendingProfile, LendingProfileState } from "./lendingProfile/state";

import { AppStateInfo } from "./types";
import { lists, ListsState } from "./lists/state";

// Export Actions, Selectors, Types

export * from "./auth/actions";
export * from "./auth/selectors";

export * from "./ui/actions";
export * from "./ui/selectors";

export * from "./lender/actions";
export * from "./lender/selectors";

export * from "./lendingProfile/actions";
export * from "./lendingProfile/selectors";

export * from "./lists/actions";
export * from "./lists/selectors";

export * from "./deals/actions";
export * from "./deals/selectors";

export interface AppState {
  auth: AuthState;
  ui: UIState;
  deals: DealState;
  lender: LenderState;
  lists: ListsState;
  lendingProfile: LendingProfileState;
}

const states: AppStateInfo[] = [
  // Export States
  auth,
  ui,
  deals,
  lender,
  lendingProfile,
  lists,
];
export default states;
